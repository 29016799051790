@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --PropertyGroup_lineHeight: 24px;
  --PropertyGroup_lineThroughTop: calc(var(--PropertyGroup_lineHeight) - 7px);
  --PropertyGroup_lineThroughBottom: calc(var(--PropertyGroup_lineHeight) - 6px);
  --PropertyGroup_lineThroughTopMobile: calc(var(--PropertyGroup_lineHeight) - 9px);
  --PropertyGroup_lineThroughBottomMobile: calc(var(--PropertyGroup_lineHeight) - 8px);
}

.root {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.checkIcon {
  stroke: var(--marketplaceColorDark);
}

.checked {
  fill: var(--marketplaceColorDark);
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  height: var(--PropertyGroup_lineHeight);
}

.labelWrapper {
  display: inline-block;
}

.selectedLabel,
.notSelectedLabel {
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightMedium);
}

.selectedLabel {
  color: var(--marketplaceColorDark);
}

.notSelectedLabel {
  color: var(--matterColorAnti);
}
