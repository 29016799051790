@import '../../marketplace.css';


.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
  padding: 30px 40px;
  @media (max-width: 767px) {
    padding: 0;
    margin-top: 30px;
  }
}
.titleSection {
  padding: 30px 40px;
  border-bottom: 1px solid #e6e6e6;
  @media (max-width: 767px) {
    padding: 0;
    border: 0;
  }
}
.title {
  color: #026786;
  margin: 0 0 10px;
  font-size: 32px;
  line-height: normal;
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: normal;
    font-weight: 700;
  }
}

.titleSection p {
  font-size: 16px;
  font-weight: 500 !important;
  line-height: normal;
  margin: 0 0 15px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
.titleSection p:last-child {
  margin: 0;
}

.priceCurrencyInvalid {
  color: var(--failColor);
}

