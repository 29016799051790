@import '../../marketplace.css';

@keyframes selectedAnimation {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

.root {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 24px 24px 42px 24px;
  background-color: #1aa2ac;

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 81px 0;
    border-top: none;
  }
}

.accordionbox {
  padding-bottom: 4px;  
  margin-bottom: 16px;
  max-width: 516px;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  @media only screen and (max-width: 767px) {
    max-width: 100%;
  }
}
.accordionbox.isActive::after {
  animation: selectedAnimation 5s linear forwards;
}
.accordionbox > div {
  position: relative;
  z-index: 1;
}
.accordionbox::after {
  content: "";
  width: 100%;
  height: 50%;
  background: #1aa2ac;
  left: -100%;
  top: auto;
  position: absolute;
  border-radius: 16px;
  z-index: 0;
  bottom: 0;
}
.accordionbox .accordionTitle {
  padding: 22px 22px 22px 56px;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #222222;
  display: flex;
  position: relative;
  @media only screen and (max-width: 767px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 16px 16px 50px;
  }
}
.accordionbox .accordionTitle span {
  display: block;
  position: absolute;
  padding: 22px;
  left: 0;
  top: 0;
  @media only screen and (max-width: 767px) {
    padding: 16px;
  }
}
.accordionbox .accordionDescription {
  padding: 0 20px 28px 56px;
  @media only screen and (max-width: 767px) {
    padding: 0 16px 16px 50px;
  }
}
.accordionboxWrapper {
  background: #f4f6fa;
  border-radius: 16px;
}
.accordionbox .accordionDescription p {
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}
