@import '../../marketplace.css';

.root {
  display: block;
  flex-direction: column;
  overflow-x: hidden;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}


.superHostLogo {
  margin-right: 5px;
}

.longTextWrapper {
  overflow: hidden;
}

.longText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
}

.superHost {
  padding: 0 52px;
  margin-right: 5px;
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
}


.longText:hover {
  animation: scrolling 10s 0.1s infinite;
  text-overflow: unset;
  overflow: visible;
}

@keyframes scrolling {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  border-radius: 10px;
  /* transition: var(--transitionStyleButton); */

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

.listingImgWrapper {
  padding-bottom: 66.6667%;
  background: var(--matterColorNegative);
  border-radius: 10px;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 10px;
}

.titleWrapper {
  /* overflow: hidden; */
  max-width: 270px;
}

.listingTitle {
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
}

.leftInfos {
  flex-basis: 80%;
  width: 80%;
}

.rightInfos {
  flex-basis: 20%;
  width: 20%;
}

.infos {
  padding: 15px 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
}

.rightInfos {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.price {
  margin-top: 5px;
  font-size: 18px;
  line-height: 22px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
}

.highlightPrice {
  font-weight: var(--fontWeightBold);
}
.instantBooking {
  padding: 0 8px;
  background-color: var(--instantBookingLabelColor);
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
}

.star {
  width: 10px;
  height: 10px;
  fill: var(--marketplaceColorDark);
}

.ratingWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rating {
  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
  margin-left: 5px;
}

.doneTrips {
  font-size: 12px;
  line-height: 14px;
  font-weight: 100;
  color: var(--marketplaceColorDark);
}

.logo {
  position: relative;
  display: inline-block;
}

.logo:hover > .hintText {
  display: block;
}

.hintText {
  font-size: 12px;
  display: none;
  position: absolute;
  z-index: 10000;
  color: var(--matterColorAnti);
  border: 1px solid var(--matterColorNegative);
  border-radius: 5px;
  background-color: var(--matterColorLight);
  font-weight: normal;
  line-height: 16px;
  padding: 15px 25px;
  left: 0px;
  bottom: 0px;
  margin-bottom: 25px;
  width: 275px;
  box-shadow: 3px 3px 10px var(--matterColorNegative);

  &::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 10px;
    margin-left: 10px;
    border-width: 8px;
    border-style: solid;
    border-color: var(--matterColorLight) transparent transparent transparent;
  }
}

.instantBooking {
  height: 26px;
  width: 79px;
  background-color: var(--instantBookingLabelColor);
  color: var(--matterColorLight);
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.superHost {
  height: 26px;
  width: 94px;
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logos {
  font-size: 18px;
  line-height: 32px;
  color: var(--matterColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drivelahGoLogo {
  margin-right: 5px;
}

.logoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flexLogo {
  position: absolute;
  top: 6px;
  left: 10px;
  z-index: 1;

  @media (--vieewportLarge) {
    top: 24px;
    left: 24px;
  }
}
