@import '../../marketplace.css';

.root {
  
  @media (--viewportLarge) {
    max-width: 550px;
  }
}

.inputField {
  margin-bottom: 30px;
}

.readMore {
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorNear);
  margin-bottom: 0px;
}

.learnMore {
  font-size: 16px;
  line-height: 20px;
  color: var(--marketplaceColor);
}

.previewFile {
  margin-left: 0px !important;
  margin-right: 20px;
}