@import '../../marketplace.css';

.root {
}

.card {
  margin-top: 20px;

  @media (--viewportLarge) {
    margin-top: 40px;
  }
}

button.submit{
  border-radius: 6px !important;
  margin-top: 45px;
  background-color: #ffcd05;
  color: #026786;
  text-transform: none;
  font-size: 28px;
  font-weight: 900;
  &:hover {
    background-color: #ffcd05;
  }
  @media (--viewportMedium) {
    max-width: 358px !important;
    margin: 20px auto 0;
  }

  @media (--viewportLarge) {
  }
}

.termAndConditions {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  & a {
    text-decoration: underline;
  }
}

.card {
  @apply --marketplaceInputStyles;

  /* Layout */
  padding: 8px 0 6px 0;
  height: 36px;

  /* Border */
  border-bottom-color: var(--attentionColor);

  @media (--viewportMedium) {
    height: 38px;
  }
  @media (--viewportLarge) {
    height: 38px;
    padding: 6px 0 14px 0;
  }
}

.cardSuccess {
  border-bottom-color: var(--successColor);
}

.cardError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 0 0 12px 0;
  /* color: var(--matterColorAnti); */

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 16px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentMethodSelector {
  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 48px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.selectViroQ {
  margin-bottom: 40px;
  & .optionLabel {
    border-bottom: 1px solid #EEE;
  }
}

.selectViroQLabelTitle {
  font-size: 32px;
  font-weight: 900;
  color: var(--marketplaceColorDark);
  padding-bottom: 25px;
  @media (--viewportMedium) {
    font-size: 40px;
  }
}

.selectViroQDescription {
  font-size: 18px;
  font-weight: 500;
  padding-left: 25px;
  padding-bottom: 10px;
}

span.selectViroQLabel {
  font-size: 22px;
  font-weight: 700;
  color: #000000;
  & s {
    font-weight: 500;
    color: #AAAAAA;
  }
  @media (--viewportMobile) {
    font-size: 20px;
  }
}

.textField {
  margin-bottom: 14px;
  margin-top: 4px;
  @media (--viewportLarge) {
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.paymentLabel {
  margin: 0;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 2px;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 36px 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;

  @media (--viewportLarge) {
    margin-top: 72px;
  }
}

.paymentInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  padding: 0 42px;
  margin: 28px 0 0 0;

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0;
  }
}

.submitButton {
  margin-top: 22px;
  
  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 17px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}

.form {
  /* margin-top: 15px; */
}

.formWrapper {
  margin-bottom: 30px;
  @media (--viewportLarge) {
    display: flex;
    justify-content: space-around;
  }
}

.smallText {
  @apply --marketplaceTinyFontStyles;
}

.error {
  /* position: absolute; */
  right: 0;
}

.selectInputWrapper{
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
}
