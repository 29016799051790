@import '../../marketplace.css';

.root {
  width: 100%;
  height: 100%;
  background-color: var(--matterColorNegative);
}

.mapRoot {
  width: 100%;
  height: 100%;
}


.customLabelWithText {
  width:180px;
  height: fit-content;
  font-size: 14px;
  background-color: white;
  color: #4A4A4A;
  border-radius: 6px;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  margin-top: 35px !important;
  position: relative;
}

.customWithOutTextLabel{
  width:180px;
  display: inline-flex;
  margin-top: 35px !important;
  position: relative;}

.customLabel {
  margin-left: -135px !important;
}

.arrowDown {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  top: 2px;
  right: 36px;
  margin-top: 30px;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  border-bottom: 10px solid rgb(250, 250, 250);
  transform: translateY(-50%);
}

.arrowTop {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  right: 36px;
  margin-top: 23px;
  margin-left: 2px;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  border-top: 10px solid #0aa3ad;
  transform: translateY(-50%);
}
