@import '../../marketplace.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: auto;

  @media (--viewportMedium) {
    flex-basis: 576px;
    /* height: 100%; */
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.error {
  @apply --marketplaceMessageFontStyles;
  color: var(--failColor);
  display: inline-block;
  font-size: 12px;
  text-align: center;
  margin: 0;
  flex-shrink: 0;
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: -2px;
    margin-bottom: 33px;
  }
}

.bookingTitle {
  /* Font */
  color: var(--marketplaceColorDark);

  margin-top: 0;
  margin-bottom: 9px;
}

.bookingHelp {
  display: none;
  @apply --marketplaceH5FontStyles;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0 84px 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 10px 24px 0px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openBookingFormWrapper {
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 0px 13px 2px 10px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  display: flex;
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
  align-self: center;
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookButton {
  @apply --marketplaceButtonStylesPrimary;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  font-size: 12px;
  //margin: 10px 10px 0px 10px;
  margin: 0;
  flex-shrink: 0;
}

.smallPrintForMember {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColorDark);
  font-size: 12px;
  text-align: center;
  //margin: 10px 10px 10px 10px;
  margin: 0;
  flex-shrink: 0;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.loadingTimeSlotWrapper {
  min-height: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.updateBookingTitle {
  color: var(--marketplaceColorDark);
  font-size: 24px;
}

.updateBookingSubTitle {
  color: var(--matterColor);
  font-size: 16px;
  line-height: 22px;
}

.updateBookingPanel {
  @media (--viewportMedium) {
    background: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    margin: 0;
    padding: 16px 48px 48px 48px;
    border-radius: 4px;
  }
}
