@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.card {
  @apply --marketplaceInputStyles;

  /* Layout */
  padding: 8px 0 6px 0;
  height: 36px;

  /* Border */
  border-bottom-color: var(--attentionColor);
  border: 0;
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 15px;

  &:focus,
  &:hover {
    border: 1px solid #e6e6e6;
  }
}

.cardSuccess {
  border-bottom-color: var(--successColor);
}

.cardError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);
  color: #026786;
  padding-top: 4px;
  padding-bottom: 2px;
  font-size: 24px;
  line-height: 1.2;
  @media (--viewportMedium) {
    margin: 0 0 16px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 1.2;
  }
}

.stripeHeading {
  text-align: left;
  font: normal normal normal 18px/24px Museo Sans Rounded 500;
  letter-spacing: -0.11px;
  color: #4a4a4a;
  font-size: 18px;

  /* @media (--viewportMobile) {
    font-size: 18px;
    line-height: 1.2;
  } */
}

.stripeHeadingNewAddress {
  text-align: left;
  font: normal normal normal 18px/24px Museo Sans Rounded 500;
  letter-spacing: -0.11px;
  color: #4a4a4a;
  font-size: 18px;

  /* @media (--viewportMobile) {
    font-size: 18px;
    line-height: 1.2;
  } */
}


.paymentMethodSelector {
  margin-bottom: 15px;
}

.saveForLaterUse {
  display: none;
  padding-top: 6px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 48px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.deliveryContainer {
  padding: 15px 0;
}

.deliveryCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.deliveryLabel {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingHeading {
  margin: 0 0 10px 0;
  color: var(--matterColorAnti);
  font-size: 18px;
  line-height: normal;
  color: #026786;
  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentLabel {
  margin: 0;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: normal;
  color: #026786;
  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 2px;
  }
  @media (--viewportMobile) {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: normal;
    color: #026786;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 0 0 20px;
  color: #026786;
  font-size: 24px;
  line-height: 1.2;
  @media (--viewportMedium) {
    margin: 0 0 20px;
  }
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 1.2;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;

  @media (--viewportLarge) {
    margin-top: 0;
  }
}

.paymentInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  padding: 0 42px;
  margin: 28px 0 0 0;

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0;
  }
}

.submitButton {
  margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 17px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}

.longTermHintText {
  margin-top: 14px;
  color: var(--matterColorAnti);
  margin-top: 18px;
}

.cardOuter {
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  margin-bottom: 30px;
  padding: 20px;
  position: relative;
  background: #FFFFFF;
  @media (--viewportMobile) {
    border: 0;
    background: white;
  }
}
.discountHeading {
  color: var(--matterColorAnti);
  margin: 0 0 20px;
  color: #026786;
  font-size: 24px;
  line-height: 1.2;
  @media (--viewportMedium) {
    margin: 0 0 20px;
  }
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 1.2;
  }
}
.discountBlock ul {
  margin: 0;
}
.discountBlock li {
  padding: 15px 0;
  border-bottom: 1px solid #e6e6e6;
}
.discountBlock li:last-child {
  border-bottom: 0;
}
.discountBlock .customRadio input[type='radio'] {
  position: absolute;
  left: -99999px;
}
.discountBlock .customRadio input[type='radio'] + label {
  padding: 0 0 0 30px;
  font-size: 20px;
  color: #026786;
  line-height: 1.2;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 1.2;
  }
}
.discountBlock .customRadio input[type='radio'] + label:before {
  content: '';
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
  left: 0;
  top: 3px;
  @media (--viewportMobile) {
    top: -1px;
  }
}
.discountBlock .customRadio input[type='radio']:checked + label:before {
  box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 1);
  background: #026786;
}

.formInputNew input,
.formInputNew select,
.formInputNew textarea {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  line-height: normal;
  padding: 15px;
  box-sizing: border-box;
}
.formInputNew input:hover,
.formInputNew select:hover,
.formInputNew textarea:hover {
  border: 1px solid #e6e6e6;
}
.formInputNew label {
  color: #4a4a4a;
  font-weight: 300;
}

.submitSection {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  background: #ffffff;
  z-index: 99;
}
.submitSectionInner {
  max-width: 1230px;
  margin: 0 auto;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (--viewportMobile) {
    padding: 5px 20px;
  }
}
.submitSectionPrice {
  text-align: center;
  font-size: 12px;
  line-height: normal;
  color: #b2b2b2;
}
.submitSectionPrice .totalAmount {
  text-align: center;
  display: block;
  font-size: 28px;
  line-height: normal;
  color: #00a3ad;
  font-weight: 900;
}
.submitSectionPrice button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 300px;
  font-size: 20px;
  line-height: 1;
  color: #026786;
  border-radius: 4px;
  background-color: #ffcd05;
  text-transform: capitalize;
  @media (--viewportMobile) {
    height: 48px;
    min-height: 48px;
    width: 190px;
    font-size: 18px;
  }
}
.submitSectionPrice button:hover {
  opacity: 0.85;
  background-color: #ffcd05;
}

.stripeHeading {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.stripeHeadingNewAddress {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}
.stripeHeading p {
  padding-left: 0;
  padding-right: 10px;
  font-weight: 500;
  margin: 0;
  display: flex;
  align-items: center;
  color: #4A4A4A;
  @media (--viewportMobile) {
    flex: calc(100% - 150px);
    max-width: calc(100% - 148px);
    font-size: 15px;
    line-height: normal;
  }
}

.stripeHeadingNewAddress p {
  padding-left: 0;
  padding-right: 10px;
  font-weight: 500;
  margin: 0;
  display: flex;
  align-items: center;
  color: #4A4A4A;
  @media (--viewportMobile) {
    flex: calc(100% - 150px);
    max-width: calc(100% - 148px);
    font-size: 15px;
    line-height: normal;
  }
}
.stripeHeading p strong {
  font-weight: 900;
}

.stripeHeadingNewAddress p strong {
  font-weight: 900;
}

.privacyIcon {
  width: 22px;
  height: 22px;
  flex-basis: 22px;
  max-width: 22px;
  display: block;
  flex-basis: 22px;
  max-width: 22px;
  display: block;
  min-width: 22px;
  margin-right: 10px;
}
.privacyIcon img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}
.stripeIcon {
  width: 150px;
  flex-basis: 150px;
  max-width: 150px;
}
