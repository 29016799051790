@import '../../marketplace.css';

.root {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--zIndexTopbar);
}
