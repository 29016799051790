@import '../../marketplace.css';

.root {
  position: relative;
}

.input {
  border-bottom-color: var(--inputBorderBottomColor);
}

.inputSuccess {
  border-bottom-color: var(--inputBorderBottomColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.suggestField {
  position: absolute;
  z-index: 999;
  top: 100%;
  left: 0;
  right: 0;
  line-height: 1.5em;
  color: var(--marketplaceColorDark);
  border: 1px solid var(--matterColorAnti);
  border-radius: 5px;
  background-color: var(--matterColorLight);
  max-height: 372px;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  min-width: 400px;
  width: 100%;
  border-radius: 16px;
  background: #FFFFFF;
  border: 0;
  @media only screen and (max-width: 767px) {
    min-width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    top: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-height: 75%;
    height: 100%;
  }
  
}
.suggestField .title {
  position: relative;
  font-size: 12px;
  line-height: normal;
  padding: 14px 16px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.6);
  color: #7C7C7C;
  font-weight: 400;
  display: none;
  @media only screen and (max-width: 767px) {
    display: block;
  }
}
.suggestField .title button{
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  transform: translateY(-50%);
  top: 50%;
  display: block;
  border: 0;
}
.suggestField .title button span{
  position: absolute;
  width: 12px;
  height: 1px;
  bottom: 0;
  display: block;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #1a1a1a;

}
.suggestField .title button span:first-child{
  left: 0;
  transform: translateY(-50%) rotate(-45deg);
}
.suggestField .title button span:last-child{
  right: 0;
  transform: translateY(-50%) rotate(45deg);
}

.optionField {
  cursor: pointer;
  padding: 5px 20px;
  transition: 0.3s;
  font-size: 18px;
  line-height: 1.2;
  padding: 12px 20px;
  color: #222222;
  font-weight: 400;
  text-align: left;
  &:hover, &:focus  {
    background: #F8CE47;
    font-weight: 600;
  }
}

.selectionWrapper {
  position: relative;
  white-space: nowrap;
  width: auto;
  cursor: pointer;
}
.selectionWrapper input {
  position: absolute;
  opacity: 0;
}