@import '../../marketplace.css';

.root {
  background-color: white;
}

.banner {
  width: 100%;
  height: auto;
}

.sectionBrowseCarDesktop {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

.sectionBrowseCarMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.sectionHeroBg {
  color: var(--marketplaceColorDark);
  font-size: 16px;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 20px;
  }

  & .title {
    color: var(--marketplaceColor);
    font-weight: 900;
    font-size: 36px;
    line-height: 1.2;
    text-align: center;
    margin-top: 25px;
  }

  & .sectionHeroExcerptMobile {
    text-align: center;
    margin: 10px 20px;
    @media (--viewportMedium) {
      display: none;
    }
  }

  & .sectionHeroExcerpt {
    text-align: center;
    margin: 10px 20px;
  }

  & .sectionHeroExcerptDesktop {
    text-align: center;
    margin: 10px 20px;
    font-size: 20px;
    display: none;
    @media (--viewportMedium) {
      display: block;
    }
  }

  @media (--viewportMedium) {
    margin: 0 40px;
    & .descriptionWrapper {
      display: flex;
      margin-top: 35px;
    }

    & .heroSectionEnrolButton{
      display: none;
    }
  }

  @media (--viewportLMLarge) {
    margin: 0 153px;
    & .descriptionWrapper {
      display: flex;
      margin-top: 35px;
    }

    & .heroSectionEnrolButton{
      display: none;
    }
  }
}

.wrapperLabel {
  position: relative;
  margin: 0 24px;
  top: -30px;
  margin-top: 64px;
  @media (--viewportMedium) {
    position: relative;
    margin-top: 0;
    top: -24px;
    width: 80%;
    margin: auto;
  }

  & .numberDays {
    background-color: var(--marketplaceSecondaryColor);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    z-index: 3;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -5px;
    & .number {
      font-size: 30px;
      font-weight: 900;
    }
    & .day {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 16px;
    }

    @media (--viewportMedium) {
      width: 75px;
      height: 75px;
      & .number {
        font-size: 42px;
      }
      & .day {
        font-size: 20px;
      }
    }
  }
  & .textLabel {
    color: var(--marketplaceSecondaryColor);
    font-size: 36px;
    font-weight: 900;
    text-transform: uppercase;
    background-color: var(--marketplaceColor);
    border-radius: 10px;
    padding: 18px 10px;
    z-index: 2;
    padding-left: 52px;
    margin-left: 24px;

    @media (--viewportMedium) {
      padding: 25px 10px;
      padding-left: 60px;
      font-size: 25px;
    }
  
    @media (--viewportLarge) {
      padding-left: 60px;
      font-size: 40px;
    }

    @media (--viewportLMLarge) {
      padding: 25px 10px;
      padding-left: 70px;
      font-size: 44px;
    }
  }
}

.usefulViroQ {
  background-color: #F0EFEF;
  & .sectionHeroExcerpt {
    margin-top: -6px;
  }
  @media (--viewportMedium) {
    width: 50%;
    margin-top: 35px;
    border-radius: 8px;
    padding-bottom: 18px;
  }
}

.enrolButton {
  margin: auto;
  border-radius: 8px;
  width: 94%;
  & .enrolAndBuy {
    font-size: 30px;
    font-weight: 900;
    text-transform: none;
    padding-top: 15px;
  }

  & .price {
    font-size: 24px;
    font-weight: 900;
    padding: 15px 0;
  }
}

.sectionHeroWhyBuy {
  text-align: center;
  padding: 10px 0 35px 0;
  color: var(--marketplaceColorDark);
  font-size: 16px;
}

.sectionHeroDescription {
  display: none;
  @media (--viewportMedium) {
    display: block;
    width: 50%;
    padding-right: 50px;
    font-size: 20px;
    & .description1 {
      margin-bottom: 15px;
    }
  }
  @media (--viewportLarge) {
    padding-right: 100px;
  }
}

.sectionHowToEnrolMobile {
  @media (--viewportMedium) {
    display: none;
  }
  & .sectionHowToEnrol {
    color: var(--marketplaceColorDark);
    margin: 15px 20px;
    & .title {
      font-size: 36px;
      font-weight: 900;
      margin: 20px 0;
      text-align: center;
    }
  
    & .why60DaysWrapper {
      display: flex;
      margin-top: 25px;
      & .why60Days {
        margin-left: 21px;
        font-size: 16px;
        & .why60DaysTitle {
          font-size: 30px;
          font-weight: 900;
          margin-bottom: 14px;
        }
        & .why60DaysDesciption{
          line-height: 19px;
        }
      }
    }
  }
}

.sectionHowToEnrolDesktop {
  display: none;
  margin: 60px 126px;
  color: var(--marketplaceColorDark);
  @media (--viewportMedium) {
    display: flex;
  }
  & .sectionHowToEnrol {
    margin-bottom: 20px;
    margin-right: 20px;
    & .title {
      font-size: 36px;
      font-weight: 900;
      line-height: 1.2;
      margin-bottom: 20px;
    }
  }

  & .why60DaysWrapper {
    margin-left: 20px;
    & .why60Days {
      margin-left: 21px;
      font-size: 20px;
      & .why60DaysTitle {
        font-size: 30px;
        font-weight: 900;
        margin-bottom: 14px;
      }
      & .why60DaysDesciption{
        line-height: 24px;
        padding-bottom: 15px;
      }
    }
  }

}

.listFeature {
  margin-bottom: 20px;
  display: flex;
  align-items: end;
  & .listDescription {
  }
  
  & .listIcon {
    margin-right: 10px;
  }
}

.viewCertifications {
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  margin-top: -13px;
  margin-bottom: 30px;
}

.sectionGuideToDisinfect {
  color: #FFF;
  background-color: var(--marketplaceColorDark);
  padding: 0 20px;
  @media (--viewportMedium) {
    display: none;
  }
  & .title {
    color: var(--marketplaceSecondaryColor);
    font-size: 36px;
    font-weight: 900;
    margin: 32px 0;
    line-height: 40px;
  }

  & .viroQProductTitle {
    font-size: 30px;
    font-weight: 900;
    margin-top: 36px;
    margin-bottom: 30px;
  }
}

.sectionHowToApplyViroQ {
  color: #FFF;
  background-color: var(--marketplaceColor);
  padding: 0 126px;
  display: none;
  counter-reset: howToApply;
  @media (--viewportMedium) {
    display: block;
  }

  @media (--viewportLarge) {
    display: flex;
  }
  
  & .title {
    font-size: 36px;
    font-weight: 900;
    margin: 32px 0;
    line-height: 40px;
  }

  & .listStep {
    display: flex;
    align-items: start;
    font-size: 16px;
    line-height: 16px;
    padding-bottom: 25px;
    &:before {
      counter-increment: howToApply;
      content: counter(howToApply);
      font-weight: 900;
      font-size: 30px;
      margin-right: 8px;
      line-height: 24px;
    }
  }

  & .sectionHowToApplyViroQWrapper {

  }
  & .stepWrapper {
    display: flex;
    margin-right: 60px;

    @media (--viewportLMLarge) {
      margin-right: 120px;
    }
    & .stepLeft {
      flex-basis: 50%
    }
    & .stepRight {
      flex-basis: 50%;
    }
  }

  & .viroQProductTitle {
    font-size: 30px;
    font-weight: 900;
    margin-top: 36px;
    margin-bottom: 30px;
  }
}

.videoContentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    display: block;
  }
}

.iframeVideo {
  width: 100%;
  height: 40%;

  @media (--viewportMedium) {
    height: 100%;
  }
}

.viroQProduct {
  margin-bottom: 46px;
  & .video {
    width: 100%;
    background-color: var(--matterColorAnti);
    cursor: pointer;
    margin: auto;
    border-radius: 8px;
    @media (--viewportMedium) {
      width: 500px;
      /* height: 280px; */
    }
  
    @media (--viewportLarge) {
      width: 412px;
      /* height: 150px; */
    }
  }
  
  & .videoLogo {
    width: 100%;
  }
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 40px 0 0 0;

  @media (--viewportMedium) {
    padding: 0px;
    flex-basis: 576px;
    min-height: 0;
    min-width: 80vw;
    height: 48vw;
    margin-top: calc(50vh - 30vw);
    border-bottom: 0px solid transparent;
  }

  @media (--viewportLarge) {
    margin-top: calc(50vh - 25vw);
    min-width: 80vw;
    height: 48vw;
  }

  @media (--viewportMLarge) {
    margin-top: calc(50vh - 20vw);
    min-width: 66vw;
    height: 40vw;
  }
}

.modalCloseButton {
  top: 0px;
  right: 0px;
  padding: 10px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    color: var(--matterColorLight);    
    top: -40px;
  }

  &:hover {
    color: var(--matterColorAnti) !important;
  }
}

.contentModal {
  height: 100%;
}

.sectionBrowseCar {
  padding: 20px 18px;

  @media (--viewportLarge) {
    margin-top: 54px;
  }

  & .listings {
    margin-top: 30px;
  
    @media (--viewportMLarge) {
      max-width: 1072px;
      margin: 30px auto 0;
    }
  }

  & :global(.slick-slide) {
    padding-right: 24px;

    &:last-child {
      padding-right: 0;
    }
  }
}

.btnBookNow {
  margin-top: 25px;
  text-align: center;
  & .text {
    display: block;
    color: var(--marketplaceColorDark);
    font-size: 30px;
    background-color: var(--marketplaceSecondaryColor);
    font-size: 30px;
    font-weight: 900;
    text-transform: none;
    padding: 18px 0;
    width: 100%;
    border-radius: 8px;
    @media (--viewportMedium) {
      width: 270px;
      display: inline-block;
    }
  }
  & a:hover {
    text-decoration: none;
  }
}

.button {
  display: block;
  background-color: var(--marketplaceSecondaryColor);
  color: var(--marketplaceColorDark);
  text-align: center;
  cursor: pointer;
  &:hover {
    outline: none;
    background-color: #c79d00;
    text-decoration: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }
}

