@import '../../marketplace.css';

.root {
  display: none;
  background-color: var(--matterColorLight);
  align-items: center;
  padding: 6px 10px;
  border-radius: 25px;
  border: 1px solid rgba(234,234,234, 0.6);

  @media (--viewportMedium) {
    display: flex;
    /* border: none; */
  }
}

.questionMark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: var(--marketplaceColor);
  border-radius: 50%;
  font-size: 16px;
  font-weight: 900;
  color: var(--matterColorLight);
  cursor: help;
  
  @media screen and (min-width: 600px) {
    width: 40px;
    height: 40px;
    font-size: 35px;
  }
}

.telLink {
  &:hover {
    text-decoration: none;
  }
}

.telLinkWhite {
  color: var(--matterColorLight);
}

.moreinfoText {
  font-size: 14px;
  font-weight: bold;
  color: var(--marketplaceColor);
  margin-left: 5px;

  @media screen and (min-width: 600px) {
    font-size: 20px;
  }
}