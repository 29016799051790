@import '../../marketplace.css';



.verificationIndication p {
    color: #4A4A4A !important;
    font-size: 18px !important;
    font-weight: 500 !important;

    @media (--viewportMobile) {
        font-size: 15px !important;
    }

}



.continueVerificationButton {
    margin-top: 50px;
}

.continueVerificationButton button {
    background-color: #fecd2a;
    min-height: 50px;
    border-radius: 4px;
    text-transform: none;
}
    
.continueVerificationButton button:hover {
background-color: #fecd2a;
color: #0d6786;
}

.continueVerificationButton button span {
    font-weight: 700;
    font-size: 18px;
    color: #026786;
}

.stepTitle span {
    color: #4A4A4A;
    font-weight: 500;
    font-size: 18px !important;
    margin-left: 10px;
}

.stepsContainer {
    width: 45%;
    margin-top: 20px;

    @media (--viewportMobile) {
        width: 100% !important;
    }
}



.singleStepContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.titleIconContainer {
    display: flex;
    flex-direction: row;
}

.stepIconContainer {
    width: 25px;
}

.titleContainer {
    width: 200px;
}