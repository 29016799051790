.subscriptionAddons {
    margin: 0 auto;
  }
  
  h3 {
    margin-bottom: 15px;
  }
  
  .addonsOptions {
    display: flex;
    gap: 15px;

    @media (--viewportMobile) {
      display: block;
    }
  }
  
  .addonOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    cursor: pointer;
    min-width: 250px;
    transition: border-color 0.3s;
    width: 100%;
    position: relative;
    max-width: 50%;

    @media (--viewportMobile) {
      max-width: 100%;
      margin-top: 14px;
    }
  }

  
  .selected {
    border-color: #026786;
    background-color: #00A3AD0D;
  }
  
  .disabled {
    color: #999;
    background-color: #f9f9f9;
    cursor: not-allowed;
  }
  
  .addonOption span {
    font-size: 14px;
    color: #008080;
  }

  .addonOption {
    @media (--viewportMedium) {
      margin-top:  14px  
    }
  }
  
  .disabled span {
    color: #999;
  }
  
  .comingSoonTag {
    background-color: #ccc;
    color: white;
    padding: 3px 6px;
    font-size: 12px;
    margin-right: 10px;
    border-radius: 3px;
  }
  
  .radioCircle {
    width: 20px;
    height: 20px;
    border: 2px solid #00A3AD;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .radioSelected {
    width: 10px;
    height: 10px;
    background-color: #008080;
    border-radius: 50%;
  }
  
  .badgeContainer {
    position: absolute;
    font-size: 2px;
    top: -10px;
    left: 5px;
    background-color: #E8E8E8;
    color: #7C7C7C;
    max-height: 22px;
    border-radius: 4px;
    height: 18px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }

  .badgeContent {
    color: #7C7C7C;
    font-size: 12px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .disabled {
    cursor: not-allowed;   /* Changes the cursor to indicate non-interactiveness */
  }