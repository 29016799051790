@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --DateInput_selectionHeight: 45px;
  --DateInput_hoveredOverlayColor: rgba(255, 255, 255, 0.2);
  --selectedColor: #fecd2a54;

  /* Distance to top */
  --DateInput_top: 252px;
}

.root {
  /* Contain repainting to this component only */
  transform: translate3d(0, 0, 0);
  height: 315px;
  width: 100%;

  /* Override react-dates default styles to match input styles */

  & :global(.SingleDatePicker) {
    display: block;
  }

  & :global(.DayPickerNavigation) {
    display: block;
    width: 30px;
    height: 0;
  }

  & :global(.SingleDatePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 36px !important;
    width: 100%;
    min-height: calc(100vh - var(--DateInput_top));
    background-color: transparent;

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 40px !important;
      min-height: auto;
      box-shadow: var(--boxShadowPopup);
      border-radius: 0 0 2px 2px;
    }
  }

  & :global(.DateInput_input) {
    @apply --marketplaceDefaultFontStyles;
    @apply --marketplaceInputStyles;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: var(--marketplaceColor);
    transition: border-bottom-color var(--transitionStyle);
    transition: all 0.15s ease-out;
    background: none;

    @media (--viewportMedium) {
      padding: 4px 0 9px 0;
      border-bottom-width: 3px;
    }
  }
  & :global(.SingleDatePickerInput) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }
  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    background-color: transparent;
    box-shadow: none;
  }

  & :global(.DayPickerNavigation__horizontal) {
    position: relative;
    width: 100%;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 0;
    position: absolute;
    cursor: pointer;
    display: inline;

    &:first-of-type {
      left: 24px;
    }

    &:last-of-type {
      right: 24px;
    }
  }

  & :global(.DayPickerNavigation_button) {
    color: var(--marketplaceColorDark);
    border: 0;
  }
  & :global(.SingleMonthCalender_root__8nuRU .CalendarDay){
    height: 40px !important;
  }
  & :global(.CalendarMonth),
  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }
  & :global(.DateInput) {
    display: block;
    width: 100%;
  }
  & :global(.DayPicker_weekHeader) {
    color: var(--marketplaceColorDark);
    top: 35px;
  }

  & :global(.DayPicker_weekHeader_li) {
    font-weight: 100;
  }

  & :global(.CalendarMonth_caption) {
    color: var(--marketplaceColorDark);
    font-size: 20px;
    line-height: 24px;
    font-weight: var(--fontWeightLight);
    text-align: center;
    padding: 2px 0 37px;
    font-weight: 100;
    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.CalendarDay__default) {
    background-color: transparent;
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  & :global(.CalendarDay) {
    color: var(--marketplaceColorDark);
    font-size: 16px;
    line-height: 19px;
    font-weight: var(--fontWeightSemiBold);
    border: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 100;
    height: 40px !important;
    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
    background-color: transparent;
  }
  & :global(.CalendarDay__hovered_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay__selected_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    background-color: var(--selectedColor);
    transition: all 0.2s ease-out;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected) {
    background-color: transparent;
    background-image: none;
  }
  & :global(.CalendarDay__selected .leftSide),
  & :global(.CalendarDay__selected .rightSide) {
    /* width: 100%; */
    height: 36px;
    background-color: var(--selectedColor);
    /* border-radius: calc(var(--DateInput_selectionHeight) / 2); */
  }
  & :global(.CalendarDay__selected_end .dayWrapper) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--marketplaceSecondaryColor);
    height: 36px;
    width: 36px;
    border-radius: 4px;
    z-index: 1;
  }
  & :global(.CalendarDay__selected_end .renderedDay) {
    /* background-color: var(--selectedColor); */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    font-weight: 700;
    margin: 0 auto;
    /* &::after {
      position: absolute;
      content: ' ';
      top: 0;
      right: 0;
      bottom: 0;
      width: 22.5px;
      height: 32px;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      z-index: 0;
      background-color: #fff;
    } */
  }

  & :global(.CalendarDay__selected .renderedDay) {
    /* background-color: var(--selectedColor); */
    /* border-top-left-radius: 16px;
    border-bottom-left-radius: 16px; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    font-weight: 700;
    /* &::before {
      position: absolute;
      content: ' ';
      top: 0;
      left: 0;
      bottom: 0;
      width: 22.5px;
      height: 32px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      z-index: 0;
      background-color: #fff;
    } */
  }

  & :global(.CalendarDay__selected .dayWrapper) {
    background-color: var(--marketplaceSecondaryColor);
    height: 36px;
    width: 36px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__after-hovered) {
    background-color: transparent;
  }
  & :global(.CalendarDay__after-hovered .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  /* & :global(.CalendarDay:hover .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  } */
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_out_of_range),
  & :global(.CalendarDay__blocked_out_of_range:active),
  & :global(.CalendarDay__blocked_out_of_range:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
    opacity: 0.5;
    font-weight: 100;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_calendar),
  & :global(.CalendarDay__blocked_calendar:active),
  & :global(.CalendarDay__blocked_calendar:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }
  & :global(.CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay) {
    background-color: transparent;
  }
  & :global(.DateInput_fang) {
    display: none;
  }
  & :global(.CalendarMonth_caption) {
    text-transform: capitalize;
  }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.withMobileMargins {
  & :global(.SingleDatePickerInput) {
    width: calc(100vw - 48px);
    margin: 0 24px;

    @media (--viewportMedium) {
      width: 100%;
      margin: 0;
    }
  }

  & :global(.SingleDatePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 34px !important;
    border-top: 2px solid var(--matterColorDark);
    min-height: calc(100vh - var(--DateInput_top) + 2px);

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 40px !important;
      border-top: 0;
      min-height: auto;
    }
  }

  /* Create gutter between inputs */
  & :global(.DateInput) {
    width: 100%;
    background: none;
  }
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
}


.CalendarMonthGrid, .CalendarMonthGrid_1, .CalendarMonthGrid__horizontal, .CalendarMonthGrid__horizontal_2 {
    width: 100%;
}

.DayPicker, .DayPicker_1, .DayPicker__horizontal, .DayPicker__horizontal_2 {
    width: 100% !important;
}

.DayPicker_transitionContainer, .DayPicker_transitionContainer_1, .DayPicker_transitionContainer__horizontal, .DayPicker_transitionContainer__horizontal_2 {
    width: 100% !important;
}

.CalendarMonthGrid_month__hidden, .CalendarMonthGrid_month__hidden_2 {
    display: none !important;
}

.CalendarMonthGrid, .CalendarMonthGrid_1, .CalendarMonthGrid__horizontal, .CalendarMonthGrid__horizontal_2 {
    width: 100% !important;
}

.CalendarMonthGrid_month__horizontal, .CalendarMonthGrid_month__horizontal_1 {
    width: 33% !important;
}

.DayPickerKeyboardShortcuts_buttonReset, .DayPickerKeyboardShortcuts_buttonReset_1, .DayPickerKeyboardShortcuts_show, .DayPickerKeyboardShortcuts_show_2, .DayPickerKeyboardShortcuts_show__bottomRight, .DayPickerKeyboardShortcuts_show__bottomRight_3 {
    margin-right: 15px;
    display: none;
}

.DayPicker_weekHeaders, .DayPicker_weekHeaders_1, .DayPicker_weekHeaders__horizontal, .DayPicker_weekHeaders__horizontal_2 {
    width: 100% !important;
}

.DayPicker_weekHeader, .DayPicker_weekHeader_1:first-child {
    width: 33% !important;
    left: 0 !important;
    /*position: static !important;*/
}
.DayPicker_weekHeader, .DayPicker_weekHeader_1:nth-child(even) {
    width: 33% !important;
    left: 355px !important;
    /*position: static !important;*/
}
.DayPicker_weekHeader, .DayPicker_weekHeader_1:last-child {
    width: 33% !important;
    left: 710px !important;
    /*position: static !important;*/
}



.DayPicker_weekHeaders, .DayPicker_weekHeaders_1, .DayPicker_weekHeaders__horizontal, .DayPicker_weekHeaders__horizontal_2 > div {
    width: 100% !important;
}

#datepicker-container > div > div > div {
    width: 100% !important;
}