@import '../../marketplace.css';

.root {
  width: 100%;
  display: flex;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingpage/readyToGoMobile-min.jpg');
  background-size: 100% 120%;
  background-position: bottom left;
  height: 300px;
  justify-content: center;

  @media (--viewportMedium) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingpage/readyToGo.jpg');
    background-size: cover;
    background-position: center;
    height: 380px;
  }

  @media (--viewportLarge) {
    height: 380px;
  }
}

.textWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('');
  background-size: cover;
  background-position: center;
  height: 300px;
  padding: 80px 20px 30px;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding: 0px;
    height: 380px;
    max-width: 100%;
    margin: 0px 36px;
    justify-content: center;
  }

  @media (--viewportLarge) {

  }

  @media screen and (min-width: 1200px) {
    max-width: 1100px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);
  font-weight: 900;
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 10px;
  line-height: 32px;

  @media (--viewportMedium) {
    font-size: 38px;
    line-height: normal;
    margin-bottom: 36px;
  }
}

.buttonFindACar {
  @apply --marketplaceButtonStyles;
  width: 250px;
  font-size: 24px;
  line-height: 45px;
  min-height: 45px;
  padding: 0px;
  border-radius: 5px;

  @media (--viewportMedium) {
    font-size: 24px;
    width: 205px;
  }
}

.buttonJoinUs {
  @apply --marketplaceButtonStyles;
  width: 160px;
  font-size: 24px;
  line-height: 45px;
  min-height: 45px;
  padding: 0px;
  border-radius: 5px;

  @media (--viewportMedium) {
    font-size: 24px;
    width: 160px;
  }
}