@import '../../marketplace.css';

.benifitSection {

}

.box1,
.box2 {
  padding: 34px 20px;
  width: 100%;
  margin: auto;
  padding-bottom: 0;

  @media (--viewportMedium) {
    padding: 34px 0px;
    width: auto;
    max-width: 100%;
    margin: 0 7.5vw;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    padding-bottom: 34px;
  }

  @media screen and (min-width: 1200px) {
    margin: auto;
    padding-bottom: 34px;
    max-width: 1100px;
  }
}

.box1 {
  display: flex;
  flex-direction: column;
  
  @media (--viewportMLarge) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.textBoxTitle1 {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);
  font-weight: 900;
  margin-top: 0px;

  @media (--viewportMedium) {
  }
}

.box2 .textBoxTitle1 {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.textBoxDesc1 {
  @apply --marketplaceDescFontStyles;
  color: var(--marketplaceColorDark);
  /* max-width: 590px; */
  margin-bottom: 40px;

  @media (--viewportMedium) {
    margin-bottom: 0px;
    max-width: 590px;
  }
}

.seperateLine {
  width: 200px;
  height: 1.5px;
  background-color: var(--matterColorNear);
  margin: 10px auto;

  @media (--viewportMedium) {
    width: 600px;
  }

  @media (--viewportLarge) {
    width: 900px;
  }
}

.listTextBox {
  display: flex;
  flex-wrap: wrap;  
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.subBox {
  flex-basis: 90%;
  text-align: center;
  margin-bottom: 20px;

  &::before {
    content: '';
    display: block;
    width: 48px;
    height: 48px;
    font-size: 30px;
    line-height: 46px;
    font-weight: bold;
    background-color: var(--marketplaceSecondaryColor);
    color: var(--marketplaceColorDark);
    border-radius: 50%;
    margin: auto;
  }

  @media (--viewportMedium) {
    flex-basis: 90%;
  }

  @media (--viewportLarge) {
    margin-bottom: 0px;
    flex-basis: 28%;
  }
}

.subBox:nth-child(1)::before {
  content: '1';
}

.subBox:nth-child(2)::before {
  content: '2';
}

.subBox:nth-child(3)::before {
  content: '3';
}

.subBoxTitle {
  font-size: 24px;
  line-height: 34px;
  font-weight: 900;
  color: var(--marketplaceColorDark);
  margin-bottom: 10px;
  text-transform: lowercase;

  @media (--viewportMedium) {
    font-size: 30px;
    margin-bottom: 10px;
  }

  @media (--viewportMLarge) {
    line-height: 56px;
  }
}

.subBoxDesc {
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColorDark);
  font-weight: normal;
  margin-top: 4px;
}

.videoWrapper {
  padding: 0;

  @media (--viewportMedium) {
    padding: 40px 20px;
  }

  @media (--viewportMLarge) {
    padding: 0 20px;
  }
}

.video {
  width: 100%;
  background-color: var(--matterColorAnti);
  cursor: pointer;
  margin: auto;

  @media (--viewportMedium) {
    width: 500px;
    /* height: 280px; */
  }

  @media (--viewportLarge) {
    width: 412px;
    /* height: 150px; */
  }
}

.videoLogo {
  width: 100%;
}

.contentModal {
  height: 100%;
}

.videoContentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    display: block;
  }
}

.iframeVideo {
  width: 100%;
  height: 40%;

  @media (--viewportMedium) {
    height: 100%;
  }
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 40px 0 0 0;

  @media (--viewportMedium) {
    padding: 0px;
    flex-basis: 576px;
    min-height: 0;
    min-width: 80vw;
    height: 48vw;
    margin-top: calc(50vh - 30vw);
    border-bottom: 0px solid transparent;
  }

  @media (--viewportLarge) {
    margin-top: calc(50vh - 25vw);
    min-width: 80vw;
    height: 48vw;
  }

  @media (--viewportMLarge) {
    margin-top: calc(50vh - 20vw);
    min-width: 66vw;
    height: 40vw;
  }
}

.modalCloseButton {
  top: 0px;
  right: 0px;
  padding: 10px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    color: var(--matterColorLight);    
    top: -40px;
  }

  &:hover {
    color: var(--matterColorAnti) !important;
  }
}