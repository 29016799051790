@import '../../marketplace.css';


.root {
  
} 

.readyToShareSection {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/becomeAGuest/readyToShare_bg-min.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 330px;
  max-height: 330px;
  @media (--viewportMedium) {
    max-height: unset;
    height: 24vw;
    padding: 40px 0px;
  }
}

.textBox {
  max-width: 100%;
  width: auto;
  height: 330px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportMedium) {
    height: auto;
    justify-content: unset;
    margin: 0 7.5vw;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1100px;    
    margin: auto;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);
  font-weight: 900;
  margin: 40px 24px;
  text-transform: none;
  @media (--viewportMedium) {
    margin: 0px;
  }
}

.listYourCar {
  @apply --marketplaceButtonStylesPrimary;
  background-color: var(--marketplaceSecondaryColor);
  width: 150px;
  color: var(--marketplaceColorDark);
  width: 100%;
  border-radius: 0;
  border: none;
  font-size: 24px;
  font-weight: 700;

  @media (--viewportMedium){
    font-size: 30px;
  }
  &:hover, &:focus {
    background-color: var(--marketplaceSecondaryColorDark);
  }

  @media (--viewportMedium) {
    border-radius: 5px;
    width: 165px;
    margin-top: 60px;
  }
}

.listYourCarDesktop {
  display: none;
  @media (--viewportMedium){
    display: block;
  }
}

.listYourCarMobile {
  display: block;
  @media (--viewportMedium){
    display: none;
  }
}

.newlineDesktop {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.newlineMobile {

  @media (--viewportMedium) {
    display: none;
  }
}