@import '../../marketplace.css';

.root {
  @media (--viewportLarge) {
    max-width: 550px;
  }
}

.inputField {
  margin-bottom: 30px;
}

.displayInlineContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.birthDate {
  margin-top: 32px;
  margin-bottom: 40px;
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--marketplaceColorDark);

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.locationAddress,
.city,
.genericField,
.country {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.building {
  flex-basis: 100%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 6;

  @media (--viewportMedium) {
    margin-bottom: 32px;
    flex-basis: 60%;
  }
}

.floor {
  flex-basis: 100%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 1;
  @media (--viewportMedium) {
    margin-bottom: 32px;
    margin-left: 4%;
    flex-basis: 40%;
  }
}

.streetName {
  flex-basis: 100%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 30;
  max-width: 100% !important;
  @media (--viewportMedium) {
    margin-bottom: 0px;
    flex-basis: 70%;
  }
}

.streetName .validationClassName {
  position: relative !important;
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.blockNo {
  flex-basis: 100%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 1;
  @media (--viewportMedium) {
    margin-bottom: 32px;
    flex-basis: 20%;
    margin-right: 4%;
  }
}

.readMore {
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorNear);
  margin-bottom: 0px;
}

.learnMore {
  font-size: 16px;
  line-height: 20px;
  color: var(--marketplaceColor);
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--marketplaceColorDark);

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}
