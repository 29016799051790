@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.root .submitButton {
  margin-top: 20px;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    margin-top: 40px;
  }
}

.checkBoxAgree {
  margin: 14px 0px;
}

.trackingDeviceId {
  margin-top: 20px;
  margin-bottom: 50px;
}

.btnTerms {
  display: inline;
  margin: 0;
  padding: 0;
  border: none;
  color: #00a3ad;
  text-decoration: none;
  text-align: left;
}
