@import '../../marketplace.css';

.root {
  margin: 20px 20px 0;

  @media (--viewportMedium) {
    padding-top: 20px;
    margin: 0;
  }

  @media (--viewportLarge) {
    padding-top: 0px;
  }
}

.insuranceBottom {
  text-align: left;
  margin-top: 25px;

  @media (--viewportMedium) {

  }

  @media (--viewportLarge) {
    padding: 0px;
    text-align: left;
  }
}

.insuranceTitle {
  font-size: 20px;
  font-weight: bold;
  color: var(--marketplaceColor);
  margin-bottom: 10px;
}

.insuranceBottomLine {
  font-size: 16px;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.insuranceBottomImg {
  width: 150px;
  margin-top: 25px;
}

.insuranceBottomImgText {
  font-size: 14px;
  display: block;
  color: var(--matterColor);  
}

.insuranceBottomImgText > span > span {
  color: var(--marketplaceColor);  
  cursor: pointer;
}

.noInsuranceBottom {
  padding: 22px;
  background-color: var(--marketplaceSecondaryColor);
  color: var(--marketplaceColorDark);
  min-height: 300px;
}

.noInsuranceBottomTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.noInsuranceBottomDesc {
  display: block;
  font-size: 20px;
  margin-top: 20px;
}

.openHostInsurance {
  color: var(--marketplaceColor);
  font-size: 18px;
  cursor: pointer;
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 576px;
    min-height: auto;
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @media (--viewportLarge) {
    flex-basis: 724px;
    min-height: auto;
    height: auto;
    padding: 40px 40px 50px 40px;
  }

  @media (--viewportMLarge) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.desktop {
  display: none;

  @media (--viewportMLarge) {
    display: block;
  }
}

.mobile {
  display: block;

  @media (--viewportMLarge) {
    display: none;
  }
}