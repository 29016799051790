@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.email {
  margin-top: 24px;
}

.inputClassName {
  border-bottom-width: 3px;
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--marketplaceColorDark);
}

.labelClassName {
  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
}

.password {
  margin-top: 44px;

}

.submitButton {
  background-color: var(--marketplaceColorDark) !important;
  margin-top: 30px !important;
  border-radius: 6px !important;
  margin-bottom: 30px !important;
}