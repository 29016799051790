@import '../../marketplace.css';

.insuranceSection {
  width: 100%;
  padding: 45px 20px;
  background-color: var(--marketplaceSecondaryColorFake);
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/howitwork/insuranceBg.png');
  background-size: 120%;
  background-position: center;

  @media (--viewportMedium) {
    padding: 35px 20px;
    background-size: 120%;
  }

  @media (--viewportLarge) {
    background-size: contain;
  }

  @media screen and (min-width: 1368px) {
    background-size: cover;
  }
}

.textBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 100%;

  @media (--viewportMedium) {
    margin: 0 40px;
  }

  @media (--viewportLarge) {
    width: 900px;
  }

  @media (--viewportMLarge) {
    width: 1056px;
    margin: auto;
  }
}

.textBoxTitle h4{
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);
  line-height: normal;
  font-weight: 900;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 ;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.textBoxTitle span{
  display: flex;
  justify-content: center;
  align-items: center;
}

.insuranceLogo {
  width: 160px;
  margin-top: 20px;
  margin-left: 0px;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-left: 20px;
    /* width: 260px; */
  }
}

.textBoxDesc {
  @apply --marketplaceDescFontStyles;
  color: var(--marketplaceColorDark);
  max-width: 70%;
  margin: auto;
  margin-bottom: 30px;

  @media (--viewportLarge) {
    max-width: 65%;
  }

  @media (--viewportLarge) {
    max-width: 60%;
  }

  @media screen and (min-width: 1200px) {
    color: var(--marketplaceColorDark);
    margin: unset;
    max-width: 630px;    
    margin-bottom: 40px;
  }

}

.findOutMore {
  @apply --marketplaceButtonStylesPrimary;
  background-color: var(--marketplaceColorDark);
  border-radius: 5px;
  min-height: 50px;
  padding: 0;
  line-height: 50px;

  &:hover, &:focus {
    background-color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    width: 265px;
    padding: 0;
    line-height: 65px;
    min-height: 65px;
  }
}