@import '../../marketplace.css';

:root {
  --TopbarMobileMenu_topMargin: 96px;
}

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  height: auto;
}

.space {
  flex-basis: 25%;
}

.content {
  flex-basis: 75%;
  background-color: var(--marketplaceColor);
  display: flex;
  flex-direction: column;
}

.links {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  margin-bottom: 70px;
}

.linkWrapper {
  padding: 13px 20px;
}

.link {
  @apply --marketplaceLinkStyles;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
}

.avatarWrapper {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px var(--matterColorNegative);
}

.userName {
  @apply --marketplaceLinkStyles;
  color: var(--marketplaceSecondaryColor);
  font-weight: var(--fontWeightMedium);
  margin-left: 10px;
}

.logout {
  @apply --marketplaceLinkStyles;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
  padding: 20px;
  border-top: solid 1px var(--matterColorNegative);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 25%;
  background-color: #00A3AD;
}

.signUpLinkWrapper {
  padding: 20px;
  border-bottom: solid 1px var(--matterColorNegative);
}

.authLink {
  color: var(--marketplaceSecondaryColor);
}