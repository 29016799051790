.contentWrapper {
  position: relative;
  background: #ffffff;
}

.containerFluid {
  max-width: 100%;
  padding: 0 20px;
}
.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}
.row {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
}

.column4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
/* section grids */
.sectionGrids {
  padding: 50px 0 10px;
  background: #FFFFFF;
  @media (max-width: 767px) {
    padding: 30px 0 20px;
  }
}
.gridBox {
  margin-bottom: 40px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}
.gridBoxInner {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  overflow: hidden;
}
.gridBoxInner .gridThumb {
  position: relative;
  height: 0;
  padding-bottom: 50%;
  overflow: hidden;
  @media (max-width: 767px) {
    padding-bottom: 72%;
  }
}
.gridBoxInner .gridThumb::after {
  content: '';
  position: absolute;
  width: calc(100% + 50px);
  height: 50px;
  background: #ffffff;
  top: 100%;
  left: 0;
  transform-origin: 0 0;
  transform: rotate(-5deg);
}
.gridBoxInner .gridThumb img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-position: center center;
  object-fit: cover;
}
.gridBoxInner .gridThumb .thumbContent {
  position: absolute;
  z-index: 9;
  max-width: 219px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}
.gridHorizontal .gridBoxInner .gridThumb .thumbContent {
  width: 103px;
  height: 51px;
}
.gridHorizontal .gridBoxInner .gridThumb .thumbContent svg {
  width: 100%;
  height: 100%;
}
.gridBoxInner .gridContent {
  padding: 20px;
  @media (max-width: 767px) {
    padding: 20px 10px;
  }
}
.gridBoxInner .gridContent .title {
  font-size: 28px;
  color: #026786;
  line-height: normal;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 22px;
  }
}
.gridBoxInner .gridContent p {
  font-size: 22px;
  color: #4a4a4a;
  line-height: normal;
  font-weight: 500;
  margin: 0 0 10px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
.gridBoxInner .gridContent p span {
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
}
.gridBoxInner .gridContent ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.gridBoxInner .gridContent ul li {
  margin: 0 0 10px;
}
.gridBoxInner .gridContent ul li svg {
  vertical-align: baseline;
  display: inline-block;
  margin: 0 10px 0 0;
}
.gridBoxInner .gridContent .btnWrapper {
  padding: 20px 0 0;
  text-align: left;
}
.gridBoxInner .btnWrapper .btnMore {
  min-width: 214px;
  height: 49px;
  padding: 10px 33px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: normal;
  color: #026786;
  border: 1px solid #026786;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  -moz-transition: 0.3s all ease;
  transition: 0.3s all ease;
  text-decoration: none !important;
  @media (max-width: 767px) {
    min-width: 100%;
  }
  &:hover {
    background: #026786;
    color: #ffffff;
  }
}
.gridHorizontal .gridBoxInner {
  display: flex;
  flex-wrap: wrap;
}
.gridHorizontal .gridBoxInner .gridThumb {
  max-width: 347px;
  min-width: 347px;
  flex: 1;
  position: relative;
  overflow: hidden;
  padding-bottom: 30%;
  @media (max-width: 767px) {
    min-height: 20px;
    max-height: 100%;
    height: 0;
    padding-bottom: 72%;
    min-height: 20px;
    max-height: 100%;
    height: 0;
    width: 100%;
    min-width: 10px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.gridHorizontal .gridBoxInner .gridThumb::after {
  height: calc(100% + 50px);
  width: 50px;
  background: #ffffff;
  bottom: 0;
  left: auto;
  transform-origin: 0 100%;
  transform: rotate(5deg);
  right: 0;
  top: auto;
  @media (max-width: 767px) {
    content: '';
    position: absolute;
    width: calc(100% + 50px);
    height: 50px;
    background: #ffffff;
    top: 100%;
    left: 0;
    transform-origin: 0 0;
    transform: rotate(-5deg);
  }
}
.gridHorizontal .gridBoxInner .gridContent {
  flex: 1;
}
.gridHorizontal .gridContent .horizontalList {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
  padding-top: 20px;
}
.gridHorizontal .horizontalList li {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 22px !important;
  }
}
.gridHorizontal .horizontalList .iconThumb {
  max-width: 50px;
  flex: 1;
}
.gridHorizontal .horizontalList .iconText {
  flex: 1;
  padding-left: 10px;
  max-width: 172px;
  @media (max-width: 767px) {
    max-width: 142px;
  }
}
.gridHorizontal .btnWrapper .btnMore {
  border: 1px solid #fecd2a;
  background: #fecd2a;
  color: #026786;
}
.gridHorizontal .gridContent .badgeListing {
  list-style: none;
  margin: 0;
  padding: 0;
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
}
.gridHorizontal .gridContent .badgeListing li {
  display: inline-block;
  margin-right: 15px;
  @media (max-width: 767px) {
    margin-right: 0;
    padding: 0 10px;
  }
}
.gridHorizontal .gridContent .badgeListing li button {
  display: block;
  padding: 0;
  border: 0;
  cursor: pointer;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
  }
}
.gridHorizontal .gridContent .badgeListing li button img {
  @media (max-width: 767px) {
    display: block;
    width: 100%;
  }
}
.gridHorizontal.gridImageRight .gridBoxInner .gridThumb {
  padding-bottom: 17%;
  order: 2;
  background: #00a3ad;
  @media (max-width: 767px) {
    order: -1;
    min-height: 20px;
    max-height: 100%;
    height: 0;
    padding-bottom: 72%;
    width: 100%;
    min-width: 10px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.gridHorizontal.gridImageRight .gridBoxInner .gridThumb::after {
  left: 0;
  right: auto;
  transform: rotate(-5deg);
  transform-origin: 100% 100%;
  @media (max-width: 767px) {
    content: '';
    position: absolute;
    width: calc(100% + 50px);
    height: 50px;
    background: #ffffff;
    top: 100%;
    left: 0;
    transform-origin: 0 0;
    transform: rotate(-5deg);
  }
}
/* section grids Ends */