@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }

  --FlexBasis_DateTime_LargeField: 100%;
  --FlexBasis_DateTime_SmallField: 100%;
}

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.root {
  width: 100%;
  padding: 20px 0px;
  background-color: var(--matterColorLight);
  /* Text positioning is done with paddings */
}

.heroSearchBox {
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  @apply --SectionHero_animation;
  animation-delay: 0.3s;

  max-width: 100%;
  margin: 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw;
  }

  @media (--viewportMedium) {
    margin: 0 24px;
  }

  @media (--viewportMLarge) {
    max-width: 1100px;
    margin: 0 auto;
  }

}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--marketplaceColor);
  font-size: 30px;
  line-height: 35px;
  margin-top: 6px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 20px;
    /* max-width: var(--SectionHero_desktopTitleMaxWidth); */
  }
}

.heroSubtitle {
  color: var(--marketplaceColor);
  margin-bottom: 30px;

  @media (--viewportMedium) {
  }
}

.fieldsContainerSolid {
  height: 320px;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    height: 90px;
  }
}

.fieldsContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.fieldWrapper {

}

.desktopInputRoot {
  margin-bottom: 18px;
}

.desktopInput {
  padding-left: 0px;
  border-bottom-color: var(--marketplaceColor);

  @media (--viewportMedium) {
    width: 200px;
  }

  @media (--viewportLarge) {
    width: 250px;
  }

  @media (--viewportMLarge) {
    width: 330px;
  }
}

.desktopIcon {
  border-bottom: 2px solid var(--marketplaceColor);
}

.desktopIcon svg{
  display: none;
}

.labelField {
  font-size: 16px;
  color: var(--matterColorAnti);
  margin-bottom: 5px;
}

.field {
  margin-bottom: 18px;
}

.dateTimeFields {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    width: 150px;
  }

  @media (--viewportLarge) {
    width: 170px;
  }

  @media (--viewportMLarge) {
    width: 190px;
  }
}

.fieldDateTimeLabel:nth-child(2n+1) {
  flex-basis: var(--FlexBasis_DateTime_LargeField);
}

.fieldDateTimeLabel:nth-child(2n) {
  flex-basis: var(--FlexBasis_DateTime_SmallField);
}

.fieldDateTimeInput {
  z-index: 10;
}

.fieldDateTimeInput:nth-child(2n+1) {
  flex-basis: var(--FlexBasis_DateTime_LargeField);
}

.fieldDateTimeInput:nth-child(2n) {
  flex-basis: var(--FlexBasis_DateTime_SmallField);
}

.fieldDateStartTimeInput {
  z-index: 11 !important;
}

.fieldInput {
  border-bottom-color: var(--marketplaceColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColorDark);
  }
}


.heroButton {
  @apply --marketplaceButtonStyles;
  align-self: flex-end;
  margin-bottom: 18px;
  line-height: 45px;
  padding: 0px;
  min-height: 45px;
  width: 100%;

  @media (--viewportMedium) {
    display: block;
    min-height: 45px;
    padding: 0px;
    width: 150px;
  }

  @media (--viewportLarge) {
    display: block;
    min-height: 45px;
    padding: 0px;
    width: 180px;
  }
}

.heroButtonLong {
  width: 100%;
}

.joinButton {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
}

.joinButtonTemp {
  width: 200px;
  margin-top: 20px;
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 30px;
    margin-bottom: 25px;
  }
}

.insuranceParrner {
  font-size: 16px;
  color: var(--matterColorAnti);
  line-height: 40px;
  display: flex;
  align-items: center;
}

.insuranceText {
  max-width: 115px;
  padding-right: 25px;
  line-height: 1.2em;
}

.insuranceLogo {

}

.insuranceLogoImg {
  width: 180px;

  @media (--viewportMedium) {
    width: 200px;
  }
}

.hideDate {
  visibility: hidden;
}

.searchButtonDisabled {
  pointer-events: none;
  background-color: #e7e7e7;
  color: #b2b2b2;
}

.emailField {
  margin-bottom: 20px;
}

.campaignSubmitBtn {
  min-height: 50px;
  margin-bottom: 20px;

  @media (--viewportLarge) {
    min-height: 50px;
    margin-bottom: 30px;
  }
}

.null {

}

.submitWarning {
  @apply --submitWarning;
  text-align: left;
  padding-left: 0;
}

.submitWarningCenter {
  @apply --submitWarning;
  padding-left: 0;
}
