@import '../../marketplace.css';

/* .logoMobile {
  & path {
    fill: var(--matterColorLight);
  }
} */
.logoMobile img {
  width: 100%;
  height: auto;
}
