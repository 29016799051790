@import '../../marketplace.css';

@keyframes blink{
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 0;
  /* Remove link's hover effect */
  margin-right: 20px;
  &:hover {
    text-decoration: none;
  }
}

.dateRangeBannerContainer {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 2;
}

.cardOuter {
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.08);
  border-radius: 10px;
  position: relative;
  height: 100%;
  padding-bottom: 29px;
  background: #FFFFFF;
}

.cardWithPadding {
  padding-bottom: 29px;
}

.cardThreeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    /*transform: scale(1.02);*/
    box-shadow: var(--boxShadowListingCard);
  }
}

.cardNameLogo {
  position: absolute;
  right: 25px;
  bottom: -27px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-image: linear-gradient(var(--marketplaceColor), var(--marketplaceColorDark));
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: var(--matterColorLight);
  overflow: hidden;
  visibility: hidden;
}

.cardNameLogoImg {
  width: 100%;
  border-radius: 50%;
}

.cardOwnerUsername {
  font-size: 12px;
  line-height: normal;
  font-weight: 300;
  color: #0d6786;
  display: block;
}

.cardRatingBox {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 5px;
}

.cardStar {
  width: 15px;
  height: 15px;
  /* fill: var(--marketplaceSecondaryColor); */
}

.cardNumberRatings {
  font-size: 14px;
  line-height: normal;
  margin-left: 5px;
  margin-top: 0;
  color: #0d6786;
  font-weight: 700;

  @media (--viewportLMLarge) {
    font-size: 16px;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.cardAspectWrapper {
  padding-bottom: 209px; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  background-color: var(--matterColorNegative); /* Loading BG color */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.custom {
  padding-bottom: 209px; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.cardRootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.cardRootForNoImage {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
  height: 100%;
  object-fit: cover;
  object-position: center;
  background-color: #0aa3ad;
}

.threeToTwoWrapper {
  padding: 0;
}

.cardLinkText {
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColorDark);
  margin-top: 0;
  margin-bottom: 3px;
  max-width: 67%;
  font-size: 20px;
  line-height: 1.375;
  letter-spacing: normal;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0;
  @media (--viewportMedium) {
  }
}

.cardExtraInfo {
  font-size: 18px;
  flex-wrap: wrap;
  color: var(--matterColor);
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cardInstantBooking {
  height: 26px;
  width: 79px;
  background-color: var(--instantBookingLabelColor);
  color: var(--matterColorLight);
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.cardSuperHost {
  height: 26px;
  width: 86px;
  background-color: #0C67860D;
  line-height: 21px;
  color: #026786;
  font-weight: 700;
  font-size: 12px;
  margin-top: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  padding: 14px 6px;
  margin-right: 6px;
  border: 1px solid #0C67861A;
  border-radius: 4px;

  @media(--viewportLMLarge) {
    width: 94px;
    font-size: 14px;
  }
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.cardLongWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.cardDoneTrips {
  font-size: 14px;
  color: var(--marketplaceColorDark);
  margin: 0;
  font-weight: 100;

  @media (--viewportLMLarge) {
    font-size: 16px;
  }
}

.cardDrivelahGoLogo {
  display: block;
}

.cardDrivelahGoLogo svg {
  display: block;
}

.cardSuperHostLogo {
  margin-right: 5px;
}

.cardInstantBooking {
  padding: 0 8px;
  background-color: var(--instantBookingLabelColor);
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
  margin-top: 0;
}
.cardInstantBooking span {
  line-height: normal;
}

.cardHintText {
  color: #00A3AD;
  font-weight: 700;
  width: fit-content;
  font-size: 12px;

  @media(--viewportLMLarge) {
    width: 115px;
    font-size: 14px;
  }
}

.cardLogo {
  display: flex;
  width: 113px;
  padding: 2px 6px;
  align-items: center;
  border-radius: 4px;
  justify-content: space-between;
  border: 1px solid #00A3AD1A;
  background-color: #19A3AD0D;

  @media(--viewportLMLarge) {
    width: 125px;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cardLogoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cardNewLabel {
  position: relative;
  background-color: var(--marketplaceSecondaryColor);
  color: var(--marketplaceColorDark);
  top: 15px;
  left: 15px;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 5px;
  font-weight: 800;
  font-size: 16px;
}

.cardWrapperLabelDisinfected {
  position: relative;
  display: inline-block;
  top: 15px;
  left: 15px;
  font-size: 20px;

  & .cardNumberDays {
    background-color: var(--marketplaceSecondaryColor);
    color: var(--marketplaceColorDark);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    z-index: 0;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -3px;

    & .cardNumber {
      font-weight: 900;
      line-height: 18px;
    }
    & .cardDay {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 12px;
      line-height: 12px;
    }
  }
  & .cardTextLabel {
    color: var(--marketplaceSecondaryColor);
    display: inline-block;
    font-weight: 900;
    text-transform: uppercase;
    background-color: #00a3ad;
    border-radius: 5px;
    padding: 8px 17px;
    z-index: 2;
    padding-left: 30px;
    margin-left: 15px;
  }
}

.cardListingInfoOuter {
  padding: 10px;
  min-height: 75px;
}

.cardFeatureInfo {
  line-height: normal;
}

.cardFeatureInfo .cardFeatureInfoList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cardFeatureInfo .cardFeatureInfoList li {
  display: inline-block;
  margin-right: 16px;
  font-size: 12px;
  line-height: normal;
  font-weight: 300;
  color: #0d6786;
  position: relative;
  vertical-align: middle;
}

.cardFeatureInfo .cardFeatureInfoList li:last-child {
  margin-right: 0;
}

.cardFeatureInfo .cardFeatureInfoList li::after {
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  background: #0d6786;
  top: 50%;
  transform: translateY(-50%);
  right: -10px;
}

.cardFeatureInfo .cardFeatureInfoList li:last-child::after {
  display: none;
}

.cardListingRatingsAndPrice {
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid #e6e6e6;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #FECD2A;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.cardListingRatingsAndPrice img {
  margin-right: 6px;
}

.flexEnd {
  justify-content: flex-end;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: normal;
  color: #026786;
  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }
}

.cardListingPrice {
  display: flex;
  align-items: baseline;
}
.cardListingPrice strong {
  font-weight: 900;
}
.cardListingPrice .or {
  display: inline-block;
  margin: 0 5px;
}

.cardTripPrice,
.cardTripPriceLoading {
  margin-right: 8px;
}

.cardTripPriceLoading {
  animation: blink 1s ease infinite;
}

.cardListingRatingsAndPrice .cardListingPrice > span,
.cardTripPrice,
.cardTripPriceLoading {
  font-size: 16px;
  line-height: 1;
  font-weight: 900;
  padding: 5px 0;
  color: #0d6786;
  display: inline-flex;
  align-items: flex-end;

  @media (--viewportLMLarge) {
    font-size: 20px;
  }
}

.cardTripTotalPrice {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: #0d6786;
  display: inline-flex;
  align-items: flex-end;
  text-decoration: underline;
  white-space: nowrap;
  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.tripDiscount {
  font-size: 14px !important;
  @media (--viewportMedium) {
    font-size: 18px !important;
  }
}

.cardListingRatingsAndPrice .cardListingPrice > span > span,
.cardTripPriceUnits {
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  color: #0d6786;

  @media (--viewportLMLarge) {
    font-size: 16px;
  }
}

.or {
  font-size: 14px !important;
  font-weight: 500 !important;

  @media (--viewportLMLarge) {
    font-size: 16px !important;
  }
}

.cardTripTotalPriceUnits {
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  color: #0d6786;
  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.cardListingRatingsAndPrice .cardListingPrice > span + span {
  display: flex;
  margin: 0 0 0 10px;
  align-items: self-end;
}

.cardListingRatingsAndPrice .cardListingPrice > span + span::before {
  font-size: 13px;
}

.cardListingRatings {
  display: flex;
  align-items: center;
}

.cardListingTrips {
  margin-right: 4px;
}


/* Discount and delivery labels */
.discountAndDeliverLabels{
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: 10px;
}

.discountLabel{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #E9F2FD;
  width: fit-content;
  border-radius: 4px;
  font-size: 14px;
  line-height: normal;
  color: #569CEF;
  padding: 5px 10px;
  font-weight: normal;
}

.discountLabel span{
  font-size: 12px;
  margin-left: 8px;
  line-height: normal;
  display: block;
  font-weight: 400;

  @media (--viewportLMLarge) {
    font-size: 14px;
  }
}

.deliveryLabel{
  background: #2680EB;
  width: 96px;
  border-radius: 0 4px 4px 0;
  font-size: 10px;
  line-height: 10px;
  color: #FFFFFF;
  padding: 6px 10px;
  letter-spacing: normal;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
}
.deliveryLabel span{
  max-width: 32px;
  flex: 1;
}
.deliveryLabel strong{
  max-width: 46px;
  flex: 1;
  padding-left: 5px;
  color: #FFFFFF;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.distanceLabel {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 4px;
  background-color: var(--matterColorLight);
}

.distanceText {
  line-height: 18px;
  padding: 7px;
  margin: 0;
  color:#0D6786;
  font-size: 14px;
}

.root :global(.slick-slide img) {
  max-height: 209px;
  height: 100%;
}
