@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingPhotosForm_imageWidthDesktop: calc(50% - 12px);
}

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.fieldSet {
  padding: 40px 0px;
  border-top: 1px solid var(--matterColorNegative);
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: var(--EditListingPhotosForm_imageWidthDesktop);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: var(--EditListingPhotosForm_imageWidthDesktop);
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
  font-size: 14px;
}

.errorRequired {
  color: var(--failColor);
  margin-bottom: 30px;
  font-size: 14px;
}

.submitButton {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 24px;
  background-color: #00a3ad;
  color: #ffffff;
  @media (--viewportLarge) {
    display: inline-block;
    width: 300px;
    margin-top: 100px;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--marketplaceColorDark);

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.editLocation {
  /* position: absolute; */
  /* right: 0px; */
  /* bottom: 40px; */
  /* width: 50px; */
  margin-bottom: 20px;
  /* flex-basis: 100%; */
  width: 100%;
  font-size: 20px;
  color: var(--marketplaceColorDark);
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.editLocationText {
  flex-shrink: 1;
  align-self: center;
}

.editLocationBox {
  flex-basis: 20%;
  flex-shrink: 1;
  align-self: center;

  @media (--viewportMedium) {
    flex-basis: 10%;
  }
}

.locationAddress,
.city,
.genericField,
.country {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.building {
  flex-basis: 60%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 6;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.floor {
  /* margin-left: 4%; */
  flex-basis: 40%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 1;
  @media (--viewportMedium) {
    margin-left: 4%;
    margin-bottom: 32px;
  }
}

.streetName {
  flex-basis: 70%;
  flex-shrink: 1;
  margin-bottom: 12px;
  flex-grow: 30;
  position: relative;
  @media (--viewportMedium) {
    margin-bottom: 0px;
  }
  max-width: 100% !important;
}

.blockNo {
  /* margin-right: 4%; */
  flex-basis: 20%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 1;
  @media (--viewportMedium) {
    margin-bottom: 32px;
    margin-right: 4%;
  }
}

.displayInlineContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: column;
  position: relative;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.submitWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  font-weight: bold;
  color: var(--matterColorLight);

  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 65px;
    display: inline-block;
    width: 340px;
    height: 65px;
    margin-top: 40px;
  }
}

.thankYouTextFirstTime {
  flex-grow: 1;
  padding: 30px 40px;
  font-size: 16px;
  @media (max-width: 767px) {
    padding: 0;
    margin-top: 30px;
    font-size: 16px;
  }
}

.guestVerifiedNoti {
  font-size: 18px;
  font-style: italic;

  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.inputField {
  margin-bottom: 30px;
}

.uploadBoxWrapper {
  display: flex;
}

.photoTip {
  font-size: 14px;
  color: var(--marketplaceColorDark);
  font-weight: normal;
  margin-top: 0px;
  line-height: normal;

  @media (--viewportMedium) {
    font-size: 18px;
    margin-bottom: 40px;
  }
}

.photoIdentify {
  width: 156px;
  height: 156px;
  background-color: var(--matterColorAnti);
  margin-right: 25px;
}

.frontalPhoto {
  width: 156px;
  height: 156px;
}

.bottomField {
  margin-bottom: 12px !important;
}

.checkingStripeStatus {
  margin-top: 30px;
}

.checkingStripeStatusText {
  margin-left: 10px;
}

.abnNumber {
  margin-top: 24px;
}



.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: var(--EditListingPhotosForm_imageWidthDesktop);
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
  font-size: 14px;
}

.errorRequired {
  color: var(--failColor);
  margin-bottom: 30px;
  font-size: 14px;
}

.submitButton {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 24px;
  background-color:#00a3ad;
  color: #ffffff;
  @media (--viewportLarge) {
    display: inline-block;
    width: 300px;
    margin-top: 100px;
  }
}


.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--marketplaceColorDark);

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.editLocation {
  /* position: absolute; */
  /* right: 0px; */
  /* bottom: 40px; */
  /* width: 50px; */
  margin-bottom: 20px;
  /* flex-basis: 100%; */
  width: 100%;
  font-size: 20px;
  color: var(--marketplaceColorDark);
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.editLocationText {
  flex-shrink: 1;
  align-self: center;
}

.editLocationBox {
  flex-basis: 20%;
  flex-shrink: 1;
  align-self: center;

  @media (--viewportMedium) {
    flex-basis: 10%;
  }
}

.locationAddress,
.city,
.genericField,
.country {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.building {
  flex-basis: 60%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow:  6;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.floor {
  /* margin-left: 4%; */
  flex-basis: 40%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 1;
  @media (--viewportMedium) {
    margin-left: 4%;
    margin-bottom: 32px;
  }
}

.streetName {
  flex-basis: 70%;
  flex-shrink: 1;
  margin-bottom: 12px;
  flex-grow: 30;
  position: relative;
  @media (--viewportMedium) {
    margin-bottom: 0px;
  }
  max-width: 100% !important;
}

.blockNo {
  /* margin-right: 4%; */
  flex-basis: 20%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 1;
  @media (--viewportMedium) {
    margin-bottom: 32px;
    margin-right: 4%;
  }
}

.displayInlineContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: column;
  position: relative;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.submitWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  font-weight: bold;
  color: var(--matterColorLight);

  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 65px;
    display: inline-block;
    width: 340px;
    height: 65px;
    margin-top: 40px;
  }
}

.thankYouTextFirstTime {
  flex-grow: 1;
  padding: 30px 40px;
  font-size: 16px;
  @media (max-width: 767px) {
    padding: 0;
    margin-top: 30px;
    font-size: 16px;
  }
}

.guestVerifiedNoti {
  font-size: 18px;
  font-style: italic;

  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.inputField {
  margin-bottom: 30px;
}

.uploadBoxWrapper {
  display: flex;
}

.photoTip {
  font-size: 14px;
  color: var(--marketplaceColorDark);
  font-weight: normal;
  margin-top: 0px;
  line-height: normal;

  @media (--viewportMedium) {
    font-size: 18px;
    margin-bottom: 40px;
  }
}

.photoIdentify {
  width: 100px;
  height: 100px;
  background-color: transparent;
  margin-right: 0;
  border-radius: 4px;
  border: 1px dashed #026786;
  padding: 10px;
}

.photoIdentify img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.profilePhotoImageInfo{
  color: #4A4A4A;
  font-size: 14px;
  line-height: 1.42;
  max-width: 225px;
}

.photoIdentify button {
  display: none;
}

.frontalPhoto {
  width: 100%;
  height: 100%;
  object-position: center center;
  object-fit: contain;
  display: block;
}
.placeholderImage{
  max-width: 48px;
  margin: 0 auto;
}

.bottomField {
  margin-bottom: 0px !important;
  position: relative;
}
.bottomField label {
  padding: 0;
}

.checkingStripeStatus {
  margin-top: 30px;
}

.checkingStripeStatusText {
  margin-left: 10px;
}

.collapseOuter h3{
  font-size: 30px;
  line-height: normal;
  font-weight: 300;
  color: #4A4A4A;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 24px;
  }
}
.collapseOuter p{
  font-size: 14px;
  line-height: 1.42;
  font-weight: 300;
  color: #4A4A4A;
  margin: 0;
}

/* Input new design */
.streetName {
  position: relative;
  flex-basis: 60%;
  flex-shrink: 1;
  margin-top: 10px;

  /* margin-bottom: 24px; */
  flex-grow: 30;
  @media (--viewportMedium) {
    /* margin-bottom: 32px; */
    margin-top: 0px;
    flex-basis: 70%;
  }
  max-width: 100% !important;
}
.newInput {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0 0 20px;
}
.newInput input, .newInput select, .newInput textarea {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  margin: 0;
  font-weight: 500;
  background-position: calc(100% - 10px) center;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}
.newInput label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
  @media (max-width: 767px) {
    margin-bottom: 5px;
  }
}

.column {
  padding-left: 20px;
  padding-right: 20px;
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column4 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column3 {
  flex: 0 0 25%;
  max-width: 25%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.displayInlineContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  flex-wrap: wrap;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}
.fieldSet .nrcFileUpload{
  border: 1px solid #E6E6E6;
  padding: 10px;
  background: #FCFCFC;
  display: block;
}
.fieldSet .nrcFileUpload label{
  display: block;
  font-size: 18px;
  line-height: normal;
  color: #026786;
  margin-bottom: 10px;
  padding: 0;
}
.fieldSet .nrcFileUploadInputWrapper{
  display: block;
  width: 100%;
}
.fieldSet .nrcFileUploadInputWrapper > div{
  display: block;
  width: 100%;
}
.fieldSet .nrcFileUploadInputWrapper button{
  display: block;
  border: 1px dashed #026786;
  width: 100%;
  height: 100px;
  background: transparent;
  box-shadow: none;
  border-radius: 4px;
}
.collapseDivider{
  box-shadow: none;
  border: none;
  border-top: 1px solid #E6E6E6;
  outline: none;
  margin: 20px -40px 40px;
}

.stickyButtons {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  @media (max-width: 767px) {
    height: auto;
    box-shadow: none;
  }
}
.stickyButtons .stickButtonsContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}
.stickyButtons button {
  height: 60px;
  border-radius: 4px;
  max-width: 301px;
  margin-left: auto;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    height: 52px;
  }
}
.stickButtonsDescription {
  flex: 0 0 70%;
  max-width: 70%;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: left;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 15px 20px;
    background: #eff7f8;
  }
}

.stickButtonsDescription .descIcon {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
}
.stickButtonsDescription .descIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.stickButtonsDescription p {
  color: #4a4a4a;
  max-width: 600px;
  line-height: normal;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }
}
.stickButton {
  text-align: right;
  flex: 0 0 30%;
  max-width: 30%;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 6px 20px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  }
}
.profileFileUploadInputWrapper{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.profileUploadInput{
  display: flex;
  align-items: flex-start;
}
.profileUploadInput p{
  padding-left: 10px;
}

/* Collapse */
.collapseOuter .collapseHeader{
  cursor: pointer;
  position: relative;
  padding-right: 30px;
}
.collapseOuter .collapseContent{
  display: none;
}
.collapseOuter .collapseHeader.collapseIsOpen::after{
  transform: translateY(-50%) rotate(0); 
}
.collapseOuter .collapseHeader::after{
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  width: 16px;
  height: 16px;
  background-image: url(https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/updatelisting/collapse-icon.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.referenceLink{
  font-size: 14px;
  line-height: 1.2;
  text-decoration: underline;
}

.nricFnInputField{
  padding-bottom: 10px;
}