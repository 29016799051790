@import '../../marketplace.css';


.titleContainer {
    color: #00a3ad;
    display: flex;
    padding: 20px !important;
    flex-direction: column;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
  
    @media (--viewportMedium) {
      padding: 0px;
    }
  }

  .titleContainer h3 {
    text-align: left;
  }