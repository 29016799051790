@import '../../marketplace.css';

.container {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  flex-basis: 375px;
  min-height: auto;
  height: auto;
  padding: 100px 30px 53px;
}

.title {
  font-size: 24px;
  line-height: 29px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
}

.description {
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightMedium);
  margin-top: 5px;
  margin-block: 20px;
  color: var(--marketplaceColorDark);
}

.listYourCar {
  @apply --marketplaceButtonStylesPrimary;
  margin-top: 20px;
  background-color: var(--marketplaceSecondaryColor);
  border-radius: 6px;
  color: var(--marketplaceColorDark);
}

.error {
  font-size: 14px;
  line-height: 20px;
  color: var(--failColor);
}