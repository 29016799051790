@import '../../../marketplace.css';

.wrapper {
  display:flex;
  flex-direction:row;
}
.feedbackContainer {
  .starWrapper {
    padding: 0px 20px 0px 29px;
    margin-top: 8px;
  }
  .title {
    line-height: 32px;
    color: #026786;
    font-size: 22px;
    margin-bottom: 10px;
    letter-spacing: 0px;
  }
  .description {
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }
  .tickContainer {
    width: 21px;
    height: 21px;
    margin: 28px 10px 0 0;
    flex-shrink: 0;
  }
}
.stepContainer {
  @media (--viewportMedium) {
    padding: 0px 36px;
  }
  .contentWrapper {
    h1 {
      font-family: 'sofiapro';
      font-size: 36px;
      font-weight: var(--fontWeightBold);
      margin: 12px 0px;
      color: var(--marketplaceColorDark);
    }
    p {
      font-family: 'sofiapro';
      font-weight: var(--fontWeightMedium) !important;
      font-size: 20px;
      line-height: 20px;
      color: var(--matterColor);
    }
  }
}
.contentWrapper {
  h2 {
    font-size: 36px;
    margin: 12px 0px;
    color: var(--marketplaceColorDark);
  }
}
