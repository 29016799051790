@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.addOnsList {
  margin: 0;
}

.addOnsItem {
  border-radius: 7px;
  border: 1px solid #e6e6e6;
  margin-bottom: 30px;
  padding: 25px 20px 18px;
  position: relative;
  background: white;

  @media (--viewportMobile) {
    /* border: none; */
    margin-bottom: 20px;
    padding: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.addOnsItemProtection {
  border-radius: 7px;
  border: 1px solid #e6e6e6;
  /* margin-bottom: 30px; */
  /* padding: 25px 30px 18px; */
  position: relative;
  background: white;
  margin-bottom: 30px;

  @media (--viewportMobile) {
    /* border: none; */
    margin-bottom: 20px;
    /* padding: 20px; */
    margin-left: 10px;
    margin-right: 10px;
  }
}

.maximumProtectionDiv {
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}

.addOnHighlight {
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  margin-bottom: 30px;
  padding: 25px 20px 18px;
  position: relative;
  background: rgba(0, 163, 173, 0.07);

  @media (--viewportMobile) {
    /* border: none; */
    margin-bottom: 20px;
    padding: 20px;
  }
}

.addOnBannerWrapper {
  padding: 22px 20px 14px;

  @media (--viewportMobile) {
    /* border: none; */
    margin-bottom: 20px;
    padding: 20px 20px 12px;
  }
}

.addOnBannerWrapperProtectionPlan {
  padding: 22px 20px 14px;

  @media (--viewportMobile) {
    /* border: none; */
    margin-bottom: 20px;
    padding: 20px 20px 12px;
  }
}

.detailBlock {
  display: flex;
  flex-wrap: wrap;
}

.detailBlockAddon {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.protectionPlanContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 22px 20px 14px;
  border-bottom: 1px solid #e6e6e6;

  @media (--viewportMobile) {
    /* border: none; */
    /* margin-bottom: 20px; */
    padding: 20px 20px 12px;
  }
}

.premiumProtection {
  padding: 6px 20px 0px;

  @media (--viewportMobile) {
    /* border: none; */
    /* margin-bottom: 20px; */
    padding: 6px 20px 0px;
  }
}

.protectionPlanContainer .protectionPlanDiv {
  display: flex;
}

.detailBlockAddon .detailsDataAddon {
  display: flex;
}

.mileageAddonCssClass {
  margin-bottom: 12px !important;
}

.detailsDataAddon .detailBlockThumb {
  max-width: 27px;
  margin: 9px 12px 0px;
  margin-left: 0px;

  @media (--viewportMobile) {
    max-width: 21px;
    margin: 5px 0 0px;
  }
}

.protectionPlanDiv .protectionPlanThumb {
  max-width: 27px;
  margin: 5px 12px 5px;
  margin-left: 0px;

  @media (--viewportMobile) {
    max-width: 21px;
    margin: 5px 0 5px;
  }
}

.detailsDataAddon .priceUnitBlockContent {
  /* flex: 1; */
  /* padding-left: 20px; */
  font-size: 24px;
  line-height: normal;
  margin: 7px 0 0px;
  font-weight: 400;
  @media (--viewportMobile) {
    font-size: 16px;
    font-weight: 700;
  }

}

.detailsDataAddon .priceUnitBlockContent .priceInfoText {
  font-weight: 300;
  font-size: 18px;

  @media (--viewportMobile) {
    font-size: 12px;
  }
}

.detailsDataAddon .detailBlockThumb img {
  width: 40px;
 }

 .protectionPlanDiv .protectionPlanThumb img {
  width: 40px;
 }


 .detailsDataAddon .detailBlockContent {
  /* flex: 1; */
  margin-right: 20px;
  /* padding-left: 20px; */

  @media (--viewportMobile) {
    padding-left: 6px;
    margin-right: 6px;
  }
}

.detailBlockContentProtectionPlan {
  @media (--viewportMobile) {
    padding-left: 0px !important;
  }
}

.protectionPlanDiv .protectionPlanBlockContent {
  margin-right: 20px;
  /* padding-left: 20px; */

  @media (--viewportMobile) {
    padding-left: 5px;
    margin-right: 12px;
  }
}


.detailsDataAddon .detailBlockContent h4 {
  font-size: 24px;
  line-height: normal;
  color: #026786;
  margin: 7px 0 0px;
  font-weight: 700;

  @media (--viewportMobile) {
    font-size: 18px;
  }
}

.protectionPlanDiv .protectionPlanBlockContent h4 {
  font-size: 24px;
  line-height: normal;
  color: #026786;
  margin: 7px 0 5px;
  font-weight: 700;

  @media (--viewportMobile) {
    font-size: 18px;
  }
}

.protectionPlansNames h4 {
  font-weight: 400 !important;
}

.detailBlockAddon  p {
  font-size: 16px;
  line-height: normal;
  color: #4a4a4a;
  margin: 0;
  font-weight: 500;

  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 1.4666
  }
}

.premiumProtectionContentDiv {
  
  padding: 0px 20px 14px;

  @media (--viewportMobile) {
    padding: 0px 20px 14px;
  }
}

.premiumProtectionEndBlock {
  border-bottom: 1px solid #e6e6e6;
}

.premiumProtectionEndBlock p {
    font-size: 16px;
    line-height: normal;
    color: #4a4a4a;
    margin: 0;
    font-weight: 500;

    @media (--viewportMobile) {
      font-size: 14px;
      line-height: 1.4666
    }
}


.detailBlockAddon .addonButtonContent {
  margin: 10px 0 0;
  text-align: center;
  cursor: pointer;
  @media (--viewportMobile) {
    font-size: 18px;
    margin: 6px 0 0;
  }
}
.detailBlockAddon .addonButtonContent .addonAddItemClass {
  /* display: inline-flex;
  justify-content: center;
  align-items: center; */
  padding: 12px 24px;
  font-size: 20px;
  line-height: 1;
  border: 1px solid  #00A3AD;
  border-radius: 6px;
  text-align: center;
  background-color:  #00A3AD;
  color: white;
  font-weight: bold;
  cursor: pointer;
  /* padding: 12px 24px; */
  transition: 0.2s ease-out;

  @media (--viewportMobile) {
    padding: 4px 6px;
    font-size: 14px;
    text-align: center;
  }
}

.detailBlockAddon .addonButtonContent .addonRemoveItemClass {
  /* display: inline-flex;
  justify-content: center;
  align-items: center; */
  padding: 12px 24px;
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
  background-color: white;
  border: 1px solid #00a3ad;
  border-radius: 6px;
  text-align: center;
  color: #00A3AD;
  cursor: pointer;
  /* padding: 12px 24px; */
  transition: 0.2s ease-out;
  @media (--viewportMobile) {
    padding: 4px 6px;
    font-size: 14px;
    text-align: center;
  }
}

.priceKmsCharge {
  /* display: inline-flex;
  justify-content: center;
  align-items: center; */
  font-size: 17px;
  font-weight: 400;
  /* padding: 12px 24px; */

  @media (--viewportMobile) {
    font-size: 14px;
  }
}

.priceIncludeStatus {
  font-size: 17px;
  border-radius: 14px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #e6e6e6;
  background: rgba(0, 163, 173, 0.07);
  text-align: center;
  color: #00A3AD;
  @media (--viewportMobile) {
    font-size: 14px;
  }
}

.mileageDetails {
  margin-top: 10px;
}



/* .detailBlock .detailBlockThumb {
  flex: 1;
  max-width: 44px;

  @media (--viewportMobile) {
    max-width: 24px;
  }
} */


.detailBlock .detailBlockThumb img {
  /* max-width: 100%; */
  display: block;
  width: 32px;
  line-height: normal;
  margin: 5px 0 5px;
  @media (--viewportMobile) {
    margin: 0 0 5px;
  }
}

.detailBlock .detailBlockContent {
  /* flex: 1; */
  margin-right: 20px;
  /* padding-left: 20px; */

  @media (--viewportMobile) {
    padding-left: 10px;
    margin-right: 12px;
  }
}

.detailBlock .detailBlockContent h4 {
  font-size: 24px;
  line-height: normal;
  color: #026786;
  margin: 0 0 5px;
  font-weight: 700;

  @media (--viewportMobile) {
    font-size: 18px;
    font-weight: 500;
  }
}
.detailBlock .priceUnitBlockContent {
  /* flex: 1; */
  /* padding-left: 20px; */
  font-size: 32px;
  line-height: normal;
  margin: 0 0 5px;
  font-weight: 400;
  @media (--viewportMobile) {
    font-size: 18px;
  }

}

.detailBlock .addonButtonContent {
  margin: 10px 0 0;
  text-align: center;
  cursor: pointer;
  @media (--viewportMobile) {
    font-size: 18px;
    margin: 6px 0 0;
  }
}

.detailBlock .addonButtonContent .addonAddItemClass {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 300px;
  font-size: 20px;
  line-height: 1;
  border: 1px solid #00a3ad;
  border-radius: 6px;
  padding: 12px 24px;
  transition: 0.2s ease-out;
  cursor: pointer;

  @media (--viewportMobile) {
    height: 48px;
    min-height: 48px;
    width: 190px;
    font-size: 18px;
  }
}

.removedAddons {
  background-color: #F2FAFB !important;
}

.addedAddons {
  background-color: white !important;
}

.addonButtonContent .addonRemoveItemClass {
  /* display: inline-flex;
  justify-content: center;
  align-items: center; */
  padding: 12px 24px;
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
  background-color: white;
  border: 1px solid #00a3ad;
  border-radius: 6px;
  text-align: center;
  color: #00A3AD;
  cursor: pointer;
  /* padding: 12px 24px; */
  transition: 0.2s ease-out;
  @media (--viewportMobile) {
    padding: 6px 12px;
    font-size: 18px;
    text-align: center;
  }
}



.detailBlock .priceUnitBlockContent h4 {
  font-size: 32px;
  line-height: normal;
  margin: 0 0 5px;
  font-weight: 700;

  @media (--viewportMobile) {
    font-size: 18px;
    font-weight: 500;
  }
}
.detailBlock .priceUnitBlockContent .priceInfoText {
  font-weight: 300;
  font-size: 24px;

  @media (--viewportMobile) {
    font-size: 15px;
  }
}


.detailBlock .detailBlockContent p {
  font-size: 16px;
  line-height: normal;
  color: #4a4a4a;
  margin: 0;
  font-weight: 500;

  @media (--viewportMobile) {
    font-size: 15px;
    line-height: 1.4666
  }
}

.sliderCss {
  color: #eff4f6;
  height: 6px;
  margin-bottom: 33px;
}

.sliderfill {
  max-width: 100%;
}
.sliderfill :global(.rangeslider-horizontal) {
  box-shadow: none;
}

.sliderfill :global(.rangeslider-horizontal .rangeslider__fill) {
  box-shadow: none;
  background-color: #00a3ad;
}

.sliderfill :global(.rangeslider-horizontal .rangeslider__labels) {
  margin: 0 0 0;
}

.sliderfill :global(.rangeslider-horizontal .rangeslider__handle) {
  border: 2px solid #00a3ad;
  background: #fff;
  box-shadow: none;
  height: 24px;
  width: 24px;
  border-radius: 100%;
}

.sliderfill :global(.rangeslider-horizontal .rangeslider__handle::after) {
  background: initial;
  box-shadow: none;
}

.sliderfill :global(.rangeslider__handle-tooltip) {
  width: 52px;
  height: auto;
  text-align: center;
  position: absolute;
  background-color: #00a3ad;
  font-weight: normal;
  font-size: 12px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: white;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  top: auto;
  bottom: -35px;
}

.sliderfill :global(.rangeslider__handle-tooltip::after) {
  border-top: none;
  border-bottom: 8px solid #00a3ad;
  bottom: 26px;
}

.sliderfill :global(.rangeslider__handle-tooltip span) {
  margin-top: 0;
  padding: 7px 3px;
}
.sliderfill :global(.rangeslider__label-item) {
  color: #00a3ad;
}

.customCheckbox {
  margin-right: 20px;
  position: relative;
}

.customCheckboxFuel {
  margin-right: 20px;
  position: relative;
}

.customCheckbox input[type='checkbox'] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  border: 0;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.customCheckboxFuel input[type='checkbox'] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  border: 0;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.customCheckbox input[type='checkbox']+label {
  font-size: 20px;
  text-transform: uppercase;
  color: #00a3ad;
  cursor: pointer;
  padding: 0 0 0 25px;
  position: relative;

  @media (--viewportMobile) {
    font-size: 18px;
    padding: 0 0 0 20px;
  }
}

.customCheckboxFuel input[type='checkbox']+label {
  font-size: 20px;
  text-transform: uppercase;
  color: #00a3ad;
  cursor: pointer;
  padding: 0 0 0 25px;
  position: relative;

  @media (--viewportMobile) {
    font-size: 18px;
    padding: 0 0 0 20px;
  }
}

.customCheckbox input[type='checkbox']+label:before {
  content: '';
  width: 20px;
  height: 20px;
  display: block;
  border: 1px solid #00a3ad;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 2px;

  @media (--viewportMobile) {
    width: 14px;
    height: 14px;
    top: 5px;
  }
}

.customCheckboxFuel input[type='checkbox']+label:before {
  content: '';
  width: 20px;
  height: 20px;
  display: block;
  border: 1px solid #00a3ad;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 2px;

  @media (--viewportMobile) {
    width: 14px;
    height: 14px;
    top: 5px;
  }
}

.customCheckbox input[type='checkbox']+label:after {
  content: '';
  background: url('https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/whiteCheck.svg');
  background-position: center center;
  background-size: 14px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  display: block;
  border: 1px solid #00a3ad;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 2px;

  @media (--viewportMobile) {
    width: 14px;
    height: 14px;
    background-size: 12px;
    top: 5px;
  }
}

.customCheckboxFuel input[type='checkbox']+label:after {
  content: '';
  background: url('https://drivelah-public-files.s3.ap-southeast-1.amazonaws.com/Images/greycheck.svg');
  background-position: center center;
  background-size: 14px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  display: block;
  border: 1px solid #00a3ad;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 2px;

  @media (--viewportMobile) {
    width: 14px;
    height: 14px;
    background-size: 12px;
    top: 5px;
  }
}

.customCheckbox input[type='checkbox']:checked+label:before {
  content: '';
  background: #00a3ad;
}

.customCheckboxFuel input[type='checkbox']:checked+label:before {
  content: '';
  background: #00a3ad;
}

.selectionBlock {
  padding-top: 25px;
  display: flex;
  justify-content: flex-start;

  @media (--viewportMobile) {
    padding-top: 20px;
  }
}

.expandContent {
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;

  @media (--viewportMobile) {
    width: auto;
    height: 60px;
    right: 20px;
  }
}

.expandContent .imageThumb {
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00a3ad;
  border-radius: 100%;
  cursor: pointer;
}


.submitSection {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  background: #FFFFFF;
  z-index: 99;
}

.submitSectionInner {
  max-width: 1230px;
  margin: 0 auto;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--viewportMobile) {
    padding: 5px 20px;
  }
}

.submitSectionPrice {
  text-align: center;
  font-size: 12px;
  line-height: normal;
  color: #B2B2B2;
}

.submitSectionPrice .totalAmount {
  width: 168px;
  text-align: left;
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #B2B2B2;
  font-weight: 500;


  @media (--viewportMobile) {
    width: 90px;
    font-size: 12px;
  }
}

.submitSectionPrice button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 300px;
  font-size: 20px;
  line-height: 1;
  color: #026786;
  border-radius: 4px;
  background-color: #FFCD05;
  text-transform: capitalize;

  @media (--viewportMobile) {
    height: 48px;
    min-height: 48px;
    width: 190px;
    font-size: 18px;
  }
}

.submitSectionPrice button:hover {
  opacity: 0.85;
  background-color: #FFCD05;
}

.addonPrice {
  @media (--viewportMobile) {
    font-size: 18px;
  }
}

.addedTitleContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px;
}

.addedTitle {
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  background-color: var(--matterColorBright);
}

.addedTitleContainer::after {
  content: '';
  position: absolute;
  height: 1px;
  background-color: var(--borderColorGray);
  width: 100%;
  top: 50%;
  z-index: -1;
}

.addonBanner {
  position: absolute;
  top: -12px;
  left: 10px;
  font-size: 14px;
  line-height: normal;
  color: #292827;
  padding: 5px 5px;
  height: 28px;
  border-radius: 7px;
  border: 1px solid #e6e6e6;

  @media (--viewportMobile) {
    font-size: 12px;
    left: 10px;
  }
}

.addonBanner strong {
  font-weight: 900;
  /* text-transform: uppercase; */
  letter-spacing: 1px;
  @media (--viewportMobile) {
    font-weight: 700;
  }

}

.expandedItem {
  transform: rotate(180deg);
}

.tooltip {
  background: #00a3ad;
  color: #fff;
  padding: 10px 15px;
  font-size: 18px;
  border-radius: 10px;
  width: 300px;
  cursor: pointer;
  top: -85px;
  left: -2px;
}

input[type="checkbox"].disabled {
  pointer-events: none !important;
}

input[type="checkbox"].disabled+label {
  pointer-events: none !important;
  user-select: none;
  filter: grayscale(1);
}
