@import '../../marketplace.css';

.root {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 20px;
  margin-bottom: 20px;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px 0;
  justify-content: space-evenly;
  min-height: 130px;
  max-width: 500px;

  & > div {
    flex-basis: 50%;
    max-width: 50%;
  }

  @media (--viewportLarge) {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: -15px;
    background-color: var(--matterColorLight);
    padding: 30px 20px;
    & > div {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}

.title {
  font-size: 12px;
  line-height: 14px;
  color: var(--marketplaceColorDark);
  margin-bottom: 12px;

  @media (--viewportLarge) {
    font-size: 18px;
    line-height: 24px;
  }
}

.insurance {
  width: auto;
  height: 40px;

  @media (--viewportLarge) {
    height: 46px;
  }
}

.approvedBy {
  width: auto;
  height: 35px;

  @media (--viewportLarge) {
    height: 40px;
  }
}

.review {
  width: auto;
  height: 70px;

  @media (--viewportLarge) {
    height: 80px;
  }

  @media (--viewportMLarge) {
    /* background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/review.png'); */
  }
}
