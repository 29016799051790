@import '../../marketplace.css';

.SectionGuideToDisinfectDesktop {
  color: #FFF;
  background-color: var(--marketplaceColorDark);
  padding: 0 100px;
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
  & .title {
    color: var(--marketplaceSecondaryColor);
    font-size: 40px;
    font-weight: 900;
    margin-top: 50px;
    margin-bottom: 25px;
    text-align: center;
    line-height: 42px;
  }
}

.listStepWrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 36px;
  border-bottom: 0.1px solid #E2E2E2;
  flex-direction: column;
  @media (--viewportLMLarge) {
    flex-direction: row;
  }
  & .listStep {
    flex-basis: 26%;

    display: flex;
    margin: auto;
    padding-bottom: 25px;
    flex-direction: column;
    width: 50%;
    @media (--viewportLMLarge) {
      display: block;
      padding-bottom: 0;
      width: auto;
    }
  }

  & .listStepImage {
    border-radius: 8px;
    width: 100%;
    @media (--viewportLMLarge) {
      width: auto;
    }
  }

  & .listStepTitle {
    color: var(--marketplaceSecondaryColor);
    font-size: 24px;
    font-weight: 900;
    margin-top: 15px;
    margin-bottom: 6px;
  }

  & .listStepContent {
    font-size: 16px;
  }
}

.recommendAndTouchpoints {
  margin-top: 36px;
  margin-bottom: 50px;
  display: flex;
  font-size: 20px;
  & .recommendWrapper {
    flex-basis: 50%;
    margin-right: 80px;
    & .recommendTitle {
      font-size: 24px;
      font-weight: 900;
      color: var(--marketplaceSecondaryColor);
      margin-bottom: 15px;
    }
    & .recommendList {
      display: flex;
      padding: 15px 0;
      align-items: end;
      & .listIcon {
        padding-right: 20px
      }
    }
  }

  & .touchpointsWrapper {
    font-size: 16px;
    flex-basis: 36%;
    & .touchpointTitle {
      font-size: 24px;
      font-weight: 900;
      color: var(--marketplaceSecondaryColor);
      margin-bottom: 15px;
    }
    & .touchpointList {
      padding: 5px 0;
    }
  }
}