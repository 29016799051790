@import '../../marketplace.css';

:root {
  
}

.root {
  padding: 35px 30px;

  @media (--viewportMedium) {
    padding: 50px 7.5vw;
  }

  @media (--viewportLarge) {
    padding: 40px 36px;
  }

  @media screen and (min-width: 1200px) {
    padding: 65px calc((100vw - 1100px)/2);
  }
}

.questionTitle {
  color: var(--matterColorLight);
  font-size: 30px;
  line-height: 34px;
  letter-spacing: -1.5px;
  font-weight: 900;

  @media (--viewportMedium) {
    font-size: 38px;
    line-height: 46px;
  }

  @media (--viewportLarge) {
    font-size: 38px;
    line-height: normal;
  }
}

.answerTitle {
  color: var(--matterColorLight);
  font-size: 30px;
  font-weight: 900;
  line-height: 34px;
  letter-spacing: -1.5px;

  @media (--viewportMedium) {
    font-size: 33px;
    line-height: 42px;
  }

  @media (--viewportLarge) {
    font-size: 38px;
    line-height: normal;
  }
}

.subtitle {
  color: var(--matterColorLight);
  font-size: 20px;
  line-height: 22px;
  margin-top: 25px;
  max-width: 755px;

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 30px;
    margin-top: 20px;
  }

  @media (--viewportLarge) {
    font-size: 27px;
    line-height: 32px;
    margin-top: 20px;
  }
}