.check {
    display: inline-block;
    transform: rotate(45deg);
    height: 15px;
    width: 8px;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
    @media (max-width: 767px) {
      height: 11px;
      width: 6px;
      border-bottom: 2px solid white;
      border-right: 2px solid white;
      position: relative;
      top: -1px;
    }
  }
  
.behind {
    text-align: center;
    border-radius: 55%;
    width: 25px;
    height: 25px;
    background: #026786;
    @media (max-width: 767px) {
      width: 18px;
      height: 18px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }