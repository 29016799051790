@import '../../marketplace.css';

.benifitSection {
  
  @media (--viewportMedium) {
    display: flex;
  }
}

.box1,
.box2 {
  /* padding: 34px 20px; */
  width: 100%;
  margin: auto;

  @media (--viewportMedium) {
    flex-basis: 50%;
    padding: 34px 0px 0px;
    max-width: 568px;
  }

  @media (--viewportLarge) {
    max-width: 684px;
    flex-direction: row;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1100px;
  }
}

.box1 {
  padding: 0;
  margin: 0;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/becomeAGuest/benifit-bg-min.jpg');
  background-position: left;
  background-size: 100% 100%;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.box2 {
  /* padding: 0 22px 0 0; */
  @media (--viewportLarge) {
    padding-left: 50px;
  }
}

.row {
  display: flex;
  align-items: center;
}

.textBoxTitle1 {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);
  font-weight: 900;
  text-align: center;
  word-wrap: break-word;
  max-width: 133px;
  @media (--viewportMedium) {
    text-align: left;
    margin-top: 0px;
    max-width: 100%;
  }
}

.benifit_bg{
  width: 230px;
  height: 281px;
  @media (--viewportMedium){
    display: none;
  }
}

.textBoxDesc1 {
  @apply --marketplaceDescFontStyles;
  color: var(--marketplaceColorDark);
  max-width: 590px;
  text-align: center;
  margin-bottom: 15px;

  @media (--viewportMedium) {
    text-align: left;
    margin-bottom: 0px;
  }
}

.seperateLine {
  width: 200px;
  height: 1.5px;
  background-color: var(--matterColorNear);
  margin: 10px auto;

  @media (--viewportMedium) {
    width: 600px;
  }

  @media (--viewportLarge) {
    width: 900px;
  }
}

.listTextBox {
  display: flex;
  flex-wrap: wrap;  
  max-width: 100%;
  justify-content: center;
  margin: 30px 20px 0;
  border-bottom-width: 2px;
  border-bottom-style: solid;

  @media (--viewportMedium) {
    justify-content: space-between;
    margin-top: 50px 0px 0px;
    border-bottom-width: 0;

  }
}

.subBox {
  position: relative;
  flex-basis: 100%;
  text-align: center;
  margin-top: 48px;

  &::before {
    content: '';
    display: block;
    width: 48px;
    height: 48px;
    text-align: center;
    font-size: 30px;
    line-height: 46px;
    font-weight: bold;
    background-color: var(--marketplaceSecondaryColor);
    color: var(--marketplaceColorDark);
    border-radius: 50%;
    margin: auto;
  }

  &:last-child {
    margin-bottom: 42px;
  }
  @media (--viewportMedium) {
    text-align: left;
    flex-basis: 90%;
    margin-bottom: 70px;
    margin-top: 0;
    &::before {
      position: absolute;
      top: 4px;
      left: 0px;
    }
  }

  @media (--viewportLarge) {
    margin-bottom: 90px;
    /* flex-basis: 28%; */
  }
}

.subBox:nth-child(1)::before {
  content: '1';
}

.subBox:nth-child(2)::before {
  content: '2';
}

.subBox:nth-child(3)::before {
  content: '3';
}

.subBoxTitle {
  font-size: 30px;
  line-height: 56px;
  font-weight: 900;
  color: var(--marketplaceColorDark);
  margin-bottom: 10px;
  letter-spacing: -1px;

  @media (--viewportMedium) {
    margin-left: 62px;
    max-width: 526px;
  }
}

.subBoxDesc {
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColorDark);
  font-weight: normal;
  margin-top: 4px;

  @media (--viewportMedium) {
    max-width: 526px;
  }
}

.videoWrapper {
  padding: 0;

  @media (--viewportMedium) {
    padding: 0 20px;
  }
}

.video {
  width: 100%;
  background-color: var(--matterColorAnti);
  cursor: pointer;

  @media (--viewportMedium) {
    width: 500px;
    /* height: 280px; */
  }

  @media (--viewportLarge) {
    width: 412px;
    /* height: 150px; */
  }
}

.videoLogo {
  width: 100%;
}

.iframeVideo {
  width: 100%;
  height: 300px;

  @media (--viewportMedium) {
    height: 600px;
  }
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 40px 0 0 0;
  
  @media (--viewportMedium) {
    padding: 0px;
    flex-basis: 600px;
    min-height: auto;
    height: 0;
    margin-top: calc(50vh - 300px);
    border-bottom: 0px solid transparent;
  }
}

.modalCloseButton {
  top: 0px;
  right: 0px;
  padding: 10px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    color: var(--matterColorLight);    
    top: -40px;
  }

  &:hover {
    color: var(--matterColorAnti) !important;
  }
}