@import '../../marketplace.css';

.name {
  display: flex;
  justify-content: space-between;
  margin: 0 0px;
  margin-bottom: 15px;
}

.name {
  display: flex;
  margin-bottom: 0;
  @media screen and (max-width: 414px) {
    flex-direction: row;
  }
}

.formInput {
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  @media (--viewportMobile){
    margin-top: 0px;
  }
}

.formInput label {
  display: none;
}

.formInput input {
  border-bottom: none;
  padding: 11px 15px;
  font-size: 18px;

  @media (--viewportMobile) {
    font-size: 16px;
  }
}

.fieldWrapper {
  width: 100%;
}

.minYearError {
  @apply --marketplaceH4FontStyles;
  margin-top: 6px;
  margin-bottom: -10px;
  color: var(--failColor);
  overflow: hidden;
  line-height: 16px;
  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.codeInvalid {
  color: red;
}

.root {
  @apply --marketplaceModalFormRootStyles;
  flex-flow: column;
  justify-content: flex-start;
  @media (--viewportMedium) {
    flex-flow: column;
    justify-content: space-between;
  }
}

.centerBlock {
  width: 40px;
  @media (--viewportMobile) {
    width: 20px;
  }
}


.roleSelect {
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  outline: none;
}

.phoneNumberWrapper {
  display: flex;
}

.phoneCodeSelect {
  width: 67px;
  margin-right: 10px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  outline: none;
}

.phoneCodeSelect select {
  background-image: none;
  padding: 11px 12px;
  border-bottom: none;
}

.phoneNumberField {
  width: 150px;
  @media (--viewportMobile) {
    width: 100%
  }
}

.noMargin {
  margin-top: 0 !important;
}

.roleSelect select {
  background-position: 95% center;
  padding: 11px 15px;
  border-bottom: none;
}

.fieldLabel {
  font-size:16px;
  font-weight: 300;
  letter-spacing: 0;
  color: #4A4A4A;
  opacity: 1;
  margin-top: 20px;
}

.birthdayWrapper {
  display: flex;
}

.birthdaySelect {
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  outline: none;
}

.day, .month {
  margin-right: 10px;
  flex: 1;
}

.year {
  flex: 1;
}

.birthdaySelect select {
  background-image: none;
  padding: 11px 15px;
  border-bottom: none;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
  margin-top: 10px;
  text-align: center;
  font-weight: 100;
}

.termsText {
  font-size: 16px;
  line-height: normal;
  color: var(--matterColorAnti);
  @media (--viewportMobile) {
    font-size: 13px;
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin-top: 0;
}

.bottomWrapper .signInButton {
  background: #fecd2a !important;
  color: #0d6786 !important;
  font-size: 18px;
  line-height: 48px;
  min-height: auto;
  text-transform: none;
  border-radius: 5px;
}
