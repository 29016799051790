@import '../../marketplace.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    padding: 36px 36px 0 36px;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.5 * 100vw);
    max-height: 65vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.bottomActions {
  position: absolute;
  display: flex;
  flex-wrap: wrap;

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;

  @media (--viewportMedium) {
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  margin: 0;
  margin-left: 10px;
  padding: 6px 13px 8px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
    margin-left: 10px;
  }
}

.shareButton {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  margin: 0;
  padding: 6px 13px 8px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (min-width: 1500px) {
    bottom: 19px;
    margin: 0;
    left: 24px;
    right: unset;
  }
}

.logo {
  width: 15px;
  height: 12px;
  margin-right: 10px;
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  width: 100%;

  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 95px;
    margin-left: 60px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);

    padding-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 53px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.instantBooking {
  width: 158px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: var(--instantBookingLabelColor);
  color: var(--matterColorLight);
  margin-bottom: 0;
  font-weight: 900;
  border-radius: 2px;
}

.rentalAgreementText {
  font-size: 14px;
  margin-left: 20px;
}

.rentalAgreementText:hover {
  text-decoration: underline;
  cursor: pointer;
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorNameLink {
  color: var(--marketplaceColorDark);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0;
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.distanceCarToYourLocation {
  color: var(--marketplaceColorDark);
  font-weight: normal;

  @media (--viewportMedium) {
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 15px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColorDark);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.pendingNewline {
  margin-top: 15px;
}

.averageContainer {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
}

.star {
  fill: var(--marketplaceColor);
  width: 14px;
  vertical-align: top;
  margin-right: 5px;
}

.doneTrips {
  @apply --marketplaceH4FontStyles;
  color: var(--marketplaceColorDark);
  margin-left: 5px;
}

.errorWrapper {
  justify-content: center;
  align-items: center;
}

:root {
  --card-padding: 25px;
  --card-height: 500px;
  --card-skeleton: linear-gradient(var(--matterColorBright) var(--card-height), transparent 0);

  --image-height: 250px;
  --image-width: 100%;
  --image-size: var(--image-width) var(--image-height);
  --image-position: 0 0;
  --image-skeleton: linear-gradient(var(--matterColorNegative) var(--image-height), transparent 0);

  --avatar-size: 60px;
  --avatar-position: var(--card-padding) 0;
  --avatar-skeleton: radial-gradient(circle 30px, var(--matterColorNear) 99%, transparent 0);

  --title-height: 32px;
  --title-width: 250px;
  --title-size: var(--title-width) var(--title-height);
  --title-position: var(--card-padding) 300px;
  --title-skeleton: linear-gradient(var(--matterColorAnti) var(--title-height), transparent 0);

  --desc-line-height: 16px;
  --desc-line-skeleton: linear-gradient(
    var(--matterColorAnti) var(--desc-line-height),
    transparent 0
  );

  --desc-line-1-width: 300px;
  --desc-line-1-position: var(--card-padding) 350px;
  --desc-line-2-width: 280px;
  --desc-line-2-position: var(--card-padding) 385px;

  --blur-width: 300px;
  --blur-size: var(--blur-width) calc(var(--card-height));
}

.listingSketch {
  background-repeat: no-repeat;
  height: var(--card-height);
  background-image: var(--desc-line-skeleton), var(--desc-line-skeleton), var(--title-skeleton),
    var(--avatar-skeleton), var(--image-skeleton), var(--card-skeleton);
  background-size: var(--desc-line-2-width) var(--desc-line-height),
    var(--desc-line-1-width) var(--desc-line-height), var(--title-size), var(--avatar-size),
    var(--image-size), 100% 100%;
  background-position: var(--desc-line-2-position), var(--desc-line-1-position),
    var(--title-position), var(--avatar-position), var(--image-position), 0 0;
}
.listingSketch::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0 10px 45px rgba(0, 0, 0, 0.1);

  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--desc-line-skeleton), var(--desc-line-skeleton), var(--title-skeleton),
    var(--avatar-skeleton), var(--image-skeleton), var(--card-skeleton);

  background-size: var(--blur-size), var(--desc-line-2-width) var(--desc-line-height),
    var(--desc-line-1-width) var(--desc-line-height), var(--title-size), var(--avatar-size),
    var(--image-size), 100% 100%;

  background-position: -150% 0, var(--desc-line-2-position), var(--desc-line-1-position),
    var(--title-position), var(--avatar-position), var(--image-position), 0 0;

  background-repeat: no-repeat;
  animation: loading 3s infinite;
}

@keyframes loading {
  to {
    background-position: 350% 0, var(--desc-line-2-position), var(--desc-line-1-position),
      var(--title-position), var(--avatar-position), var(--image-position), 0 0;
  }
}

.mobileWrapper {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.labelGroup {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.wrapperLabelDisinfected {
  position: relative;
  display: inline-block;
  left: 15px;
  font-size: 16px;

  & .numberDays {
    background-color: var(--marketplaceSecondaryColor);
    color: var(--marketplaceColorDark);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    z-index: 3;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -3px;

    & .number {
      font-weight: 900;
      line-height: 18px;
    }
    & .day {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 10px;
      line-height: 12px;
    }
  }
  & .textLabel {
    color: var(--marketplaceSecondaryColor);
    display: inline-block;
    font-weight: 900;
    text-transform: uppercase;
    background-color: #00a3ad;
    border-radius: 5px;
    padding: 8px 17px;
    z-index: 2;
    padding-left: 30px;
    margin-left: 15px;
  }
}
