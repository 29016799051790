@import '../../marketplace.css';

.root {
  background-image: linear-gradient(to right, var(--marketplaceColorDark), var(--marketplaceColor));
}

.content {
  padding: 40px 20px;

  @media (--viewportMLarge) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1600px;
    margin: 0 auto;
    padding: 80px 0 80px;
    align-items: center;
  }
}

.title {
  font-size: 32px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--matterColorLight);
  letter-spacing: 0;
  margin-bottom: 15px;
  @media (--viewportMLarge) {
    display: none;
    font-size: 40px;
    line-height: 48px;
  }
}

.image {
  width: 100%;
  height: 215px;
  margin-top: 30px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    height: 350px;
  }

  @media (--viewportLarge) {
    height: 400px;
  }

  @media (--viewportMLarge) {
    height: 350px;
    margin-right: 40px;
    width: auto;
    margin-bottom: unset;
  }

  @media screen and (min-width: 1440px) {
    height: 450px;
    margin-right: unset;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}


.row {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}


.steps {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (--viewportLarge) {
    flex-basis: 100%;
  }
}

.step {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  flex: 0 0 100%;
  max-width: 100%;
  @media (--viewportLarge) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 20px 50px 20px 20px;

    &:hover {
      background-color: #00a3ad80;
      border-radius: 10px;

      & .circleIndex {
        border: solid 1px var(--marketplaceSecondaryColor);
        color: var(--marketplaceSecondaryColor);
        border-width: 2px;
      }
    }
  }
}

.circleIndex {
  width: 28px;
  min-width: 28px;
  height: 28px;
  border-radius: 50%;
  border: solid 1px var(--marketplaceSecondaryColor);
  font-size: 17px;
  line-height: 23px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceSecondaryColor);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--viewportLarge) {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-color: var(--matterColorLight);
    color: var(--matterColorLight);
    border-width: 2px;
    font-size: 24px;
    line-height: 32px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  color: var(--matterColorLight);

  @media (--viewportLarge) {
    margin-left: 30px;
  }
}

.stepTitle {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0;
  font-weight: var(--fontWeightBold);

  @media (--viewportLarge) {
    font-size: 24px;
    line-height: 32px;
  }
}

.stepDesc {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: var(--fontWeightMedium);

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 26px;
  }
}

.deskTitle {
  display: none;

  @media (--viewportMLarge) {
    display: block;
    margin-bottom: 30px;
  }
}
