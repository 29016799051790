@import '../../marketplace.css';

.root {
  display: flex;
  justify-content: space-between;
  background-color: var(--matterColorBright);
  position: relative;
  flex-direction: column;
}

.longInfo {
  flex-wrap: wrap;
}

.filters {
  padding-left: 20px;
  padding-right: 20px;
  & > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
  }
  @media (max-width: 767px) {
    padding: 0 8px;
    flex: 0 0 70% !important;
    max-width: 70% !important;
  }
}

.sorts {
  display: flex;
  justify-content: flex-end;
  color: rgba(51, 51, 51);
  @media (max-width: 767px) {
    padding: 0 8px;
    flex: 0 0 30% !important;
    max-width: 30% !important;
    display: block;
    justify-content: flex-end;
    display: flex;
  }
}

.sorts > div > button {
  border: 1px solid rgba(230, 230, 230, 1);
  border-radius: 4px;
  padding: 7px 12px;
  margin: 5px 10px;
  font-size: 18px;
  line-height: normal;
  display: inline-flex;
  align-items: center;
  color: rgba(51, 51, 51);
  font-weight: 500;
  @media (max-width: 767px) {
    margin: 0;
    width: 100%;
    justify-content: space-between;
  }
}
.sorts button img {
  margin-left: 7px;
  @media (max-width: 767px) {
    width: 12px;
  }
}

.searchOptions {
  display: block;
  margin: 0;
}

.searchResultSummary {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  margin-top: 9px;
  margin-bottom: 11px;

  /* parent uses flexbox: this defines minimum width for text "6 results" */
  flex-basis: 55px;
  flex-shrink: 0;
}

.noSearchResults {
  @apply --marketplaceH4FontStyles;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-shrink: 0;
}

.resultsFound {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  display: inline-block;
  margin-right: 24px;
}

.searchFiltersPanelClosed {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  background-color: var(--matterColorLight);
  color: var(--marketplaceColorDark);

  display: inline-block;
  padding: 7px 16px 7px 16px;
  font-size: 18px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  font-weight: 300;
  &:focus {
    outline: none;
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border-color: #b2b2b2;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
  &:hover {
    outline: none;
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border-color: #b2b2b2;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  background-color: var(--matterColorLight);
  color: var(--marketplaceColorDark);

  display: inline-block;
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);
  font-weight: 300;
  &:hover,
  &:focus {
    background-color: var(--matterColorLight);
    color: var(--marketplaceColorDark);
    border: 1px solid var(--marketplaceColorDark);
  }
}

.row {
  display: -webkit-flex; /* Safari */
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  flex-wrap: wrap;
  align-items: center;
  @media (--viewportMedium) {
    flex-direction: row;
  }
  @media (max-width: 767px) {
    margin-left: -8px;
    margin-right: -8px;
  }
}

.column {
  padding-left: 20px;
  padding-right: 20px;
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column4 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column8 {
  flex: 0 0 80%;
  max-width: 80%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column2 {
  flex: 0 0 20%;
  max-width: 20%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column3 {
  flex: 0 0 25%;
  max-width: 25%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column80 {
  flex: 0 0 80%;
  max-width: 80%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column20 {
  flex: 0 0 20%;
  max-width: 20%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.filterWrapper {
  @media (max-width: 767px) {
    padding-right: 20px;
  }
}
.filterWrapper button {
  border: 1px solid rgba(230, 230, 230, 1);
  border-radius: 4px;
  padding: 7px 12px;
  margin: 5px;
  font-size: 18px;
  line-height: normal;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  color: #4a4a4a;
  font-weight: 500;
  background: #ffffff !important;
  @media (max-width: 767px) {
    margin: 0;
    width: 100%;
    justify-content: space-between;
  }
}
.filterWrapper button img {
  margin-left: 7px;
  @media (max-width: 767px) {
    width: 12px;
  }
}

/* Search filter css */
.longTerm_Search_Filters {
  position: absolute;
  background: #fff;
  width: 350px;
  top: 100%;
  left: 10px;
  padding: 20px;
}

.filterSlection .rangeSliderInput {
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 30%);
  @media (--viewportMedium) {
    height: 120px;
  }
}

.range_Filters,
.customRadioOuter {
  @media (--viewportMobile) {
    margin: 15px 20px;
  }
}

.range_Filters {
  @media (--viewportMobile) {
    margin-bottom: 30px;
  }
}

.price_filter_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.filter_title {
  margin: 0;
  font-size: 16px;
  color: #026786;
}

.filter_range {
  color: #4a4a4a;
  font-size: 16px;
}

.filterSlection {
  position: relative;
  display: inline-flex;
  @media (--viewportMobile) {
    display: flex;
  }
}

/* RangeSlider */
.sliderfill :global(.rangeslider-horizontal) {
  box-shadow: none;
  height: 6px;
}

.sliderfill :global(.rangeslider-horizontal .rangeslider__fill) {
  box-shadow: none;
  background-color: #00a3ad;
}

.sliderfill :global(.rangeslider-horizontal .rangeslider__handle) {
  border: 2px solid #00a3ad;
  background: #fff;
  box-shadow: none;
  height: 24px;
  width: 24px;
  border-radius: 100%;
}

.sliderfill :global(.rangeslider-horizontal .rangeslider__handle::after) {
  background: initial;
  box-shadow: none;
}

.sliderfill :global(.rangeslider__handle-tooltip) {
  width: auto;
  height: auto;
  padding: 0px 5px;
  text-align: center;
  position: absolute;
  background-color: #00a3ad;
  font-weight: normal;
  font-size: 12px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: white;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  top: auto;
  bottom: -35px;
}

.sliderfill :global(.rangeslider__handle-tooltip::after) {
  border-top: none;
  border-bottom: 8px solid #00a3ad;
  bottom: 24px;
}

.sliderfill :global(.rangeslider__handle-tooltip span) {
  margin-top: 8px;
}

/* Filter Modal */
.root :global(.Modal_container) {
  padding: 0;
}

.rentalCard {
  width: 100%;
}

.filterContentClass {
  overflow-y: auto;
  margin: -20px;
  padding-bottom: 120px;
}

.rentalModal :global(.Modal_isOpen__l3Mke .Modal_scrollLayer__1ZL1w) {
  background-color: #666666;
}

.rentalModal :global(.Modal_close__1dtxC) {
  position: absolute;
  padding: 16px;
}

.rentalCarModalScrollLayer {
  background: transparent !important;
}

.rentalCarModalCloseClass {
  position: absolute;
}

.rentalCarModalContainer {
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  padding: 20px;
  overflow: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.rentalCarModalContainer > button {
  position: absolute;
  padding: 18px 25px;
}

.rentalCard .head {
  color: #026786;
  font-size: 22px;
  margin: 20px;

  @media (--viewportMobile) {
    margin: 15px 20px;
  }
}

.rentalCard .border {
  border: 1px solid #e6e6e6;
}

.fixedBottomBtn {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  background: #fff;
  -webkit-box-shadow: 0px -3px 10px 0px rgba(243 243 243);
  -moz-box-shadow: 0px -3px 10px 0px rgba(243 243 243);
  box-shadow: 0px -3px 10px 0px rgb(243 243 243);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  @media (--viewportLarge) {
    display: none;
  }
}
.fixedBottomBtn .button {
  background: #00a3ad;
  border: 0;
  color: #fff;
  font-size: 20px;
  padding: 14px;
  border-radius: 5px;
  width: 160px;
}

.fixedBottomBtn .monthWisePrice {
  @media (--viewportMobile) {
    display: flex;
    flex-direction: column;
  }
}

.fixedBottomBtn .monthWisePrice .link {
  text-decoration: underline;
  font-size: 14px;
  color: #00a3ad;
}

/* custom checkbox */

.customRadioOuter .filter_title {
  margin-bottom: 10px;
}

.customRadioOuter input[type='checkbox'] {
  position: absolute;
  left: -999999px;
}

.customRadioOuter input[type='checkbox'] + label span:first-child {
  opacity: 0;
  margin-right: 6px;
}

.customRadioOuter input[type='checkbox'] + label span:nth-child(2) {
  font-size: 14px;
  min-width: 300px;
  line-height: initial;
}

.customRadioOuter input[type='checkbox'] + label {
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid #00a3ad;
  margin-bottom: 8px;
}
.customRadioOuter input[type='checkbox'] + label::before {
  content: '';
  left: 2px;
  top: 2px;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  transition: 0.1s all ease;
}

.customRadioOuter input[type='checkbox']:checked + label::before {
  /* left: 19px; */
  top: 2px;
  background: #00a3ad;
}

/* MultiSlider css */

.multiSliderCss :global(.multi-range-slider) {
  display: flex;
  position: relative;
}

.multiSliderCss :global(.bar) {
  display: flex;
  height: 6px;
  border-radius: 10px;
}

.multiSliderCss :global(.bar-left) {
  width: 25%;
  background-color: #eff4f6;
  border-radius: 10px 0px 0px 10px;
  padding: 4px 0px;
}
.multiSliderCss :global(.bar-right) {
  width: 25%;
  background-color: #eff4f6;
  border-radius: 0px 10px 10px 0px;
}
.multiSliderCss :global(.input-type-range) {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  opacity: 0;
  pointer-events: none;
}
.multiSliderCss :global(.bar-inner) {
  background-color: #00a3ad;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  position: relative;
  justify-content: space-between;
}
.multiSliderCss :global(.bar-inner-left) {
  width: 50%;
}
.multiSliderCss :global(.bar-inner-right) {
  width: 50%;
}
.multiSliderCss :global(.thumb) {
  background-color: #fff;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.multiSliderCss :global(.thumb::before) {
  content: '';
  background-color: inherit;
  position: absolute;
  width: 20px;
  height: 20px;
  border: solid 2px #00a3ad;
  border-radius: 50%;
  z-index: 1;
  margin: -8px -12px;
  cursor: pointer;
}
.multiSliderCss :global(.input-type-range:focus + .thumb::after) {
  content: '';
  position: absolute;
  top: -4px;
  left: -7px;
  width: 11px;
  height: 11px;
  z-index: 2;
  border-radius: 50%;
  border: dotted 1px #00a3ad;
}
.multiSliderCss :global(.labels) {
  display: none;
}
.multiSliderCss :global(.caption) {
  position: absolute;
  width: 2px;
  height: 2px;
  bottom: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  display: none;
}
.multiSliderCss :global(.thumb .caption *) {
  position: absolute;
  min-width: auto;
  height: auto;
  padding: 5px;
  font-size: 12px;
  text-align: center;
  background-color: #00a3ad;
  border-radius: 4px;
  color: white;
  white-space: nowrap;
}
.multiSliderCss :global(.thumb .caption::after) {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  border-top: none;
  border-bottom: 8px solid #00a3ad;
  bottom: 18px;
}
.multiSliderCss :global(.thumb:active .caption) {
  display: flex;
}
.multiSliderCss :global(.input-type-range:focus + .thumb .caption) {
  display: flex;
}

.inputWrapper {
}
.priceFilterSubmit {
  padding: 20px 0 0;
  display: flex;
  justify-content: flex-end;
  color: #00a3ad;
}
.priceFilterSubmit > .filter_range {
  color: #00a3ad;
  cursor: pointer;
  margin-left: 10px;
}

.mobileFilterWrapper {
  padding: 15px 20px;
}
.rootClassName {
  padding: 0;
}
.labelClassName {
  color: #026786;
  font-size: 16px;
}
.labelClassName > button > div {
  margin: 0;
}
.filterCategoryElement fieldset,
.filterCategoryElement button {
  padding: 0;
}
.filterCategoryElement form button {
  margin-bottom: 10px;
}
.filterCategoryElement fieldset li {
  margin-bottom: 8px;
}

.filterCategoryElement input[type='checkbox'] + label {
  font-size: 14px;
  font-weight: 400;
  color: #4a4a4a;
  position: relative;
  padding-left: 20px;
}
.filterCategoryElement input[type='checkbox'] + label:before {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  border: 1px solid #00a3ad;
  left: 0;
  top: 2px;
}
.filterCategoryElement input[type='checkbox']:checked + label:before {
  border: 1px solid #00a3ad;
  background: #00a3ad;
  box-shadow: inset 0 0 0 2px #ffffff;
}
.filterCategoryElement input + label span {
  font-size: 14px;
  line-height: 1.42;
}
.filterCategoryElement input + label span:first-child {
  display: none;
}

.fullHeight > button + div {
  max-height: 60vh;
  overflow: auto;
}
.clearbutton {
  display: none;
}