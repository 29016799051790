@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  padding: 0 24px 96px 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (--viewportLarge) {
    padding: 0 36px 140px 36px;
  }
}

.listingCard {
  margin-bottom: 36px;
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-right: 0;
  }

  @media (--viewportMLarge) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-right: 0;
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex: 0 0 25%;
    max-width: 25%;
    margin-right: 0;
  }
}
.fullWidthListingCard {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  margin-right: 0;

  /* @media (--viewportMLarge) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-right: 0;
  }
  */
  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex: 0 0 25%;
    max-width: 25%;
    margin-right: 0;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    /* margin-right: 0; */
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    /* margin-right: 0; */
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.mobileProductCount {
  padding: 20px 10px 8px;
}
.mobileProductCount .numberOfResults {
  font-size: 14px;
  line-height: 1.357;
  color: #026786;
}
.mobileProductCount .placeOfResults {
  font-size: 20px;
  line-height: 1.36;
  color: #026786;
  font-weight: 700;
}
