@import '../../marketplace.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  background-color: var(--matterColorLight);
  color: var(--marketplaceColorDark);

  padding: 7px 16px 7px 16px;
  font-size: 18px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  text-transform: none;
  font-weight: 300;
  &:hover, &:focus {
    background-color: var(--matterColorLight);
    color: var(--marketplaceColorDark);
    outline: none;
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);
  text-transform: none;

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColor);
    border: 1px solid var(--marketplaceColorDark);
  }

  &:focus {
    background-color: var(--matterColorlight);
    border: 1px solid var(--marketplaceColorDark);
  }
}
