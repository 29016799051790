@import '../../marketplace.css';

.root {
  padding-top: 15px;
  padding-bottom: 15px;
}

.days {
  display: flex;
  flex-direction: row;
  user-select: none;
}

.day {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  line-height: 22px;
  font-weight: var(--fontWeightMedium);
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);
  margin-right: 10px;
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
    font-size: 14px;
    line-height: 1.2;
  }
}

.selectedDay {
  color: var(--marketplaceColorDark);
  background: #efefef;
}

.label {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  line-height: 1.375;
  font-weight: 300;
}
