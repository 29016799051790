@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin-top: 25px
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperText;
}
.validationReferralError{
  font-size: 16px;
  color: #ff0000;
  line-height: 1.2;
  padding-top: 10px;
}
.invalidPassword {

  margin-top: 40px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}
.bottomWrapper {
  padding-bottom: 18px;
}
.bottomWrapper p {
  margin: 0 0 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: rgba(34, 34, 34, 0.52);
}
.bottomWrapper p a {
  color: #296683;
}
.submitButton {
  height: 48px;
  background: #F8CE47;
  border: 1px solid #FFCD2D;
  border-radius: 6px;
  text-transform: capitalize;
  margin-bottom: 10px;
  color: #296683;
  font-size: 16px;
  line-height: normal;
  font-weight: 500;
}
.submitButton:hover, .submitButton:focus {
  background: #FFC60F;
}
.submitButton:disabled {
  border: 1px solid #e7e7e7;
}

.checkboxWrapper input {
  position: absolute;
  left: -99999px;
}
.checkboxWrapper input + label {
  position: relative;
  padding-left: 32px;
  font-size: 12px;
  line-height: 19px;
  color: rgba(34, 34, 34, 0.52);
  font-weight: 400;
  padding-top: 10px;
  cursor: pointer;
}
.checkboxWrapper input + label:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  border: 1px solid rgba(204, 204, 204, 0.6);
  border-radius: 6px;
  top: 7px;
  left: 0;
  transition: 0.3s all cubic-bezier(0.18,0.52,0.49,0.85);
}
.checkboxWrapper input + label:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background: url("../../assets/check-solid.svg");
  background-position: center center;
  background-size: 12px;
  top: 7px;
  left: 0;
  background-repeat: no-repeat;
  transform: scale(0);
  transition: 0.3s all cubic-bezier(0.18,0.52,0.49,0.85);
}
.checkboxWrapper input:checked + label:before {
  background: #296683;
  border-color: #296683;
}
.checkboxWrapper input:checked + label:after {
  transform: scale(1);
}


.newInput {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0 0 20px;
}

.newInput input,
.newInput select {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 16px;
  line-height: 24px;
  color: #7C7C7C;
  width: 100%;
  margin: 0;
  background-position: calc(100% - 10px) center;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}

.newInput select {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 7px 45px 7px 15px;
}

.newInput textarea {
  height: 120px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 15px 15px;
  font-size: 20px;
  margin: 0;
  resize: none;
  box-sizing: border-box;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}
.newInput label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #4a4a4a;
  @media (max-width: 767px) {
    margin-bottom: 5px;
    font-size: 14px;
  }
}