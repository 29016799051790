@import '../../marketplace.css';

.root {
  padding: 10px 20px;
  
  @media (--viewportMedium) {
    padding: 40px 36px;
  }

  @media (--viewportMLarge) {
    padding: 40px calc((100vw - 1100px) / 2);
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);
  font-weight: 900;

  @media (--viewportMedium) {
    font-size: 38px;
  }
}

.boxList {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.box {
  width: 100%;
  margin: 0 auto 35px;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    width: 70%;
  }

  @media (--viewportLarge) {
    flex-basis: 49%;
    margin: 0;
  }
}

.boxTitle {
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: var(--marketplaceColor);
  margin-bottom: 15px;

  @media (--viewportMedium) {
    font-size: 24px;
  }
  
  @media (--viewportLarge) {
    height: 60px;
  }
}

.boxContent {
  width: 100%;
  min-height: auto;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  padding: 18px 22px;

  @media (--viewportMedium) {
    min-height: 165px;
  }
}

.moreQuestion {
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: var(--marketplaceColor);
  margin: 0 auto 20px;

  @media (--viewportMedium) {
    font-size: 24px;
    width: 70%;
  }

  @media (--viewportLarge) {
    width: 100%;
    margin-bottom: 0px;
  }
}

.listInContent {
  padding-left: 22px;
  list-style-type: disc;
}

.listInContent li {
  padding: 5px 0px;
}

.moreAnswer {
  color: var(--marketplaceSecondaryColor);
  cursor: pointer;
}

.lessAnswer {
  color: var(--marketplaceSecondaryColor);
  cursor: pointer;
}

.link {
  color: white;
}
