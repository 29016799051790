@import '../../marketplace.css';

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

:root {
  --ManageAvailabilityCalendar_gridColor: #e0e0e0;
  --ManageAvailabilityCalendar_availableColor: #ffffff;
  --ManageAvailabilityCalendar_availableColorHover: #fafafa;
  --ManageAvailabilityCalendar_blockedColor: #ebebeb;
  --ManageAvailabilityCalendar_partiallyblockedColor: #FCEEEE;
  --ManageAvailabilityCalendar_bookedBlockedColor: #E2F3F4;
  --ManageAvailabilityCalendar_bookedBlockedColorHover: #E2F3F4;
  --ManageAvailabilityCalendar_partiallyblockedColorHover: #FCEEEE;
  --ManageAvailabilityCalendar_reservedColor: #E2F3F4;
  --ManageAvailabilityCalendar_reservedColorHover: #E2F3F4;
  --ManageAvailabilityCalendar_failedColor: #fff2f2;
}

.root {
  @apply --marketplaceH5FontStyles;
  margin-top: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  z-index: 0;

  & :global(.CalendarMonth_caption) {
    @apply --marketplaceH3FontStyles;
    font-weight: var(--fontWeightMedium);
    text-align: left;
    padding-top: 18px;
    padding-bottom: 55px;

    margin-left: 97px;
    margin-top: 0;
    margin-bottom: 0;

    & strong {
      font-weight: 500;
      letter-spacing: 0.2px;
    }
  }

  & :global(.DayPicker) {
    margin: 0 auto;
    box-shadow: none;
  }

  & :global(.DayPicker__horizontal) {
    background-color: transparent;
    margin-left: -18px;
  }

  & :global(.DayPicker_weekHeader) {
    top: 65px;

    & small {
      @apply --marketplaceH5FontStyles;
    }
  }
  & :global(.DayPicker_weekHeader_li) {
    text-align: left;
  }

  & :global(.DayPickerNavigation__horizontal) {
    width: 80px;
    margin-left: 18px;
    position: relative;

    & :first-child {
      border-top-left-radius: 2px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 2px;
    }

    & :last-child {
      /* The navigation arrows have 9px padding. Add -9px margin to
      align the arrows with the calendar */
      left: 35px;
      right: unset;
      border-top-left-radius: 0;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 0;
    }
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 13px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px var(--matterColorNegative);
    background-color: var(--matterColorBright);

    &:hover {
      background-color: var(--ManageAvailabilityCalendar_availableColorHover);

      & svg {
        fill: var(--matterColorDark);
      }
    }
    &:focus {
      outline: none;
      background-color: var(--ManageAvailabilityCalendar_availableColorHover);
      & svg {
        fill: var(--matterColorDark);
      }
    }
  }

  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }

  & :global(.CalendarMonth) {
    background-color: transparent;
  }

  & :global(.CalendarMonth_table) {
    border: 1px solid var(--ManageAvailabilityCalendar_gridColor);
  }

  & :global(.CalendarDay__default) {
    border: 0;
    background-color: var(--ManageAvailabilityCalendar_gridColor);

    &:hover {
      border: 0;
      background-color: var(--ManageAvailabilityCalendar_gridColor);
    }
  }

  & :global(.CalendarDay__selected) {
    color: var(--matterColor);
  }

  & :global(.DayPickerKeyboardShortcuts_show__bottomRight) {
    right: -20px;
    right: 19px;
    bottom: -24px;
  }
}

.dayWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.day {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 8px;

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_availableColorHover);
  }
}

.dayNumber {
  font-size: 14px;
  line-height: 14px;
}

.default {
  composes: day;
  background-color: var(--ManageAvailabilityCalendar_availableColor);
}

.outsideRange {
  background-color: var(--ManageAvailabilityCalendar_blockedColor);

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  }

  & .dayNumber {
    text-decoration: line-through;
  }
}

.today {
  background-color: var(--ManageAvailabilityCalendar_availableColor);

  & .dayNumber {
    position: relative;
    text-decoration: none;

    /* underline */
    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 100%;
      right: 0;
      bottom: -4px;
      left: 0;
      margin: 0 auto;
      background-color: var(--matterColor);
    }
  }
}

.p_blocked {
  background: repeating-linear-gradient(
    135deg, /* Diagonal lines from top-left to bottom-right */
    #6b6b6b, /* Darker line color */
    #6b6b6b 1px, /* Line width */
    #b3b3b3 1px, /* Space between lines */
    #b3b3b3 10px /* Pattern repeat */
  );
  pointer-events: none; /* Disable interactions */
  position: relative; /* For pseudo-elements or child elements */
  height: 100%; /* Ensure the background covers the cell */
  width: 100%; /* Ensure the background covers the cell */
}
.p_blocked:hover {
  background: repeating-linear-gradient(
    135deg,
    #5a5a5a, /* Darker hover line color */
    #5a5a5a 2px,
    #a3a3a3 2px,
    #a3a3a3 10px
  );
}

.blocked {
  background-color: var(--ManageAvailabilityCalendar_blockedColor);

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
  }
}

.paritallyblocked {
  background-color: var(--ManageAvailabilityCalendar_partiallyblockedColor);

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_partiallyblockedColorHover);
  }
}

.bookedBlocked {
  background-color: var(--ManageAvailabilityCalendar_bookedBlockedColor);

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_bookedBlockedColorHover);
  }
}

.reserved {
  background-color: var(--ManageAvailabilityCalendar_reservedColor);

  &:hover {
    background-color: var(--ManageAvailabilityCalendar_reservedColorHover);
  }
}

.inProgress {
  width: 12px;
  height: 12px;
  stroke: var(--matterColor);
  stroke-width: 3px;
}

.exceptionError {
  opacity: 1;

  /* Animation */
  animation-name: errored;
  animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

@keyframes errored {
  30%,
  70% {
    background-color: var(--ManageAvailabilityCalendar_failedColor);
  }
}

.monthElement {
  display: flex;
  position: relative;
}

.monthString {
  font-weight: var(--fontWeightSemiBold);

  &:first-letter {
    text-transform: uppercase;
  }
}

.monthInProgress {
  width: 12px;
  height: 12px;
  margin-top: 7px;
  margin-left: 8px;

  stroke: var(--marketplaceColor);
  stroke-width: 4px;
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 6px 0 24px 0;
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--matterColor);
  fill: var(--matterColor);
}

.legend {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.legendRow {
  display: flex;
  flex-grow: row;
  line-height: 24px;
  margin-right: 18px;
  margin-bottom: 6px;
}
.legendColor {
  width: 20px;
  height: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  margin-top: 2px;
  margin-right: 8px;
}
.legendAvailableColor {
  composes: legendColor;
  background-color: var(--matterColorLight);
  border: solid 2px var(--matterColorNegative);
}
.legendHourlyAvailableColor {
  composes: legendColor;
  background-color: var(--ManageAvailabilityCalendar_partiallyblockedColor);
}
.legenbookedBlockedColor {
  composes: legendColor;
  background-color: var(--ManageAvailabilityCalendar_bookedBlockedColor);
}
.legendReservedColor {
  composes: legendColor;
  background-color: var(--ManageAvailabilityCalendar_reservedColor);
}
.legendBlockedColor {
  composes: legendColor;
  background-color: var(--ManageAvailabilityCalendar_blockedColorHover);
}

.legendText {
  @apply --marketplaceH4FontStyles;
  white-space: nowrap;
  margin: 0;
}

@media (--viewportSmall) {
  /* day aka table cell content should have bigger paddings when there's more space */
  .day {
    padding: 16px;
  }
  .dayNumber {
    font-size: 16px;
    line-height: 16px;
  }
}

/* Standard break points */
@media (--viewportMedium) {
  .root {
    margin-top: 0;
    margin-bottom: 0;

    & :global(.CalendarMonth_caption) {
      font-size: 24px;
      padding-bottom: 62px;
    }
    & :global(.DayPicker_weekHeader) {
      top: 67px;
    }

    & :global(.DayPickerNavigation__horizontal) {
      & :last-child {
        /* The navigation arrows have 9px padding. Add -9px margin to
        align the arrows with the calendar */
        left: 39px;
      }
    }

    & :global(.DayPickerNavigation_button__horizontal) {
      width: 40px;
      height: 40px;
    }
  }

  .inProgress {
    width: 16px;
    height: 16px;
  }

  .monthInProgress {
    width: 16px;
    height: 16px;
    margin-top: 5px;
  }
  .error {
    margin: 8px 0 24px 0;
  }

  .legendRow {
    margin-right: 24px;
    margin-bottom: 8px;
  }
  .legendText {
    margin: 0;
  }
}

@media (--viewportLarge) {
  .root {
    & :global(.DayPickerNavigation_button__horizontal) {
      background-color: var(--matterColorLight);
    }
  }

  .legend {
    margin: 0;
  }
}

@media (--viewportXLarge) {
  .root {
    & :global(.CalendarMonth_caption) {
      margin-left: 108px;
    }
  }
}

.infoText {
  padding: 8px 0 5px;
  line-height: normal;
  display: flex;
  align-items: flex-start;
  flex: 0 0 100%;
  max-width: 100%;
}
.infoText .infoIcon {
  display: block;
  margin-right: 5px;
  margin-top: 0;
  max-width: 14px;
  flex: 1;
}
.infoText img {
  display: block;
}
.infoText p {
  flex: 1;
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  color: #4a4a4a;
  margin: 0;
}

.customToggleInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    margin-top: -1px;
  }
}
.customToggleInput label {
  font-size: 20px;
  line-height: 22px;
  font-weight: 300;
  padding: 0;
  @media (max-width: 767px) {
    flex: 1;
  }
}

.toggleLabels {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex: 1;
    max-width: 80%;
  }
}
.toggleInput {
  @media (max-width: 767px) {
    flex: 1;
    max-width: 20%;
    display: flex;
    justify-content: flex-end;
  }
}
.customClass {
  min-width: 16px;
  max-width: 16px;
}

/* Modal design */
.availabilityContainerClass {
  flex-basis: 480px;
  min-height: auto;
  height: auto;
  padding: 0;
  margin-top: 12.5vh;
  margin-bottom: 12.5vh;
  border-bottom: 8px solid #00a3ad;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  border: 0;
}
.availabilityCloseIcon {
}
.availabilityScrolllayer {
}
.availabilityCloseButton {
}
.availabilityCloseText {
  display: none;
}
.availabilityContentClass {
}

.modalHeader{
  padding: 20px 30px;
  border-bottom: 1px solid #E6E6E6;
}
.modalHeader h2{
  font-size: 28px;
  line-height: normal;
  color: #0D6786;
  margin: 0;
  font-weight: 700;
}
.bookedBlock{
  padding: 20px 30px;
  border-bottom: 1px solid #E6E6E6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.bookedBlock .userAvatar{
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background: #E6E6E6;
}
.bookedBlock .userDetails{
  padding-bottom: 10px;
}
.bookedBlock .userDetails span{
  display: block;
  color: #4A4A4A;
  font-weight: 300;
}
.bookedBlock .userDetails span.userName{
  font-size: 20px;
  line-height: normal;
  margin-bottom: 10px;
}
.bookedBlock .userDetails span.userTime{
  font-size: 14px;
  line-height: normal;
}

.fullDayBooking{
  padding: 20px 30px;
  border-bottom: 1px solid #E6E6E6;
}
.slotSelection .fieldWrapper{
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  /* flex-wrap: wrap; */
  flex: 1;
  align-items: center;
  margin-bottom:20px;
}
.slotSelection .fieldWrapper .field{
  padding-left: 10px;
  padding-right: 10px;
  flex: 0 0 50%;
  max-width: 46%;
}
.slotSelection .fieldWrapper .field label{
  font-size: 16px;
  line-height: 22px;
  color: #4A4A4A;
  font-weight: 300;
}

.newInput {
  flex: 0 0 100%;
  max-width: 100%;
  /* margin: 0 0 20px; */
}
.newInput input,
.newInput select {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  width: 100%;
  margin: 0;
  background-position: calc(100% - 10px) center;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}

.newInput select {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 7px 45px 7px 15px;
}

.newInput textarea {
  height: 120px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 15px 15px;
  font-size: 20px;
  margin: 0;
  resize: none;
  box-sizing: border-box;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}
.newInput label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
  @media (max-width: 767px) {
    margin-bottom: 5px;
  }
}
.addSlotFields{
  font-size: 18px;
  color: #00A3AD;
  font-weight: 300;
  cursor: pointer;
  user-select: none;
}
.modalFooter{
  padding: 20px 30px;
}
.modalFooter .submitButton{
  padding: 20px 30px;
  border-radius: 5px;
}
.locationLoader {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.locationLoader span {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #00a3ad;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 0.75s linear infinite; /* Safari */
  animation: spin 0.75s linear infinite;
}
.selctionOuter{
  display: flex;
  align-items: center;
  position: relative;
}
.selctionOuter .selectionActions{
  flex: 1;
  max-width: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 0;
}
.selectionLoader{
  padding: 0;
}
.selectionRemove{
  margin: 0 0 0 5px;
  padding: 0;
}
.closeIcon{
  cursor: pointer;
}

.presentSlots {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.presentSlotItemContainer{
  padding: 2.5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-right: 0px;
  background: #05949b0d;
  border-radius: 4px;
}

.presentSlotRemoval {
  cursor: pointer;
  margin-left: 20px;
}

.guestBookingLabel{
    font-size: 20px;
    line-height: 22px;
    font-weight: 300;
    padding: 0;
    margin-bottom: 8px;
}
