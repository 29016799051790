@import '../../marketplace.css';

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.prefixContainerCss{
  display: flex;
}

.currencyError{
  position: relative !important;
  margin-bottom: 5px !important;
}
