@import '../../marketplace.css';


.titleContainer {
  color: #00a3ad;
  display: flex;
  padding: 20px !important;
  flex-direction: column;
  border: 1px solid #E6E6E6;
  border-radius: 5px;

  @media (--viewportMedium) {
    padding: 0px;
  }
}

.titleContainer h3 {
  text-align: left;
}

.titleStatusBox {
  /* margin: auto; */
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    margin-left: 15px;
  }
}

.statusBarVerified {
  background-color: var(--marketplaceColorDark);
  border: none;
  color: var(--matterColorLight);
  border-radius: 10px;
  padding: 5px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.statusBarPending {
  background-color: #00a3ad;
  border: none;
  color: var(--matterColorLight);
  border-radius: 10px;
  padding: 5px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}


.statusBarNotVerified {
  background-color: var(--marketplaceSecondaryColor);
  border: none;
  color: var(--matterColorDark);
  border-radius: 10px;
  padding: 5px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.thankYouPage {
  max-width: 700px;
}

.thankYouPage h2 {
  color: var(--marketplaceColorDark);
}

.thankYouPage p {
  color: var(--marketplaceColorDark);
}

.sectionTitle {
  font-size: 18px;
  line-height: normal;
  font-weight: 700;
  margin: 0 0 10px;
  color: #026786;
}

.tripPageDocumentUploadFormRoot {
  padding: 0;
}


.cardTitle {
  font-size: 24px;
  color: #026786;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (--viewportMobile) {
    font-size: 16px;
  }
}

.cardTitle .submissionStatus {
  font-size: 18px;
  line-height: normal;
  font-weight: 700;
  color: #026786;
  background: #E6E6E6;
  border-radius: 4px;
  padding: 8px;
  display: block;
  min-height: 38px;


  @media (--viewportMobile) {
    font-size: 10px;
    min-height: 25px;
  }
}



.verificationPage {
  padding-top: 0 !important;
}

.identityVerificationGuide p {
  color: #4A4A4A !important;
  font-size: 18px !important;

}

.identityVerificationGuideHeading {
  font-weight: 600 !important;
}

.identityVerificationGuideText {
  font-weight: 500 !important;
}

.verificationModalButton {
  margin-top: 45px;
  width: 40%;


  @media (--viewportMedium) {
    /* margin-top: 10px; */
  }

  /* width: 300px; */
}

.verificationModalButton button {
  background: #fecd2a;
  min-height: 50px;
  border-radius: 4px;
  text-transform: initial;
  font-weight: 700;
  font-size: 18px;
  color: #026786;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

.callBackcontent {
  display: flex;
  justify-content: center;

}