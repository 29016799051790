@import '../../marketplace.css';

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.root {
  @apply --marketplaceModalRootStyles;

  @media (--viewportMedium) {
    @apply --authBackgroundImage;
  }
}

.content {
  @apply --marketplaceModalBaseStyles;
}

.contentError {
  border-bottom-color: var(--failColor);
}

.loadingContent {
  display: flex;
  width: 100%;
}

.loadingContent h3 {
  margin-left: 20px;
  margin-top: 0px;
}

.error {
  color: var(--failColor);
}