@import '../../marketplace.css';

.root {
  width: 100%;
}

.signUpTitle {
  font-size: 24px;
  line-height: 30px;
  color: var(--marketplaceSecondaryColor);
  font-weight: var(--fontWeightBold);
  margin-bottom: 30px;

  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 36px;
  }
}

.field {
  margin-bottom: 30px;
}

.input input {
  position: relative;
  border-color: var(--matterColorLight);
  color: var(--matterColorLight);

  &::placeholder {
    color: var(--matterColorLight);
  }

  &:focus::placeholder,
  &:hover::placeholder {
    color: var(--matterColorLight);
    position: absolute;
    top: -20px;
  }
}

.input label {
  color: var(--matterColorLight);
}

.submitBtn {
  border: none;
}

.smallText {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);
  margin-top: 15px;
  color: var(--matterColorLight);
}

.termOfUse {
  color: var(--marketplaceSecondaryColor);
}
