@import '../../marketplace.css';

.root {
  display: inline-block;
  position: relative;
}
.toolTip{
  position: absolute;
  background-color: rgba(0,0,0,0.5);
  font-size: 16px;
  color: #FFFFFF;
  bottom: calc(100% + 6px);
  padding: 10px 20px;
  z-index: 999;
  line-height: normal;
  border-radius: 10px;
  left: 50%;
  margin: 0 auto;
  white-space: nowrap;
  width: auto;
  transform: translateX(-50%);
}
.toolTip::after{
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7.5px 0 7.5px;
  border-color: rgba(0,0,0,0.5) transparent transparent transparent;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
}