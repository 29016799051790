@import '../../marketplace.css';

.root {
  position: relative;
  margin-bottom: 16px;
}

.inline {
  display: flex;
  flex-direction: row;
}

.input {
  border-bottom-color: var(--inputBorderBottomColor);
}
.input:focus + label {
  border-bottom-color: var(--inputBorderBottomColor);
}
.inputSuccess {
  border-bottom-color: var(--inputBorderBottomColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.inputInline {
  margin-left: 12px;
  max-width: 80px;
}

.labelContainer {
  display: flex;
}

.currentCount{
  justify-self: flex-end;
  margin-left: auto;
}

.inputWrapper {
  height: 58px;
  position: relative;
}
.inputWrapper label{
  position: absolute;
  font-size: 16px;
  line-height: 24px;
  color: #7C7C7C;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 16px;
  font-weight: 400;
  transition: 0.3s all cubic-bezier(0.18,0.52,0.49,0.85);
}
.inputWrapper input:focus + label, .inputWrapper.inputFocused input + label{
  position: absolute;
  font-size: 12px;
  top: 25%;
  transform: translateY(-50%);
}
.inputWrapper input{
  height: 100%;
  width: 100%;
  border: 1px solid rgba(204, 204, 204, 0.6);
  border-radius: 9px;
  padding: 24px 16px 10px;
}

.errorMessage {
  margin: 0;
  font-size: 14px;
  margin-top: 5px;
}