@import '../../marketplace.css';

.root {
  @media (--viewportLarge) {
    max-width: 550px;
  }
}

.inputField {
  margin-bottom: 30px;
}

.addressVerificationForm p {
  color: black;
}

.verificationIndication p {
  color: #4A4A4A !important;
  font-size: 18px !important;
  font-weight: 500 !important;

  @media (--viewportMobile) {
    font-size: 15px !important;
  }

}

.displayInlineContainer {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--marketplaceColorDark);

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.locationAddress,
.postalCode,
.genericField,
.country {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.building {
  flex-basis: 100%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 6;

  @media (--viewportMedium) {
    margin-bottom: 32px;
    flex-basis: 60%;
  }
}

.floor {
  flex-basis: 100%;
  flex-shrink: 1;
  flex-grow: 1;

  @media (--viewportMedium) {
    margin-left: 4%;
    flex-basis: 40%;
  }
}

.streetName {
  flex-basis: 100%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 30;
  max-width: 100% !important;

  @media (--viewportMedium) {
    margin-bottom: 0px;
    flex-basis: 70%;
  }
}

.streetName label {
  color: #4a4a4a;
  font-weight: 300;
  font-size: 16px;
}

.streetName .validationClassName {
  position: relative !important;
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.blockNo {
  flex-basis: 100%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 1;

  @media (--viewportMedium) {
    margin-bottom: 32px;
    flex-basis: 20%;
    margin-right: 4%;
  }
}

.readMore {
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorNear);
  margin-bottom: 0px;
}

.learnMore {
  font-size: 16px;
  line-height: 20px;
  color: var(--marketplaceColor);
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--marketplaceColorDark);

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.tripPageRoot {
  max-width: 100%;
}

.newInput {
  flex: 0 0 100%;
  max-width: 100% !important;
  margin: 0;
}

.newInput label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
}

.newInput input,
.newInput select {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  width: 100%;
  margin: 0;
  color: #4a4a4a;
}

.halfInputs {
  margin-left: -10px;
  margin-right: -10px;
}

.halfInputs .newInput {
  padding-left: 10px;
  padding-right: 10px;
  flex: 0 0 50%;
  max-width: 50%;

  @media (--viewportMobile) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}


/* For sticky submit button design */

.stickyButtons {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  z-index: 99;

  @media (max-width: 767px) {
    height: auto;
    box-shadow: none;
  }
}

.stickyButtons .stickButtonsContainer {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.stickyButtons button {
  height: 60px;
  border-radius: 4px;
  max-width: 301px;
  margin-left: auto;

  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    height: 52px;
  }
}

.stickButtonsDescription {
  flex: 0 0 70%;
  max-width: 70%;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    box-shadow: none;
    text-align: left;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 15px 20px;
    background: #eff7f8;
  }
}

.stickButtonsDescription .descIcon {
  margin-right: 10px;
  width: 40px;
  height: 40px;

  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
}

.stickButtonsDescription .descIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.stickButtonsDescription p {
  color: #4a4a4a;
  max-width: 600px;
  line-height: normal;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }
}

.stickButton {
  text-align: right;
  flex: 0 0 30%;
  max-width: 30%;

  @media (max-width: 767px) {
    box-shadow: none;
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 6px 20px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  }
}

.submitVerificationButtonWrapper {
  margin-top: 45px;
  width: 40%;

  @media (--viewportMedium) {
    /* margin-top: 96px; */
  }
}

.submitVerificationButtonWrapper button {
  background: #fecd2a;
  min-height: 50px;
  border-radius: 4px;
  text-transform: initial;

  &:hover {
    background: #fecd2a;
    color: #0d6786;
  }
}


.submitVerificationButtonWrapper button span {
  font-weight: 700;
  font-size: 18px;
  color: #026786;
}