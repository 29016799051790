@import '../../marketplace.css';

.root {

}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    padding: 36px 36px 0 36px;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.5 * 100vw);
    max-height: 65vh;
    object-fit: cover;
    border-radius: var(--borderRadius);
  }
}

.root :global(.slick-dots) {
  bottom: 10px;

  @media (--viewportLarge){
    bottom: 30px;
  }
}

.root :global(.slick-dots li button) {
  background-color: var(--matterColorAnti);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: solid 3px var(--matterColorLight);

  @media (--viewportLarge){
    width: 15px;
    height: 15px;
  }
}

.root :global(.slick-dots li.slick-active button) {
  background-color: var(--matterColorLight);
}