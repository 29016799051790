@import '../../marketplace.css';

.root {
  position: sticky;
  z-index: 81;
  top: 0;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: var(--matterColorDark);
  opacity: 0.6;
}

.loadingImg {
  padding-right: 60px;
}

.loadingMsg {
  color: var(--matterColorLight);
}