@import '../../marketplace.css';

.root {
  display: block;
  padding: 0 20px 72px;
}

.time {
  flex-basis: calc((100% - 72px) / 3);
  border: none;
  border-radius: 0;
  text-align: center;
  font-size: 12px;
  line-height: 19px;
  color: var(--marketplaceColorDark);
  padding: 0;
  user-select: none;
  cursor: pointer;
  &:nth-child(n + 4) {
    margin-top: 11px;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }

  @media (--viewportLarge) {
    flex-basis: calc((100% - 40px) / 5);
    margin-right: 10px;

    margin-top: 0;
    &:nth-child(n + 4) {
      margin-top: 0;
    }

    &:nth-child(n + 6) {
      margin-top: 11px;
    }

    &:nth-child(3n) {
      margin-right: 10px;
    }

    &:nth-child(5n) {
      margin-right: 0;
    }
  }
}
.timeStick{
  width: 100%;
  height: 18px;
  display: block;
  position: relative;
}
.timeStick::before{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 1px;
  height: 100%;
  background: #0D6786;
}
.timeChip {
  display: block;
}

.selectedTime {
  background-color: var(--marketplaceSecondaryColor);
}
.selectedTimePreviewWrapper {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 18px;
}
.selectedTimePreview {
  text-align: center;
  max-width: 130px;
  padding: 0 15px;
  margin: 0 auto;
}
.selectedTimePreview .selectedDatePreview {
  font-size: 14px;
  line-height: normal;
  color: #00a3ad;
  font-weight: 300;
  display: block;
}
.selectedTimePreview .selectedTimePill {
  height: 32px;
  background: rgba(254, 205, 42, 1);
  font-size: 18px;
  line-height: normal;
  font-weight: 700;
  color: #0d6786;
  display: block;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 6px;
  position: relative;
}
.selectedTimePreview .selectedTimePill::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 0 auto;
  height: 20px;
  width: 1px;
  background: #0d6786;
}

.swiperTimePicker {
  border-top: 1px solid #0d6786;
  position: relative;
  min-height: 40px;
}
.swiperTimePicker::after {
  content: "";
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #FECD2A;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  margin-top: -6px;
  border: 0;
  z-index: 2;
}

.submitFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 72px;
  background-color: var(--matterColorLight);
  width: 100%;
  padding: 14px 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
}
.searchBtn {
  @apply --marketplaceButtonStyles;
  background-color: var(--marketplaceSecondaryColor);
  min-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 6px;
  text-transform: none;
  font-size: 18px;
  font-weight: 500;
}