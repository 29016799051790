@import '../../../marketplace.css';

:root {
  --sectionThankYou: {
    color: var(--marketplaceColorDark);
    margin: 15px 10px;
    padding-right: 0;
    padding-bottom: 0;
    @media (--viewportMedium) {
      margin: 15px 20px;
      padding-right: 25%;
      padding-bottom: 100px;
      line-height: 29px;
    }
    & .title {
      font-size: 40px;
      font-weight: 900;
      margin-bottom: 45px;
      line-height: 1.0;
    }

    & .orderNumber {
      font-size: 24px;
      font-weight: 900;
      color: var(--marketplaceColor);
      margin-bottom: 20px;
    }

    & .description {
      color: #555555;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 40px;
    }
  }
}

.background {
  @media (--viewportMedium) {
    background-image: url('../../../assets/landingPageCovid/backgroundCovid.jpg');
    background-size: cover;
    position: relative;
    min-height: 789px;
    padding: 66px 36px 53px;
  }
}

.contentWrapper {
  background-color: var(--matterColorLight);
  width: 100%;
  height: 100%;
  padding: 55px 30px 55px;
  color: var(--marketplaceColor);
  @media (--viewportMedium) {
    width: 100%;
    padding: 40px 20px 40px;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
  }
  @media (--viewportLMLarge) {
    width: 80%;
    padding: 40px 70px 40px;
  }
}

.bannerImage {
  display: block;
  width: 100%;
  height: auto;
  @media (--viewportMedium) {
    display: none;
  }
}

.sectionThankYou {
  @apply --sectionThankYou;
}

.sectionDeclaration {
  @apply --sectionThankYou;
  @media (--viewportMedium) {
    padding-right: 0;
  }
}

.error {
  color: var(--failColor);
}

.nameAndDate {
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
}

.descriptionWrapper {
  padding-right: 25%;
}

.submit{
  border-radius: 6px !important;
  margin-top: 45px;
  background-color: #ffcd05;
  color: #026786;
  text-transform: none;
  font-size: 28px;
  font-weight: 900;
  &:hover {
    background-color: #ffcd05;
  }
  &:focus {
    background-color: #ffcd05;
  }
  @media (--viewportMedium) {
    max-width: 458px !important;
    margin: 20px auto 0;
  }

  @media (--viewportLarge) {
  }
}