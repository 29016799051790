@import '../../marketplace.css';

.root {
  margin-top: 40px;
}

.flexLogo {
  height: 38px;
  width: auto;
}

.title {
  font-size: 32px;
  line-height: 38px;
  color: var(--marketplaceColorDark);
  letter-spacing: -1px;
  font-weight: var(--fontWeightBold);
  text-align: center;
}

.title span {
  display: inline-flex;
}
.title span img {
  display: inline-block;
  margin-left: 15px;
}

.reasons {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin: 20px auto 0;

  @media (--viewportLarge) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    & > * {
      flex-basis: calc(50% - 10px);
    }
    margin-top: 40px;
  }

  @media (--viewportMLarge) {
    & > * {
      flex-basis: calc(25% - 10px);
    }
  }

  @media (--viewportXLarge) {
    max-width: 1600px;
    margin-top: 80px;
    & > * {
      flex-basis: 370px;
    }
  }
}

.reason {
  background-color: #00a3ad1a;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.reasonTitle {
  font-size: 24px;
  line-height: 32px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);
  margin: 20px 0 10px;
}

.reasonDescription {
  font-size: 20px;
  line-height: 32px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);
}
