@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;

  --storyBackgroundColor: #f2f2f2;
}

.root {
  overflow: hidden;
}

/* Rental categories section */
.rentalCarCategories {
  background: #1aa2ac;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 0;
  }
}

.rentalCarCategoriesContainer {
  max-width: 1128px;
  margin: 0 auto;
  padding: 0 20px;
}

.rentalCarCategoriesRow {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
}

.columnEight {
  flex: 0 0 40%;
  max-width: 40%;
  padding: 0 20px;

  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.columnTwo {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 20px;

  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.rentalCarCategories .columnTitle {
  font-size: 18px;
  line-height: normal;
  color: #f8ce47;
  font-weight: 500;
  margin-bottom: 16px;
  position: relative;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    font-size: 14px;
    line-height: normal;
  }
}

.rentalCarCategories .categoriesListing {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    display: block;
    column-count: 4;
    margin-bottom: 40px;
  }
}

.rentalCarCategories .categoriesListing li {
  font-size: 16px;
  line-height: 2;
  color: #ffffff;
  font-weight: 300;
  flex: 0 0 50%;
  max-width: 50%;

  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.rentalCarCategories .columnTwo .categoriesListing li {
  flex: 0 0 100%;
  max-width: 100%;
}

.predictionsDropDown {
  top: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  background: #ffffff;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000024;
  border-radius: 4px;
  margin-top: 7px;
  padding: 10px;
  z-index: 1;
  /* opacity: 0;
  visibility: hidden; */
}

.isVisible {
  opacity: 1;
  visibility: visible;
}

.predictionsDropDown .currentLocation svg {
  margin: 0 10px 0 0;
}

.predictionsDropDown ul {
  margin: 0 0 10px;
}

.predictionsDropDown ul li {
  color: #0d6786;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  cursor: pointer;
  position: relative;
}

.predictionsDropDown ul li::before {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  width: 0;
  height: 100%;
  background: #0d6786;
}

.predictionsDropDown ul li:hover {
  opacity: 0.85;
}

.predictionsDropDown ul li:hover::before {
  width: 5px;
}

.predictionsDropDown .searchLabels {
  color: #b2b2b2;
  height: 40px;
  font-size: 16px;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 8px 20px;
}

.predictionsDropDown .suggestionTitle {
  font-size: 16px;
  line-height: normal;
  color: #b2b2b2;
}

/* Location landing page */
.sectionLocationCars {
  width: 100%;
}

.sectionLocationCars .columnListings {
  padding-bottom: 60px;
  background: #ffffff;

  @media (max-width: 767px) {
    padding-bottom: 30px;
  }
}

.sectionLocationCars .sectionTitle {
  padding: 105px 0 60px;
  margin-bottom: 0;

  @media (max-width: 767px) {
    padding: 42px 0 10px;
    font-size: 30px;
  }
}

.sectionLocationCars .listingGrid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;

  @media (max-width: 767px) {
    display: block;
  }
}

.sectionLocationCars .listingCardWrapper {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
  margin-bottom: 50px;

  @media (max-width: 1024px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px 0 0;
    margin-bottom: 0;
  }

  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 10px;
  }
}

.carsListingAndMaps {
  overflow: hidden;
}

.reusableMap {
  display: none;
}

.searchForTheLocation {
  max-width: 100%;
  height: 60px;
  border: 1px solid #fecd2a;
  background: #fecd2a;
  padding: 10px 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #026786;
  font-size: 20px;
  line-height: normal;
  text-decoration: none !important;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;

  @media (max-width: 767px) {
    font-size: 18px;
    padding: 10px;
  }
}

.searchForTheLocation svg {
  margin-right: 10px;

  @media (max-width: 767px) {
    margin-right: 5px;
  }
}

.mobileMapHolder {
  @media (max-width: 767px) {
    height: 600px;
    padding: 0;
    position: relative;
  }

  &::after {
    content: '';

    @media (max-width: 767px) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }
}

.locationLandingMapLayout {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 41.666667%;

  @media (max-width: 767px) {
    height: 100%;
    width: 100%;
    position: static;
  }
}

.contentModal {
  height: 100%;
}

.videoContentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    display: block;
  }
}

.video {
  width: 280px;
  background-color: var(--matterColorAnti);
  cursor: pointer;

  @media (--viewportMedium) {
    width: 500px;
    /* height: 280px; */
  }

  @media (--viewportLarge) {
    width: 313px;
    /* height: 150px; */
  }
}

.videoLogo {
  width: 100%;
}

.iframeVideo {
  width: 100%;
  height: 40%;

  @media (--viewportMedium) {
    height: 100%;
  }
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 40px 0 0 0;

  @media (--viewportMedium) {
    padding: 0px;
    flex-basis: 576px;
    min-height: 0;
    min-width: 80vw;
    height: 48vw;
    margin-top: calc(50vh - 30vw);
    border-bottom: 0px solid transparent;
  }

  @media (--viewportLarge) {
    margin-top: calc(50vh - 25vw);
    min-width: 80vw;
    height: 48vw;
  }

  @media (--viewportMLarge) {
    margin-top: calc(50vh - 20vw);
    min-width: 66vw;
    height: 40vw;
  }
}

.modalCloseButton {
  top: 0px;
  right: 0px;
  padding: 10px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    color: var(--matterColorLight);
    top: -40px;
  }

  &:hover {
    color: var(--matterColorAnti) !important;
  }
}

.fieldLocationBLanding {
  height: 72px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
  background: #ffffff;

  @media (max-width: 767px) {
    height: 56px;
  }
}

.fieldLocationBLanding input {
  height: 72px;
  border: 0;
  width: 100%;
  padding: 15px 100px 15px 20px;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: none !important;

  @media (max-width: 767px) {
    height: 56px;
    padding: 15px 60px 15px 15px;
  }

  &:hover {
    @media (min-width: 768px) {
      border: 1px solid transparent !important;
    }
  }

  &:focus {
    @media (min-width: 768px) {
      border: 1px solid #ffcd05 !important;
    }
  }
}

.fieldLocationBLanding .hideSearchIconBLanding {
  display: none !important;
}

.searchIconWrapper {
  width: 60px;
  height: 60px;
  background-color: #ffcd05;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  right: 6px;
  top: 6px;

  @media (max-width: 767px) {
    width: 46px;
    height: 46px;
    right: 5px;
    top: 5px;
  }
}

.searchIconWrapper svg {
  width: 20px;
}

.trustPilotLogo {
  flex: 0 0 40%;
  max-width: 40%;
  padding: 0 15px;

  @media (--viewportMobile) {
    flex: 0 0 45%;
    max-width: 45%;
    display: flex;
    justify-content: center;
    padding: 0;
  }
}

.trustPilotLogoMobile {
  @media (--viewportMobile) {
    flex: 0 0 55%;
    max-width: 55%;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
}

.insuranceListingWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;

  @media (--viewportLarge) {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: var(--matterColorLight);
    margin-top: 0;
    padding: 30px 0 0;
  }

  @media (max-width: 767px) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--matterColorLight);
    margin: 0 0 0;
    padding: 30px 0 0;
  }
}

.insuranceListingImage {
  width: auto;
}

.insuranceListingText {
  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);

  margin-bottom: 12px;

  @media (--viewportLarge) {
    font-size: 15px;
  }

  @media (max-width: 767px) {
    font-size: 10px;
    margin-bottom: 5px;
  }
}

/** Insurance modal css */
.comprehensiveInsuranceBlock {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    align-items: flex-start;
  }
}

.comprehensiveInsuranceBlockWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 275px;
  padding: 0 15px;

  @media (max-width: 767px) {
    max-width: 270px;
    padding: 10px 0 0 10px;
    margin: 0 auto;
    flex: 0 0 55%;
    max-width: 55%;
    align-items: center;
  }
}

.comprehensiveInsuranceBlockWrapper .cIContent {
  padding-left: 10px;

  @media (--viewportMobile) {
    padding-left: 5px;
  }
}

.comprehensiveInsuranceBlockWrapper .cIContent span {
  font-size: 18px;
  line-height: 24px;
  color: #026786;
  font-weight: 500;
  display: block;

  @media (max-width: 767px) {
    line-height: 1.33;
    font-size: 14px;
  }
}

.comprehensiveInsuranceBlockWrapper .cIContent span strong {
  font-weight: 900;
}

.comprehensiveInsuranceBlockWrapper .cILogo {
  min-width: 82px;
  max-width: 82px;
  flex: 1;

  @media (max-width: 767px) {
    min-width: 60px;
    max-width: 60px;
  }
}

.linkName {
  display: inline;
  margin: 0;
  padding: 0;
  border: none;
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  line-height: 2;
  font-weight: 400;
  cursor: pointer;
  transition: ease-in-out 0.1s;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 1;
  }
}

.linkName:hover {
  opacity: 0.75;
  text-decoration: none;
}

.arrayGrid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.cardGrid {
  padding: 0 15px;
  flex: 0 0 33.333%;
  max-width: 33.333%;
  margin-bottom: 30px;

  @media (--viewportMobile) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* @media (--viewportSmall) {
    flex: 0 0 44.4%;
  };
  @media (--viewportMedium) {
    flex: 0 0 46%;
  };
  @media (--viewportLargeWithPaddings) {
    flex: 0 0 30.6%;
  };
  @media (--viewportLMLarge) {
    flex: 0 0 30.7%;
  }; */
}

.card {
  border-radius: 10px;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  padding-bottom: 59.5%;
  position: relative;
  cursor: pointer;
}

.card:hover .imageOverlay {
  transform: scale(1.25);
}

.overlay {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
}

.imageOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: 0.3s all ease;
}

.imageOverlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.categoryTitle {
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  color: #ffffff;
}

.carRentBrands {
  background: #ffffff;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (max-width: 767px) {
    padding-bottom: 30px;
  }
}

.carRentBrandsContainer {
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 20px;
}

.carRentBrandsTitle {
  text-align: center;
  font-size: 48px;
  line-height: normal;
  color: #026786;
  font-weight: 900;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    font-size: 36px;
    line-height: 1;
    margin-bottom: 15px;
  }
}

.carRentBrandsTitle span {
  display: block;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
}

.carRentBrandsTitle p {
  text-align: center;
  font-size: 20px;
  line-height: normal;
  color: #026786;
  font-weight: 500;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 767px) {
    font-size: 20px;
    line-height: normal;
  }
}
.singleColumn ul {
  margin-bottom: 60px !important;
  @media (max-width: 767px) {
    margin-bottom: 40px !important;
  }
}
.singleColumn ul li {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.mobileBox .categoriesListing{
  @media (max-width: 767px) {
    display: flex;
  }
}
