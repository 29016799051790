@import '../../marketplace.css';

.title {
  @apply --marketplaceH1FontStyles;

  font-weight: 900;
  color: var(--marketplaceColorDark);
  margin-top: 0;
  /* max-width: 735px; */
  @media (--viewportMedium) {
    font-size: 38px;
    line-height: 46px;
    margin-bottom: 23px;
  }

  @media (--viewportLarge) {
    font-size: 48px;
    line-height: 56px;
  }
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 57px;
  }
}

.location {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

.nameLogo {
  position: absolute;
  right: 25px;
  bottom: -27px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-image: linear-gradient(var(--marketplaceColor),var(--marketplaceColorDark));
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: var(--matterColorLight);
  overflow: hidden;
}

.nameLogoImg {
  width: 100%;
  border-radius: 50%;
}

.ownerUsername {
  position: absolute;
  bottom: -55px;
  right: 25px;
  color: var(--marketplaceColorDark);
  font-size: 12px;
  min-width: 60px;
  text-align: center;
}

.extraInfo {
  font-size: 18px;
  line-height: 32px;
  color: var(--matterColor);
}

.ratingBox {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  align-items: center;
}

.star {
  width: 20px;
  height: 20px;
  fill: var(--marketplaceSecondaryColor);
}

.numberRatings {
  font-size: 20px;
  color: var(--matterColorAnti);
  margin-left: 10px;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColorDark);
}