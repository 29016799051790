@import '../../marketplace.css';


.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    padding: 36px 36px 0 36px;
  }
}

.verificationIndication p {
  color: #4A4A4A !important;
  font-size: 18px !important;
  font-weight: 500 !important;

  @media (--viewportMobile) {
    font-size: 15px !important;
  }

}

:global(.onfido-sdk-ui-Theme-modalOverlay--after-open) {
  z-index: 1000;
}

:global(.onfido-sdk-ui-Uploader-buttonLinkUploadCopy) {
  display: none !important;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative;
  /* allow positioning own listing action bar */
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative);
  /* Loading BG color */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0;
    /* No fixed aspect on desktop layouts */
  }
}


.identityVerificationGuide p {
  color: #4A4A4A !important;
  font-size: 18px !important;

}

.identityVerificationGuideHeading {
  font-weight: 600 !important;
}

.identityVerificationGuideText {
  font-weight: 500 !important;
}

.continueButtonWrapper {
  margin-top: 45px;
  width: 35%;

  @media (--viewportMedium) {
    /* margin-top: 96px; */
  }
}

.continueButtonWrapper button {
  background-color: #fecd2a;
  min-height: 50px;
  border-radius: 4px;
  text-transform: none;
  }
  
  .continueButtonWrapper button:hover {
  background-color: #fecd2a;
  color: #0d6786;
  }

.continueButtonWrapper button span {
  font-weight: 700;
  font-size: 18px;
  color: #026786;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.5 * 100vw);
    max-height: 65vh;
    object-fit: cover;
    border-radius: var(--borderRadius);
  }
}

.root :global(.slick-dots) {
  bottom: 10px;

  @media (--viewportLarge) {
    bottom: 30px;
  }
}

.root :global(.slick-dots li button) {
  background-color: var(--matterColorAnti);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: solid 3px var(--matterColorLight);

  @media (--viewportLarge) {
    width: 15px;
    height: 15px;
  }
}

.verificationModalElement {
  z-index: 999;
}

.root :global(.slick-dots li.slick-active button) {
  background-color: var(--matterColorLight);
}

.verificationModalButton {
  margin-top: 45px;
  width: 40%;


  @media (--viewportMedium) {
    /* margin-top: 10px; */
  }

  /* width: 300px; */
}

.verificationModalButton button {
  background: #fecd2a;
  min-height: 50px;
  border-radius: 4px;
  text-transform: initial;
  font-weight: 700;
  font-size: 18px;
  color: #026786;
}




@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --ContactDetailsForm_infoText: {
    @apply --marketplaceH4FontStyles;
    color: var(--successColor);

    display: inline-block;
    padding-left: 18px;
    background-repeat: no-repeat;
    background-position: top 7px left;

    margin-top: 11px;
    margin-bottom: 0;

    @media (--viewportMedium) {
      margin-top: 14px;
      margin-bottom: 1px;
    }
  }
}



.contactDetailsSection {
  margin-top: 16px;
  margin-bottom: 46px;
  padding-top: 6px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
    padding-top: 0;
  }
}

.phoneNumberFieldWrapper {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.phoneNumberWrapper {
  display: flex;
  align-items: center;
}

.phoneCodeText {
  flex: 1;
}

.phone {
  flex: 6;
}

.phoneCode {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  padding: 0;
  opacity: 0;
}

.confirmChangesSectionVisible {
  opacity: 1;
}

.emailVerified {
  @apply --ContactDetailsForm_infoText;
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%232ECC71" fill-rule="evenodd"/></svg>');
}

.emailUnverified {
  @apply --ContactDetailsForm_infoText;
  color: var(--matterColorAnti);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.pendingEmailUnverified {
  @apply --ContactDetailsForm_infoText;
  color: var(--failColor);
  width: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.checkInbox {
  color: var(--matterColor);
}

.emailStyle {
  font-weight: var(--fontWeightBold);
  word-wrap: break-word;
}

.helperLink {
  color: var(--matterColor);
  border-bottom: solid 1px var(--matterColor);
  line-height: 20px;
  margin: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.tooMany {
  color: var(--matterColorAnti);
}

.emailSent {
  color: var(--matterColor);
}

.confirmChangesTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.confirmChangesInfo {
  margin-top: 0;
  margin-bottom: 37px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.bottomWrapper {
  margin-top: 46px;

  @media (--viewportMedium) {
    margin-top: 96px;
  }
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}