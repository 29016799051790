@import '../../marketplace.css';

:root {
  --ConvenienceWidth: 100px;
  --ConvenienceMarginRight: 41px;
}

.root {
  background-color: white;
}

.sectionHero {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30px 0 55px;

  @media (--viewportLarge) {
    padding-top: 70px;
    padding: 70px 0 0;
  }
}

.goLogo {
  height: 54px;
  width: 125px;

  @media (--viewportLarge) {
    height: 125px;
    width: 280px;
  }
}

.sectionHeroBg {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: 440px;
  background-repeat: no-repeat;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingPageGo/shutterstock.jpg');
  background-size: cover;
  background-position: 50% 100%;

  @media (--viewportMedium) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingPageGo/shutterstock@2x.jpg');
    background-size: cover;
    background-position: bottom;
    height: 670px;
  }

  @media (--viewportLarge) {
    width: 100%;
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingPageGo/shutterstock@2x.jpg');
    height: 982px;
  }

  @media (--viewportXLarge) {
    background-size: 100% auto;
    height: 1240px;
  }

  @media (--viewportXXLarge) {
    height: 2160px;
  }
}

.title {
  font-size: 24px;
  line-height: 29px;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightBold);
  margin-top: 10px;

  @media (--viewportLarge) {
    font-size: 50px;
    line-height: 60px;
    margin-top: 0;
  }
}

.titleDark {
  color: var(--marketplaceColorDark);
}

.description {
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightNormal);
  margin-top: 20px;
  text-align: center;
  color: var(--marketplaceColorDark);

  @media (--viewportLarge){
    margin-top: 18px;
    font-size: 24px;
    line-height: 29px;
  }
}

.sectionHeroDescription {
  padding: 0 48px 0;

  @media (--viewportMedium) {
    max-width: 520px;
  }

  @media (--viewportLarge){
    padding: 0;
    max-width: 704px;
  }
}

.desktop {
  display: none !important;

  @media (--viewportLarge){
    display: block !important;
  }
}

.mobile {
  @media (--viewportLarge){
    display: none !important;
  }
}

.goToSearchButton {
  @apply --marketplaceButtonStylesPrimary;
  padding: 13px 38px;
  min-height: 50px;
  max-width: 315px;
  border-radius: 6px;
  text-transform: unset !important;
  margin-top: 38px;

  @media (--viewportMedium) {
    max-width: 418px;
  }
}

.sectionIntroducing {
  padding: 35px 30px 47px;

  @media (--viewportLarge){
    max-width: 1056px;
    padding: 78px 30px 90px;
    margin: 0 auto;
  }
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.conveniences {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px calc(calc(100% - var(--ConvenienceWidth) - var(--ConvenienceWidth) - var(--ConvenienceMarginRight)) / 2) 0;

  @media (--viewportLarge){
    padding: 46px 23px 18px;
  }
}

.convenience {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  margin-right: 38px;
  margin-top: 0px;

  @media (--viewportLarge){
    margin-right: 38px;
    width: 196px;
  }
}

.convenience:nth-child(2n+2){
  margin-right: 0px;

  @media (--viewportLarge){
    margin-right: 55px;
  }
}

.convenience:nth-child(n+3){
  margin-top: 40px;

  @media (--viewportLarge){
    margin-top: 0;
  }
}

.convenience:last-child {
  @media (--viewportLarge){
    margin-right: 0;
  }
}

.convenienceLabel {
  font-size: 16px;
  line-break: 19px;
  font-weight: var(--fontWeightSemiBold);
  text-align: center;
  color: var(--marketplaceColor);
  margin-top: 15px;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.sectionVision {
  padding-bottom: 530px;
  @media (--viewportMobile){
    padding-bottom: 450px;
  }
}

.visionBackgroundImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingPageGo/shutterstock_2.jpg');
  background-size: 100% auto;
  background-position: top;
  position: relative;
  height: 650px;

  @media (--viewportMedium) {
    height: 725px;
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingPageGo/shutterstock_2@2x.jpg');
  }

  @media (--viewportLarge){
    height: 1148px;
  }

  @media (--viewportXLarge) {
    height: 1520px;
  }
}

.sectionVisionContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 220px;
  padding: 0 30px 0;

  @media (--viewportMedium) {
    top: 425px;
  }

  @media (--viewportLarge) {
    top: 525px;
  }

  @media (--viewportMLarge) {
    top: 725px;
  }

  @media (--viewportXLarge) {
    top: 925px;
  }

  @media (--viewportXXLarge) {
    top: 1225px;
  }
}


.sectionVisionContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (--viewportMedium) {
    max-width: 580px;
  }

  @media (--viewportLarge) {
    max-width: 850px;
  }
}

.highlightText {
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--fontWeightSemiBold);
  margin-top: 30px;

  color: var(--marketplaceColorDark);
}

.outlineButton {
  @apply --marketplaceButtonStylesPrimary;
  padding: 13px 38px;
  min-height: 50px;
  max-width: 315px;
  border-radius: 6px;
  margin-top: 20px;
  text-transform: unset !important;
  background-color: var(--matterColorLight);
  border: solid 1px var(--marketplaceColorDark);
  color: var(--marketplaceColorDark);
}

.outlineButton:hover {
  color: var(--matterColorLight);
}

.sectionDirectAccess {
  background-color: var(--marketplaceColor);

}

.sectionDirectAccessWrapper {
  padding: 30px 30px 38px;

  @media (--viewportLarge){
    padding: 100px 193px 92px;
  }

  @media (--viewportMLarge){
    max-width: 1056px;
    padding: 100px 0 92px;
    margin-left: auto;
    margin-right: auto;
  }
}

.whiteText {
  color: var(--matterColorLight);
}

.darkBackground {
  background-color: var(--marketplaceColorDark);
}

.videoTestimonialSlider{
  max-width: 450px;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 30px;
  @media (--viewportMobile){
    max-width: 300px;
  }
}

.slider{
  width: 100%;
}

.videoWrapper{
  position: relative;
  padding-bottom: 56.5%;
  height: 0;
  display: block;
  width: 320px;
  margin: 0 auto;
  @media (--viewportMobile){
    width: 275px;
  }
}

.videoWrapper iframe, .videoWrapper video{
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 5px;
  display: block;
  left: 0;
  top: 0;
}
