
@import '../../marketplace.css';


.lottieElementContainer p {
    color: #4A4A4A !important;
    font-size: 18px !important;
  
  }
  
  .lottieElementContainerHeading {
    font-weight: 600 !important;
  }
  
  .lottieElementContainerText {
    font-weight: 500 !important;
  }


  .lottieElementButtonContainer {
    margin-top: 45px;
    width: 40%;
  
  
    @media (--viewportMedium) {
      /* margin-top: 10px; */
    }
  
    /* width: 300px; */
  }
  
  .lottieElementButtonContainer button {
    background: #fecd2a;
    min-height: 50px;
    border-radius: 4px;
    text-transform: initial;
    font-weight: 700;
    font-size: 18px;
    color: #026786;
  }
  
  