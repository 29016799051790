@import '../../marketplace.css';

.label {
  position: relative;
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;
  text-transform: unset;
  display: inline-block;
  padding: 7px 16px 7px 16px;
  font-size: 18px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  background-color: var(--matterColorLight);
  font-weight: 300;
  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }

  &:hover {
    background-color: var(--matterColorLight);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);
  text-transform: unset;
  display: inline-block;
  padding: 7px 16px 7px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);
  font-weight: 300;
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
    background-color: var(--marketplaceColorLight);
  }
}

.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label:hover {
  & .hintText {
    display: inline-block;
  }
}

.hintText {
  font-size: 12px;
  display: none;
  position: absolute;
  color: var(--matterColorAnti);
  border: 1px solid var(--matterColorNegative);
  border-radius: 5px;
  background-color: var(--matterColorLight);
  font-weight: normal;
  line-height: 16px;
  padding: 15px 25px;
  left: 0px;
  bottom: 20px;
  margin-bottom: 25px;
  width: 275px;
  box-shadow: 3px 3px 10px var(--matterColorNegative);

  z-index: 81;
  &::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 10px;
    margin-left: 10px;
    border-width: 8px;
    border-style: solid;
    border-color: var(--matterColorLight) transparent transparent transparent;
  }
}
