@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.title {
  @apply --EditListingDescriptionForm_formMargins;
}

.description {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.category {
  @apply --EditListingDescriptionForm_formMargins;
}

.column {
  padding-left: 20px;
  padding-right: 20px;
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column4 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column3 {
  flex: 0 0 25%;
  max-width: 25%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.displayInlineContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  flex-wrap: wrap;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.newInput {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0 0 20px;
}
.newInput input,
.newInput select {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  width: 100%;
  margin: 0;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}

.newInput select {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 7px 45px 7px 15px;
}

.newInput textarea {
  height: 120px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 15px 15px;
  font-size: 20px;
  margin: 0;
  resize: none;
  box-sizing: border-box;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}
.newInput label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
  @media (max-width: 767px) {
    margin-bottom: 5px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  background-color: #00a3ad;
  color: #ffffff;
  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.stickyButtons {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  @media (max-width: 767px) {
    height: auto;
    box-shadow: none;
  }
}
.stickyButtons .stickButtonsContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}
.stickyButtons button {
  height: 60px;
  border-radius: 4px;
  max-width: 301px;
  margin-left: auto;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    height: 52px;
  }
}

.stickButtonsDescription {
  flex: 0 0 70%;
  max-width: 70%;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    box-shadow: none;
    text-align: left;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 15px 20px;
    background: #eff7f8;
  }
}

.stickButtonsDescription .descIcon {
  margin-right: 10px;
  width: 40px;
  height: 40px;

  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
}

.stickButtonsDescription .descIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.stickButtonsDescription p {
  color: #4a4a4a;
  max-width: 600px;
  line-height: normal;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }
}

.stickButton {
  text-align: right;
  flex: 0 0 30%;
  max-width: 30%;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 6px 20px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  }
}

.fieldWrapper {
  position: relative;
}

.limitCounter {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
  line-height: 24px;
  color: #4a4a4a;
  padding-top: 6px;
  padding-bottom: 2px;
  @media (max-width: 767px) {
    padding-top: 0;
  }
}

.dropDownInput input,
.dropDownInput select {
  background-image: url(https://dv0eqz2t0y9gj.cloudfront.net/drivelah/updatelisting/arrow-down-gray.svg);
  background-size: 16px 16px;
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
}
