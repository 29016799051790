@import '../../marketplace.css';

.root {
  width: 100%;
  background-color: var(--matterColorLight);
}

.contentWrapper {
  padding: 10px 25px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    padding: 20px;
    max-width: fit-content;
  }

  @media (--viewportLarge) {
    padding: 20px 40px 20px 0;
  }
}

.icon {
  margin-right: 10px;
  width: 90px;
  height: 90px;

  @media (--viewportLarge) {
    margin-right: 20px;

    width: 110px;
    height: 110px;
  }
}

.closeIcon {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 20px;
  right: 12px;

  @media (--viewportMedium) {
    right: 0;
  }
}

.title {
  font-size: 18px;
  line-height: 21px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--marketplaceColorDark);

  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 33px;
  }
}

.description {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--marketplaceColor);

  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 36px;
  }
}

.appleStoreLink {
  margin-top: 5px;
  color: black;
}
