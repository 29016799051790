@import '../../../marketplace.css';



.tripPageRoot {
    max-width: 100%;
  }

:root {
    /*
      These variables are available in global scope through ":root"
      element (<html> tag). Variables with the same names are going to
      overwrite each other if CSS Properties' (PostCSS plugin)
      configuration "preserve: true" is used - meaning that variables
      are left to CSS bundle. We are planning to enable it in the future
      since browsers support CSS Properties already.
   */
  
    --ContactDetailsForm_infoText: {
      @apply --marketplaceH4FontStyles;
      color: var(--successColor);
  
      display: inline-block;
      padding-left: 18px;
      background-repeat: no-repeat;
      background-position: top 7px left;
  
      margin-top: 11px;
      margin-bottom: 0;
  
      @media (--viewportMedium) {
        margin-top: 14px;
        margin-bottom: 1px;
      }
    }
  }
  


.root :global(.slick-dots) {
    bottom: 10px;
  
    @media (--viewportLarge) {
      bottom: 30px;
    }
  }

  .root :global(.slick-dots li button) {
    background-color: var(--matterColorAnti);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: solid 3px var(--matterColorLight);
  
    @media (--viewportLarge) {
      width: 15px;
      height: 15px;
    }
  }

  .root :global(.slick-dots li.slick-active button) {
    background-color: var(--matterColorLight);
  }
  
  .streetName {
    flex-basis: 100%;
    flex-shrink: 1;
    margin-bottom: 24px;
    flex-grow: 30;
    max-width: 100% !important;
  
    @media (--viewportMedium) {
      margin-bottom: 0px;
      flex-basis: 70%;
    }
  }

  .streetName label {
    color: #4a4a4a;
    font-weight: 300;
    font-size: 16px;
  }
  
  .streetName .validationClassName {
    position: relative !important;
  }

  .streetName {
    flex-basis: 100%;
    flex-shrink: 1;
    margin-bottom: 24px;
    flex-grow: 30;
    max-width: 100% !important;
  
    @media (--viewportMedium) {
      margin-bottom: 0px;
      flex-basis: 70%;
    }
  }

  .streetName label {
    color: #4a4a4a;
    font-weight: 300;
    font-size: 16px;
  }

  .streetName .validationClassName {
    position: relative !important;
  }
  

  .newInput {
    flex: 0 0 100%;
    max-width: 100% !important;
    margin: 0;
  }
  
  .newInput label {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    color: #4a4a4a;
  }
  
  .newInput input,
  .newInput select {
    height: 54px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 7px 15px;
    font-size: 20px;
    width: 100%;
    margin: 0;
    color: #4a4a4a;
  }
  
  .halfInputs {
    margin-left: -10px;
    margin-right: -10px;
  }
  
  .halfInputs .newInput {
    padding-left: 10px;
    padding-right: 10px;
    flex: 0 0 50%;
    max-width: 50%;
  
    @media (--viewportMobile) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .displayInlineContainer {
    display: -webkit-flex;
    /* Safari */
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
  
    @media (--viewportMedium) {
      flex-wrap: nowrap;
    }
  }

  .root {
    @media (--viewportLarge) {
      max-width: 550px;
    }
  }
  
  .inputField {
    margin-bottom: 30px;
  }
  
  .addressVerificationForm p {
    color: black;
  }
  
  .verificationIndication p {
    color: #4A4A4A !important;
    font-size: 18px !important;
    font-weight: 500 !important;
  
    @media (--viewportMobile) {
      font-size: 15px !important;
    }
  
  }
  
  .displayInlineContainer {
    display: -webkit-flex;
    /* Safari */
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
  
    @media (--viewportMedium) {
      flex-wrap: nowrap;
    }
  }
  
  .locationAutocompleteInput {
    flex-grow: 1;
    height: 36px;
    line-height: unset;
    padding-left: 0;
    margin: 0;
    border-bottom: 2px solid var(--marketplaceColorDark);
  
    &:hover,
    &:focus {
      border-bottom-color: var(--marketplaceColor);
      outline: none;
    }
  
    /* Safari bugfix: without this Safari will print placeholder to a wrong place */
    &::-webkit-input-placeholder {
      line-height: normal;
    }
  
    @media (--viewportMedium) {
      height: 40px;
    }
  }
  
  .locationAutocompleteInputIcon {
    display: none;
  }
  
  .predictionsRoot {
    position: absolute;
    width: 100%;
    padding-bottom: var(--locationAutocompleteBottomPadding);
    top: 36px;
    left: 0;
    background-color: var(--marketplaceColor);
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    box-shadow: var(--boxShadowPopup);
    z-index: calc(var(--zIndexPopup) + 1);
  
    @media (--viewportMedium) {
      top: 40px;
    }
  }
  
  .locationAddress,
  .postalCode,
  .genericField,
  .country {
    flex-shrink: 0;
    margin-bottom: 24px;
  
    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
  
  .building {
    flex-basis: 100%;
    flex-shrink: 1;
    margin-bottom: 24px;
    flex-grow: 6;
  
    @media (--viewportMedium) {
      margin-bottom: 32px;
      flex-basis: 60%;
    }
  }
  
  .floor {
    flex-basis: 100%;
    flex-shrink: 1;
    flex-grow: 1;
  
    @media (--viewportMedium) {
      margin-left: 4%;
      flex-basis: 40%;
    }
  }
  
  .streetName {
    flex-basis: 100%;
    flex-shrink: 1;
    margin-bottom: 24px;
    flex-grow: 30;
    max-width: 100% !important;
  
    @media (--viewportMedium) {
      margin-bottom: 0px;
      flex-basis: 70%;
    }
  }
  
  .streetName label {
    color: #4a4a4a;
    font-weight: 300;
    font-size: 16px;
  }
  
  .streetName .validationClassName {
    position: relative !important;
  }
  
  .validLocation {
    border-bottom-color: var(--successColor);
  }
  
  .blockNo {
    flex-basis: 100%;
    flex-shrink: 1;
    margin-bottom: 24px;
    flex-grow: 1;
  
    @media (--viewportMedium) {
      margin-bottom: 32px;
      flex-basis: 20%;
      margin-right: 4%;
    }
  }
  
  .readMore {
    font-size: 16px;
    line-height: 20px;
    color: var(--matterColorNear);
    margin-bottom: 0px;
  }
  
  .learnMore {
    font-size: 16px;
    line-height: 20px;
    color: var(--marketplaceColor);
  }
  
  .error {
    @apply --marketplaceH4FontStyles;
    color: var(--failColor);
    text-align: center;
    display: inline-block;
    width: 100%;
    margin: 24px 0;
  
    @media (--viewportMedium) {
      margin: 24px 0;
    }
  }
  
  .locationAutocompleteInput {
    flex-grow: 1;
    height: 36px;
    line-height: unset;
    padding-left: 0;
    margin: 0;
    border-bottom: 2px solid var(--marketplaceColorDark);
  
    &:hover,
    &:focus {
      border-bottom-color: var(--marketplaceColor);
      outline: none;
    }
  
    /* Safari bugfix: without this Safari will print placeholder to a wrong place */
    &::-webkit-input-placeholder {
      line-height: normal;
    }
  
    @media (--viewportMedium) {
      height: 40px;
    }
  }
  
  .locationAutocompleteInputIcon {
    display: none;
  }
  
  .predictionsRoot {
    position: absolute;
    width: 100%;
    padding-bottom: var(--locationAutocompleteBottomPadding);
    top: 36px;
    left: 0;
    background-color: var(--marketplaceColor);
    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    box-shadow: var(--boxShadowPopup);
    z-index: calc(var(--zIndexPopup) + 1);
  
    @media (--viewportMedium) {
      top: 40px;
    }
  }
  
  .tripPageRoot {
    max-width: 100%;
  }
  
  .newInput {
    flex: 0 0 100%;
    max-width: 100% !important;
    margin: 0;
  }
  
  .newInput label {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    color: #4a4a4a;
  }
  
  .newInput input,
  .newInput select {
    height: 54px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 7px 15px;
    font-size: 20px;
    width: 100%;
    margin: 0;
    color: #4a4a4a;
  }
  
  .halfInputs {
    margin-left: -10px;
    margin-right: -10px;
  }
  
  .halfInputs .newInput {
    padding-left: 10px;
    padding-right: 10px;
    flex: 0 0 50%;
    max-width: 50%;
  
    @media (--viewportMobile) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  
  /* For sticky submit button design */
  
  .stickyButtons {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
    z-index: 99;
  
    @media (max-width: 767px) {
      height: auto;
      box-shadow: none;
    }
  }
  
  .stickyButtons .stickButtonsContainer {
    display: -webkit-flex;
    /* Safari */
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
    height: 100%;
    justify-content: space-between;
  
    @media (--viewportMedium) {
      flex-direction: row;
    }
  }
  
  .stickyButtons button {
    height: 60px;
    border-radius: 4px;
    max-width: 301px;
    margin-left: auto;
  
    @media (max-width: 767px) {
      max-width: 100%;
      width: 100%;
      height: 52px;
    }
  }
  
  .stickButtonsDescription {
    flex: 0 0 70%;
    max-width: 70%;
    display: flex;
    align-items: center;
  
    @media (max-width: 767px) {
      box-shadow: none;
      text-align: left;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 15px 20px;
      background: #eff7f8;
    }
  }
  
  .stickButtonsDescription .descIcon {
    margin-right: 10px;
    width: 40px;
    height: 40px;
  
    @media (max-width: 767px) {
      width: 32px;
      height: 32px;
    }
  }
  
  .stickButtonsDescription .descIcon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
  
  .stickButtonsDescription p {
    color: #4a4a4a;
    max-width: 600px;
    line-height: normal;
    margin: 0;
  
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
      text-align: left;
    }
  }
  
  .stickButton {
    text-align: right;
    flex: 0 0 30%;
    max-width: 30%;
  
    @media (max-width: 767px) {
      box-shadow: none;
      text-align: center;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 6px 20px;
      box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
    }
  }
  
  .submitVerificationButtonWrapper {
    margin-top: 45px;
    width: 40%;
  
    @media (--viewportMedium) {
      /* margin-top: 96px; */
    }
  }
  
  .submitVerificationButtonWrapper button {
    background: #fecd2a;
    min-height: 50px;
    border-radius: 4px;
    text-transform: initial;
  
    &:hover {
      background: #fecd2a;
      color: #0d6786;
    }
  }
  
  
  .submitVerificationButtonWrapper button span {
    font-weight: 700;
    font-size: 18px;
    color: #026786;
  }



  .titleSection {
    /* padding: 30px 40px;
    border-bottom: 1px solid #e6e6e6;
    @media (max-width: 767px) {
      padding: 0;
      border: 0;
    } */
  }
  .title {
    color: #026786;
    margin: 0 0 10px;
    font-size: 20px;
    line-height: normal;
    letter-spacing: -0.4px;
    font-weight: 700;
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: normal;
      font-weight: 700;
    }
  }


  .column {
    padding-left: 20px;
    padding-right: 20px;
  }
  .column12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .column6 {
    flex: 0 0 50%;
    max-width: 50%;
    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .column4 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .column3 {
    flex: 0 0 25%;
    max-width: 25%;
    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  


  .phoneNumberSection {
    margin-top: 16px;
    margin-bottom: 46px;
    padding-top: 6px;
    display: inline;
  
    @media (--viewportMedium) {
      margin-bottom: 56px;
      padding-top: 0;
    }
  }
  
  .phoneNumberFieldWrapper {
    /* margin-top: 24px; */
    width: 50%;
    margin-left: 10px;
    display: inline-block;
  
    @media (--viewportMedium) {
      /* margin-top: 32px; */
      width: 100%;
    }
  }
  
  .phoneNumberWrapper {
    display: flex;
    align-items: center;
  
  }
  
  .phoneCodeText {
    flex: 1;
  }
  
  .phone {
    flex: 6;
  }
  
  .phoneCode {
    /* margin-top: 24px; */
    width: 40%;
  
  
    @media (--viewportMedium) {
      /* margin-top: 32px; */
    }
  }
  
  .newInput {
    flex: 0 0 100%;
    max-width: 100% !important;
    margin: 0;
  }
  
  .newInput label {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    color: #4a4a4a;
  }
  
  .newInput input,
  .newInput select {
    height: 54px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 7px 15px;
    font-size: 20px;
    width: 100%;
    margin: 0;
  }
  
  .halfInputs {
    margin-left: -10px;
    margin-right: -10px;
  }
  
  .halfInputs .newInput {
    padding-left: 10px;
    padding-right: 10px;
    flex: 0 0 80%;
    width: 100%;
  
    @media (--viewportMobile) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  /* */
  .newInputPhoneCode {
    height: 54px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 7px 15px;
    font-size: 20px;
    width: 100%;
    margin: 0;
  }
  
  .newInputPhoneCode label {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    color: #4a4a4a;
  }
  
  .newInputPhoneCode input,
  .newInputPhoneCode select {
    height: 54px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 7px 15px;
    font-size: 20px;
    width: 100%;
    margin: 0;
  }
  
  
  
  .halfInputs .newInputPhoneCode {
    padding-left: 10px;
    padding-right: 10px;
    flex: 0 0 20%;
    width: 100%;
  
    @media (--viewportMobile) {
      flex: 0 0 100%;
      max-width: 20%;
    }
  }
  
  /* */
  .newInputCountry {
    flex: 0 0 100%;
    max-width: 50% !important;
    margin: 0;
  }
  
  .newInputCountry label {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    color: #4a4a4a;
  }
  
  .newInputCountry input,
  .newInputCountry select {
    height: 54px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 7px 15px;
    font-size: 20px;
    width: 100%;
    margin: 0;
  }
  
  
  
  .halfInputs .newInputCountry {
    padding-left: 10px;
    padding-right: 10px;
    flex: 0 0 80%;
    width: 100%;
  
    @media (--viewportMobile) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  .confirmChangesSection {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    padding: 0;
    opacity: 0;
  }
  
  .confirmChangesSectionVisible {
    opacity: 1;
  }
  
  .emailVerified {
    @apply --ContactDetailsForm_infoText;
    background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%232ECC71" fill-rule="evenodd"/></svg>');
  }
  
  .emailUnverified {
    @apply --ContactDetailsForm_infoText;
    color: var(--matterColorAnti);
    background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
  }
  
  .pendingEmailUnverified {
    @apply --ContactDetailsForm_infoText;
    color: var(--failColor);
    width: 100%;
    background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
  }
  
  .checkInbox {
    color: var(--matterColor);
  }
  
  .emailStyle {
    font-weight: var(--fontWeightBold);
    word-wrap: break-word;
  }
  
  .helperLink {
    color: var(--matterColor);
    border-bottom: solid 1px var(--matterColor);
    line-height: 20px;
    margin: 0;
    cursor: pointer;
  
    @media (--viewportMedium) {
      margin: 0;
    }
  }
  
  .tooMany {
    color: var(--matterColorAnti);
  }
  
  .emailSent {
    color: var(--matterColor);
  }
  
  .confirmChangesTitle {
    /* Font */
    color: var(--matterColorAnti);
  
    margin-top: 0;
    margin-bottom: 13px;
  
    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 16px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
  
  .confirmChangesInfo {
    margin-top: 0;
    margin-bottom: 37px;
  
    @media (--viewportMedium) {
      margin-bottom: 48px;
    }
  }
  
  .bottomWrapper {
    margin-top: 46px;
  
    @media (--viewportMedium) {
      margin-top: 96px;
    }
  }
  
  .error {
    @apply --marketplaceH4FontStyles;
    color: var(--failColor);
    text-align: center;
    display: inline-block;
    width: 100%;
    margin: 24px 0;
  
    @media (--viewportMedium) {
      margin: 24px 0;
    }
  }

  .phoneNumberNumberInput {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }

  .phoneNumberNumberInput label {
    display: none;
  }


.verificationModalButton {
    margin-top: 45px;
    width: 40%;
  
  
    @media (--viewportMedium) {
      /* margin-top: 10px; */
    }
  
    /* width: 300px; */
  }

 
  
  .verificationModalButton button {
    background: #fecd2a;
    min-height: 50px;
    border-radius: 4px;
    text-transform: initial;
    font-weight: 700;
    font-size: 18px;
    color: #026786;
  }
  



.titleContainer {
    color: #00a3ad;
    display: flex;
    padding: 20px !important;
    flex-direction: column;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
  
    @media (--viewportMedium) {
      padding: 0px;
    }
  }

  .titleContainer h3 {
    text-align: left;
  }


.identityVerificationGuide p {
    color: #4A4A4A !important;
    font-size: 18px !important;
  
  }
  
  .identityVerificationGuideHeading {
    font-weight: 600 !important;
  }
  
  .identityVerificationGuideText {
    font-weight: 500 !important;
  }
  

  .verificationModalButton {
    margin-top: 45px;
    width: 40%;
  
  
    @media (--viewportMedium) {
      /* margin-top: 10px; */
    }
  
    /* width: 300px; */
  }
  
  .verificationModalButton button {
    background: #fecd2a;
    min-height: 50px;
    border-radius: 4px;
    text-transform: initial;
    font-weight: 700;
    font-size: 18px;
    color: #026786;
  }
  