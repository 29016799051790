@import '../../marketplace.css';

.howDoesItWorkSection {
  width: 100%;
}

.howDoestItWorkContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;

  @media screen and (min-width: 1200px) {
    width: 1056px;
    margin: auto;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    padding: 45px 0px;
  }
}

.videoWrapper {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 30px;

  @media (--viewportLarge) {
    width: 100%;
  }

  @media screen and (min-width: 1200px) {
    width: auto;
    padding: 0 0 0 40px;
    margin-top: 0px;
  }
}

.video {
  width: 100%;
  background-color: var(--matterColorAnti);
  cursor: pointer;

  @media (--viewportMedium) {

  }

  @media (--viewportLarge) {
    width: 500px;
    margin: auto;
  }

  @media (--viewportMLarge) {
    width: 500px;
    margin-right: -80px;
  }

  @media screen and (min-width: 1300px) {
    width: 520px;
    margin-right: -145px;
  }
}

.videoLogo {
  width: 100%;
}

.contentModal {
  height: 100%;
}

.videoContentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    display: block;
  }
}

.iframeVideo {
  width: 100%;
  height: 40%;

  @media (--viewportMedium) {
    height: 100%;
  }
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 40px 0 0 0;

  @media (--viewportMedium) {
    padding: 0px;
    flex-basis: 576px;
    min-height: 0;
    min-width: 80vw;
    height: 48vw;
    margin-top: calc(50vh - 30vw);
    border-bottom: 0px solid transparent;
  }

  @media (--viewportLarge) {
    margin-top: calc(50vh - 25vw);
    min-width: 80vw;
    height: 48vw;
  }

  @media (--viewportMLarge) {
    margin-top: calc(50vh - 20vw);
    min-width: 66vw;
    height: 40vw;
  }
}

.modalCloseButton {
  top: 0px;
  right: 0px;
  padding: 10px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    color: var(--matterColorLight);    
    top: -40px;
  }

  &:hover {
    color: var(--matterColorAnti) !important;
  }
}

.textBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 15px;

  @media (--viewportMedium) {
    padding: 0 50px;
  }

  @media screen and (min-width: 1200px) {
    padding: 0;
    padding-right: 30px;
  }
}

.textBoxTitle {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  font-weight: 900;
  margin-top: 0px;
  flex-basis: 100%;
}

.textBoxDesc {
  @apply --marketplaceDescFontStyles;
  color: var(--marketplaceColorDark);
  flex-basis: 100%;
  max-width: 100%;
  margin: auto;

  @media screen and (min-width: 1200px) {
    margin: unset;
    /* max-width: 600px; */
    max-width: 100%;
    width: 100%;
    margin-bottom: 40px;
  }
}

.watchNow {
  font-weight: 700;
  cursor: pointer;
}

.howToRentACar {
  @apply --marketplaceButtonStylesSecondary;
  border-radius: 5px;
  flex-basis: 100%;
  margin: 10px 0px;
  min-height: 50px;
  padding: 0;
  line-height: 50px;

  @media (--viewportMedium) {
    margin: 0px;
    flex-basis: 40%;
    padding: 0;
    line-height: 65px;
    min-height: 65px;
  }
}

.howToShareYourCar {
  @apply --marketplaceButtonStylesPrimary;
  background-color: var(--marketplaceColorDark);
  border-radius: 5px;
  flex-basis: 100%;
  margin: 10px 0px;
  min-height: 50px;
  padding: 0;
  line-height: 50px;

  &:hover, &:focus {
    background-color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    margin: 0px;
    flex-basis: 55%;
    padding: 0;
    line-height: 65px;
    min-height: 65px;
  }
}

.mobile {
  display: block;

  @media (--viewportMLarge) {
    display: none;
  }
}

.desktop {
  display: none;

  @media (--viewportMLarge) {
    display: block;
  }
}