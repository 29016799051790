@import '../../marketplace.css';
/* .layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));
  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
} */

/* .root {
  @apply --marketplaceModalRootStyles;
  border: 0;
  border-radius: 0;
  background-image: none;
  background: #ffffff;
  @media (--viewportMedium) {
    @apply --authBackgroundImage;
    border: 0;
    border-radius: 0;
    background-image: none;
    background: #ffffff;
  }
} */

.content {
  @apply --marketplaceModalBaseStyles;
  flex-basis: 460px;
  max-width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  border: 0;
  background-color: #fcfcfc;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (--viewportMedium) {
    max-width: 460px;
  }
  @media (--viewportMobile) {
    padding: 40px 0 0;
    background-color: #00A3AD;
  }
}

.errorMessage {
  margin-left: auto;
  margin-right: auto;
  color: red;
}

/* ================ Tabs & Form ================ */

.tabs {
  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.tab {
  @apply --marketplaceModalTitleStyles;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* .form {
  margin-top: 24px;
  @media (--viewportMedium) {
    margin-top: 30px;
    padding-top: 2px;
  }
} */

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* Terms of Service modal*/

.termsWrapper {
  width: 100%;
  padding-top: 40px;
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.termsHeading {
  @apply --marketplaceH1FontStyles;
  margin: 0 0 19px 0;
  @media (--viewportMedium) {
    margin: 0 0 19px 0;
  }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Close icon ================ */

.verifyClose {
  @apply --marketplaceModalCloseStyles;
  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeText {
  @apply --marketplaceModalCloseText;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
}

/* ================ Typography ================ */

/* Title of the modal */

.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */

.modalMessage {
  @apply --marketplaceModalParagraphStyles;
  color: var(--marketplaceColorDark);
}

/* Make the email pop */

.email {
  @apply --marketplaceModalHighlightEmail;
}

/* Helper links */

.modalHelperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

/* Helper texts for the links, not the actual links */

.modalHelperText {
  @apply --marketplaceModalHelperText;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;
  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Errors ================ */

.error {
  @apply --marketplaceModalErrorStyles;
}

/* Redesign CSS */
.authPageContent {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  max-width: 100%;
  min-height: 100vh;
}
.authPagesRootClass {
  padding: 0;
}
.contentWrapper {
  padding-top: 0;
}
.leftBannerImage {
  display: none;
  max-width: 900px;
  flex: 1;
  width: 100%;
  flex-basis: 47%;
  height: 100vh;
  @media (--viewportMedium) {
    display: flex;
  }
}

.mobileWrapper {
  background-color: #fcfcfc;
  width: 100%;
  padding: 0;
  @media (--viewportMobile) {
    padding: 20px 20px 40px 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.leftBannerImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.rightContent {
  flex: 1;
  flex-basis: 53%;
  max-height: 100vh;
  overflow: auto;
}

.authLogoSection {
  max-width: 151px;
  margin: 0 auto 40px;
  display: flex;
  @media (--viewportMobile) {
    display: none;
  }
}

.authLogoSectionMobile {
  display: none;
  @media (--viewportMobile) {
    max-width: 230px;
    margin: 0 auto 40px;
    display: flex;
    padding: 40px 0 0;
    background-color: #00A3AD;
  }
}

.authLogoSection:hover {
  cursor: pointer;
}

.authPageTitle {
  font-weight: 900;
  font-size: 36px;
  line-height: 1.19444;
  color: #026786;
  text-align: center;
  position: relative;
  @media (--viewportMobile) {
    font-size: 28px;
  }
}
.authPageTitle::after {
  content: '';
  margin: 10px auto 0;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 32px;
  border-radius: 3px;
  height: 5px;
  background: #026786;
}

/* Confirm page styles */

.confirmRightContent {
  padding: 40px 100px;
  flex: 1;
  overflow: hidden;
  @media (--viewportMobile) {
    padding: 0;
  }
}

.confirmRoot {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  background-color: #FCFCFC;
  @media (--viewportMobile) {
    margin-top: 0;
  }
}

.confirmAuthPageTitle {
  font-weight: 900;
  font-size: 36px;
  line-height: 1.19444;
  color: #026786;
  text-align: left;
  position: relative;
  margin: 0;
  @media (--viewportMobile) {
    font-size: 28px;
  }
}

.confirmMobileWrapper {
  background-color: #0AA3AD;
}

.confirmAuthPageContent {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  max-width: 1000px;
  align-items: center;
}

.confirmLeftBannerImage {
  display: none;
  @media screen and (min-width: 910px) {
    display: block;
  }
}
