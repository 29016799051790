@import '../../marketplace.css';

/* Grab banner */
.grabBannerContainer {
  max-width: 1240px;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
  @media (max-width: 767px) {
    margin: 0;
    padding: 0;
    background-color: var(--matterColorLight);
  }
}

.grabBannerContentContent {
  padding: 20px 20px 40px;
}

.grabBannerContent {
  padding: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.29);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 206px;
  @media (max-width: 767px) {
    padding: 10px;
  }
}
.grabBannerContent.colorDark {
  color: #026786;
}
.grabBannerContent .contentRow {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 767px) {
    margin: 0 -10px;
  }
}
.grabBannerContent .contentRow .contentLeft {
  flex: 0 0 65%;
  max-width: 65%;
  padding: 0 20px;
  @media (max-width: 767px) {
    padding: 0 10px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.grabBannerContent .contentTitle {
  font-size: 28px;
  line-height: 1.1785;
  color: #ffffff;
  font-weight: 700;
  margin: 0 0 10px;
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 1.2727;
  }
}
.grabBannerContent p {
  font-size: 22px;
  line-height: 1.2727;
  color: #ffffff;
  font-weight: 300;
  max-width: 325px;
  margin: 0 0 20px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 1.33333;
    margin: 0 0 7px;
  }
}
.grabBannerContent .btWrap {
  min-width: 10px;
  height: auto;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: normal;
  color: #026786;
  border: none;
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  -moz-transition: 0.3s all ease;
  transition: 0.3s all ease;
  text-decoration: none !important;
  font-weight: 700;
  color: #fecd2a;
  line-height: normal;
  @media (max-width: 767px) {
    font-size: 16px;
  }
  &:hover {
    opacity: 0.85;
  }
}
.grabBannerContent .btWrap .arrowIconOuter {
  display: inline-flex;
  width: 18px;
  height: 18px;
  margin-left: 10px;
  margin-top: 3px;
  @media (max-width: 767px) {
    width: 12px;
    height: 12px;
    margin-top: 0;
  }
}
.grabBannerContent .btWrap .arrowIconOuter img {
  display: block;
  width: 100%;
  height: 100%;
}
.grabBannerContent .btWrap::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.grabBannerContent .contentRow .contentRight {
  flex: 0 0 35%;
  max-width: 35%;
  padding: 0 20px;
  @media (max-width: 767px) {
    padding: 0 10px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.grabBannerContent .contentRow .contentRight .grabIcon {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  @media (max-width: 767px) {
    max-width: 100%;
    margin: 20px 0 0;
  }
}
.grabBannerContent .grabIcon img {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}

.colorDark .contentTitle {
  color: #026786;
}
.colorDark p {
  color: #4a4a4a;
}
.colorDark .btWrap {
  color: #00a3ad;
}

.grabBannerContainer :global(.slick-active) {
  z-index: 9;
}
.grabBannerContainer :global(.slick-dots) {
  bottom: 55px;
  height: 10px;
  @media (max-width: 767px) {
    bottom: 45px;
  }
  & > li {
    width: 20px;
    height: 10px;
    vertical-align: top;
    @media (max-width: 767px) {
      height: 4px;
    }
  }

  & > li > button {
    width: 20px;
    height: 8px;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #026786;
    @media (max-width: 767px) {
      height: 4px;
      padding: 0;
    }
  }
  & > li.slick-active > button {
    background-color: var(--marketplaceColor);
  }
}
.grabBannerContainer :global(.slick-dots) :global(li.slick-active) > button {
  background-color: #fecd2a;
}
.searchContactModalSLayer {
  display: flex;
  padding: 30px 0;
  min-height: 100vh;
  overflow: auto;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  background-image: none;
  @media (max-width: 767px) {
    display: block;
    padding: 0;
  }
}
.searchContactModalContainer {
  width: 720px;
  background: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  margin-top: 60px;
  @media (max-width: 767px) {
    width: 100%;
    overflow: auto;
    border-radius: 0;
  }
}
.searchContactModalContainer .searchContactModalCloseButton {
  padding: 20px;
}
.searchContactModalContainer .searchContactModalCloseButton .searchContactModalCloseText {
  display: none;
}
.searchContactModalInner {
  position: relative;
  padding-left: 260px;
  @media (max-width: 767px) {
    padding-left: 0;
  }
}
.searchContactLeftImage {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 260px;
  width: 100%;
  height: 100%;
  @media (max-width: 767px) {
    display: none;
  }
}
.searchContactLeftImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.searchContactRightContent {
  padding: 40px 30px;
  @media (max-width: 767px) {
    padding: 40px 20px 100px 20px;
  }
}
.searchContactRightContent h3 {
  margin: 0 0 20px;
  font-size: 28px;
  color: #0d6786;
  line-height: 1.357;
  @media (max-width: 767px) {
    font-size: 24px;
    margin: 0 0 15px;
  }
}
.searchContactRightContent p {
  font-size: 18px;
  line-height: normal;
  margin: 0 0 20px;
  color: #4a4a4a;
}

.adjustHeight{
  height: 140px;
}
.adjustHeight img{
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center center;
}