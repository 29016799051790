@import '../../marketplace.css';

.shareButton,
.copyLink {
  padding-left: 30px;
  padding-right: 30px;
}

.shareDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* .shareButton {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.shareDialog {
  display: none;
}
.shareDialog .socialShareInner {
  width: 95%;
  max-width: 500px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  z-index: -1;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 2;
}
.shareDialog::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.shareDialog.isOpen {
  display: block;
  z-index: 200;
}

header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  /* Not */
}

.targets {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.closeButton {
  background-color: transparent;
  border: none;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  max-width: 30px;
  height: 30px;
  width: 30px;
}

.closeButton svg {
  margin-right: 0;
  max-width: 100%;
  height: 30px;
}

.hidden {
  display: none;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  background-color: #eee;
}

.penUrl {
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shareButton {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 20px;
  font-weight: 400;
  height: 50px;
  border-radius: 5px;
}
.shareButton .logo{
  display: inline-block;
  max-width: 20px;
  width: 100%;
  margin: 0 10px 0 0;
}