@import '../../marketplace.css';

.root {

}

.title {
  color: var(--marketplaceColor);
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 35px;
}

.time {
  color: var(--matterColor);
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 60px;
}

.paragraph {
  margin-bottom: 35px;
}

.paragraphTitle {
  margin-bottom: 20px;
}

.iframeDoc {
  width: 1px;
  min-width: 100%;
  height: 90vh;
  margin-top: 20px;
  display: none;

  @media (--viewportMedium) {
    height: 60vh;
  }

  @media (--viewportLarge) {
    height: 70vh;
  }

  @media (--viewportMLarge) {
    display: block;
  }
}