@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 2;

  & :global(.DateInput) {
    max-width: 130px;

    @media (--viewportLarge) {
      max-width: unset;
    }
  }
}

.bookingEndDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;

  & :global(.DateInput) {
      max-width: 130px;

    @media (--viewportLarge) {
      max-width: unset;
    }
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 38px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--marketplaceColor);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.timeSlotsError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.rentalAgreementLink {
  color: var(--marketplaceColorDark);
  margin: 10px 0 10px 20px;
  font-size: 18px;

  @media (--viewportMedium) {
    margin: 10px 0;
  }
}

.rentalAgreementLink span {
  cursor: pointer;
}

.smallPrintForMember {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColorDark);
  font-size: 14px;
  text-align: center;
  margin: 0;
  flex-shrink: 0;
}

.voucherContainer {
  margin-top: 38px;
  width: 100%;
  display: flex;
}

.voucherInput {
  flex-grow: 1;
}

.voucherInput label {
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorAnti);
  font-weight: 700;
}

.root .voucherButton {
  margin-left: 20px;
  max-width: 60px;
  align-self: flex-end;
  min-height: 45px;
  font-size: 16px;
}

.error {
  font-size: 14px;
  color: var(--failColor);
  margin: 0px 24px 16px;
  display: inline-block;

  @media (--viewportLarge) {
    margin: 0px 0 14px;
  }
}

.estimating {
  font-size: 16px;
  line-height: 20px;
  margin: 10px 0;
  text-align: center;
}

.label {
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorAnti);
  font-weight: 700;
}

.smallPrintForDeposit {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColorDark) !important;
  font-size: 14px;
  text-align: center;
  margin: 0 0 14px;
  flex-shrink: 0;
}

.explainText {
  color: var(--matterColorDark);
}

.explain {
  color: var(--matterColorDark);

  &::before {
    border-color: var(--matterColorDark);
  }
}

.fieldsPromos {
  padding: 0 24px;
  margin-bottom: 38px;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.newCarDiscountNotes {
  font-size: 16px;
}


.submitSection{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -3px 6px rgba(0,0,0,0.14);
  background: #FFFFFF;
  z-index: 99;
}
.submitSectionInner{
  max-width: 1230px;
  margin: 0 auto;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
@media (--viewportMobile) {
  padding: 5px 20px;
}
}
.submitSectionPrice{
  text-align: center;
  font-size: 12px;
  line-height: normal;
  color: #B2B2B2;
}
.submitSectionPrice .totalAmount{
  text-align: center;
  display: block;
  font-size: 28px;
  line-height: normal;
  color: #00A3AD;
  font-weight: 900;
}
.submitSectionPrice button{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 300px;
  font-size: 20px;
  line-height: 1;
  color: #026786;
  border-radius: 4px;
  background-color: #FFCD05;
  text-transform: capitalize;
@media (--viewportMobile) {
  height: 48px;
  min-height: 48px;
  width: 190px;
  font-size: 18px;
}
}
.submitSectionPrice button:hover{
  opacity: 0.85;
  background-color: #FFCD05;
}
.addonPrice{
@media (--viewportMobile) {
  font-size: 18px;
}
}
