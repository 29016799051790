@import '../../marketplace.css';

.formInput {
    /* margin-top: 20px; */

    @media (--viewportMobile) {
        /* margin-top:15px; */
    }
}

.formInput label {
    display: none;
}

.verificationIndication p {
    color: #4A4A4A !important;
    font-size: 18px !important;
    font-weight: 500 !important;

    @media (--viewportMobile) {
        font-size: 15px !important;
    }

}

/*   
  .formInput input {
    border-bottom: none;
    padding: 12px 20px;
    font-size: 18px;
    @media (--viewportMobile) {
      font-size: 16px;
    }
  } */

.emailVerificationForm p {
    color: black;
}

.emailLabel {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    color: #4a4a4a;
}

.emailFormContainer {
    display: flex;
    flex-direction: row;

    @media (--viewportMedium) {
        /* margin-top: 32px; */
    }
}

.emailWrapper {
    align-items: center;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    width: 45%;
    height: 0;
}

.emailWrapper input {
    height: 54px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    padding: 7px 15px;
    font-size: 20px;
    width: 100%;
    margin: 0;
    color: black;
}

.resendButtonWrapper {
    /* margin-top: 45px; */
    width: 30%;
    margin-left: 20px;

    @media (--viewportMedium) {
        /* margin-top: 96px; */
    }
}

.resendButtonWrapper button {
    background: white;
    min-height: 50px;
    border-radius: 4px;
    border: 1px solid #00a3ad;
    text-transform: initial;
}


.resendButtonWrapper button span {
    font-weight: 700;
    font-size: 18px;
    color: #00a3ad;
}

.continueButtonWrapper {
    margin-top: 45px;
    width: 35%;

    @media (--viewportMedium) {
        /* margin-top: 96px; */
    }
}

.continueButtonWrapper button {
    background: #fecd2a;
    min-height: 50px;
    border-radius: 4px;
    text-transform: initial;

    &:hover {
        background: #fecd2a;
        color: #0d6786;
    }
}


.continueButtonWrapper button span {
    font-weight: 700;
    font-size: 18px;
    color: #026786;
}
.verifiedRefresher {
    padding: 7px;
    font-size: 17px;
    color: #4A4A4A;
}

.refresherPageButton {
    text-decoration: underline;
    color: #00A3AD;
}