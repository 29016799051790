@import '../../marketplace.css';

.root {
  margin: 0;

  /* Clearfix */
  @apply --clearfix;
}
.sectionTestimonials {
  /* padding-top: 40px; */
  padding-bottom: 25px;
  padding-top: 60px;
  background: #FFFFFF;
  @media (max-width: 767px) {
    padding-top: 30px;
  }
}

.sectionTestimonialsDescription {
  color: var(--marketplaceColorDark);
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  display: block;
  margin-bottom: 50px;
  @media (max-width: 767px) {
    display: none;
  }
}

.sectionTestimonialsTitleWrapper {
  padding: 0 30px;
  max-width: 1240px;
  padding: 0 20px;
  & > .sectionTestimonialsTitle {
    white-space: pre-wrap;
    margin-bottom: 10px;
  }
  @media (--viewportLarge) {
    text-align: center;
    margin: 0 auto;
    & > .sectionTestimonialsTitle {
      white-space: nowrap;
    }
  }
  @media (max-width: 767px) {
    padding: 0 20px;
  }
}

.sectionTestimonialsTitle {
  font-size: 35px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: rgba(24, 163, 173, 1);
  @media (--viewportLarge) {
    text-align: left;
  }
  @media (--viewportMLarge) {
    font-size: 48px;
    line-height: normal;
    text-align: left;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin-bottom: 30px;
    font-size: 30px;
    text-align: center;
    line-height: normal;
  }
}

.sliderWrapper {
  margin-top: 50px;
  margin: 10px auto 50px;
  max-width: 1240px;
  width: 100%;
  @media (--viewportLarge) {
    width: 100%;
  }
  @media (max-width: 767px) {
    padding: 0 20px;
    max-width: 100%;
    margin: 0;
    margin-top: 20px;
  }
}

.desc {
  padding: 15px 0;
  text-align: center;
  @media (--viewportMobile) {
    font-size: 15px;
    line-height: normal;
  }
}

/* SectionStories */
.sectionStoriesDescription {
  display: none;
  color: var(--marketplaceColorDark);
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  display: block;
  margin-bottom: 50px;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: normal;
    margin-bottom: 15px;
  }
}

.sectionStoriesTitleWrapper {
  padding: 0 30px;
  @media (max-width: 767px) {
    padding: 0 20px;
    text-align: center;
  }
  & > .sectionTitle {
    white-space: pre-wrap;
    font-size: 48px;
    line-height: normal;
    text-align: center;
    font-weight: var(--fontWeightBold);
    color: var(--marketplaceColorDark);
    @media (max-width: 767px) {
      font-size: 35px;
      line-height: normal;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  @media (--viewportLarge) {
    text-align: center;
    margin: 0 auto;
    & > .sectionTitle {
      white-space: nowrap;
    }
  }
}
