@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.paymentAddressField {
  padding-top: 38px;
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }
  @media (--viewportMobile) {
    margin-top: 0;
  }
}

.field {
  width: 100%;
  margin-top: 24px;

  @media (--viewportSmall) {
    width: calc(50% - 12px);
  }
}

.formInputNew input, .formInputNew select{
  height: 54px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  line-height: normal;
  padding: 15px;
}
.formInputNew input:hover, .formInputNew select:hover{
  border: 1px solid #E6E6E6;
}
.formInputNew label{
  color: #4A4A4A;
  font-weight: 300;
}