@import "../../TripPanel.css";

/* link Modal */
.modal {
  /* @apply --marketplaceModalInMobileBaseStyles; */
  border-radius: 8px;
  height: max-content;
  /* width: 720px; */
  max-height: 550px;
  overflow-y: auto;
  max-width: 100%;
  background-color: white;
  position: fixed;
}

@media (--viewportMedium) {
  .modal {
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
  }
 }

@media (--viewportMobile) {
  .modal {
    max-height: 100vh;
  }
}

.main {
  display: flex;
}

.tripModalContainer {
  width: 750px;
  height: 800px;
  padding: 0px;
}

.pickupModalContainer {
  width: 750px;
  padding: 0px;
}

.boxPadding  span {
  padding-top: 20px;
}

.modalInfoTripContainer {
  align-items: center;
}

.borderBottom {
  border-bottom: 1px solid #E6E6E6;
}

.tripBox {
  padding: 20px;
}
@media (--viewportMobile) {
  .tripBox {
    padding: 10px 5px;
  }
}


.tripBox h3 {
  font-size: 26px;
  color: #0D6786;
}

.tripBox h5 {
  font-size: 22px;
  color: #0D6786;
  font-weight: 700;
}

.tripBox  ol {
  padding-left: 21px;
  margin-bottom: 10px;
}

.tripBox span a {
  text-decoration: underline;
}

.tripBox:last-child span {
  line-height: 28px;
}

@media (--viewportMobile) {
  .tripBox h3 {
    font-size: 22px;
  }

  .tripBox h5 {
    font-size: 18px;
  }

  .tripBox span{
    font-size: 14px;
  }

  .tripBox li {
    font-size: 14px;
  }
}

.displayFlex {
  display: flex;
}

.justifyContentBetween {
  justify-content: space-between;
}

.instructionsWrapper {
  overflow-wrap: anywhere;
}

.readMoreButton {
  color: #0aa3ad;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  margin-top: 8px;
  text-decoration: underline;
}

.boxDistance {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.headingDistance {
  font-weight: bold;
}

@media (--viewportMobile) {
  .boxDistance {
    flex-direction: row;
    align-items: flex-start;
    font-size: 15px;
  }

  .headingDistance {
    margin-bottom: 5px;
  }

  .boxDistance a {
    font-size: 14px;
    margin-top: 5px;
  }
}

@media (max-width: 400px) {
  .boxDistance {
    font-size: 14px;
  }
}

@media (max-width: 370px) {
  .boxDistance {
    flex-direction: column;
    align-items: flex-start;
    font-size: 15px;
  }
}

