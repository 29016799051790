@import '../../marketplace.css';

.root {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 24px 24px 42px 24px;
  background-color: #1aa2ac;

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 81px 0;
    border-top: none;
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  @media only screen and (min-width: 576px) {
    max-width: 540px;
  }
  @media only screen and (min-width: 768px) {
    max-width: 720px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 960px;
  }
  @media only screen and (min-width: 1200px) {
    max-width: 1140px;
  }
  @media only screen and (min-width: 1400px) {
    max-width: 1368px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.column {
  padding-left: 15px;
  padding-right: 15px;
}
/* Large */
.columnLarge3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.columnLarge50 {
  flex: 0 0 20%;
  max-width: 20%;
}
.columnLarge5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.columnLarge6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.columnLarge7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.columnLarge8 {
  flex: 0 0 66.666%;
  max-width: 66.666%;
}
.columnLarge10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.columnLarge12 {
  flex: 0 0 100%;
  max-width: 100%;
}

/* Medium */
.columnMedium3 {
  @media (min-width: 768px) and (max-width: 1199px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.columnMedium50 {
  @media (min-width: 768px) and (max-width: 1199px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.columnMedium6 {
  @media (min-width: 768px) and (max-width: 1199px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.columnMedium12 {
  @media (min-width: 768px) and (max-width: 1199px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Small */
.columnSmall3 {
  @media only screen and (max-width: 767px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.columnSmall50 {
  @media only screen and (max-width: 767px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.columnSmall6 {
  @media only screen and (max-width: 767px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.columnSmall10 {
  @media only screen and (max-width: 767px) {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}
.columnSmall12 {
  @media only screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.column12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.column4 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
}

.column6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.column8 {
  flex: 0 0 66.666%;
  max-width: 66.666%;
}

.column10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.alignItemsCenter {
  display: flex;
  align-items: center;
}
.justifyContentCenter {
  display: flex;
  justify-content: center;
}
