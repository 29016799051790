@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --Topbar_logoHeight: 25px;
}

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
  transition: 0.3s all cubic-bezier(0.18,0.52,0.49,0.85);
  top: 0;
}

.topbarRootMobileWithBanner {
  margin-bottom: 100px;

  @media (--viewportLarge) {
    margin-bottom: unset;
  }
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);
  position: relative;
  top: 0;
  left: 0;
  padding: 0 20px;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: #FFFFFF !important;

  @media (--viewportMedium) {
    display: none;
  }
}

.home {
  display: flex;
  padding: calc((var(--topbarHeight) - var(--Topbar_logoHeight)) / 2) 24px;
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  /* Layout */
  display: flex;
  width: 66px;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  padding: 15px 24px;
  position: relative;
}
.searchMenu {
  padding: 12px 24px;
  visibility: hidden;
}

.menuIcon,
.searchMenuIcon {
  margin: 9px 0;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 17px;
  right: 17px;

  /* Style: red dot with white border */
  background-color: var(--failColor);
  border: 2px solid var(--matterColorLight);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--matterColorLight);
}
.rootSearchIcon {
  stroke: var(--matterColorLight);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.mobileHelp {
  color: var(--matterColorAnti);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;
  padding: 0;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.missingInformationModal {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

.helperLink {
  @apply --marketplaceModalHelperLink;
}

.helperText {
  @apply --marketplaceModalHelperText;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

.reminderModalLinkButton {
  @apply --marketplaceButtonStylesPrimary;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  border-radius: 4px;
  background-color: var(--marketplaceColorLight);
  border-radius: 4px;
}

.genericErrorText {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 0;
  }
}

.callUsWrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50vh + 44px + 6px);
  right: 15px;
  width: 44px;
  height: 44px;
  z-index: 500;
}

.callUs {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingpage/call_icon_mobile.svg');
  background-position: center;
  background-size: contain;
  width: 40px;
  height: 40px;
}

.whatsappUsWrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50vh + 88px + 12px);
  right: 15px;
  width: 44px;
  height: 44px;
  z-index: 500;
}

.chatUsWrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50vh);
  right: 15px;
  width: 44px;
  height: 44px;
  z-index: 500;
  cursor: pointer;
}

.whatsappUs {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingpage/whatsapp.png');
  background-position: center;
  background-size: contain;
  width: 40px;
  height: 40px;
}

.chatUs {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingpage/chat_icon_mobile.png');
  background-position: center;
  background-size: contain;
  width: 40px;
  height: 40px;
}

.contactUsWrapper {
  width: 100px;
  height: 38px;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: 5px;
  position: fixed;
  right: 0;
  top: var(--topbarHeight);
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: none;
  @media (--viewportMobile) {
    position: absolute;
  }
}

.contactUsWrapperFixed {
  top: 0;
  right: 0;
  position: fixed;
}

.contactUs {
  flex: 1;
  font-size: 16px;
  line-height: 18px;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
  letter-spacing: -0.3px;
}

.closeButtonClassName {
  @apply --marketplaceModalCloseStyles;

  top: 0;
  left: 0;
  display: none;
}

.closeTextClassName {
  display: none;
}

.mobileMenuLogoWrapper {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%);
}

.mobileMenuLogo {
  height: 27px;
}

.mobileMenuContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  background-color: #4a4a4a33;
  padding: 0;
}

.avatarWrapper {
}

.closeIconClassName {
  fill: var(--matterColorLight);
  margin: 0;
}

.avatarTopbarWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrowDown {
  margin-left: 10px;
}

.mobileWithBanner {
  top: 110px;

  @media (--viewportLarge) {
    top: unset;
  }
}

.bannerInMobile {
  position: fixed;
  top: 0;
  left: 0;

  @media (--viewportLarge) {
    position: unset;
    top: unset;
    left: unset;
  }
}

.contactInWithBanner {
  top: calc(109px + var(--topbarHeight));

  @media (--viewportLarge) {
    top: unset;
  }
}

.mobileMenuScrollLayer {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.isHiddenForSearchPage{
  display: none !important;
}

.signupButtonTopBar {
  width: 119px;
  height: 38px;
  border: 1px solid #296683;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  font-size: 14px;
  line-height: normal;
  color: #296683;
  border-radius: 38px;
  cursor: pointer;
  font-family: 'Inter', sans-serif !important;
  transition: 0.3s all cubic-bezier(0.18,0.52,0.49,0.85);
  font-weight: 500;
}
.signupButtonTopBar {
  background: #F8CE47;
  border: 1px solid #ffcd2d;
}
.signupButtonTopBar:hover, .signupButtonTopBar:focus {
  background: #FFC60F;
}

.scroll {
  top: -80px;
}
.stickTopbar {
  box-shadow: 0 0 10px 0 rgba(67, 67, 67, 0.15);
}