@import '../../marketplace.css';

.root {
  position: relative;
}

.inline {
  display: flex;
  flex-direction: row;
}

.input {
  border-bottom-color: var(--inputBorderBottomColor);
}

.inputSuccess {
  border-bottom-color: var(--inputBorderBottomColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.inputInline {
  margin-left: 12px;
  max-width: 80px;
}

.labelContainer {
  display: flex;
}

.currentCount{
  justify-self: flex-end;
  margin-left: auto;
}

.inputContainer{
  display: flex;
}