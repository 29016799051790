@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.moreDesc {
  font-size: 16px;
  font-style: italic;
  color: var(--matterColorDark);
  margin-top: 5px;
  margin-bottom: 5px;
}

.moreDescSmall {
  font-size: 14px;
  margin-bottom: 35px;
  color: var(--matterColorAnti);
}
.calendlyWidget {
  position: relative;
  min-width: 320px;
  height:750px;
}
.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  background-color:#00a3ad;
  color: var(--matterColorLight);
  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
