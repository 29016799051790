@import '../../marketplace.css';
.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: 0;
}

.title {
  margin-bottom: 24px;
}

.error {
  color: var(--failColor);
}

.calendarWrapper {
  flex-grow: 1;
  position: relative;
  width: 100%;
  padding: 30px 10px;
  @media (max-width: 767px) {
    padding: 30px 0 0;
    border-left: 0;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  background-color: #00a3ad;
  color: #ffffff;
}

.leftContainer .availabilitySaveButton {
  height: 45px;
  min-height: 45px;
  border-radius: 4px;
  max-width: 100%;
  margin-left: auto;
  background-color: #ffcd05;
  color: #026786;
  text-align: center;
  text-decoration: none;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
  font-size: 18px;
  text-transform: none;
  letter-spacing: normal;
}

@media (--viewportMedium) {
  .root {
    padding-top: 0;
  }
  .title {
    margin-bottom: 32px;
  }
}

@media (--viewportLarge) {
  .calendarWrapper {
    flex-grow: 0;
  }
}

.fieldHourlyBooking {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkboxWrapper {
  margin-left: 12px;
}

.input {
  max-width: 80px;
  margin-left: 12px;
}

.field {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.error {
  @media (--viewportMedium) {
    margin-top: 45px !important;
  }
}

.column {
  padding-left: 20px;
  padding-right: 20px;
}

.column12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.column4 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.column3 {
  flex: 0 0 25%;
  max-width: 25%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.column8 {
  flex: 0 0 66.6666%;
  max-width: 66.6666%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.displayInlineContainer {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  flex-wrap: wrap;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.newInput {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0 0 20px;
}

.newInput input,
.newInput select {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  width: 100%;
  margin: 0;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}

.newInput select {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 7px 45px 7px 15px;
}

.newInput textarea {
  height: 120px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 15px 15px;
  font-size: 20px;
  margin: 0;
  resize: none;
  box-sizing: border-box;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}

.newInput label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
  @media (max-width: 767px) {
    margin-bottom: 5px;
  }
}

.stickyButtons {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  z-index: 9;
  @media (max-width: 767px) {
    height: auto;
    box-shadow: none;
  }
}

.stickyButtons .stickButtonsContainer {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.stickyButtons button {
  height: 60px;
  border-radius: 4px;
  max-width: 301px;
  margin-left: auto;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    height: 52px;
  }
}

.stickButtonsDescription {
  flex: 0 0 70%;
  max-width: 70%;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: left;
    flex: 0 0 100%;
    max-width: 100%;
    /* padding: 15px 20px; */
    padding: 0;
    background: #eff7f8;
    display: none;
  }
}

.stickButtonsDescription .descIcon {
  margin-right: 10px;
  width: 80px;
  height: auto;
  min-width: 80px;
  position: relative;
  @media (--viewportMobile) {
    width: 42px;
    min-width: 42px;
  }
}

.stickButtonsDescription .descIcon .needle {
  width: 40px;
  position: absolute;
  bottom: -20px;
  left: 0;
  transform-origin: 100%;
  @media (--viewportMobile) {
    width: 20px;
    bottom: -12px;
  }
}

.stickButtonsDescription .descIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.stickButtonsDescription p {
  color: #4a4a4a;
  line-height: normal;
  margin: 0;
  max-width: 600px;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }
}

.stickButton {
  text-align: right;
  flex: 0 0 30%;
  max-width: 30%;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 6px 20px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  }
}

.contentRight {
  margin: 0;
  border-left: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  @media (max-width: 767px) {
    padding: 0;
    border: 0;
  }
}

.contentLeft {
  margin: 0;
  border-bottom: 1px solid #e6e6e6;
  @media (max-width: 767px) {
    padding: 0;
    border: 0;
  }
}

.leftContainer {
  padding: 30px 10px;
  @media (max-width: 767px) {
    padding: 0;
  }
}

.formHeading {
  font-size: 22px;
  line-height: 1.181;
  color: #4a4a4a;
  font-weight: 700;
  margin-bottom: 8px;
}

.leftContainer p {
  font-size: 18px;
  line-height: 1.444;
  color: #4a4a4a;
  font-weight: 300;
  margin: 0;
}

.calendarWrapper p {
  font-size: 18px;
  line-height: 1.444;
  color: #4a4a4a;
  font-weight: 300;
  margin: 0;
}

.daysAvailabilityListing {
  padding-top: 30px;
}

.toggleWrapper {
  background: #00a3ad0d;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 10px;
  margin-bottom: 15px;
}

.toggleWrapperDisabled {
  background: #ebebeb;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 10px;
  margin-bottom: 15px;
}

.availabilityToggle {
  margin: 0 9px 0 0;
}

.availabilityToggle label {
  margin: 0;
}

.availabilityToggle + label {
  padding: 0;
  font-size: 18px;
  line-height: 22px;
  color: #026786;
}

.availabilityLabel {
  font-size: 18px;
  line-height: 22px;
  color: #00a3ad;
}

.customInputLabel {
  font-weight: 300;
  color: #4a4a4a;
  padding-top: 6px;
  padding-bottom: 2px;
  font-size: 16px;
  line-height: 24px;
}

.inputWithPostFix {
  display: flex;
  align-items: center;
}

.inputWithPostFix .inputPostFix {
  align-self: center;
  font-size: 20px;
  line-height: 1.2;
  margin-right: 0;
  margin-left: 10px;
}

.inputWithPostFix .newInput {
  margin: 0;
  /* max-width: 80px; */
}

.otherFormInputs {
  padding: 20px 10px 0;
  @media (max-width: 767px) {
    padding: 30px 0 0;
  }
}

.otherFormInputs .column {
  @media (max-width: 767px) {
    padding: 0;
  }
}

.infoText {
  padding: 8px 0 5px;
  line-height: normal;
  display: flex;
  align-items: flex-start;
  flex: 0 0 100%;
  max-width: 100%;
}

.infoText .infoIcon {
  display: block;
  margin-right: 5px;
  margin-top: 0;
  max-width: 14px;
  flex: 1;
}

.infoText img {
  display: block;
}

.infoText p {
  flex: 1;
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  color: #4a4a4a;
  margin: 0;
}

.customToggleInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    margin-top: 15px;
  }
}

.customToggleInput label {
  font-size: 20px;
  line-height: 22px;
  font-weight: 300;
  padding: 0;
  @media (max-width: 767px) {
    flex: 1;
  }
}

.toggleLabels {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex: 1;
    max-width: 80%;
  }
}

.toggleInput {
  @media (max-width: 767px) {
    flex: 1;
    max-width: 20%;
    display: flex;
    justify-content: flex-end;
  }
}

.customClass {
  min-width: 16px;
  max-width: 16px;
}

/* togglebtn */

.flexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  @media (--viewportMobile) {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 10px;
  }
}

.dateOverride {
  margin-bottom: 0px;
}

.toggleSwitchMode {
  display: flex;
  align-items: center;
  @media (--viewportMobile) {
    width: 100%;
    justify-content: center;
    margin: 20px 0;
  }
}

.hideSwitchModeMobile {
  @media (--viewportMobile) {
    width: auto;
    display: flex;
    flex-direction: row;
    margin: 0;
  }
}

.hideSwitchMode {
  display: none;
  @media (--viewportMobile) {
    display: flex;
    flex-wrap: wrap;
  }
}

.mode {
  font-size: 14px;
  line-height: 11px;
  color: var(--matterColor);
  text-transform: uppercase;
}

.info {
  margin: 0 4px;
  line-height: 0px;
}

.toggleBtn {
  text-align: center;
  color: var(--matterColorAnti);
  @media (--viewportMobile) {
    max-width: 100%;
  }
}

.btnContainer {
  display: inline-block;
  border: 1px solid var(--matterColorAnti);
  border-radius: 4px;
  position: relative;
}

.btnContainer input {
  width: 80px;
  height: 32px;
  display: inline-block;
  opacity: 0;
  position: absolute;
  z-index: -111;
}
.toggleLabel {
  padding: 0;
  display: flex;
}
.toggleLabel span {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  font-weight: 100;
  display: inline-block;
  width: 80px;
  padding: 4px 0;
}

.toggleLabel::before {
  content: '';
  width: 80px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--marketplaceColorDark);
  transition: all 0.2s ease;
  border-radius: 2px;
}
.daily {
  z-index: -111;
}

/* .daily:checked + .toggleLabel::before {
  left: 50%;
} */

.toggleLabel::before {
  left: 0;
}
.isHourly.toggleLabel::before {
  left: 50%;
}
/*
#daily+.toggleLabel::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  background: var(--marketplaceColorDark);
  transition: all 0.2s ease;
  border-radius: 2px;
}

#daily:checked+.toggleLabel::before {
  left: 0;
}

#daily:checked+.toggleLabel span, #hourly:checked+.toggleLabel span {
  position: relative;
  color: #fff;
}

input[type='radio']:checked+label {
  color: #fff;
} */

.newInput{
  flex-wrap: wrap;
}

.inputClass{
  max-width: 100px;
}

.errorClassName{
  position: relative !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  justify-self: flex-start;
}

.inputContainerClass{
  width: 100%;
}
