@import '../../marketplace.css';

.root {
  padding: 40px 20px;
  background-color: var(--marketplaceColorDark);
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (--viewportMedium) {
    align-items: center;
    padding: 40px 7.5vw;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    align-items: flex-start;
    padding: 40px 36px;
  }

  @media (--viewportMLarge) {
    padding: 40px calc((100vw - 1100px)/2);
    justify-content: space-between;
  }
}

.textWrapper {
  max-width: 266px;

  @media (--viewportMedium) {
    max-width: unset;
  }
}

.title {
  @apply --marketplaceH1FontStyles;

  font-weight: 900;
  color: var(--marketplaceSecondaryColor);
  margin: 0px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-bottom: 0px;
    font-size: 38px;
  }

  @media (--viewportLarge) {
    font-size: 38px;
    line-height: normal;
    max-width: 650px;
  }
}

.subtitle {
  color: var(--matterColorLight);
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 25px;

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (--viewportLarge) {
    font-size: 24px;
    line-height: 32px;
    margin-top: 15px;
    margin-bottom: 0px;
    padding-right: 50px;
    max-width: 630px;
  }
}

.subtitleLine1 {
  margin-bottom: 25px;
}

.subtitle2 {
  color: var(--marketplaceSecondaryColor);
  cursor: pointer;
}

.buttonWrapperTemp {
  padding: 0;
  width: 100%;

  @media (--viewportMedium) {
    width: auto;
  }
}

.buttonVideo {
  @apply --marketplaceButtonStyles;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  align-self: flex-end;
  margin-top: 18px;
  padding-top: 12px;
  min-height: 45px;
  width: 178px;
  margin-left: auto;

  &:hover,
  &:focus {
    background-color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    margin: 0px;
    display: block;
    padding-top: 16px;
    min-height: 60px;
    width: 260px;
  }
}

.videoWrapper {
  padding: 0;

  @media (--viewportMedium) {
    padding: 0 20px;
    /* margin-top: 40px; */
  }

  @media (--viewportMLarge) {
    margin-top: 0px;
  }
}

.contentModal {
  height: 100%;
}

.videoContentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    display: block;
  }
}

.video {
  width: 280px;
  background-color: var(--matterColorAnti);
  cursor: pointer;

  @media (--viewportMedium) {
    width: 500px;
    /* height: 280px; */
  }

  @media (--viewportLarge) {
    width: 313px;
    /* height: 150px; */
  }
}

.videoLogo {
  width: 100%;
}

.iframeVideo {
  width: 100%;
  height: 40%;

  @media (--viewportMedium) {
    height: 100%;
  }
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 40px 0 0 0;
  
  @media (--viewportMedium) {
    padding: 0px;
    flex-basis: 576px;
    min-height: 0;
    min-width: 80vw;
    height: 48vw;
    margin-top: calc(50vh - 30vw);
    border-bottom: 0px solid transparent;
  }

  @media (--viewportLarge) {
    margin-top: calc(50vh - 25vw);
    min-width: 80vw;
    height: 48vw;
  }

  @media (--viewportMLarge) {
    margin-top: calc(50vh - 20vw);
    min-width: 66vw;
    height: 40vw;
  }
}

.modalCloseButton {
  top: 0px;
  right: 0px;
  padding: 10px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    color: var(--matterColorLight);    
    top: -40px;
  }

  &:hover {
    color: var(--matterColorAnti) !important;
  }
}