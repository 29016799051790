@import '../../marketplace.css';

:root {

}

.root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    justify-content: flex-start;
    margin-bottom: 0px;
  }
}

.iconWrapper {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0;  
  margin-top: 20px;

  @media (--viewportMedium) {
    justify-content: flex-end;
    width: 160px;
    margin-top: 0px;  
  }

  @media (--viewportLarge) {
    margin-right: 65px;
    margin-top: 0px;  
  }
}

.iconWrapper:first-child {
  width: 100%;

  @media (--viewportMedium) {
    width: 160px;
  }
}

.imgWrapper {
  width: 150px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  @media (--viewportMedium) {
    width: 160px;
    height: 160px;
    margin-bottom: 25px;
  }
}

.iconImg {
  height: 120px;

  @media (--viewportMedium) {
    height: 160px;
  }
}

.iconText {
  display: block;
  text-align: center;
  color: var(--matterColorLight);
  font-weight: bold;
  font-size: 18px;
  width: 150px;

  @media (--viewportMedium) {
    width: 160px;
    font-size: 22px;
  }
}