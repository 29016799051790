@import '../../marketplace.css';

/* .logoMobile {
  & path {
    fill: var(--matterColorLight);
  }
} */

.logoMobile {
  height: 27px;
}