@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;

  --storyBackgroundColor: #f2f2f2;
}

.root {
  overflow: hidden;
}

.antiCompetitionRoot{
  padding-top: 0;
}

img {
  display: block;
  max-width: 100%;
}
.contentWrapper {
  position: relative;
  background: #ffffff;
}

.containerFluid {
  max-width: 100%;
  padding: 0 20px;
}
.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}
.row {
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
}

.column4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.alignItemsCenter {
  align-items: center !important;
}
.textCenter {
  text-align: center !important;
}

.channelsListings {
  background: #ffffff;
}

.landingPageBanner {
  padding-top: 74px;
  background: #ffffff;

  @media (max-width: 991px) {
    padding-top: 30px;
  }
  @media (max-width: 767px) {
    padding-top: 20px;
  }
}

.landingPageBannerRow {
  margin-left: -50px;
  margin-right: -50px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.contentSliderWrapper {
  display: flex;
  align-items: center;
  height: 900px;
  position: relative;
  min-height: 740px;
  height: 740px;

  @media (--viewportXLarge) {
    min-height: 900px;
    height: 900px;
  }
  @media (--viewportMobile) {
    display: block;
    min-height: 330px;
    height: auto;
    padding: 90px 0 40px;
  }
}

.contentSliderWrapper .container {
  position: relative;
  z-index: 2;
}

.carsSlider {
  position: absolute;
  left: 50%;
  top: 0;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  @media (--viewportMobile) {
    position: static;
    width: 100%;
    display: none;
  }
}
.carsSliderMobile{
  display: none;
  @media (--viewportMobile) {
    position: static;
    width: 100%;
    display: block;
  }
}
.carsSliderMobile .carsSlider{
  @media (--viewportMobile) {
    position: static;
    width: 100%;
    display: block;
  }
}
.carThumb {
  max-width: 80%;
  position: relative;
  height: 50%;
  @media (--viewportMobile) {
    max-width: 100%;
    height: auto;
    padding: 40px 0 120px;
  }
}
.carThumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.carDiscount {
  color: #ffffff;
  text-align: left;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 5px solid rgba(254, 205, 42, 1);
  text-transform: uppercase;
  font-size: 24px;
  background: #0c6786;
  padding: 25px 0 40px 26px;
  position: absolute;
  top: 100%;
  left: 50%;
  @media (--viewportXLarge) {
    width: 228px;
    height: 228px;
    border: 10px solid rgba(254, 205, 42, 1);
    font-size: 30px;
    top: 90%;
    padding: 38px 0 40px 33px;
  }
  @media (--viewportMobile) {
    width: 116px;
    height: 116px;
    font-size: 16px;
    padding: 14px 0 0px 16px;
    top: calc(100% - 116px);
    left: auto;
    right: 0;
  }
}
.carDiscount::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 50px 15px;
  border-color: transparent transparent #fecc2a transparent;
  position: absolute;
  top: -52px;
  left: 25px;
  transform: rotate(-19deg);
  transform-origin: 100% 50%;
  @media (--viewportXLarge) {
    top: -52px;
    left: 45px;
    transform: rotate(-19deg);
  }
  @media (--viewportMobile) {
    top: -42px;
    left: -17px;
    transform: rotate(-41deg);
    transform-origin: 100% 50%;
  }
}
.carDiscount strong {
  display: block;
  color: rgba(254, 205, 42, 1);
  font-size: 50px;
  line-height: 1;
  font-weight: 900;
  @media (--viewportXLarge) {
    font-size: 64px;
    line-height: 1.33;
    font-weight: 900;
  }
  @media (--viewportMobile) {
    font-size: 40px;
    line-height: 33px;
  }
}

.bannerCarousel {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/anti-competition/banner-background.webp');
  background-image: image-set(
    url('https://dv0eqz2t0y9gj.cloudfront.net/anti-competition/banner-background-1.webp') 1x,
    url('https://dv0eqz2t0y9gj.cloudfront.net/anti-competition/banner-background-1.webp') 2x
  );
  background-size: cover;
  @media (--viewportLMLarge) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/anti-competition/anti-competition/banner-background.webp');
    background-image: image-set(
      url('https://dv0eqz2t0y9gj.cloudfront.net/anti-competition/banner-background-1.webp') 1x,
      url('https://dv0eqz2t0y9gj.cloudfront.net/assets/anti-competition/banner-background-1.webp') 2x
    );
    background-size: cover;
  }
  @media (--viewportMobile) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/anti-competition/banner-background-mobile.webp');
    background-image: image-set(
      url('https://dv0eqz2t0y9gj.cloudfront.net/anti-competition/banner-background-mobile.webp') 1x,
      url('https://dv0eqz2t0y9gj.cloudfront.net/anti-competition/banner-background-mobile.webp') 2x
    );
    background-size: cover;
    background-position: center;
  }
}

.content {
  padding: 20px;
}

.heroImage {
  /* background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/flexHeroMobile@2x.png');
  background-image: image-set(
    url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/flexHeroMobile.png') 1x,
    url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/flexHeroMobile@2x.png') 2x
  ); */
  background-size: contain;
  min-height: 198px;
  margin-top: calc(var(--topbarHeight) + 10px);
  position: relative;

  @media (--viewportMedium) {
    width: 420px;
    margin: calc(var(--topbarHeight) + 10px) auto 0;
    background-size: cover;
  }

  @media (--viewportMLarge) {
    display: none;
    /* background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/flexHero@2x.png');
    background-image: image-set(
      url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/flexHero.png') 1x,
      url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/flexHero@2x.png') 2x
    ); */
    background-size: contain;

    width: 580px;
    max-width: unset;
    min-height: 400px;

    margin: unset;
  }

  @media (--viewportXLarge) {
    width: 1550px;
    height: 580px;
  }
}

.heroImageDesktop {
  display: none;
  @media (--viewportMLarge) {
    display: block;
  }
}

.heroImageDesktop span {
  display: block;
  width: 230px;
  height: 230px;
  background-color: #0c6786;
  position: absolute;
  bottom: -28px;
  right: 19.8%;
  border-radius: 100%;
  border: 10px solid #fecd2f;
}

.heroContent {
  /* display: flex;
  flex-direction: column;

  @media (--viewportMLarge) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: calc(var(--topbarHeight) + 80px) auto 0;
    max-width: 1120px;
  } */

  max-width: 550px;
  @media (--viewportMobile) {
    max-width: 100%;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  font-size: 38px;
  line-height: 44px;
  color: var(--matterColorLight);
  letter-spacing: 0;
  margin: 10px 0 0;
  font-weight: var(--fontWeightBold);
  @media (--viewportLarge) {
    font-size: 38px;
    line-height: 44px;
  }
  @media (--viewportMLarge) {
    font-size: 60px;
    line-height: 80px;
  }
  @media (--viewportMLarge) {
    font-size: 60px;
    line-height: 80px;
  }
  @media (--viewportMobile) {
    margin: 0;
    font-size: 32px;
  }
}

.subText {
  font-weight: var(--fontWeightBold);
  display: block;
  font-size: 80px;
  color: var(--marketplaceSecondaryColor);
  @media (--viewportLarge) {
    font-size: 54px;
  }
  @media (--viewportMLarge) {
    font-size: 80px;
  }
  @media (--viewportXLarge) {
    font-size: 80px;
  }
  @media (--viewportMobile) {
    margin: 0;
    font-size: 45px;
  }
}

.description {
  @apply --marketplaceDefaultFontStyles;
  font-size: 18px;
  line-height: 24px;
  color: var(--matterColorLight);
  letter-spacing: 0;
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 60px;
  @media (--viewportMobile) {
    margin-bottom: 35px;
  }
}

.description p {
  @media (--viewportMLarge) {
    font-size: 30px;
    line-height: 38px;
    margin-top: 35px;
    margin-bottom: 0;
  }
  @media (--viewportMobile) {
    margin: 0;
  }
}

.signUpButton {
  min-height: 65px;
  background-color: var(--marketplaceSecondaryColor);
  border-radius: 5px;
  @apply --marketplaceDefaultFontStyles;
  text-transform: none;
  color: var(--marketplaceColorDark);
  margin-top: 30px;
  margin-bottom: 40px;
  border-color: unset;

  @media (--viewportLarge) {
    max-width: 335px;
  }
}

.btnMore {
  min-width: 345px;
  height: 65px;
  padding: 10px 33px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: normal;
  color: #026786;
  border: 1px solid #fecd2a;
  background: #fecd2a;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  -moz-transition: 0.3s all ease;
  transition: 0.3s all ease;
  text-decoration: none !important;
  @media (--viewportMobile) {
    margin-top: 0;
    height: 49px;
    font-size: 18px;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    font-weight: 700;
    min-width: 100px;
  }
}
.btnMore:hover {
  background: #026786;
  color: #ffffff;
}

/* Competition section */
.competitionWrapper {
  background: #18a3ad;
  padding: 40px 0;
  @media (--viewportXLarge) {
    padding: 60px 0;
  }
  @media (--viewportMobile) {
    padding: 30px 0;
  }
}

.competitionWrapper .sectionTitle h2 {
  font-size: 40px;
  line-height: 1.187;
  text-align: center;
  max-width: 612px;
  margin: 0 auto;
  color: #ffffff;
  font-weight: var(--fontWeightBold);
  @media (--viewportXLarge) {
    font-size: 48px;
    line-height: 1.187;
  }
  @media (--viewportMobile) {
    font-size: 22px;
    line-height: 1.181;
    text-align: left;
  }
}

.comparitionOptionsSlider {
  padding: 50px 0;
  margin-left: -30px;
  margin-right: -30px;
  @media (max-width: 1240px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media (--viewportMobile) {
    padding: 17px 0;
  }
}

.sliderWrapper{
  padding: 0 50px;
  @media (--viewportMobile) {
    padding: 0 30px;
  }
}

.sliderWrapper :global(.slick-list) {
  overflow: hidden;
  @media (--viewportMobile) {
    padding: 0 !important;
  }
}

.sliderWrapper :global(.slick-arrow) {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  @media (--viewportMobile) {
    width: 20px;
    height: 20px;
  }
}

.sliderWrapper :global(.slick-arrow::before) {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: url("https://dv0eqz2t0y9gj.cloudfront.net/anti-competition/arrow-down-blue.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(0);
  margin: 0 auto;
  @media (--viewportMobile) {
    width: 12px;
    height: 12px;
  }
}

.sliderWrapper :global(.slick-next) {
  right: -45px;
  @media (--viewportMobile) {
    right: -30px;
  }
}
.sliderWrapper :global(.slick-next::before) {
  transform: translateY(-50%) rotate(-90deg);
}

.sliderWrapper :global(.slick-prev) {
  left: -45px;
  @media (--viewportMobile) {
    left: -30px;
  }
}

.sliderWrapper :global(.slick-prev::before) {
  transform: translateY(-50%) rotate(90deg);
}

.optionWrapper {
  height: 160px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  @media (--viewportMobile) {
    margin-right: 0;
    height: 115px;
  }
}

.optionPill {
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  /* width: 420px; */
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2);
}
.optionPill::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  filter: blur(50px);
  filter: brightness(25);
  filter: opacity(43%);
}
.optionPill.isSelected {
  background: #ffffff;
}
.optionPill.isSelected::before,
.optionPill.isSelected::after {
  filter: blur(0);
  filter: brightness(0);
  filter: opacity(0);
  background: #ffffff;
}
.optionPill::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 -3px 10px 0 rgba(0, 0, 0, 0.14);
}
.optionPill.isSelected .categoryType {
  opacity: 0.3;
}
.optionThumb {
  width: 72px;
  height: 72px;
  flex: 1;
  max-width: 72px;
  min-width: 72px;
  border-radius: 10px;
  border: 1px solid transparent;
  background: #ffffff;
  position: relative;
  z-index: 2;
}

.optionCaption {
  padding-left: 15px;
  position: relative;
  z-index: 2;
}
.optionCaption span {
  font-size: 20px;
  line-height: 1.2;
  color: #000000;
  display: block;
  font-weight: 700;
  @media (--viewportMobile) {
    font-size: 14px;
    line-height: normal;
  }
}
.optionCaption .categoryType {
  margin-bottom: 5px;
}
/* .optionCaption .exampleCars {
  display: none;
} */

.comparitionOptionsSlider {
  overflow: hidden;
  padding-bottom: 50px;
  @media (--viewportMobile) {
    padding-bottom: 20px;
  }
}

.rangeFilters {
  border-radius: 10px;
  background: #ffffff;
  position: relative;
  display: flex;
  margin-bottom: 35px;
}
.rangeFilters::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  background: rgb(8, 53, 68, 0.3);
  width: 1px;
  height: 60px;
}
.rangeFiltersLeft,
.rangeFiltersRight {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 25px 20px 34px;
}

.rangeFiltersLeft {
  @media (--viewportMobile) {
    padding: 15px 15px 23px;
  }
}

.rangeFiltersRight {
  @media (--viewportMobile) {
    padding: 15px 15px 23px 15px;
  }
}

.filterTitle {
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  line-height: normal;
  margin-bottom: 29px;
  @media (--viewportMobile) {
    font-size: 16px;
  }
}
.filterTitle .selectedValues {
  font-weight: var(--fontWeightBold);
  color: #4a4a4a;
  position: relative;
}
.filterTitle .selectedValues::after {
  content: "";
  position: absolute;
  width: 110%;
  height: 1px;
  background: rgba(13, 107, 136, 0.1);
  top: 102%;
  right: 0;
}
.filterTitle .selectedValues i {
  font-style: normal;
  font-size: 20px;
  @media (--viewportMobile) {
    font-size: 12px;
  }
}
.filterInput {
  height: 25px;
  /* background: #fecd2f; */
}

.comparisonGraph {
  border-radius: 20px;
  padding: 45px 35px;
  background: #ffffff;
  border: 1px solid #fecf2f;
  @media (--viewportMobile) {
    padding: 20px 15px;
  }
}

.comparisonHighlight {
  padding: 15px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: #0d6786;
  background: -moz-linear-gradient(top, #0d6786 0%, #073443 100%);
  background: -webkit-linear-gradient(top, #0d6786 0%, #073443 100%);
  background: linear-gradient(to bottom, #0d6786 0%, #073443 100%);
  @media (--viewportMobile) {
    padding: 10px 15px;
  }
}
.highlightIcon {
  width: 61px;
  height: 61px;
  display: block;
  @media (--viewportMobile) {
    width: 41px;
    height: 41px;
    min-width: 41px;
  }
}
.highlightIcon img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  object-position: center center;
}
.comparisonHighlight p {
  font-size: 30px;
  line-height: normal;
  font-weight: var(--fontWeightMedium);
  color: #ffffff;
  padding-left: 20px;
  @media (--viewportMobile) {
    font-size: 18px;
    padding-left: 10px;
    margin: 0;
  }
}
.comparisonGraphBottomSection {
  padding: 45px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (--viewportMobile) {
    padding: 25px 0;
  }
}
.comparisonGraphBottomSection .namedLink {
  font-size: 20px;
  color: #fecd2f;
  line-height: normal;
  border: 0;
  padding: 0;
  cursor: pointer;
  @media (--viewportMobile) {
    font-size: 18px;
  }
}
.comparisonGraphBottomSection .namedLink .buttonIcon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(-90deg);
}
.comparisonGraphBottomSection .namedLink .buttonIcon img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}
.comparisonGraphBottomSection .signUpTitle {
  @media (--viewportMobile) {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
}
.comparisonGraphBottomSection .btnMore {
  margin-top: 60px;
  @media (--viewportMobile) {
    margin-top: 35px;
    height: 49px;
    font-size: 18px;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    font-weight: 700;
  }
}

.statisticsWrapper {
  background: rgba(12, 103, 135, 0.05);
  padding: 60px 0;
  @media (--viewportXLarge) {
    padding: 90px 0;
  }
  @media (--viewportMobile) {
    padding: 45px 0 30px;
  }
}
.statisticsWrapper .column4 {
  @media (--viewportMobile) {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}
.statsContent {
  text-align: center;
}
.statsThumb {
  width: 100px;
  height: 100px;
  margin: 0 auto 30px;
  border-radius: 50%;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.14);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  @media (--viewportXLarge) {
    width: 134px;
    height: 134px;
    padding: 25px;
  }
  @media (--viewportMobile) {
    width: 81px;
    height: 81px;
    padding: 15px;
    margin: 0 auto 16px;
  }
}
.statsThumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}
.statsContent h3 {
  font-size: 36px;
  font-weight: var(--fontWeightBold);
  line-height: normal;
  color: #000000;
  margin: 0 0 20px;
  @media (--viewportXLarge) {
    font-size: 48px;
  }
  @media (--viewportMobile) {
    font-size: 22px;
    line-height: normal;
    margin: 0 0 7px;
  }
}
.statsContent p {
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  line-height: normal;
  color: #000000;
  margin: 0;
  @media (--viewportXLarge) {
    font-size: 30px;
  }
  @media (--viewportMobile) {
    font-size: 12px;
  }
}

.homeDeliverWrapper {
  background: #ffffff;
  padding: 60px 0;
  position: relative;
  @media (--viewportXLarge) {
    padding: 90px 0;
  }
  @media (--viewportMobile) {
    padding: 30px 0;
  }
}
.homeDeliverWrapper::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50%;
  background: rgba(12, 103, 135, 0.05);
  z-index: 1;
  @media (--viewportMobile) {
    height: 35%;
  }
}

.homeDeliverContent {
  padding: 75px 45px;
  background: #ffffff;
  z-index: 2;
  border-radius: 10px 20px 20px 10px;
  position: relative;
  overflow: hidden;
  @media (--viewportMobile) {
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.05);
  }
}

.homeDeliverContent .leftContent {
  @media (--viewportMobile) {
    padding: 30px 20px;
  }
}
.homeDeliverContent h4 {
  margin: 0 0 15px;
  font-size: 40px;
  line-height: 1.187;
  color: #18a3ad;
  font-weight: var(--fontWeightBold);
  @media (--viewportXLarge) {
    font-size: 48px;
  }
  @media (--viewportMobile) {
    font-size: 22px;
    line-height: 1.181;
    margin: 0 0 10px;
  }
}

.homeDeliverContent p {
  font-size: 24px;
  line-height: 1.2666;
  @media (--viewportXLarge) {
    font-size: 30px;
  }
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 1.3333;
    margin-bottom: 30px;
  }
}
.homeDeliverContent .btnMore {
  @media (--viewportMobile) {
    height: 49px;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.deliveryBannerBlock {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
  @media (--viewportMobile) {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 0 0 20px 20px;
  }
}
.deliveryBannerBlock img {
  object-position: center center;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.comparisonLegends {
  padding-left: 2%;
  padding-top: 45px;
  padding-bottom: 20px;
  @media (--viewportMobile) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.comparisonLegendsRow {
  display: flex;
  flex-wrap: wrap;
}
.comparisonLegendsColumn4 {
  flex: 0 0 25%;
  max-width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.comparisonLegendsColumn5 {
  flex: 0 0 20%;
  max-width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.legendThumbs {
  width: 74px;
  height: 74px;
  @media (--viewportMobile) {
    width: 45px;
    height: 45px;
  }
}
.legendThumbs img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: center center;
}

.isSelected {
  border: 3px solid #fecd2f;
  width: 100%;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.filterInput :global(.rangeslider) {
  background: rgba(254, 205, 47, 0.2);
  box-shadow: none;
}
.filterInput :global(.rangeslider__fill) {
  background: rgba(254, 205, 47, 1);
  box-shadow: none;
}
.filterInput :global(.rangeslider__handle) {
  box-shadow: none;
  background: rgba(13, 103, 134, 1);
  @media (--viewportMobile) {
    height: 20px;
    width: 20px;
  }
}
.filterInput :global(.rangeslider__handle::after) {
  box-shadow: none;
  width: 18px;
  height: 18px;
  top: 5px;
  left: 5px;
  background: #ffffff;
  @media (--viewportMobile) {
    width: 12px;
    height: 12px;
    top: 3px;
    left: 3px;
  }
}
.filterInput :global(.rangeslider-horizontal) {
  @media (--viewportMobile) {
    height: 5px;
  }
}

.noDataAvailable {
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: var(--fontWeightBold);
  color: #073443;
  text-align: center;
  @media (--viewportMobile) {
    padding: 10px;
    font-size: 18px;
    line-height: 1.2;
  }
}
.insuranceListingWrapper h3 {
  font-size: 36px;
  font-weight: var(--fontWeightBold);
  color: #026786;
}
.insuranceListingWrapper ul {
  list-style: disc;
  padding-left: 20px;
}
.insuranceListingWrapper ul li {
  margin-bottom: 15px;
}


/* Toggle input */
.mobileDurationToggle{
  display: flex;
  justify-content: space-between;
  max-width: 150px;
  margin: 0 auto;
}

.mobileFilterTitle{
  @media (--viewportMobile) {
    margin-bottom: 20px;
  }
}

.toggleCaption{
  /* width: 40px; */
  font-size: 16px;
  font-weight: var(--fontWeightBold);
}
.toggleInput input[type="checkbox"]{
  position: absolute;
  left: -999999px;
}
.toggleInput input[type="checkbox"] + label{
  position: relative;
  width: 40px;
  height: 22px;
  border-radius: 30px;
  border: 1px solid rgba(13, 103, 134, 1);
}
.toggleInput input[type="checkbox"] + label::before{
  content: "";
  left: 2px;
  top: 2px;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: rgba(13, 103, 134, 1);
  transition: 0.3s all ease;
}
.toggleInput input[type="checkbox"]:checked + label::before{
  left: 19px;
  top: 2px;
}
