@import '../../marketplace.css';

.root {
  margin: 0;
}

.reviewItemWrapper {
  margin-bottom: 32px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  padding: 10px;
  margin-right: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 39px;
  }
}

.review {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.avatar {
  flex: 1;
  width: 43px;
}

.authorName {
  font-size: 18px;
  color: #141414;
  font-size: 18px;
  line-height: normal;
  font-weight: 700;
  margin-bottom: 7px;
}

.reviewContentAuthorWrapper {
  padding-left: 10px;
  flex: 0 0 calc(100% - 40px);
  max-width: calc(100% - 40px);
}
.reviewDate {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: normal;
  color: #141414;
}
.reviewDate svg {
  margin-right: 5px;
}

.reviewContentWrapper{
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 10px;
  font-size: 18px;
  color: #4A4A4A;
  font-weight: 300;
}

.reviewContent {
  @apply --marketplaceH4FontStyles;
  font-style: italic;
  margin: 0;
  white-space: pre-line;
}

.reviewInfo {
  @apply --marketplaceH5FontStyles;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  position: absolute;
  top: 4px;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.listingReview {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #141414;
  font-size: 16px;
  line-height: normal;

}
.readMoreButton {
  font-size: 16px;
  line-height: normal;
  font-weight: 700;
  color: #00A3AD;
  padding-top: 20px;
  display: inline-flex;
  align-items: center;
  @media (--viewportMobile) {
    display: none;
  }
}

.readMoreButton img {
  transform: rotate(-90deg);
  margin-left: 7px;
  width: 11px;
}