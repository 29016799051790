@import '../../marketplace.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
  @media (max-width: 767px) {
    padding: 0;
    border: 0;
  }
}

.form {
  flex-grow: 1;
  padding: 30px 40px;
  @media (max-width: 767px) {
    padding: 0;
    margin-top: 30px;
  }
}
.titleSection {
  padding: 30px 40px;
  border-bottom: 1px solid #e6e6e6;
  @media (max-width: 767px) {
    padding: 0;
    border: 0;
  }
}
.title {
  color: #026786;
  margin: 0 0 10px;
  font-size: 32px;
  line-height: normal;
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: normal;
    font-weight: 700;
  }
}

.titleSection p {
  font-size: 16px;
  line-height: normal;
  margin: 0 0 15px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}
.titleSection p:last-child {
  margin: 0;
}


.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  background-color: #00a3ad;
  color: #ffffff;
  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.stickyButtons {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  @media (max-width: 767px) {
    height: auto;
  }
}
.stickyButtons .stickButtonsContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}
.stickyButtons button {
  height: 60px;
  border-radius: 4px;
  max-width: 301px;
  margin-left: auto;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    height: 52px;
  }
}
.stickButtonsDescription {
  flex: 0 0 70%;
  max-width: 70%;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: left;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 15px 20px;
    padding: 0;
    background: #eff7f8;
    display: none;
  }
}

.stickButtonsDescription .descIcon {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
}
.stickButtonsDescription .descIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.stickButtonsDescription p {
  color: #4a4a4a;
  max-width: 600px;
  line-height: normal;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }
}
.stickButton {
  text-align: right;
  flex: 0 0 30%;
  max-width: 30%;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 6px 20px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  }
}