@import '../../marketplace.css';

.heroImage {
  display: none;

  @media (--viewportMedium) {
    display: block;
    object-fit: cover;
    width: 100%;
    height: calc(30vw);
  }

  @media (--viewportMLarge) {
    height: calc(26.2vh * 1.78);
  }
}

.heroImageMobile {
  width: 100%;
  height: 37vw;
  object-fit: cover;
  object-position: bottom;
  display: block;

  @media (--viewportMedium) {
    display: none;
  }
}

.contentWrapper {
  width: 100%;
  max-width: 1140px;
  margin: auto;
  padding: 20px 15px;

  @media (--viewportMedium) {
    padding: 50px 25px;
  }

  @media screen and (min-width: 1200px) {
    padding: 70px 0px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  font-weight: 900;
  margin-bottom: 15px;

  @media (--viewportLarge) {
    margin-bottom: 60px;
  }
}

.mobileNewline {
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.infoContactWrapper {
  display: flex;
  flex-direction: column-reverse;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.buttonsWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  order: 1;

  @media (--viewportLarge) {
    align-items: flex-start;
    order: 1;
  }
}

.buttonLink {
  @apply --marketplaceButtonStyles;
  min-height: 50px;
  padding: unset;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 900;
  margin: 10px 10px 20px 10px;
  text-transform: unset;

  &:hover, &:focus {
    background-color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    min-height: 60px;
    font-size: 30px;
    width: 400px;
    margin: 16px 0px 20px 20px;
  }

  @media (--viewportLarge) {
    margin: 16px 0px 26px 20px;
    width: 450px;
    min-height: 85px;
  }
}

.chatWithUs {
  background-color: var(--marketplaceColorDark);
}

.description {
  @apply --marketplaceDescFontStyles;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: var(--marketplaceColor);
  order: 2;
  margin-bottom: 16px;

  @media (--viewportLarge) {
    order: 2;
  }
}

.descriptionText {
  display: block;
  font-size: 20px;

  @media (--viewportMedium) {
    font-size: 27px;
  }
}

.someLinks {
  display: block;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;

  @media (--viewportLarge) {
    text-align: left;
    margin-top: 40px;
  }
}

.icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 40px;
  text-align: center;
}

.iconSize {
  width: 34px;
  height: 34px;
}

.boldText {
  font-weight: 900;
}
