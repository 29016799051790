@import '../../marketplace.css';

.root {
  position: relative;
}

.label {
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
}

.icon {
  display: none;
}

.inputClassName {
  @apply --marketplaceInputStyles;
  border-color: var(--marketplaceColor);
}

.dateInput {
  margin-top: 10px;
  width: 100%;

  @media (--viewportLarge) {
    margin-top: 0;
  }
}

.pickUp {
  z-index: 10;
}

.dropOff {
  z-index: 5;
}

.dropOff :global(.SingleDatePicker_picker__directionLeft) {
  left: -163px !important;
}

.findYourCar {
  @apply --marketplaceButtonStyles;

  background-color: var(--marketplaceColor) !important;
  color: var(--matterColorLight) !important;
  text-transform: none !important;

  font-size: 20px !important;
  line-height: 24px !important;
  &:hover,
  &:focus {
    background-color: var(--marketplaceColorDark) !important;
  }
  &:disabled {
    background-color: var(--matterColorNegative) !important;
    color: var(--matterColorLight) !important;
  }

  height: 60px;
  margin-top: 20px !important;
  border-radius: 5px !important;

  @media (--viewportXLarge) {
    margin-top: 40px !important;
  }
}

.help {
  font-size: 16px;
  line-height: 24px;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);
  margin-top: 15px;

  @media (--viewportLarge) {
    margin-top: 0;
    white-space: pre-wrap;
    text-align: right;
    font-size: 18px;
    line-height: 24px;
    flex: 1;
  }

  @media (--viewportXLarge) {
    font-size: 20px;
    line-height: 24px;
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: column-reverse;

  @media (--viewportLarge) {
    flex-direction: column;
    min-width: 200px;
    align-items: flex-end;
    justify-content: flex-end;
  }

  @media (--viewportXLarge) {
    min-width: 224px;
  }
}

.fields {
  @media (--viewportLarge) {
    /* display: flex; */
    /* flex-direction: row; */
    /* align-items: center; */
    margin-right: 20px;
  }
}

.desktopText {
  display: none;

  @media (--viewportLarge) {
    white-space: pre-wrap;
    display: flex;
    flex: 1;
    font-size: 18px;
    line-height: 24px;
    color: var(--marketplaceColor);
  }

  @media (--viewportXLarge) {
    font-size: 20px;
    line-height: 24px;
  }
}

.field {
  margin-right: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  &:last-child {
    margin-right: 0;
  }

  @media (--viewportLarge) {
    flex: 1;
  }
}

.wrapper {
  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.locationValidation {
  bottom: -25px !important;

  @media (--viewportLarge) {
    bottom: -50px !important;
  }
}

.fieldLocation {
  @media (--viewportLarge) {
    flex: 1;
    margin-right: 15px;
    width: 100%;
  }
}

.fieldDates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;

  @media (--viewportLarge) {
    flex: 1;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.root :global(input.DateInput_input) {
  border-bottom: solid 2px var(--marketplaceColor) !important;
}

.locationSearch {
  @media (--viewportLarge) {
    max-width: unset !important;
  }
}

.row {
  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
  }
}

.errorMobile {
  @apply --marketplaceTinyFontStyles;
  color: var(--failColor);

  @media (--viewportLarge) {
    display: none;
  }
}

.errorDesktop {
  display: none;
  @apply --marketplaceTinyFontStyles;
  color: var(--failColor);

  @media (--viewportLarge) {
    display: block;
  }
}
