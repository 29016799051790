@import '../../marketplace';

.howItWordSection {
    width: 100%;
}

.innerWrapper {
  max-width: 100%;
  padding: 42px 20px 20px;
  margin: auto;
  text-align: left;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    text-align: left;
    margin: 0 7.5vw;
    padding: 50px 0px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1100px;
    margin: auto;
    flex-direction: row;
    justify-content: space-between;
  }
}

.title {
  /* @apply --marketplaceH1FontStyles;
    */
  font-size: 30px;
  margin-top: 0;
  @media (--viewportMedium){
    font-size: 48px;
  }
  font-weight: 900;
  color: var(--marketplaceColor);
  line-height: 36px;
}

.description {
  @apply --marketplaceDescFontStyles;
  margin-top: 21px;
  color: var(--marketplaceColorDark);
  max-width: 590px;
  margin-bottom: 50px;

  @media (--viewportMedium) {
    margin-bottom: 50px;
  }
}

.videoWrapper {
  padding: 0;
  margin-bottom: 50px;

  @media (--viewportMedium) {
    padding: 0px 20px;
  }

  @media (--viewportMLarge) {
    padding: 0 20px;
  }
}

.video {
  width: 100%;
  background-color: var(--matterColorAnti);
  cursor: pointer;
  margin: auto;

  @media (--viewportMedium) {
    width: 500px;
    /* height: 280px; */
  }

  @media (--viewportLarge) {
    width: 412px;
    /* height: 150px; */
  }
}

.videoLogo {
  width: 100%;
}

.contentModal {
  height: 100%;
}

.videoContentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    display: block;
  }
}

.iframeVideo {
  width: 100%;
  height: 40%;

  @media (--viewportMedium) {
    height: 100%;
  }
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 40px 0 0 0;

  @media (--viewportMedium) {
    padding: 0px;
    flex-basis: 576px;
    min-height: 0;
    min-width: 80vw;
    height: 48vw;
    margin-top: calc(50vh - 30vw);
    border-bottom: 0px solid transparent;
  }

  @media (--viewportLarge) {
    margin-top: calc(50vh - 25vw);
    min-width: 80vw;
    height: 48vw;
  }

  @media (--viewportMLarge) {
    margin-top: calc(50vh - 20vw);
    min-width: 66vw;
    height: 40vw;
  }
}

.modalCloseButton {
  top: 0px;
  right: 0px;
  padding: 10px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    color: var(--matterColorLight);    
    top: -40px;
  }

  &:hover {
    color: var(--matterColorAnti) !important;
  }
}