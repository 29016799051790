@import '../../marketplace.css';

.root {
  position: relative;
}

.donationForm {
  background: #0b3561;
  border-radius: 20px;
  padding: 50px 75px;
  margin-top: 50px;
  @media (--viewportMobile) {
    padding: 30px 20px;
  }
}
.formTitle {
  display: flex;
  flex-wrap: wrap;
}
.logoThumb {
  max-width: 58px;
  flex: 0 0 58px;
  @media (--viewportMobile) {
    max-width: 60px;
    flex: 0 0 100%;
    margin: 0 auto;
  }
}
.formTitle h4 {
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
  padding-left: 21px;
  flex: 1;
  @media (--viewportMobile) {
    flex: 0 0 100%;
    padding: 20px 0 0;
    font-size: 28px;
    text-align: center;
    line-height: normal;
  }
}

.customButtons {
  display: flex;
  margin-left: -22px;
  margin-right: -22px;
  padding-top: 42px;
  margin-bottom: 30px;
  @media (--viewportMobile) {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 20px;
    padding-top: 20px;
  }
}
.customRadio {
  padding-left: 22px;
  padding-right: 22px;
  flex: 0 0 33.333%;
  max-width: 33.333%;
  @media (--viewportMobile) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.customRadio input[type='radio'] {
  position: absolute;
  left: -1111111px;
  opacity: 0;
}
.customRadio input[type='radio'] + label {
  height: 70px;
  border: 1px solid #ffffff;
  color: #ffffff;
  display: block;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 700;
  cursor: pointer;
  @media (--viewportMobile) {
    height: 60px;
    font-size: 20px;
  }
}
.customRadio input[type='radio']:checked + label {
  border: 1px solid #f2745e;
  color: #f2745e;
}
.amountInput {
  background: #316a8f;
  display: flex;
  height: 70px;
  align-items: center;
  margin-bottom: 30px;
  @media (--viewportMobile) {
    height: 60px;
    margin-bottom: 20px;
  }
}
.amountInput label {
  padding: 10px 0 10px 22px;
  color: #ffffff;
  font-size: 25px;
  font-weight: 300;
  flex: 0 0 50%;
  max-width: 50%;
  @media (--viewportMobile) {
    font-size: 16px;
  }
}
.amountInput input {
  border: 0;
  padding: 0;
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
  @media (--viewportMobile) {
    font-size: 16px;
  }
}
.buttonWrapper{
  padding-top: 30px;
}
.donateSubmit {
  background: #f2745e;
  text-transform: capitalize;
  color: #ffffff;
  padding: 10px 30px;
  min-width: 150px;
  border-radius: 2px;
  min-height: 65px;
  font-size: 28px;
  line-height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  text-decoration: none;
  width: 100%;
  &:hover{
    text-decoration: none;
  }
  @media (--viewportMobile) {
    margin-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    bottom: 0;
  }
}
