@import '../../marketplace.css';

.root {
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  min-width: 100%;
  padding-bottom: 20px;

  @media (--viewportMedium) {
    max-width: calc(100vw - 48px);
    padding-left: 0;
    padding-right: 0;
  }

  @media (--viewportLarge) {
    width: calc(100vw - 72px);
    padding-left: 0;
    padding-right: 0;
  }
}

.alertBoxWrapper{
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background-color: #FFFFFF;
  position: relative;
  padding: 15px 18px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  max-width: 355px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
}
.alertBoxWrapper::after{
  content: "";
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 8px;
  height: 100%;
  position: absolute;
  left: -8px;
  top: 0;
  background: #000000;
  border-left: 0;
}
.alertBoxWrapper.alertInformation::after{
  background: #FECF2F;
}
.alertBoxWrapper.alertSuccess::after{
  background: #00A3AD;
}
.alertBoxWrapper.alertError::after{
  background: #D91320;
}
.alertBoxWrapper h3{
  font-size: 16px;
  line-height: 1.18;
  color: #1A1A1A;
  margin: 0 0 7px;
}
.alertBoxWrapper p{
  font-size: 14px;
  line-height: 1.28;
  color: #7C7C7C;
  margin: 0 0 7px;
}
.alertCloseButton{
  position: absolute;
  right: 10px;
  top: 15px;
  border: 0;
  outline: none;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.buttonOuter button {
  background: none;
  font-size: 16px;
  padding: 0;
  height: auto;
  min-height: 10px;
  text-align: left;
  box-shadow: none !important;
  color: #1A1A1A;
  text-decoration: underline;
  font-weight: normal;
  display: inline-flex;
  width: auto;
  text-transform: capitalize;
}
.buttonOuter button:hover, .buttonOuter button:focus {
  background: none;
  box-shadow: none !important;
  opacity: 0.7;
}