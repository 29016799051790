@import '../../marketplace.css';

.root {
  position: relative;
  display: flex;
  flex-direction: row;

}

.inputSuccess {
  border-bottom-color: var(--inputBorderBottomColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--matterColorAnti);
  -webkit-transition: .3;
  transition: .3s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: var(--matterColorLight);
  -webkit-transition: .3s;
  transition: .3s;
}

input:checked + .slider {
  background-color: var(--marketplaceColorDark);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--marketplaceColorDark);
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggleMapButton {
  bottom: 0;
  right: 60px;
  font-size: 14px;
  color: var(--matterColor);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.toggleMapButton span {
  display: block;
  padding-right: 10px;
}

.label {
  padding: 0;
  margin: 0;
  margin-right: 12px;
}