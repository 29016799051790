.verificationDescription {
    font-size: 16px;
    width: 100%;
    flex: 2 1 100%;
    padding-bottom: 20px;
}

.verifyBox {
    flex: 1;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    margin: 10px;
    position: relative;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    min-height: 250px;
}


.verifyBox .verifyBoxTop {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e6e6e6;
    flex: 65;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
    @media (--viewportMobile) {
        justify-content: center;
        padding-bottom:8px;
    }
}

.verifyBox .recomendedBanner {
    position: absolute;
    top: 0;
    left: 10px;
    line-height: 0%;
}

.verifyBox .recomendedBanner span {
    font-size: 11px;
    background-color: #FFCD05;
    padding: 3px 5px;
    font-weight: 700;
    color: #000;
    border-radius: 2px;
}

.verifyBox .verifyBoxTop .verifyMethodDetails {
    padding-bottom: 20px;
}

.verifyBox .verifyBoxTop .verifyMethodAction .verifySingpasButton img {
    width: 100%;
    height: auto;
}

.verifyBox .verifyBoxTop .verifyMethodAction .verifyManualButton {
    width: 100%;
    height: 46px;
    border-radius: 4px;
    background: #FFCD05 0% 0% no-repeat padding-box;
    color: #026786;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    border: none;
    @media (--viewportMobile) {
        width: 100%;
        height:auto;
        font-size: 12px;
    }
}

.verifyBox .verifyBoxTop .verifyMethodAction .verifyManualButton:hover {
    background: #f6dc76 0% 0% no-repeat padding-box;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.verifyBox .verifyBoxBottom {
    flex: 35;
    padding-left: 15px;
    padding-right: 15px;
}

.verifyBox .verifyBoxBottom ul {
    margin: 0;
}

.verifyBox .verifyBoxBottom ul li {
    font-size: 14px;
}

.verifyBox .verifyBoxBottom ul li:before {
    content: "- ";
    text-indent: -5px;
}

.statusSection {
    background-color: #fff;
    display: flex;
    padding: 16px 20px;
}


.statusSection {
    background-color: #fff;
    display: flex;
    padding: 16px 20px;
}

.verificationText {
    flex: 1;
    font-size: 20px;
    color: #026786;
    font-weight: 700;
}

.verificationStatus {
    font-size: 16px;
    color: #026786;
}

.verificationStatusBadge {
    background-color: #e6e6e6;
    padding: 8px;
    border-radius: 4px;
    font-weight: 600;
}

.actionSection {
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: #4a4a4a;
    @media (--viewportMobile) {
        flex-direction: column;
    }
}

.stepperSection {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
}

.stepperSection .step {
    height: 6px;
    background: #E6E6E6 0% 0% no-repeat padding-box;
    width: 100%;
    margin-right: 5px;
}
.stepperSection .step:last-child {
    margin-right: 0px;
}

.stepperSection .step.complete {
    background: #FFCD05 0% 0% no-repeat padding-box;
}

.stepperSection .step.current {
    background: #00A3AD 0% 0% no-repeat padding-box;
}

