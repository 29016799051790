@import '../../marketplace.css';

.whyDriveLahSection {
  width: 100%;
  background-color: var(--matterColorLight);
}

.textBox {
  max-width: 100%;
  padding: 32px 20px 0;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (--viewportMedium) {
    margin: 0 7.5vw;
    padding: 50px 0px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1100px;
    margin: auto;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);
  font-weight: 900;
  flex-basis: 100%;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.subBoxs {
  /* flex-basis: 90%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.subBoxs1 {
  display: flex;
  flex-direction: column;
  @media (--viewportMedium){
    flex-basis: 45%;
  }
}

.subBoxs2 {
  display: flex;
  flex-direction: column;
  @media (--viewportMedium){
    flex-basis: 45%;
  }
}

.subBoxs1 .subBox:last-child {
  @media (--viewportMedium){
    border-bottom: 0px solid transparent;
  }
}

.subBoxs2 .subBox:last-child {
  @media (--viewportMedium){
    border-bottom: 0px solid transparent;
  }
}

.subBox {
  margin-bottom: 25px;
  border-bottom: 1px solid var(--matterColorAnti);
  text-align: left;
  
  &:last-child{
    border-bottom-width: 0px;
    margin-bottom: 0;
  }
}

.subBoxTitle {
  font-size: 22px;
  color: var(--marketplaceColor);
  font-weight: 900;
  /* margin: 14px 0px; */
  line-height: normal;
  text-align: left;
  letter-spacing: -1.1px;

  @media(--viewportMedium) {
    margin-bottom: 14px;
    font-size: 30px;
  }
}

.subBoxDesc {
  font-size: 20px;
  color: var(--marketplaceColor);
  font-weight: 100;
  margin-bottom: 26px;

  &:last-child{
    margin-bottom: 36px;
  }
}


.insuranceLogo {
  width: 165px;
  height: 51px;
  margin-block: 25px;
}
.btnPolicy {
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);
  border: none;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  padding: 14px 0;
  cursor: pointer;
  display: block;
  text-align: center;

  &:hover {
    background-color: var(--marketplaceColorLight);
  }
  @media (--viewportMedium){
    width: auto;
    padding: 14px 16px;
    margin-left: 28px;
    display: inline-block;
  }
  border-radius: 5px;
  margin-top: 25px;
  margin-bottom: 35px;
}

.second_half{
  grid-column-start: 2;
}

.first_half {
  grid-column-start: 1}

.newlineMobile {
  @media (--viewportMedium) {
    display: none;
  }
}