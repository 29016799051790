@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 6px;
    padding-bottom: 2px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.lineItemError {
  @apply --marketplaceH4FontStyles;

  /* Since the line items have different font sizes in the label and the
   value columns, we have to balance the line to align to the
   baseline. */
  margin: -1px 0 0 0;
  color: var(--failColor);

  @media (--viewportMedium) {
    margin: -1px 0 8px 0;

    &:last-of-type {
      /* Last item should not have bottom margin that goes to the bottom of
         the component */
      margin-bottom: 0;
    }
  }
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  @apply --marketplaceButtonFontStyles;
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-bottom: 2px;
  }
}
.itemLabel {
  @apply --marketplaceSmallFontStyles;
  flex: 1;
}

.bookingPeriodText {
  @apply --marketplaceSmallFontStyles;
  flex: 1;
  font-size: 20px;
  padding: 20px 0;
}

.bookingUpdatedPeriodText {
  @apply --marketplaceSmallFontStyles;
  font-size: 16px;
  padding: 20px 0;
  /*flex: 0 0 100%;*/
  max-width: 100%;
  margin: 0;
}

.creditsLineItem {
  color: var(--marketplaceColorLight);
  @apply --marketplaceH4FontStyles;

  /* Since the line items have different font sizes in the label and the
   value columns, we have to balance the line to align to the
   baseline. */
  margin: -1px 0 0 0;

  @media (--viewportMedium) {
    margin: -1px 0 8px 0;

    &:last-of-type {
      /* Last item should not have bottom margin that goes to the bottom of
         the component */
      margin-bottom: 0;
    }
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.premiumLineItem {
  color: var(--marketplaceColorDark);
  padding-top: 6px;
  padding-bottom: 2px;
}

.premiumLineItemLabel {
  display: inline-flex;
}

.premiumLineItemLabel div:last-child {
  margin-top: 2px;
  margin-left: 4px;
}

.itemValue {
  @apply --marketplaceH4FontStyles;
  font-weight: 500;
  margin: 0 0 0 10px;

  @media (--viewportMedium) {
    font-weight: 700;
    margin: 0 0 0 10px;
    font-size: 20px;
  }
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.totalLabel {
  font-weight: var(--fontWeightNormal);
  color: #026786;
}

.totalPrice {
  @apply --marketplaceButtonFontStyles;
  margin: 0 0 0 10px;
  padding-top: 4px;
  color: #026786;
  @media (--viewportMedium) {
    margin: 2px 0 0 10px;
    font-size: 20px;
  }
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

.tobePaid {
  padding-top: 20px;
}

.explaination {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
  color: var(--matterColorAnti);
  cursor: pointer;

  &::before {
    content: '?';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    border: 1px solid var(--matterColorAnti);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.explaination:hover .explainationText {
  display: block;
}

.explainationText {
  display: none;
  position: absolute;
  color: var(--matterColorAnti);
  border: 1px solid var(--matterColorNegative);
  border-radius: 5px;
  background-color: var(--matterColorLight);
  font-weight: normal;
  line-height: 16px;
  padding: 15px 25px;
  left: -112px;
  bottom: 0px;
  margin-bottom: 25px;
  box-shadow: 3px 3px 10px var(--matterColorNegative);
  width: 150px;
  @media (--viewportMedium) {
    width: 200px;
  }
  @media (--viewportLarge){
    width: 275px;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: var(--matterColorLight) transparent transparent transparent;
  }
}
