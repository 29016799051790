@import '../../marketplace.css';

.root {
  /* Adding empty .root class to enforce className overwrite */
}

.container {
  display: flex;
  flex-direction: column;
}

.container img {
  display: block;
  width: 100%;
  max-width: 100%;
}

.text {
  color: var(--marketplaceColorDark);
}