@import '../../marketplace.css';

.root {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #E6E6E6;
  border-radius: 8px;
  background: #FFFFFF;
  overflow: hidden;
  @media (--viewportMobile) {
    border-radius: 0;
  }
}

.insuranceSection {
  padding: 20px;
  border-right: 1px solid #E6E6E6;
  flex: 0 0 50%;
  max-width: 50%;
  @media (--viewportMobile) {
    border-bottom: 1px solid #E6E6E6;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.insuranceSection:nth-child(2) {
  border-right: 0;
  @media (--viewportMobile) {
    border-bottom: 0;
  }
}

.insuranceBottom {
  text-align: left;
  margin-top: 25px;

  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
    padding: 0px;
    text-align: left;
  }
}

.insuranceTitle {
  font-size: 20px;
  font-weight: bold;
  color: var(--marketplaceColor);
  margin-bottom: 10px;
}

.insuranceBottomLine {
  font-size: 16px;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.insuranceBottomImg {
  width: 150px;
  margin-top: 25px;
}

.insuranceBottomImgText {
  font-size: 14px;
  display: block;
  color: var(--matterColor);
  text-decoration: none;
}

.insuranceBottomImgText > span > span {
  color: var(--marketplaceColor);
  cursor: pointer;
}

.noInsuranceBottom {
  padding: 22px;
  background-color: var(--marketplaceSecondaryColor);
  color: var(--marketplaceColorDark);
  min-height: 300px;
}

.noInsuranceBottomTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.noInsuranceBottomDesc {
  display: block;
  font-size: 20px;
  margin-top: 20px;
}

.openHostInsurance {
  color: var(--marketplaceColor);
  font-size: 18px;
  cursor: pointer;
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 525px;
    min-height: auto;
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @media (--viewportLarge) {
    flex-basis: 525px;
    min-height: auto;
    height: auto;
    padding: 40px 40px 50px 40px;
  }

  @media (--viewportMLarge) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.desktop {
  display: none;

  @media (--viewportMLarge) {
    display: block;
  }
}

.mobile {
  display: block;

  @media (--viewportMLarge) {
    display: none;
  }
}

.insuranceSection {
}
.insuranceSection h5 {
  font-size: 22px;
  line-height: normal;
  font-weight: 700;
  color: #026786;
  margin: 0 0 15px;
  @media (--viewportMobile) {
    font-size: 18px;
    margin: 0 0 10px;
  }
}
.insuranceSection p {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  color: #4a4a4a;
  margin-bottom: 15px;
  @media (--viewportMobile) {
    font-size: 16px;
    margin: 0 0 10px;
  }
}

.insuranceBottomImgText {
  margin: 15px 0 0;
  color: #00a3ad;
  font-size: 20px;
  line-height: 1.2;
  display: inline-flex;
  align-items: center;
  @media (--viewportMobile) {
    font-size: 16px;
    margin: 0;
  }
}
.insuranceBottomImgText img {
  transform: rotate(-90deg);
  margin-left: 7px;
}
.insurancePointsList {
  padding-left: 20px;
}
.insurancePointsList li {
  font-size: 18px;
  line-height: normal;
  list-style: disc;
  margin-bottom: 10px;
  list-style-position: outside;
}
.insuranceListingWrapper h3 {
  font-size: 30px;
  color: #00a3ad;
  line-height: 1.2;
}
