@import '../../marketplace.css';

.root {
  position: relative;
}

.label {
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
}

.icon {
  display: none;
}

.inputClassName {
  border: 0;
  font-size: 18px;
  height: 19px;
}
.inputClassNamedasd {
  position: absolute;
  width: 100%;
  padding-bottom: 30px;
  top: calc(100% + 8px);
  left: 0;
  background-color: #00a3ad;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 30%);
  z-index: calc(50 + 1);
  margin: 0 -20px;
  width: auto;
  right: 0;
}
.dateInput {
  margin-top: 10px;
  width: 100%;

  @media (--viewportLarge) {
    margin-top: 0;
  }
}

.pickUp {
  z-index: 10;
}

.dropOff {
  z-index: 5;
}

.dropOff :global(.SingleDatePicker_picker__directionLeft) {
  left: -163px !important;
}

.row {
  display: flex;
  flex-direction: row;
  margin-left: -5px;
  margin-right: -5px;
  flex-wrap: wrap;
}

.column4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.buttonWrapper .findYourCar {
  background-color: #fecd2a;
  color: #0d6786;
  text-transform: none !important;
  font-size: 18px !important;
  line-height: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  position: relative;
  &:hover,
  &:focus {
    background-color: #fecd2a !important;
    opacity: 0.85;
  }
  &:disabled {
    background-color: var(--matterColorNegative) !important;
    color: var(--matterColorLight) !important;
  }

  height: 48px;
  min-height: 48px;
  margin-top: 20px !important;
  border-radius: 5px !important;
}

.help {
  font-size: 16px;
  line-height: 24px;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);
  margin-top: 15px;

  @media (--viewportLarge) {
    margin-top: 0;
    white-space: pre-wrap;
    text-align: right;
    font-size: 18px;
    line-height: 24px;
    flex: 1;
  }

  @media (--viewportXLarge) {
    font-size: 20px;
    line-height: 24px;
  }
}

.buttonWrapper {
  display: block;
}

.desktopText {
  display: none;

  @media (--viewportLarge) {
    white-space: pre-wrap;
    display: flex;
    flex: 1;
    font-size: 18px;
    line-height: 24px;
    color: var(--marketplaceColor);
  }

  @media (--viewportXLarge) {
    font-size: 20px;
    line-height: 24px;
  }
}

.field {
  padding: 0 5px;
}

.wrapper {
  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.locationValidation {
  bottom: -25px !important;

  @media (--viewportLarge) {
    bottom: -50px !important;
  }
}

.fieldLocation {
  @media (--viewportLarge) {
    flex: 1;
    width: 100%;
  }
}

.fieldDates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;

  @media (--viewportLarge) {
    flex: 1;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

/* .root :global(input.DateInput_input) {
  border-bottom: solid 2px var(--marketplaceColor) !important;
} */
.root :global(input.DateInput_input) {
  border-bottom: 0 !important;
  padding: 0 !important;
  font-size: 16px;
  line-height: normal;
  color: #026786;
  &::placeholder {
    color: #026786;
  }
}
.root :global(select) {
  border-bottom: 0 !important;
  padding: 0 !important;
  font-size: 16px;
  line-height: normal;
  background: none !important;
  color: #026786;
  &::placeholder {
    color: #026786;
  }
}

.locationSearch {
  @media (--viewportLarge) {
    max-width: unset !important;
  }
}

.errorMobile {
  @apply --marketplaceTinyFontStyles;
  color: var(--failColor);

  @media (--viewportLarge) {
    display: none;
  }
}

.errorDesktop {
  display: none;
  @apply --marketplaceTinyFontStyles;
  color: var(--failColor);

  @media (--viewportLarge) {
    display: block;
  }
}

.formInput {
  
}
.formInput svg {
  fill: #4a4a4a;
}
.formInput label {
  font-weight: 300;
  color: #4A4A4A;
  display: block;
  margin-bottom: 5px;
}
.formInput input {
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  padding: 8px 15px;
  height: 54px;
  margin: 0 0 10px;
  font-size: 20px;
  color: #4A4A4A;
  position: relative;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    padding: 8px 10px;
  }
  &::placeholder {
    color: #4A4A4A;
    opacity: 1;
  }
}

.contactField {
  border: 0;
  font-size: 18px;
}

.inputError {
  border-color: red;
}

.formError {
  padding: 10px 0 0;
  font-size: 14px;
  line-height: normal;
  color: red;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.locationLoader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.locationLoader span {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #00a3ad;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 0.75s linear infinite; /* Safari */
  animation: spin 0.75s linear infinite;
}

.fixedBottomBtn {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: #fff;
  -webkit-box-shadow: 0px -3px 10px 0px rgba(230, 230, 230, 1);
  -moz-box-shadow: 0px -3px 10px 0px rgba(230, 230, 230, 1);
  box-shadow: 0px -3px 10px 0px rgba(230, 230, 230, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  @media (--viewportLarge) {
    display: none;
  }

  @media (--viewportMobile) {
    bottom: 0;
    padding: 14px 20px;
  }
}

.buttonWrapper .fixedBottomBtn {
  display: block;
  position: relative;
  box-shadow: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  @media (--viewportMobile) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background: #fff;
    -webkit-box-shadow: 0px -3px 10px 0px rgba(230, 230, 230, 1);
    -moz-box-shadow: 0px -3px 10px 0px rgba(230, 230, 230, 1);
    box-shadow: 0px -3px 10px 0px rgba(230, 230, 230, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1001;
  }
}
.buttonWrapper .fixedBottomBtn button {
  max-width: 200px;
}

.fixedBottomBtn .button {
  background: #00a3ad;
  border: 0;
  color: #fff;
  font-size: 20px;
  padding: 14px;
  border-radius: 5px;
  min-width: 167px;
  min-height: 52px;
  height: 52px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


.fixedBottomBtn .monthWisePrice {
  display: flex;
  flex-direction: column;
}

.fixedBottomBtn .monthWisePrice .monthlyPrice {
  color: #0d6786;
  font-size: 20px;
  @media (--viewportMobile) {
    font-weight: 700;
  }
}

.fixedBottomBtn .monthWisePrice .monthlyPrice span {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 500;
}

.fixedBottomBtn .monthWisePrice .link {
  text-decoration: underline;
  font-size: 14px;
  color: #00a3ad;
}
.numberInput {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.numberInput > label {
  flex: 0 0 100%;
}
.numberInput .code{
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  padding: 8px 15px;
  height: 54px;
  margin: 0 0 10px;
  font-size: 20px;
  color: #4A4A4A;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 65px;
}

.numberInput .numberInput{
  margin-left: 15px;
  flex: 1;
}
.numberInput .numberInput label{
  margin: 0;
  padding: 0;
}

.disabled {
  background: rgba(0,0,0,0.2) !important;
  pointer-events: none;
  cursor: not-allowed;
}