@import '../../marketplace.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  background: #fcfcfc;
  z-index: 2;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    padding: 36px 36px 0 36px;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 53.25%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
  overflow: hidden;
  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.5 * 100vw);
    max-height: 65vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

/* .bottomActions {
  position: absolute;
  display: flex;
  flex-wrap: wrap; */

/* Position and dimensions */
/* position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;

  @media (--viewportMedium) {
  }
} */

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  margin: 0;
  margin-left: 10px;
  padding: 6px 13px 8px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
    margin-left: 10px;
  }
}

.shareButton {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  margin: 0;
  padding: 6px 13px 8px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (min-width: 1500px) {
    bottom: 19px;
    margin: 0;
    left: 24px;
    right: unset;
  }
}

.logo {
  width: 15px;
  height: 12px;
  margin-right: 10px;
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  width: 100%;
  max-width: 1230px;
  padding: 30px 15px 0;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  @media (--viewportMobile) {
    padding: 0;
    background: #ffffff;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
  }
}

.mainContent {
  flex-basis: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  background: #ffffff;
  margin-bottom: 20px;
  @media (--viewportMobile) {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #e6e6e6;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 95px;
    margin-left: 60px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);

    padding-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  /* margin-top: 22px;
  margin-bottom: 34px; */

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 53px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.instantBooking {
  width: 158px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: var(--instantBookingLabelColor);
  color: var(--matterColorLight);
  margin-bottom: 0;
  font-weight: 900;
  border-radius: 2px;
}

.rentalAgreementText {
  font-size: 14px;
  margin-left: 20px;
}

.rentalAgreementText:hover {
  text-decoration: underline;
  cursor: pointer;
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 0;
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  /* @apply --marketplaceH1FontStyles; */
  color: #026786;
  font-size: 22px;
  line-height: normal;
  font-weight: 700;
  letter-spacing: normal;
  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--marketplaceColorDark);
  font-size: 14px;
  color: #4a4a4a;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorNameLink {
  color: #00a3ad;
  text-decoration: underline;
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0;
}

.sectionDescription,
.sectionFeatures {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 0;
  /* @media (--viewportMobile) {
    padding: 20px;
    border-bottom: 1px solid #e6e6e6;
  } */
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);
  font-size: 22px;
  color: #026786;
  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
  @media (--viewportmobile) {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.sectionFeatures {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 0;
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);
  font-size: 22px;
  color: #026786;
  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.distanceCarToYourLocation {
  color: var(--marketplaceColorDark);
  font-weight: normal;

  @media (--viewportMedium) {
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 20px;
}

.sectionReviews .reviewRatings {
  margin-left: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #141414;
}
.sectionReviews img {
  margin-right: 3px;
}

.readAllReviews {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
.readAllReviewsButton {
  display: inline-flex;
  width: 176px;
  height: 48px;
  padding: 7px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: normal;
  font-weight: 700;
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 15px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColorDark);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.pendingNewline {
  margin-top: 15px;
}

.averageContainer {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
}

.star {
  fill: var(--marketplaceColor);
  width: 14px;
  vertical-align: top;
  margin-right: 5px;
}

.doneTrips {
  @apply --marketplaceH4FontStyles;
  color: var(--marketplaceColorDark);
  margin-left: 5px;
}

.errorWrapper {
  justify-content: center;
  align-items: center;
}

:root {
  --card-padding: 25px;
  --card-height: 500px;
  --card-skeleton: linear-gradient(var(--matterColorBright) var(--card-height), transparent 0);

  --image-height: 250px;
  --image-width: 100%;
  --image-size: var(--image-width) var(--image-height);
  --image-position: 0 0;
  --image-skeleton: linear-gradient(var(--matterColorNegative) var(--image-height), transparent 0);

  --avatar-size: 60px;
  --avatar-position: var(--card-padding) 0;
  --avatar-skeleton: radial-gradient(circle 30px, var(--matterColorNear) 99%, transparent 0);

  --title-height: 32px;
  --title-width: 250px;
  --title-size: var(--title-width) var(--title-height);
  --title-position: var(--card-padding) 300px;
  --title-skeleton: linear-gradient(var(--matterColorAnti) var(--title-height), transparent 0);

  --desc-line-height: 16px;
  --desc-line-skeleton: linear-gradient(
    var(--matterColorAnti) var(--desc-line-height),
    transparent 0
  );

  --desc-line-1-width: 300px;
  --desc-line-1-position: var(--card-padding) 350px;
  --desc-line-2-width: 280px;
  --desc-line-2-position: var(--card-padding) 385px;

  --blur-width: 300px;
  --blur-size: var(--blur-width) calc(var(--card-height));
}

.listingSketch {
  background-repeat: no-repeat;
  height: var(--card-height);
  background-image: var(--desc-line-skeleton), var(--desc-line-skeleton), var(--title-skeleton),
    var(--avatar-skeleton), var(--image-skeleton), var(--card-skeleton);
  background-size: var(--desc-line-2-width) var(--desc-line-height),
    var(--desc-line-1-width) var(--desc-line-height), var(--title-size), var(--avatar-size),
    var(--image-size), 100% 100%;
  background-position: var(--desc-line-2-position), var(--desc-line-1-position),
    var(--title-position), var(--avatar-position), var(--image-position), 0 0;
}
.listingSketch::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0 10px 45px rgba(0, 0, 0, 0.1);

  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--desc-line-skeleton), var(--desc-line-skeleton), var(--title-skeleton),
    var(--avatar-skeleton), var(--image-skeleton), var(--card-skeleton);

  background-size: var(--blur-size), var(--desc-line-2-width) var(--desc-line-height),
    var(--desc-line-1-width) var(--desc-line-height), var(--title-size), var(--avatar-size),
    var(--image-size), 100% 100%;

  background-position: -150% 0, var(--desc-line-2-position), var(--desc-line-1-position),
    var(--title-position), var(--avatar-position), var(--image-position), 0 0;

  background-repeat: no-repeat;
  animation: loading 3s infinite;
}

@keyframes loading {
  to {
    background-position: 350% 0, var(--desc-line-2-position), var(--desc-line-1-position),
      var(--title-position), var(--avatar-position), var(--image-position), 0 0;
  }
}

.mobileWrapper {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.labelGroup {
  display: none;
  align-items: center;
  margin-bottom: 15px;
}

.wrapperLabelDisinfected {
  position: relative;
  display: inline-block;
  left: 15px;
  font-size: 16px;

  & .numberDays {
    background-color: var(--marketplaceSecondaryColor);
    color: var(--marketplaceColorDark);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    z-index: 3;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -3px;

    & .number {
      font-weight: 900;
      line-height: 18px;
    }
    & .day {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 10px;
      line-height: 12px;
    }
  }
  & .textLabel {
    color: var(--marketplaceSecondaryColor);
    display: inline-block;
    font-weight: 900;
    text-transform: uppercase;
    background-color: #00a3ad;
    border-radius: 5px;
    padding: 8px 17px;
    z-index: 2;
    padding-left: 30px;
    margin-left: 15px;
  }
}

.floatingButton {
  position: absolute;
  top: 14px;
  width: 32px;
  height: 32px;
  background: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
}
.backButton {
  left: 20px;
}
.likeButton {
  right: 20px;
}
.includedInSubscription {
  border-bottom: 1px solid #e6e6e6;
  padding: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  background: #ffffff;
  overflow: hidden;
  margin-bottom: 20px;
  @media (--viewportMobile) {
    border-radius: 0;
  }
}

.includedInSubscription h2 {
  font-size: 22px;
  color: #026786;
  margin-top: 0;
  margin-bottom: 13px;
}

.includedInSubscription ul {
  margin: 0;
}
.includedInSubscription ul li {
  font-size: 20px;
  color: #4a4a4a;
  padding-left: 24px;
  position: relative;
  margin-bottom: 15px;
  @media (--viewportMobile) {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.includedInSubscription ul li::before {
  content: '';
  width: 19px;
  height: 19px;
  position: absolute;
  border-radius: 50%;
  background: #00a3ad;
  border: 1px solid #00a3ad;
  left: 0;
  top: 3px;
}

.includedInSubscription ul li::after {
  content: '';
  background: url(https://dv0eqz2t0y9gj.cloudfront.net/drivelah/check-solid.svg);
  background-position: center;
  background-size: 11px;
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
  position: absolute;
  border-radius: 50%;
  left: 0;
  top: 3px;
}

.hostedBy {
  border-bottom: 1px solid #e6e6e6;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  background: #ffffff;
  overflow: hidden;
  margin-bottom: 22px;
  @media (--viewportMobile) {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #e6e6e6;
    border-top: 1px solid #e6e6e6;
  }
}

.hostedBy h2 {
  font-size: 22px;
  color: #026786;
  margin-top: 0;
  display: flex;
  align-items: center;
}
.hostedBySection {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
  @media (--viewportMobile) {
    display: none;
  }
}

.aboutSection {
  padding: 20px;
}
.aboutSection h2 {
  font-size: 22px;
  color: #026786;
  margin-top: 0;
  margin-bottom: 13px;
}
.aboutSection p {
  font-size: 20px;
  line-height: 1.2;
  color: #4a4a4a;
  margin-bottom: 20px;
}

/* LongTermListingPageSideBar */

.rentalCardMobile {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.rentalCard {
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background: #ffffff;
}

.rentalCard .head {
  color: #026786;
  font-size: 22px;
  margin: 20px 20px 15px 20px;

  @media (--viewportMobile) {
    margin: 15px 20px;
  }
}

.rentalCard .border {
  border: 1px solid #e6e6e6;
}

.rentalCard .selection,
.rentalCard .priceBreakdown {
  margin: 15px 20px;
}

.rentalCard .selection h3,
.rentalCard .priceBreakdown h3 {
  color: #026786;
  font-size: 20px;
  margin: 0 0 10px;
  @media (--viewportMobile) {
    font-size: 16px;
  }
}

.rentalCard .selection .sliderCss {
  color: #eff4f6;
  height: 6px;
  margin-bottom: 33px;
}
.sliderfill {
  max-width: 92%;
}
.sliderfill :global(.rangeslider-horizontal) {
  box-shadow: none;
}

.sliderfill :global(.rangeslider-horizontal .rangeslider__fill) {
  box-shadow: none;
  background-color: #00a3ad;
}

.sliderfill :global(.rangeslider-horizontal .rangeslider__labels) {
  margin: 0 0 0;
}

.sliderfill :global(.rangeslider-horizontal .rangeslider__handle) {
  border: 2px solid #00a3ad;
  background: #fff;
  box-shadow: none;
  height: 24px;
  width: 24px;
  border-radius: 100%;
}

.sliderfill :global(.rangeslider-horizontal .rangeslider__handle::after) {
  background: initial;
  box-shadow: none;
}

.sliderfill :global(.rangeslider__handle-tooltip) {
  width: 52px;
  height: auto;
  text-align: center;
  position: absolute;
  background-color: #00a3ad;
  font-weight: normal;
  font-size: 12px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: white;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  top: auto;
  bottom: -35px;
}

.sliderfill :global(.rangeslider__handle-tooltip::after) {
  border-top: none;
  border-bottom: 8px solid #00a3ad;
  bottom: 26px;
}

.sliderfill :global(.rangeslider__handle-tooltip span) {
  margin-top: 0;
  padding: 7px 3px;
}
.sliderfill :global(.rangeslider__label-item) {
  color: #00a3ad;
}

.rentalCard .selection .customButtons {
  @media (--viewportMobile) {
    display: flex;
    overflow-x: hidden;
    flex-wrap: wrap;
  }
}

.rentalCard .selection .customRadio {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  @media (--viewportMobile) {
    position: relative;
  }
}

.rentalCard .selection .customRadio {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 5px;
  position: relative;
  @media (--viewportMobile) {
    margin-right: 0;
    margin-bottom: 10px;
    min-width: 100%;
  }
}

.rentalCard .selection .customRadio .flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  @media (--viewportMobile) {
    margin-bottom: 7px;
  }
}

.rentalCard .selection .customRadio .flexRow > div {
  display: flex;
  align-items: center;
  color: #4a4a4a;
  font-size: 18px;
  flex: 0 0 65%;
  max-width: 65%;
  @media (--viewportMobile) {
    font-size: 14px;
    align-items: baseline;
  }
}

.rentalCard .selection .customRadio .flexRow div input {
  width: auto;
  margin-right: 6px;
}

.rentalCard .selection .customRadio .flexRow .displayInDesktop {
  color: #0d6786;
  font-size: 18px;
  line-height: normal;
  @media (--viewportMobile) {
    display: none;
  }
}

.rentalCard .selection .customRadio span.protectionDescription {
  @media (--viewportMobile) {
    font-size: 14px;
    color: #7c7c7c;
    display: block;
    margin: 0 0 10px;
  }
}

.rentalCard .selection .customRadio .displayInMobile {
  @media (--viewportMedium) {
    display: none;
  }

  @media (--viewportMobile) {
    display: block;
    font-size: 14px;
    font-weight: 700;
    color: #4a4a4a;
  }
}

.rentalCard .selection .customRadio span {
  color: #7c7c7c;
  font-size: 16px;
  line-height: normal;
  margin: 0 5px 0 0;
}

.rentalCard .selection .customRadio .flexRow {
  @media (--viewportMobile) {
    font-size: 14px;
  }
}
.rentalCard .selection .customRadio input[type='radio'] {
  left: 0;
  top: 0;
  opacity: 0;
  position: absolute;
  margin: 0 !important;
  width: 100% !important;
  height: 100%;
  display: block;
  cursor: pointer;
  z-index: 5;
  @media (--viewportMobile) {
  }
}
.rentalCard .selection .customRadio input[type='radio'] + label {
  font-size: 14px;
  padding-left: 25px;
  position: relative;
  padding-top: 0;
}
.rentalCard .selection .customRadio input[type='radio'] + label:before {
  content: '';
  width: 19px;
  height: 19px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 2px;
  border: 1px solid #00a3ad;
  display: block;
  @media (--viewportMobile) {
  }
}
.rentalCard .selection .customRadio input[type='radio']:checked + label:before {
  content: '';
  background: #00a3ad;
  box-shadow: inset 0 0 0 3px #ffffff;
  @media (--viewportMobile) {
  }
}

.rentalCard .selection .customRadio input[type='radio']:checked {
  left: 0;
  top: 0;
  opacity: 1;
  position: absolute;
  margin: 0 !important;
  width: 100% !important;
  height: 100%;
  display: block;
  cursor: pointer;
  z-index: 5;
  appearance: none;
  background: #00A3AD0D;
  border: 0;
  outline: none !important;
}

.rentalCard .priceBreakdown .prices div,
.rentalCard .priceBreakdown .monthWisePrice {
  display: flex;
  justify-content: space-between;
}

.rentalCard .priceBreakdown .prices div:last-child {
  margin-bottom: 10px;
}

.rentalCard .priceBreakdown .prices div .priceTitle {
  color: #4a4a4a;
  font-size: 18px;
  @media (--viewportMobile) {
    font-size: 16px;
  }
}

.rentalCard .priceBreakdown .monthWisePrice {
  margin-top: 10px;
}

.rentalCard .priceBreakdown .monthWisePrice .monthlyPrice {
  font-size: 18px;
  color: #026786;
  @media (--viewportMobile) {
    font-size: 16px;
  }
}

.rentalCard .priceBreakdown .monthWisePrice .price {
  color: #026786;
  font-size: 20px;
}

.rentalCard .rentalButton {
  padding: 0 20px 20px;
}

.rentalCard .rentalButton button {
  background: #00a3ad;
  border-radius: 5px;
  font-size: 20px;
  color: #fff;
  text-transform: inherit;
  width: 100%;
  border: 0;
  padding: 14px;
}

/* LongTermRentalListingPageImageBanner */

.sectionImages {
  display: flex;
  flex-direction: column;

  @media (--viewportMobile) {
    flex-direction: column-reverse;
  }
}

.sectionCarHeading {
  width: 100%;
  margin: 0 auto 30px;
  max-width: 1230px;
  padding: 0 15px;
  @media (--viewportMobile) {
    padding: 20px;
    background: #ffffff;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 0;
  }
}

.sectionCarHeading .flexItem {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 40px;
  @media (--viewportMobile) {
    padding: 0;
  }
}

.sectionCarHeading .flexItem h2 {
  color: #026786;
  font-size: 36px;
  margin: 0 0 10px;
  text-transform: capitalize;
  font-weight: 900;

  @media (--viewportMobile) {
    margin: 0;
    font-size: 22px;
  }
}

.sectionCarHeading .sectionSubheading span {
  display: flex;
  align-items: center;
  font-size: 20px;

  @media (--viewportMobile) {
    font-size: 14px;
  }
}

.sectionCarHeading .sectionSubheading span .starIcon {
  margin-right: 5px;
}

.sectionCarHeading .sectionSubheading .rating {
  color: #00a3ad;
  margin-right: 5px;

  @media (--viewportMobile) {
    text-decoration: underline;
  }
}

.sectionCarHeading .sectionSubheading .circle {
  height: 8px;
  width: 8px;
  background: #4a4a4a;
  border-radius: 100%;
  margin: 0 7px;
}

.sectionCarHeading .sectionSubheading .link {
  margin-left: 7px;
  text-decoration: underline;
}

.flexImageContainer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
  align-items: center;
  max-width: 1230px;
  padding: 0 15px;
  @media (--viewportMobile) {
    padding: 0;
  }
}

.flexImageContainer .leftSideImage {
  width: 100%;
  @media (--viewportLarge) {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
  }
}

.flexImageContainer .leftSideImage img,
.flexImageContainer .rightSideImage img {
  width: 100%;
}

.flexImageContainer .leftSideImage div {
  width: 100%;
  border-radius: 0px;
  overflow: hidden;
  @media (--viewportLarge) {
    border-radius: 7px;
  }
}

.flexImageContainer .rightSideImage {
  display: none;
  @media (--viewportLarge) {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
  }
}

.flexImageContainer .rightSideImage div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.flexImageContainer .rightSideImage .thumbnail {
  @media (--viewportLarge) {
    width: 47%;
    border-radius: 7px;
    overflow: hidden;
    margin: 7px;
    background: #c7c7c7;
    height: 170px;
  }
}

.flexImageContainer .rightSideImage .thumbnail:nth-child(2),
.flexImageContainer .rightSideImage .thumbnail:nth-child(4) {
  margin-right: -7px;
}

.flexImageContainer .bottomActions {
  display: none;
  @media (--viewportLarge) {
    position: absolute;
    min-width: 180px;
    border: 1px solid #00a3ad;
    background: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    bottom: 15px;
    right: 15px;
  }
}

.flexImageContainer .bottomActions .pattern {
  display: flex;
  width: 24px;
  flex-wrap: wrap;
  margin-right: 5px;
}

.flexImageContainer .bottomActions .pattern span {
  display: block;
  height: 3px;
  width: 3px;
  margin: 2px;
  background: #18a3ad;
}

.flexImageContainer .bottomActions .text {
  line-height: initial;
  color: #00a3ad;
  font-size: 16px;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radioContainer input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.column70 {
  flex: 0 0 70%;
  max-width: 70%;
  padding: 0 15px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column30 {
  flex: 0 0 30%;
  max-width: 30%;
  padding: 0 15px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Fixed Configure rent */
.rentalModal :global(.Modal_isOpen .Modal_scrollLayer) {
  align-items: center;
}

.rentalCarModalContainer {
  overflow: hidden;
  padding: 15px;
  background: #fcfcfc;
  height: calc(100vh - 100px);
  overflow-y: scroll;
}

.layoutWrapperMain {
  position: relative;
}

.fixedBottomBtn {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: #fff;
  -webkit-box-shadow: 0px -3px 10px 0px rgba(230, 230, 230, 1);
  -moz-box-shadow: 0px -3px 10px 0px rgba(230, 230, 230, 1);
  box-shadow: 0px -3px 10px 0px rgba(230, 230, 230, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  @media (--viewportLarge) {
    display: none;
  }

  @media (--viewportMobile) {
    bottom: 0;
    padding: 14px 20px;
  }
}
.fixedBottomBtn .button {
  background: #00a3ad;
  border: 0;
  color: #fff;
  font-size: 20px;
  padding: 14px;
  border-radius: 5px;
  min-width: 167px;
  min-height: 52px;
  height: 52px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.fixedBottomBtn .monthWisePrice {
  display: flex;
  flex-direction: column;
}

.fixedBottomBtn .monthWisePrice .monthlyPrice {
  color: #0d6786;
  font-size: 20px;
  @media (--viewportMobile) {
    font-weight: 700;
  }
}

.fixedBottomBtn .monthWisePrice .monthlyPrice span {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 500;
}

.fixedBottomBtn .monthWisePrice .link {
  text-decoration: underline;
  font-size: 14px;
  color: #00a3ad;
}

.sharebtns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (--viewportMobile) {
    display: none;
  }
}
.sharebtns div,
.sharebtns button {
  width: 30px;
  height: 30px;
  margin-left: 20px;
  border: 0;
  padding: 0;
  outline: none;
}
.rentalModal > div {
  @media (--viewportMobile) {
    background: rgba(0, 0, 0, 0.08);
  }
}
.rentalModalScrollLayer {
  @media (--viewportMobile) {
    max-width: 70vh;
    display: block;
    flex: 0 0 80vh;
    align-items: flex-end;
    margin-top: auto;
    position: relative;
    background-color: rgba(0, 0, 0, 0.15) !important;
  }
}
.rentalCarModalContainer {
  @media (--viewportMobile) {
    padding: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
  }
}
.rentalCarModalContainer .rentalCard {
  @media (--viewportMobile) {
    border: 0;
  }
}
.rentalCarModalContainer .rentalModalcloseButton {
  @media (--viewportMobile) {
    position: absolute;
  }
}
.rentalCarModalContainer .rentalModalcontent {
  @media (--viewportMobile) {
    height: calc(100% - 100px);
    overflow: auto;
  }
}

.searchContactModalContainer {
  padding: 0;
  background: #ffffff !important;
  max-width: 650px;
  border-radius: 10px;
  position: relative;
  @media (--viewportMobile) {
    border-radius: 0;
  }
}
.searchContactRightContent {
  padding: 15px 20px;
}
.searchContactRightContent p {
  font-size: 16px;
  line-height: 1.25;
  color: #4a4a4a;
}

.searchContactModalSLayer {
  background: rgba(0, 0, 0, 0.25) !important;
}

.modalHeader {
  padding: 20px;
  font-size: 22px;
  color: #026786;
  border-bottom: 1px solid #e6e6e6;
}
.modalHeader h3 {
  margin: 0;
}

.imageComponent {
  position: relative;
  background-color: var(--matterColorAnti);
  height: 100%;
  width: 100%;
  flex-wrap: wrap;

  @media (--viewportLarge) {
    max-height: 670px;
  }
}

.closeButtonClassName {
  top: 20px;
  right: 20px;
  position: absolute;
  z-index: 1;
}

.exitModalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 0px;
  max-width: 740px;
  display: flex;

  @media (--viewportMedium) {
    flex-basis: 576px;
    min-height: auto;
    height: auto;
    padding: 0px;
    display: flex;
  }


  @media (--viewportSmall) {
    flex-basis: 400px;
    min-height: auto;
    height: auto;
    padding: 0px;
    display: grid;
  }


  @media (--viewportXSmall) {
    flex-basis: 400px;
    min-height: auto;
    height: auto;
    padding: 0px;
    display: grid;
  }


  @media (--viewportLarge) {
    flex-basis: 724px;
    min-height: auto;
    height: auto;
    padding: 0px;
    display: flex;
  }
}

.modalContainerContent {
  display: flex;
  /*flex-wrap: wrap;*/
  position: relative;

  @media (--viewportXSmall) {
    display: grid;
  }

  @media (--viewportSmall) {
    display: grid;
  }

  @media (--viewportMediumMax) {
    display: grid;
  }

  /*@media screen (min-width: 500px) and (max-width: 800px) {*/
  /*  display: grid;*/
  /*}*/

  @media (--viewportLarge) {
    display: flex;
  }
}

.imageComponentImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
}


.exitModalHeading {
  color: #0D6786;
  font-size: 36px;
  font-weight: 700;
  flex-wrap: wrap;
  /*margin: 20px 20px 15px 20px;*/

  @media (--viewportMobile) {
    color: #0D6786;
    /*margin: 15px 20px;*/
    font-size: 24px;
    font-weight: 700;
  }
}

.section2 {
  margin-top: 40px;
  padding: 20px;
  flex-wrap: nowrap;
  display: flex;
  line-height: normal;
  flex-direction: column;
  width: 100%;
}

.section1 {
  display: flex;
  width: 100%;
}
@media (--viewportmobile) {
  font-size: 18px;
  margin-bottom: 10px;
}

@font-face{
  font-family: sofiapro;
  src: url(https://www.drivelah.sg/static/fonts/exljbris_MuseoSansRounded_500.otf) format('opentype');
  font-style: normal
}

.para {
  font-weight: 400 !important;
  font-size: 20px;
  font-family: 'sofiapro';
}

.phoneCodeSelect {
  width: 67px;
  margin-right: 10px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  outline: none;
}

.phoneNumberField {
  width: 150px;
  @media (--viewportMobile) {
    width: 100%
  }
}


.fieldLabel @font-face{
  font-size: 16px;
  font-weight: 300 !important;
  font-family: sofiapro;
  src: url(https://www.drivelah.sg/static/fonts/exljbris_MuseoSansRounded_300.otf) format('opentype');
  font-style: normal
}

.borderBox {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  opacity: 1;
  padding-right: 5px;
}

.exitModalButton {
  height: 48px;
  padding: 0 20px;
  margin: 14px 9px 20px auto;
  border-radius: 5px;
  text-transform: none;
  min-height: auto;
}

.buttonTxt {
  letter-spacing: -0.14px;
  font-size: 16px;
  font-weight: 700;

  @font-face{
    font-size: 16px;
    font-weight: 300;
    font-family: sofiapro;
    src: url(https://www.drivelah.sg/static/fonts/exljbris_MuseoSansRounded_300.otf) format('opentype');
    font-style: normal;
  }
}
