@import '../../marketplace.css';

:root {
  --FlexBasis_DateTime_LargeField: 45%;
  --FlexBasis_DateTime_SmallField: 45%;
}

.root {
  display: flex;
  flex-direction: column;
  padding-bottom: 300px;

  @media (--viewportMedium) {
    padding-bottom: 0px;
  }
}

.dateBookingWrapper {
  width: 100%;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--marketplaceColor);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.signupCredits {
  display: none;
}

.error {
  font-size: 14px;
  color: var(--failColor);
  margin: 0px 24px 16px;
  display: inline-block;

  @media (--viewportLarge) {
    margin: 0px 0 14px;
  }
}

.dateTimeError {
  color: var(--failColor);
}

.timeSlotsError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.securityPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: left;
  margin: auto 0px 0px 0px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 20px 3px 20px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin: auto 10px 10px 10px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  @media (--viewportLarge) {
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.cancellationPolicy {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.insuranceBottom {
  padding: 10px 20px 40px;
  text-align: center;
  margin-top: 25px;

  @media (--viewportLarge) {
    padding: 0px;
    text-align: left;
  }
}

.insuranceBottomTitle {
  font-size: 16px;
  color: var(--marketplaceColorDark);
  margin-bottom: 10px;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.insuranceBottomLine {
  font-size: 16px;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.insuranceBottomImg {
  width: 150px;
  margin-top: 25px;
}

.insuranceBottomImgText {
  font-size: 14px;
  color: var(--matterColor);
  cursor: pointer;
}

.noInsuranceBottom {
  padding: 22px;
  background-color: var(--marketplaceSecondaryColor);
  color: var(--marketplaceColorDark);
}

.noInsuranceBottomTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.noInsuranceBottomDesc {
  display: block;
  font-size: 20px;
  margin-top: 20px;
}

.openHostInsurance {
  cursor: pointer;
}

.dateTimeFields {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;
  flex-wrap: wrap;
  padding: 5px 20px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.fieldDateTimeLabel:nth-child(2n + 1) {
  flex-basis: var(--FlexBasis_DateTime_LargeField);
}

.fieldDateTimeLabel:nth-child(2n) {
  flex-basis: var(--FlexBasis_DateTime_SmallField);
}

.fieldDateTimeInput {
  background: var(--matterColorBright);
  flex-shrink: 0;
}

.fieldStartDate {
  z-index: 5;
}

.fieldEndDate {
  z-index: 4;
}

.fieldDateTimeLabel {
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorAnti);
  font-weight: 700;
}

.fieldDateTimeInput:nth-child(2n + 1) {
  flex-basis: var(--FlexBasis_DateTime_LargeField);
}

.fieldDateTimeInput:nth-child(2n) {
  flex-basis: var(--FlexBasis_DateTime_SmallField);
}

.fieldInput {
  border-bottom-color: var(--marketplaceColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColorDark);
  }
}

.notVerifiedGuestWarning {
  margin-top: 15px;
  font-size: 18px;
  color: var(--marketplaceColorDark);
  margin-bottom: 20px;
}

.notVerifiedGuestWarning p {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 24px;
}

.rentalAgreementLink {
  color: var(--marketplaceColorDark);
  margin: 10px 0 10px 20px;
  font-size: 18px;

  @media (--viewportMedium) {
    margin: 10px 0;
  }
}

.rentalAgreementLink span {
  cursor: pointer;
}

.voucherContainer {
  margin-top: 38px;
  width: 100%;
  display: flex;
}

.voucherInput {
  flex-grow: 1;
}

.checkDiscount {
  margin-top: 38px;
  flex-grow: 1;
}

.checkDiscount label {
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorAnti);
  font-weight: 700;
}

.voucherInput label {
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorAnti);
  font-weight: 700;
}

.root .voucherButton {
  margin-left: 20px;
  max-width: 60px;
  align-self: flex-end;
  min-height: 45px;
  font-size: 16px;
}

.smallPrintForMember {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColorDark);
  font-size: 14px;
  text-align: center;
  margin: 0;
  flex-shrink: 0;
}

.error {
  color: var(--failColor);
}

.estimating {
  font-size: 16px;
  line-height: 20px;
  margin: 10px 0;
  text-align: center;
}

.warning {
  font-size: 20px;
  line-height: 24px;
  color: var(--failColor);
  text-align: center;
  margin: 10px 0;
}

.warning > a {
  color: var(--failColor);
  cursor: pointer;
  font-weight: var(--fontWeightBold);
}
