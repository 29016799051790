@import '../../marketplace.css';


.root {

}

.readyToShareSection {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/becomeAHost/readyToShare-bg-min.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;

  @media (--viewportMedium) {
    height: 386px;
  }
}

.textBox {
  width: 100%;
  padding: 40px 20px;
  margin: auto;

  @media (--viewportMedium) {
    padding: 40px 0px;
    width: auto;
    max-width: 100%;
    margin: 0 7.5vw;
  }

  @media (--viewportLarge) {

  }

  @media screen and (min-width: 1200px) {
    margin: auto;
    max-width: 1100px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColorLight);
  margin-top: 0px;
  font-weight: 900;
  margin-bottom: 80px;
  text-decoration: none;
  @media (--viewportMedium) {
    margin-bottom: 30px;
  }
}

.newlineMobile {
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.listYourCar {
  @apply --marketplaceButtonStylesPrimary;
  background-color: var(--marketplaceColorDark);
  border-radius: 5px;
  width: 200px;
  min-height: 50px;
  padding-top: 15px;

  &:hover, &:focus {
    background-color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    width: 265px;
    min-height: 65px;
    padding-top: 20px;
  }
}

.contactLineWrapper {
  width: 100%;
  background-color: var(--marketplaceSecondaryColor);
}

.contactLine {
  font-size: 24px;
  line-height: 30px;
  padding: 20px;
  width: 100%;
  color: var(--marketplaceColorDark);
  margin: auto;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
    padding: 25px 0px;
    font-size: 26px;
    line-height: 46px;
    max-width: 100%;
    width: auto;
    margin: 0 7.5vw;
  }

  @media (--viewportLarge) {

  }

  @media screen and (min-width: 1200px) {
    margin: auto;
    max-width: 1100px;
  }
}

.boldText {
  font-weight: 900;
  display: block;

  @media (--viewportMedium) {
    display: inline;
  }
}
