@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --PropertyGroup_lineHeight: 24px;
  --PropertyGroup_lineThroughTop: calc(var(--PropertyGroup_lineHeight) - 7px);
  --PropertyGroup_lineThroughBottom: calc(var(--PropertyGroup_lineHeight) - 6px);
  --PropertyGroup_lineThroughTopMobile: calc(var(--PropertyGroup_lineHeight) - 9px);
  --PropertyGroup_lineThroughBottomMobile: calc(var(--PropertyGroup_lineHeight) - 8px);
}

.root {
  margin: 0;
}

.twoColumns {
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
  flex-wrap: wrap;
  @media (--viewportMedium) {
    column-count: 2;
  }
}
.twoColumns li {
  padding-left: 5px;
  padding-right: 5px;
}

.itemWrapper {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  color: #4a4a4a;
  display: flex;
  align-items: center;
}
.itemWrapper strong {
  color: #b2b2b2;
  display: block;
  font-weight: 500;
  font-size: 10px;
  line-height: normal;
  mask-border: 5px;
  letter-spacing: normal;
}
.itemWrapper .icon {
  width: 41px;
  min-width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemWrapper .icon img {
  max-width: 22px;
}
.itemWrapper .labelWrapper {
  padding: 5px 8px 5px 0;
}
.item {
  display: flex;
  align-items: center;
  color: var(--marketplaceColorDark);
  margin-bottom: 5px;
  padding: 0 2px;
  @media (--viewportMobile) {
    padding: 4px 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.checkIcon {
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  height: var(--PropertyGroup_lineHeight);
}

.labelWrapper {
  display: inline-block;
}

.selectedLabel,
.notSelectedLabel {
  @apply --marketplaceBodyFontStyles;
  line-height: var(--PropertyGroup_lineHeight);
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightSemiBold);
  font-weight: normal;
}

.notSelectedLabel {
  color: var(--matterColorNegative);
  position: relative;

  /* line-through */
  background-image: linear-gradient(
    transparent var(--PropertyGroup_lineThroughTopMobile),
    var(--matterColorNegative) var(--PropertyGroup_lineThroughTopMobile),
    var(--matterColorNegative) var(--PropertyGroup_lineThroughBottomMobile),
    transparent var(--PropertyGroup_lineThroughBottomMobile)
  );

  @media (--viewportMedium) {
    background-image: linear-gradient(
      transparent var(--PropertyGroup_lineThroughTop),
      var(--matterColorNegative) var(--PropertyGroup_lineThroughTop),
      var(--matterColorNegative) var(--PropertyGroup_lineThroughBottom),
      transparent var(--PropertyGroup_lineThroughBottom)
    );
  }
}
