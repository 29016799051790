@import '../../marketplace.css';

.verificationLanding {
    font-family: "sofiapro";
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e6e6;
    max-width: 750px;
    /* max-height: 460px; */

    @media (--viewportMobile) {
        /* max-height: 100%; */
    }
}

.statusSection {
    background-color: #fff;
    display: flex;
    padding: 16px 20px;
}

.verificationText {
    flex: 1;
    font-size: 20px;
    color: #026786;
    font-weight: 700;
}

.verificationStatus {
    font-size: 16px;
    color: #026786;
}

.verificationStatusBadge {
    background-color: #e6e6e6;
    padding: 8px;
    border-radius: 4px;
    font-weight: 600;
}

.actionSection {
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: #4a4a4a;
    @media (--viewportMobile) {
        flex-direction: column;
    }
}

.stepperSection {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
}

.stepperSection .step {
    height: 6px;
    background: #E6E6E6 0% 0% no-repeat padding-box;
    width: 100%;
    margin-right: 5px;
}
.stepperSection .step:last-child {
    margin-right: 0px;
}

.stepperSection .step.complete {
    background: #FFCD05 0% 0% no-repeat padding-box;
}

.stepperSection .step.current {
    background: #00A3AD 0% 0% no-repeat padding-box;
}

