@import '../../marketplace.css';

.root {
  display: flex;
  justify-content: space-between;
  background-color: var(--matterColorBright);
  position: relative;
  flex-direction: column;
}

.longInfo {
  flex-wrap: wrap;
}

.filters {
  padding-left: 20px;
  padding-right: 20px;
  & > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 -20px 24px;
}

.searchResultSummary {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  margin-top: 9px;
  margin-bottom: 11px;

  /* parent uses flexbox: this defines minimum width for text "6 results" */
  flex-basis: 55px;
  flex-shrink: 0;
}

.noSearchResults {
  @apply --marketplaceH4FontStyles;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-shrink: 0;
}


.resultsFound {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  display: inline-block;
  margin-right: 24px;
}

.searchFiltersPanelClosed {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  background-color: var(--matterColorLight);
  color: var(--marketplaceColorDark);

  display: inline-block;
  padding: 7px 16px 7px 16px;
  font-size: 18px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  font-weight: 300;
  &:focus {
    outline: none;
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border-color: #b2b2b2;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
  &:hover {
    outline: none;
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border-color: #b2b2b2;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  background-color: var(--matterColorLight);
  color: var(--marketplaceColorDark);

  display: inline-block;
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);
  font-weight: 300;
  &:hover,
  &:focus {
    background-color: var(--matterColorLight);
    color: var(--marketplaceColorDark);
    border: 1px solid var(--marketplaceColorDark);
  }
}
