@import '../../marketplace.css';

.root {
  width: 100%;
}

.signUpTitle {
  font-size: 24px;
  line-height: 30px;
  color: var(--marketplaceSecondaryColor);
  font-weight: var(--fontWeightBold);
  margin-bottom: 30px;

  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 36px;
  }
}

.field {
  margin-bottom: 10px;
}
.input {
  position: relative;
  .root{
    position: static;
  }
}

.input input + div{
  position: static;
  font-size: 14px;
  line-height: normal;
}

.input input {
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  border-color: var(--matterColorLight);
  color: var(--matterColorLight);
  padding: 20px 10px 3px;
  color: #0d6786;
  font-weight: 500;
  border: 0 !important;

  &::placeholder {
    color: rgba(13, 103, 134, 0.6);
  }

  &:focus::placeholder,
  &:hover::placeholder {
    color: rgba(13, 103, 134, 0.6);
  }
  &:focus,
  &:hover {
    border: 0;
  }
}

.input label {
  color: var(--matterColorLight);
  position: absolute;
  z-index: 2;
  color: #b2b2b2;
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
  top: 0;
  left: 0;
  padding: 10px 10px 0;
}

button.submitBtn {
  border: 1px solid #fecd2a;
  background: #fecd2a;
  border-radius: 5px;
  display: flex;
  height: 60px;
  font-size: 20px;
  color: #026786;
  align-items: center;
  justify-content: center;
  text-transform: none;
  box-shadow: none;
  outline: none;
}

.smallText {
  font-size: 18px;
  line-height: normal;
  font-weight: 300;
  margin-top: 25px;
  color: var(--matterColorLight);
}

.termOfUse {
  color: var(--marketplaceSecondaryColor);
}
