@import '../../../marketplace.css';

.root {
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  min-width: 100%;
  padding-bottom: 20px;

  @media (--viewportMedium) {
    max-width: calc(100vw - 48px);
    padding-left: 0;
    padding-right: 0;
  }

  @media (--viewportLarge) {
    width: calc(100vw - 72px);
    padding-left: 0;
    padding-right: 0;
  }
}

.titlename{
  font-size: 22px;
  color:#026786;

  @media (--viewportMobile) {
    font-size:18px;
  }
}

.sliderWrapper :global(.slick-track) {
  display: flex;
}

.sliderWrapper :global(.slick-slide) {
  margin-right: 20px;
  @media (--viewportMobile) {
    margin-right: 0;
  }
}
.sliderWrapper :global(.slick-list) {
  overflow: hidden;
}
.sliderContainer {
  border-radius: 10px;
  overflow: hidden;
}
.cardDetails {
  border: 1px solid #e6e6e6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.imgWrapper {
  position: relative;
}

.imgWrapper .imgContainer img {
  width: 100%;
}

.border {
  border: 1px solid #e6e6e6;
}

/* heart */
.heart {
  background: #ffffff;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
}

.displaykm {
  background: #ffffff;
  border-radius: 4px;
  width: 85px;
  height: 30px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.displaykm span{
  color:#0D6786;
  font-size: 12px;
}

.carname {
  font-size: 20px;
  color: #0d6786;
  margin: 0;
  line-height: inherit;

  @media (--viewportMobile) {
    font-size:18px;
  }
}

.features {
  margin: 10px;
  @media (--viewportMobile) {
    margin:8px 10px;
  }
}

.features .carDetail{
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.features .carDetail span {
  font-size: 12px;
  color: #0d6786;
  display: flex;
  align-items: center;

  @media (--viewportMobile) {
    font-size:10px;
  }
}

.features .carDetail .circle{
  background: #026786;
  height: 4px;
  width:4px;
  margin: 7px;
  display: block;
  border-radius: 100%;

  @media (--viewportMobile) {
    margin: 5px
  }
}

.carinfo {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.pricetag {
  text-align: left;
  display: flex;
  margin-left: 2px;
  /* font: normal normal normal 16px/19px Museo Sans Rounded 100; */
  letter-spacing: 0px;
  color: #026786;
  font-size: 10px;
}

.pricetag span {
  margin-left: 3px;
}

.rating {
  color: #026786;
  font-size: 16px;

  @media (--viewportMobile) {
    font-size:13px;
  }
}

.trips {
  color: #026786;
  font-size: 16px;
  opacity: 0.7;
}

.rent {
  font-size: 20px;
  color: #026786;

  @media (--viewportMobile) {
    font-size:16px;
  }
}

.timeSpan{
  font-size: 16px;

  @media (--viewportMobile) {
    font-size:8px;
  }
}

.sliderWrapper :global(.slick-slide) {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.sliderWrapper :global(.slick-arrow) {
  position: absolute;
  left: 0;
  height: 20px;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -20px;
  transform: translateY(-50%);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 16px;
  border-radius: 4px;
  z-index: 3;
  font-size: 0;
}
.sliderWrapper :global(.slick-arrow::before) {
  display: none;
}

.sliderWrapper :global(.slick-disabled){
  opacity: 0.5;
}

.sliderWrapper :global(.slick-next) {
  left: auto;
  right: 0;
  @media (--viewportMobile) {
    display: none;
  }
}
.sliderWrapper :global(.slick-prev) {
  left: auto;
  right: 22px;
  @media (--viewportMobile) {
    display: none;
  }
}
.contentDetails {
  border: 1px solid #E6E6E6;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}