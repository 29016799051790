@import '../../marketplace.css';

.email {
  text-align: center;

  border-bottom: 3px solid var(--matterColorRPTitle);
  
  margin-bottom: 23px;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.root .errorMsg {
  position: relative;
}

.root .submitButton {
  font-size: 28px;
  min-height: 50px;
  border-radius: 6px;

  &::first-letter {
    text-transform: uppercase; 
  }

  @media (--viewportMedium) {
    width: 310px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;

  }
}

.root .errorMsg {
  text-align: center;
  color: var(--failColor);

  @media (--viewportMedium) {
    text-align: left;
  }
}
