@import '../../marketplace.css';

.root {
  position: relative;
}

.label {
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
}

.icon {
  display: none;
}

.inputClassName {
  border: 0;
  font-size: 18px;
  height: 19px;
}
.inputClassNamedasd {
  position: absolute;
  width: 100%;
  padding-bottom: 30px;
  top: calc(100% + 8px);
  left: 0;
  background-color: #00a3ad;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 30%);
  z-index: calc(50 + 1);
  margin: 0 -20px;
  width: auto;
  right: 0;
}
.dateInput {
  margin-top: 10px;
  width: 100%;

  @media (--viewportLarge) {
    margin-top: 0;
  }
}

.pickUp {
  z-index: 10;
}

.dropOff {
  z-index: 5;
}

.dropOff :global(.SingleDatePicker_picker__directionLeft) {
  left: -163px !important;
}

.row {
  display: flex;
  flex-direction: row;
  margin-left: -5px;
  margin-right: -5px;
  flex-wrap: wrap;
}

.column4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.buttonWrapper .findYourCar {
  background-color: #fecd2a;
  color: #0d6786;
  text-transform: none !important;
  font-size: 18px !important;
  line-height: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  position: relative;
  text-decoration: none !important;
  &:hover,
  &:focus {
    background-color: #fecd2a !important;
    opacity: 0.85;
  }
  &:disabled {
    background-color: var(--matterColorNegative) !important;
    color: var(--matterColorLight) !important;
  }

  height: 48px;
  min-height: 48px;
  margin-top: 20px !important;
  border-radius: 5px !important;
}

.help {
  font-size: 16px;
  line-height: 24px;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);
  margin-top: 15px;

  @media (--viewportLarge) {
    margin-top: 0;
    white-space: pre-wrap;
    text-align: right;
    font-size: 18px;
    line-height: 24px;
    flex: 1;
  }

  @media (--viewportXLarge) {
    font-size: 20px;
    line-height: 24px;
  }
}

.buttonWrapper {
  display: block;
}

/* .root :global(input.DateInput_input) {
  border-bottom: solid 2px var(--marketplaceColor) !important;
} */
.root :global(input.DateInput_input) {
  border-bottom: 0 !important;
  padding: 0 !important;
  font-size: 16px;
  line-height: normal;
  color: #026786;
  &::placeholder {
    color: #026786;
  }
}
.root :global(select) {
  border-bottom: 0 !important;
  padding: 0 !important;
  font-size: 16px;
  line-height: normal;
  background: none !important;
  color: #026786;
  &::placeholder {
    color: #026786;
  }
}

.locationSearch {
  @media (--viewportLarge) {
    max-width: unset !important;
  }
}

.errorMobile {
  @apply --marketplaceTinyFontStyles;
  color: var(--failColor);

  @media (--viewportLarge) {
    display: none;
  }
}

.errorDesktop {
  display: none;
  @apply --marketplaceTinyFontStyles;
  color: var(--failColor);

  @media (--viewportLarge) {
    display: block;
  }
}

.formInput {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 8px 20px;
  height: 56px;
  margin: 0 0 10px;
  color: #026786;
  position: relative;
  @media (max-width: 767px) {
    padding: 8px 10px;
  }
}
.formInput svg {
  fill: #4a4a4a;
}
.formInput label {
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  margin-bottom: 5px;
  color: #4a4a4a;
}
.formInput input {
  padding: 0;
  border: 0 !important;
  &::placeholder {
    color: #026786;
    opacity: 1;
  }
}

.contactField {
  border: 0;
  font-size: 18px;
}

.inputError {
  border-color: red;
}

.formError {
  padding: 10px 0 0;
  font-size: 14px;
  line-height: normal;
  color: red;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.locationLoader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.locationLoader span {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #00a3ad;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 0.75s linear infinite; /* Safari */
  animation: spin 0.75s linear infinite;
}

.vaccinationSubmitForm .uploadFileInput {
  height: 80px;
  width: 100%;
  border: 1px solid red;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px dashed #b2b2b2;
  position: relative;
  margin-bottom: 14px;
}
.vaccinationSubmitForm .uploadFileInput label {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.vaccinationSubmitForm .uploadFileInput button {
  display: none;
}
.vaccinationSubmitForm .previewFile {
  margin: 0;
}
.vaccinationSubmitForm .pad0 {
  padding: 0;
}
.uploadContentList .uploadContentListOuter {
  margin: 0;
}
.uploadContentListOuter .uploadContentItem {
  padding: 2px 35px 2px 5px;
  border: 1px solid #00a3ad;
  border-radius: 4px;
  font-size: 15px;
  color: #4a4a4a;
  font-weight: 300;
  position: relative;
  max-width: 150px;
  margin-bottom: 15px;
  box-sizing: border-box;
  height: 32px;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.uploadContentListOuter .uploadContentItem span {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 0;
  top: 0;
  font-size: 10px;
  font-weight: 900;
  color: #00a3ad;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customUpload {
  position: relative;
  margin-bottom: 15px;
}
.customUpload label {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #b2b2b2;
  font-size: 16px;
  line-height: normal;
  color: #4a4a4a;
}
.customUpload label span {
  color: #fecd2a;
  display: inline-block;
  margin-left: 5px;
}
.customUpload input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.locationLoader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.locationLoader span {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #00a3ad;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 0.75s linear infinite; /* Safari */
  animation: spin 0.75s linear infinite;
}
