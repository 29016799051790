@import '../../marketplace.css';

.root {
}

.modalOuter {
  background: transparent;
  @media (min-width: 768px) {
    display: none;
  }
}
.topBarSearchModal{
  bottom: auto;
}
.topBarSearchModal .topBarSearchModalScrollLayer{
  min-height: 10px;
  background: transparent;
}
.topBarSearchModal .topBarSearchModalScrollLayer .container {
  min-height: 10px;
  height: auto;
  background: transparent;
}

.container {
  padding: 0 !important;
  overflow: hidden;
  background: transparent;
}

.content {
  position: relative;
  flex: 1;
  overflow: auto;
}
.content::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.45); */
  display: none;
}

.modalHeader {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #00a3ad;
  flex-wrap: wrap;
  z-index: 2;
  position: relative;
}

.backButton {
  padding: 20px;
  flex: 1;
  max-width: 60px;
}
.backButton svg {
  display: block;
}

.searchTypeStep {
  padding: 10px 20px 0;
}

.title {
  font-size: 20px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);
  margin-bottom: 20px;
}

.type {
  background-color: #f6f6f6;
  border-radius: 8px;
  border: solid 1px #e6e6e6;
  padding: 10px;
  margin-bottom: 20px;
}

.typeTitle {
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
}

.desc {
  font-size: 14px;
  line-height: 18px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightRegular);
  margin-top: 5px;
}

.fieldLocation {
  height: 52px;
  border: solid 1px #e6e6e6;
  border-radius: 4px;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 100%;
  white-space: nowrap;
}

.inputClassName {
  border-bottom: none;
  font-size: 18px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  padding-right: 15px;
  padding-left: 7px;
  font-weight: 500;
}

.hideSearchIcon {
  position: absolute;
  left: 20px;
}

.searchIcon {
  width: 20px;
  height: 22px;
  margin-left: 20px;
  margin-right: 10px;
}

.searchPredictions {
  display: none;
}

.prediction {
  font-size: 20px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  margin-bottom: 18px;
  display: block;
  border: 0;
  padding: 0;
  box-shadow: none;
  outline: none !important;
}
.prediction:empty {
  margin-bottom: 0;
}

.predictions {
  margin-top: 0;
  padding: 24px 20px;
  position: relative;
  z-index: 2;
  background: #ffffff;
  overflow: auto;
}
.predictions .predictions {
  padding: 0;
}

.currentLocation svg {
  margin-right: 10px;
}

.bookingDateAndTimes {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  max-width: calc(100% - 120px);
  color: rgba(255, 255, 255, 0.75);
}

.bookingDateAndTimes > span {
  font-size: 14px;
  line-height: normal;
  display: inline-block;
  color: rgba(255, 255, 255, 0.75);
  margin: 0 5px;
}
.bookingDateAndTimes > span:first-of-type {
  margin: 0 5px 0 0;
}

.bookingDateAndTimes label {
  font-size: 16px;
  line-height: 19px;
  color: var(--marketplaceColorDark);
  display: none;
}

.selectedSearchLocation {
  flex: 0 0 100%;
  max-width: 100%;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  font-size: 20px;
}

.footer {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 72px;
  background-color: var(--matterColorLight);
  width: 100%;
  padding: 14px 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
}

.skipBtn {
  font-size: 20px;
  line-height: 24px;
  text-decoration: underline;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);
}

.searchBtn {
  @apply --marketplaceButtonStyles;
  background-color: var(--marketplaceSecondaryColor);
  min-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 6px;
  text-transform: none;
  font-size: 18px;
  font-weight: 500;
}

.clearBtn {
  padding: 20px;
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;
  color: var(--marketplaceColorDark);
  flex: 1;
  max-width: 60px;
  display: flex;
  justify-content: flex-end;
}
.clearBtn svg {
  display: block;
}

.selectDateTimeHeader {
  justify-content: space-between;
  align-items: center;
}

.hintText {
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
  margin-bottom: 20px;
}

.searchBtnDisable {
  opacity: 1;
}

.options {
  display: flex;
  flex-direction: column;
}

.option {
  font-size: 20px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  margin: 0 0 15px;
}

.selectedOption {
  background-color: var(--marketplaceSecondaryColor);
}

.locationStepHeader {
  padding-right: 20px;
}

.timeDateSelectorLabel {
  height: 42px;
  background: #f6f6f6;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: normal;
  color: #0d6786;
  font-weight: 900;
  margin-bottom: 15px;
}

.searchPreviewWrapper {
  position: static;
  left: 0;
  top: 80px;
  height: calc(100vh - 80px);
  background: rgba(0, 0, 0, 0.45);
  width: 100%;
}
.searchPreviewWrapper .searchPreviewInner {
  background: #00a3ad;
  width: 100%;
  padding: 0 20px 30px;
}
.searchPreviewWrapper::before {
  position: absolute;
  left: 0;
  top: 80px;
  background: #00a3ad;
  width: 100%;
  padding: 0 20px 30px;
}
.searchPreviewTimePickerOuter {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.searchPreviewTimePicker {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 5px;
}
.searchPreviewTimePicker .startDatePanel {
}
.searchPreviewSubmit {
  padding-top: 10px;
}
.searchPreviewInput {
  background: #fcfcfc;
  border-radius: 4px;
  padding: 26px 10px 6px;
  position: relative;
  height: 52px;
  margin-bottom: 10px;
  color: #0d6786;
}
.searchPreviewInput label {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 12px;
  padding: 6px 10px;
  z-index: 9;
  font-weight: 300;
  color: #b2b2b2;
}
.searchPreviewInput .fieldLocation {
  background: none;
  border: 0;
  height: 100%;
}
.searchPreviewInput input {
  padding: 0;
  font-size: 18px;
}

.viewAllCar {
  @apply --marketplaceButtonStylesSecondary;
  border: none;
  padding: 0;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--fontWeightMedium);
  border-radius: 5px;
  width: 100%;
  text-transform: none;
  font-size: 18px;
}
.searchPreviewFormInput {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 100%;
  color: #0d6786;
  border: 0;
}
.searchPreviewTitle {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  max-width: calc(100% - 120px);
}

.searchLabels {
  font-size: 16px;
  color: #b2b2b2;
  display: flex;
  height: 40px;
  align-items: center;
}

.dateTimeSelectionTabsOuter {
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  background: #00a3ad;
}
.dateTimeSelectionTabsOuter .dateTimeSelectionTabs {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 12px 20px;
  position: relative;
  opacity: 0.33;
}
.dateTimeSelectionTabsOuter .dateTimeSelectionTabs::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  background: #fecd2a;
  border-radius: 3px;
  width: 100%;
  display: block;
  height: 4px;
  z-index: 9;
  opacity: 0;
}
.dateTimeSelectionTabsOuter .dateTimeSelectionTabs.tabActive {
  opacity: 1;
}
.dateTimeSelectionTabsOuter .dateTimeSelectionTabs.tabActive::after {
  opacity: 1;
}
.dateTimeSelectionTabsOuter .dateTimeSelectionTabs .tabsDateTimeValues {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  display: block;
  line-height: normal;
}
.dateTimeSelectionTabsOuter .dateTimeSelectionTabs .tabsDateTimeLabel {
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  display: block;
  opacity: 0.75;
  line-height: normal;
}
.modalHeader .icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.dateTimePickerOuter {
}
.predictionsCurrentLocations {
  margin: 0;
}
.predictionsCurrentLocations {
  margin: 0;
}
.recentSearchedPredictions {
  margin: 0;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.locationLoader{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(255,255,255,0.55);
  display: flex;
  align-items: center;
  justify-content: center;
}
.locationLoader span {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #00a3ad;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 0.75s linear infinite; /* Safari */
  animation: spin 0.75s linear infinite;
}

.isDisabled{
  pointer-events: none;
  opacity: 0.78;
}