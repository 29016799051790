@import '../../marketplace.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.root {
  padding-top: var(--topbarHeight);
}

.loaderContainer{
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 12px;
  color: #4a4a4a;
}

.loader {
  border: 4px solid #f0f0f0;
  border-radius: 50%;
  border-top: 5px solid #474747;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.calenderSyncContainer{
  display: flex;
  align-items: center;
}

.calendarBoxContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 15px;
}

.googleBox {
  display: flex;
  align-items: center;
  width: 128px;
  height: 40px;
  border-radius: 2px;
  background: #64646414;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
}

.googleContainer {
  display: flex;
}

.googleLogo {
  align-self: center;
  height: 18px;
  width: 18px;
  margin: 11px;
  margin-right: 20px;
}

.googleText {
  height: 24px;
  width: 59px;
  letter-spacing: 0.30px;
  font-weight: 600;
  color: #4A4A4A;
  align-self: center;
  text-decoration: none;
}
.googleBox:hover{
  text-decoration: none;
}

.connectedContainer{
  width: 300px;
  display: flex;
  flex-grow: 1;
  color: black;
  border: 1px solid #4285F4;

}

.statusContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 12px;
  flex-grow: 1;
}

.emailText {
  color: #026786;
}

.statusText {
  color: #00a3ad;
}

.disconnect {
  display: flex;
  cursor: pointer;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding-right: 5px;
  color: black;
  width: 40px;
  height: 64px;
}

.crossIcon {
  width: 20px;
  height: 20px;
}


