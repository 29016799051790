@import '../../marketplace.css';

.root {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 24px 24px 42px 24px;
  background-color: #1aa2ac;

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 81px 0;
    border-top: none;
  }
}

.accordionbox {
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 16px;
}
.accordionbox .accordionTitle {
  padding: 20px 50px 20px 30px;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #222222;
  position: relative;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    padding: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
.accordionbox .accordionDescription {
  padding: 0 30px 28px 30px;
  @media only screen and (max-width: 768px) {
    padding: 0 16px 16px;
  }
}
.accordionbox .accordionDescription p, .accordionbox .accordionDescription li {
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin: 0;
  font-weight: 400;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 1.5;
  }
}
.accordionbox .accordionDescription ul {
  padding-top: 15px;
  padding-left: 15px;
  list-style: disc;
}
.accordionButton {
  position: absolute;
  height: 100%;
  width: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  background: none;
  border: 0;
  transform: rotate(0);
  transition: 0.3s all cubic-bezier(0.18,0.52,0.49,0.85);
  @media only screen and (max-width: 768px) {
    width: 56px;
  }
}

.accordionbox.isActive .accordionButton {
  transform: rotate(180deg);
}

.accordionButton:hover, .accordionButton:focus {
  background: none;
  border: none;
  box-shadow: none;
}
.accordionButton img{
  @media only screen and (max-width: 768px) {
    width: 10px;
  }
}
