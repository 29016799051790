@import '../../marketplace.css';

.root {
  @apply --marketplaceH4FontStyles;
  margin-top: 6px;
  margin-bottom: 12px;
  color: var(--failColor);
  overflow: hidden;
  position: absolute;
  line-height: 16px;

  @media (--viewportMedium) {
    margin-top: 6px;
    margin-bottom: 10px;
  }
}

.searchListContact {
  padding: 0 10px;
  flex: 0 0 100%;
  max-width: 100%;
}
.searchListContactWrapper {
  background: #00a3ad;
  border-radius: 4px;
  padding: 22px 30px;
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    padding: 15px 10px;
  }
  @media (max-width: 991px) {
    padding: 10px;
  }
  @media (max-width: 767px) {
    position: relative;
  }
}
.searchListContactWrapper .icon {
  max-width: 76px;
  flex: 1;
}
.searchListContactWrapper .contactDescription {
  flex: 1;
  font-size: 18px;
  line-height: 1.333;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
  @media (max-width: 991px) {
    padding-right: 0;
    flex: 0 0 calc(100% - 76px);
    max-width: calc(100% - 76px);
    font-size: 15px;
    line-height: 1.2;
  }
}
.searchListContactWrapper .contactDescription p {
  margin: 0;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.searchListContactWrapper .contactButton {
  flex: 1;
  border: 1px solid #ffcd05;
  height: 44px;
  max-width: 200px;
  font-size: 16px;
  color: #026786;
  background: #ffcd05;
  border-radius: 4px;
  cursor: pointer;
  @media (max-width: 1024px) {
    height: 34px;
    max-width: 120px;
    font-size: 14px;
  }
  @media (max-width: 991px) {
    display: none;
  }
}
.searchListContactWrapper .mobileContactButton {
  font-size: 16px;
  color: #ffcd05;
  background: none;
  cursor: pointer;
  display: none;
  border: 0;
  padding: 0;
  margin-top: 10px;
  @media (max-width: 991px) {
    display: block;
  }
}
.searchListContact .searchContactModalSLayer {
  display: flex;
  padding: 30px 0;
  min-height: 100vh;
  overflow: auto;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  background-image: none;
  @media (max-width: 767px) {
    display: block;
    padding: 0;
  }
}
.searchListContact .searchContactModalContainer {
  width: 720px;
  background: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
    overflow: auto;
    border-radius: 0;
  }
}
.searchContactModalContainer .searchContactModalCloseButton {
  padding: 20px;
}
.searchContactModalContainer .searchContactModalCloseButton .searchContactModalCloseText {
  display: none;
}
.searchContactModalInner {
  position: relative;
  padding-left: 260px;
  @media (max-width: 767px) {
    padding-left: 0;
  }
}
.searchContactLeftImage {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 260px;
  width: 100%;
  height: 100%;
  @media (max-width: 767px) {
    display: none;
  }
}
.searchContactLeftImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.searchContactRightContent {
  padding: 40px 30px;
  @media (max-width: 767px) {
    padding: 40px 20px 100px 20px;
  }
}
.searchContactRightContent h3 {
  margin: 0 0 20px;
  font-size: 28px;
  color: #0d6786;
  line-height: 1.357;
  @media (max-width: 767px) {
    font-size: 24px;
    margin: 0 0 15px;
  }
}
.searchContactRightContent p {
  font-size: 18px;
  line-height: normal;
  margin: 0 0 20px;
  color: #4a4a4a;
}

.inputError {
  border: 1px solid red;
}

.mobileContactButtonOverlay {
  display: none;
  @media (max-width: 767px) {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
