@import '../../marketplace.css';

.root {
  width: 100%;
  background-color: var(--matterColorLight);
}

.contentWrapper {
  padding: 20px 30px;
  margin: 0 auto;
  position: relative;

  @media (--viewportMedium) {
    max-width: fit-content;
  }

  @media (--viewportLarge) {
    padding: 20px 0;
  }
}

.closeIcon {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 20px;
  right: 12px;

  @media (--viewportMedium) {
    right: 0;
  }
}

.title {
  font-size: 24px;
  line-height: 27px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);

  @media (--viewportLarge) {
    font-size: 40px;
    line-height: 48px;
  }
}

.description {
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--marketplaceColor);

  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 36px;
  }
}
