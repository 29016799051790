@import '../../marketplace.css';

.root {
  position: relative;
  width: 100%;
  padding: 0;
}

.toggleMapButton {
  bottom: 0;
  right: 60px;
  font-size: 14px;
  color: var(--matterColor);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.toggleMapButton span {
  display: block;
  padding-right: 10px;
}