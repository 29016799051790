@import '../../marketplace.css';

.rootLayout {
  height: 100%;
}

.root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  background-color: #FCFCFC;
  @media (--viewportMobile) {
    margin-top: 30px;
  }
}

.authPageContent {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  max-width: 1000px;
  align-items: center;
}

.leftBannerImage {
  display: none;
  @media screen and (min-width: 910px) {
    display: block;
  }
}

.rightContent {
  padding: 40px 100px;
  flex: 1;
  overflow: hidden;
  @media (--viewportMobile) {
    padding: 0 20px;
  }
}

.authPageTitle {
  font-weight: 900;
  font-size: 36px;
  line-height: 1.19444;
  color: #026786;
  text-align: left;
  position: relative;
  @media (--viewportMobile) {
    font-size: 28px;
  }
}

.mobileMenuContainer {
  max-width: 600px;
  padding: 40px;
  background-color: #FFFFFF;
  display: flex;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}

.error {
  @apply --marketplaceModalErrorStyles;
}
