@import '../../marketplace.css';

.root {
}

.landingContainer {
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}
.landingRow {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: wrap;
}

.column4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.alignItemsCenter {
  align-items: center !important;
}
.textCenter {
  text-align: center !important;
}

.sectionHero {
  background-image: #ffffff;
  min-height: 235px;
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  padding-bottom: 70px;
  @media (--viewportMedium) {
    background-position: center;
    background-size: 100% 410px;
  }
  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 0;
  }

  @media (--viewportLarge) {
    background-attachment: fixed;
    background-size: cover;
  }
}

.bannerContent p {
  font-size: 24px;
  line-height: normal;
  color: #4a4a4a;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: normal;
    margin: 0 0 20px;
  }
}

.heroTitle {
  font-size: 48px;
  line-height: normal;
  color: #026786;
  align-self: flex-end;
  margin: 0 0 16px;
  font-weight: 900;
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: normal;
  }
}
.heroTitle br {
  @media (max-width: 767px) {
    display: none;
  }
}

.heroContent {
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
}

.heroContentMobile {
  display: flex;
  flex-direction: column;
  padding: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media (--viewportLarge) {
    display: none;
  }
}

.heroContentDesk {
  display: none;

  @media (--viewportLarge) {
    display: block;
    background-color: unset;
  }
}

.heroContentText {
  font-size: 24px;
  line-height: normal;
  font-weight: normal;
  color: #4a4a4a;
  margin-bottom: 22px;
  @media (max-width: 767px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.landingFormWrapper {
  background: #18a3ad;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.29);
  padding: 30px;
  min-height: 445px;
  @media (max-width: 767px) {
    padding: 30px 20px;
    margin: 0 -15px;
    border-radius: 16px 16px 0 0;
    box-shadow: none;
    min-height: 160px;
  }
}
.landingFormWrapper .signUpTitle {
  text-align: center;
  line-height: 1.25;
  font-size: 32px;
  @media (max-width: 767px) {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: left;
  }
}
.landingFormWrapper .signUpTitle span {
  max-width: 400px;
  margin: 0 auto;
  display: block;
}

.formWrapper {
  display: flex;
  align-items: center;
  /* background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)),
    linear-gradient(var(--marketplaceColor), var(--marketplaceColor)); */

  @media (--viewportLarge) {
    /* background-color: #0aa3ad; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & > div {
      flex-basis: 40%;
    }

    & > div:first-child {
      flex-basis: 60%;
    }
  }

  @media (--viewportXLarge) {
    margin: 65px 0 0 125px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.row svg {
  margin-right: 10px;
  margin-top: 5px;
}

.sectionProvider {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px;
  @media (--viewportLarge) {
    max-width: 155px;
  }
  @media (max-width: 767px) {
    max-width: 155px;
    margin-bottom: 30px;
  }
}
.providerTitle {
  font-size: 12px;
  line-height: 14px;
  color: var(--marketplaceColorDark);
  margin-bottom: 10px;
}
.insuranceImage {
  height: auto;
  width: 100%;
}
.landTransport {
  height: auto;
  width: 100%;
}

.sectionStories {
  margin-top: 33px;

  @media (--viewportXLarge) {
    max-width: 1600px;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    margin-top: 0px;
  }
}

.formWrapperDesktop {
  width: 100%;
  height: 100%;
  @media (--viewportLarge) {
    background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)),
      linear-gradient(var(--marketplaceColor), var(--marketplaceColor));
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
  }
}

.mobileChannels {
  background-color: var(--marketplaceColorDark);

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopChannels {
  display: none;

  @media (--viewportLarge) {
    background-color: var(--marketplaceColorDark);
    display: block;
  }
}

.channels {
  padding: 22px 15px;
  max-width: 1190px;
  margin: 0 auto;
}

.channelsRow {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.channel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
  filter: grayscale(100%);
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    filter: grayscale(0);
    opacity: 1;
  }
  @media (max-width: 767px) {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 20px;
  }
}

.channelImage {
  width: auto;
  height: auto;
  max-width: 100%;
}

.channelImageVertical {
  height: 100%;
  max-height: 38px;
  width: auto;

  @media (--viewportLarge) {
    max-height: 64px;
  }
}

.sectionCars {
  @media (--viewportXLarge) {
    max-width: 1600px;
    margin: 0 auto;
  }
}

.signUpTitle {
  font-size: 24px;
  line-height: 30px;
  color: var(--marketplaceSecondaryColor);
  font-weight: var(--fontWeightBold);
  margin-bottom: 30px;

  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 36px;
  }
}

.loginDesc {
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);
  margin: 0;

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 24px;
  }
}

.loadingWrapper {
  flex: 1;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loadingSpinner {
  stroke: var(--matterColorLight);
}

.calcTargetButton {
  font-size: 24px;
  color: #0aa3ad;
  font-weight: 700;
  display: inline-block;
  padding: 7px 0;
  /* border-bottom: 2px solid #0aa3ad; */
  @media (max-width: 767px) {
    display: none;
  }
}
.calcTargetButton span {
  display: inline-block;
  width: 11px;
  height: 20px;
  margin-left: 10px;
  vertical-align: middle;
}
.calcTargetButton span img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.remarks{
  display: block;
  font-size: 14px;
  font-style: italic;
  color: #c7c7c7;
}

/* Insurance Block */
.insuranceBlock {
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 767px) {
    padding: 20px 0;
  }
}
.insuranceBlock .blockTitle {
  font-size: 34px;
  line-height: normal;
  color: #026786;
  font-weight: 900;
  display: block;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: normal;
  }
}
.insuranceBlock p {
  font-size: 22px;
  line-height: normal;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    font-size: 16px;
    margin-top: 0;
    line-height: normal;
  }
}

.insuranceBlock a span {
  display: inline-block;
  width: 11px;
  height: 20px;
  margin-left: 10px;
  vertical-align: middle;
}
.insuranceBlock a span img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

/* Simply sharing cars */
.simplySharingCars {
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.simplySharingCars .sectionTitle {
  font-size: 48px;
  line-height: normal;
  text-align: center;
  font-weight: 900;
  color: #026786;
  margin-bottom: 50px;
  @media (max-width: 767px) {
    font-size: 35px;
    line-height: normal;
    text-align: left;
    margin-bottom: 20px;
  }
}
.simplySharingCars .blockList {
  margin-bottom: 50px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}
.simplySharingCars .columnTitle {
  font-size: 32px;
  text-align: center;
  line-height: normal;
  font-weight: 700;
  color: #0d6786;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: normal;
    text-align: left;
    margin-bottom: 10px;
  }
}
.simplySharingCars p {
  font-size: 22px;
  text-align: center;
  line-height: normal;
  font-weight: 500;
  color: #4a4a4a;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: normal;
    text-align: left;
    margin-bottom: 20px;
  }
}
.sectionButton {
  font-size: 20px;
  font-weight: 500;
  display: inline-flex;
  height: 60px;
  width: 276px;
  border-radius: 4px;
  background: #fecd2a;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: normal;
  color: #026786;
  margin: 0;
  padding: 15px 10px;
  border: 1px solid #fecd2a;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 100%;
  }
}

/* SectionStories */
.sectionStoriesDescription {
  display: none;
  color: var(--marketplaceColorDark);
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  display: block;
  margin-bottom: 50px;
  @media (max-width: 767px) {
    display: none;
  }
}

.sectionStoriesTitleWrapper {
  padding: 0 30px;
  @media (max-width: 767px) {
    padding: 0;
  }
  & > .sectionTitle {
    white-space: pre-wrap;
    font-size: 48px;
    line-height: normal;
    text-align: center;
    font-weight: var(--fontWeightBold);
    color: var(--marketplaceColorDark);
    @media (max-width: 767px) {
      font-size: 35px;
      line-height: normal;
      text-align: left;
      margin-bottom: 10px;
    }
  }

  @media (--viewportLarge) {
    text-align: center;
    margin: 0 auto;
    & > .sectionTitle {
      white-space: nowrap;
    }
  }
}

.story {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 270px;
}

.storyContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 0 0 45px 0;
}

.storyContent {
  font-size: 20px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);
  padding: 0 15px 40px;
  max-width: 375px;
  min-height: 232px;
  text-align: center;
}

.avatarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -35px;
}

.avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 70px;
  height: 70px;
}

.sectionStories {
  /* padding-top: 40px; */
  padding-bottom: 25px;
  padding-top: 60px;
  @media (max-width: 767px) {
    padding-top: 30px;
  }
}

.sectionStories :global(.slick-list) {
  padding: 0;
}
.sectionStories :global(.slick-dots) {
  bottom: -30px;
}

.sectionStories :global(.slick-slide) {
  /* margin-left: 30px;

  @media (--viewportLarge) {
    &:first-child {
      margin-left: 40px;
    }
  }

  @media (--viewportXLarge) {
    margin-left: 36px;
  } */
}

.sectionStories :global(.slick-disabled) {
  opacity: 0.5;
}

.sectionStories :global(.slick-prev) {
  left: -45px;
  height: 50px;
  width: 30px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/prev.png');
  &::before {
    content: '';
  }
}

.sectionStories :global(.slick-next) {
  right: -45px;
  height: 50px;
  width: 30px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/next.png');
  &::before {
    content: '';
  }
}

.sectionStories :global(.slick-dots) {
  & > li {
    width: 8px;
    height: 8px;
    opacity: 0.4;
    border-radius: 50%;
  }

  & > li > button {
    width: 8px;
    height: 8px;
    background-color: var(--marketplaceColor);
    border-radius: 50%;
  }
}

.sectionStories :global(.slick-active) {
  opacity: 1 !important;
}
.sectionStories :global(.slick-active) div {
  outline: none !important;
}
/*
.sectionStories :global(.slick-list) {
  padding: 0 0 0 30px;
}

.sectionStories :global(.slick-slide) {
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }
}

.sectionStories :global(.slick-dots) {

  margin-top: 40px;

  & > li {
    width: 8px;
    height: 8px;
    opacity: 0.4;
    border-radius: 50%;
  }

  & > li > button {
    width: 8px;
    height: 8px;
    background-color: var(--marketplaceColor);
    border-radius: 50%;
  }
}

.sectionStories :global(.slick-active) {
  opacity: 1 !important;
} */

.storyWrapper {
  padding: 0 20px 0 0;
  padding: 0 30px 0 0;
}

.storyAvatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.storyName {
  font-size: 20px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);
  text-align: center;
  margin-top: 7px;
  /* transform: translateX(-50%); */
}


.formPartnerLogo{
  max-width: 392px;
  margin: 0 auto 26px;
  display: block;
  @media (max-width: 767px) {
    margin: 0 0 20px;
  }
}
.formPartnerLogo img{
  display: block;
  width: 100%;
  max-width: 100%;
}