@import '../../marketplace.css';

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.root {
  @apply --marketplaceModalRootStyles;

  @media (--viewportMedium) {
    @apply --authBackgroundImage;
  }
}

.content {
  @apply --marketplaceModalBaseStyles;
}

.form {
  @apply --marketplaceModalFormRootStyles;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
  color: var(--marketplaceColorDark);
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

.helperLink {
  @apply --marketplaceModalHelperLink;
}

.helperText {
  @apply --marketplaceModalHelperText;
}

.submitButton {
  @apply --marketplaceButtonStylesPrimary;
  margin-top: 24px;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

.invisible {
  visibility: hidden;
}