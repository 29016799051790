@import '../../marketplace.css';

.root {
  word-break: break-word;
  & p {
    @apply --marketplaceH4FontStyles;
    margin: 0 auto;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 23px 0 19px 0;

    @media (--viewportMedium) {
      margin: 24px 0 24px 0;
    }
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;
    color:var(--marketplaceColor);
    
    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }

  & li {
    list-style-type: none;
  }
}

.row {
  margin-top: 24px;
}

.left {
  width: 30%;
  word-break: break-word;
  line-height: 100%;
  vertical-align: text-top;
}

.right {
  width: 70%;
}
