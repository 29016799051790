@import '../../../marketplace.css';


.titleContainer {
    color: #00a3ad;
    display: flex;
    padding: 20px !important;
    flex-direction: column;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
  
    @media (--viewportMedium) {
      padding: 0px;
    }
  }

  .titleContainer h3 {
    text-align: left;
  }

  .cardTitle {
    font-size: 24px;
    color: #026786;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    @media (--viewportMobile) {
      font-size: 16px;
    }
  }


  .cardTitle .submissionStatus {
    font-size: 18px;
    line-height: normal;
    font-weight: 700;
    color: #026786;
    background: #E6E6E6;
    border-radius: 4px;
    padding: 8px;
    display: block;
    min-height: 38px;
  
  
    @media (--viewportMobile) {
      font-size: 10px;
      min-height: 25px;
    }
  }