@import '../../marketplace.css';

:root {
  
}

.root {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingpage/section_partners_bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    background-size: 210%;
    background-position: 50% 0%;
  }

  @media (--viewportLarge) {
    background-size: 170%;
    background-position: 70% 60%;
  }

  @media screen and (min-width: 1200px) {
    background-size: cover;
    background-position: center;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (--viewportLarge) {
    justify-content: flex-start;
  }
}

.footerButton {
  @apply --marketplaceButtonStyles;
  align-self: flex-end;
  line-height: 45px;
  padding: 0px;
  margin-top: 18px;
  min-height: 45px;
  border-radius: 5px;

  @media (--viewportMedium) {

  }

  @media (--viewportLarge) {
    width: 300px;
    margin-right: 40px;
    margin-top: 85px;
  }
}

.findoutButton {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  &:hover {
    background-color: var(--marketplaceColorLight);
  }
}

.listYourCar {
  background-color: var(--marketplaceSecondaryColor);
  color: var(--marketplaceColorDark);
}

.iconTextStyle {
  color: var(--matterColorLight);
}

.mobileText {
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopText {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}