@import '../../marketplace.css';

:root {
  --SectionHeroBackground: {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/instantBookingLandingPage/instantBooking.jpg');
    background-image: image-set(url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/instantBookingLandingPage/instantBooking.jpg') 1x, url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/instantBookingLandingPage/instantBooking@2x.jpg') 2x);
    /* Add loading color for the div */
    background-color: var(--matterColorLight);

    /* Cover the whole screen with the background image */
    background-size: 100%;

    /* Align the image within the container */
    background-position: top center;
  }
}

.root {

}

.sectionHero {
  @apply --SectionHeroBackground;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 18px 18px;

  min-height: 330px;

  @media (--viewportSmall) {
    min-height: 400px;
  }

  @media (--viewportMedium) {
    min-height: 450px;
  }

  @media (--viewportLarge) {
    min-height: min(650px, 70vh);
    margin-bottom: 32px;
  }

  @media (--viewportMLarge) {
    min-height: max(675px, 70vh);
  }

  @media (--viewportXLarge) {
    min-height: max(800px, 70vh);
  }
}

.sectionHeroTitle {
  font-size: 36px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);
  text-align: center;
  white-space: pre-wrap;

  @media (--viewportLarge){
    font-size: 60px;
    line-height: 65px;
  }
}

.sectionHeroDescription {
  font-size: 18px;
  line-height: 22px;
  color: var(--matterColor);
  white-space: pre-wrap;
  text-align: center;

  @media (--viewportLarge) {
    font-size: 24px;
    line-height: 30px;
  }
}

.sectionInstantBooking {
  background-color: var(--matterColorLight);
}

.sectionInstantBookingContent {
  background-color: var(--marketplaceColor);
  width: 100%;
  height: 100%;
  padding: 40px 45px 28px;

  @media (--viewportMLarge){
    max-width: 1072px;
    margin: 0 auto;
    border-radius: 6px;
    padding-top: 60px;
  }
}

.instantBooking {
  background-color: var(--instantBookingLabelColor);
  max-width: 280px;
  height: 42px;
  margin: 0 auto;
  border-radius: 5px;
  font-size: 28px;
  line-height: 42px;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);
  text-align: center;

  @media (--viewportLarge) {
    height: 50px;
    font-size: 40px;
    line-height: 50px;
    max-width: 420px;
  }
}

.sectionInstantBookingDescription {
  font-size: 24px;
  line-height: 27px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);
  text-align: center;
  margin-top: 5px;
  margin-bottom: 20px;

  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 35px;
    margin-top: 15px;
  }
}

.properties {
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.property {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sectionBrowseCar {
  padding: 38px 18px;

  @media (--viewportLarge) {
    margin-top: 54px;
  }
}

.sectionBrowseCarDescription {
  font-size: 18px;
  line-height: 22px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
  text-align: center;

  @media (--viewportLarge) {
    max-width: 810px;
    margin: 0 auto;
    font-size: 24px;
    line-height: 30px;
  }
}

.button {
  @apply --marketplaceButtonStylesSecondary;
  height: 50px;
  min-height: 50px;
  color: var(--marketplaceColorDark);
  font-size: 24px;
  line-height: 28px;
  font-weight: var(--fontWeightSemiBold);
  padding: 11px 0;
  border-radius: 5px;
  margin-top: 20px;
  text-transform: none;

  @media (--viewportLarge) {
    max-width: 450px;
  }
}

.browseCarBtn {

  @media (--viewportLarge) {
    max-width: 460px;
    margin: 15px auto 0;
  }
}

.listings {
  margin-top: 30px;

  @media (--viewportMLarge) {
    max-width: 1072px;
    margin: 30px auto 0;
  }
}

.sectionBrowseCar :global(.slick-slide) {
  padding-right: 24px;

  &:last-child {
    padding-right: 0;
  }
}

.SectionInstantPolicies {
  width: 100%;
  height: 100%;

  background-color: var(--marketplaceColorDark);
}

.SectionInstantPoliciesWrapperContent {
  padding: 40px 20px;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (--viewportMLarge) {
    padding: 60px 0;
    max-width: 1052px;
    width: 1052px;
    margin: 0 auto;
  }
}

.sectionInstantPoliciesImageWrapper {
  border-radius: 8px;
  overflow: hidden;
}

.sectionInstantPoliciesImage {
  height: auto;
  width: 100%;


  @media (--viewportLarge) {
    max-width: 475px;
    width: 475px;
  }
  /* @media (--viewportMLarge) {
    max-width: 535px;
    width: 535px;
  } */
}

.sectionInstantPoliciesContentWrapper {
  margin-top: 15px;

  @media (--viewportLarge) {
    max-width: 488px;
  }
}

.sectionInstantPoliciesContent {
  font-size: 18px;
  line-height: 22px;
  font-weight: var(--fontWeghtMedium);
  color: var(--matterColorLight);

  @media (--viewportLarge) {
    font-size: 24px;
    line-height: 29px;
  }
}

.hiddenMobileText {
  display: none;

  @media (--viewportLarge){
    display: inline;
  }
}

.learnmore {
  @media (--viewportLarge){
    max-width: unset;
  }
}
