@import '../../marketplace.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;
  color: #026786;
  @media (--viewportMedium) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}
