@import '../../marketplace.css';

.locationItem {
  margin-bottom: 10px;
}

.spinner {
  width: 14px;
  height: auto;
}

.mb {
  margin-bottom: 20px;
}

.locationSubtitle {
  color: var(--matterColorAnti);
  font-size: 14px;
  margin: 0;
  line-height: 20px;
}

.location {
  color: var(--marketplaceColorDark);
  font-size: 16px;
  margin: 0;
  line-height: 18px;
}

.loadingText {
  margin: 24px;
}
