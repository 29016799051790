@import '../../marketplace.css';

.root {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
}

.twoColumns {
  flex-direction: column;
}

.label {
  min-width: 100px;
  font-weight: normal;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* margin: auto; */
  margin-right: 4px !important;

  @media (--viewportMedium) {
    margin: unset;
    justify-content: flex-start;
  }
}

.buttonUpload {
  position: relative;
  width: 120px;
  height: 36px;
  min-height: 36px;
  font-size: 18px;
  font-weight: normal;
  line-height: 36px;
  text-align: center;
  color: var(--matterColorDark);
  background-color: var(--matterColorNegative);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  min-height: 30px !important;
  padding: 5px !important;

  &:hover, &:focus {
    background-color: var(--matterColorAnti);
  }
}

.buttonContents {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.inputField {
  display: none;
}

.previewFile {
  width: 100%;
  height: 36px;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  color: var(--matterColor);
  background-color: var(--matterColorNegative);
  border-radius: 5px;
  margin-top: 0px;
  border: none;
  cursor: initial;
  padding: 0 10px;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    width: 220px;
    margin-top: 0px;
    margin-left: 20px;
  }
}

.previewFiles {
  margin-top: 14px;
  margin-left: 0px;
  margin-left: -20px;
}

.inputFieldLong {
  margin: auto;
  margin-top: 14px;
  width: 212px;

  @media (--viewportMedium) {
    margin-left: unset;
    margin-right: 40px;
  }
}

.error {
  color: var(--failColor);
}

.spinner {
  width: 28px;
  height: 28px;
  stroke: var(--matterColorLight);
  stroke-width: 3px;
}

.checkmark {
  width: 24px;
  height: 24px;
  stroke: var(--matterColorLight);

  /* Animation */
  animation-name: checkmarkAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

.checkmarkSmall {
  margin-left: 5px;
}

@keyframes checkmarkAnimation {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
