.outerContainer p {
    font-size: 18px;
    color: initial;
    margin: 0;
}

.outerContainer h3 {
    margin: 0;
}

.outerContainer>div {
    margin: 20px 0px;
}

.addProfileImage {
    /* border: solid 2px black; */
    display: flex;
    justify-content: space-between;
    align-items: start;
}

/* .addProfileImage>div {
    border: solid 2px blue;
} */

.addProfileImage>div:last-child {
    width: 100px;
    height: 100px;
    position: relative;
}

.addProfileImage>div:last-child>div:first-child {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.imageCameraCircle {
    width: 40%;
    height: 40%;
    background-color: #f2f2f2;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageCameraCircle {
    background-color: #e6e6e6;
    cursor: pointer;
}

.imageCircle > div {
    width: 100%;
    height: 100%;
}

.category {
    margin: 15px 0 15px 0;
}

.category .uploadButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    padding: 5px;
    text-transform: initial;

    &:hover,
    &:focus {
        background-color: #e6e6e6;
    }

    @media (--viewportMobile) {
        max-width: 100%;
        width: 100%;
    }
}

.uploadButtonContent {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font: normal normal normal 20px/22px Museo Sans Rounded 500;
    color: #4A4A4A;
    font-size: 20px;
    padding: 5px;
}

.checkmark {
    width: 24px;
    height: 24px;
    stroke: var(--successColor) !important;

    /* Animation */
    animation-name: checkmarkAnimation;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
}

@keyframes checkmarkAnimation {
    0% {
        opacity: 0;
        transform: scale(0.75);
    }

    50% {
        transform: scale(1.15);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.submitButton {
    margin-top: 70px !important;
    padding: 5px;
    width: 40%;
    height: 60px;
    font-size: 20px;

    @media (--viewportMobile) {
        max-width: 100%;
        width: 100%;
    }
}

.submitButton > .enabledBtn {
    border: none;
    border-radius: 4px;
    background-color: #FFCD05;
    color: #026786;
    width: 100%;
    height: 100%;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 24px;

    &:hover {
        background-color: #edbe00;
        cursor: pointer;
    }
}

.submitButton > .disabledBtn {
    border: none;
    border-radius: 4px;
    background-color: #9d9d9d;
    color: #353535;
    width: 100%;
    height: 100%;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 24px;
}