@import '../../marketplace.css';

.root {
  width: 100%;
  position: relative;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 20px;
  background-color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
  }

  @media (--viewportLarge) {
    padding: 20px 100px;    
  }

  @media screen and (min-width: 1200px) {
    padding: 15px 175px;    
  }

  @media screen and (min-width: 1920px) {
    padding: 20px 175px;    
  }
}

.text {
  font-size: 20px;
  color: var(--matterColorLight);
  margin-bottom: 10px;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
    font-size: 16px;
    padding-right: 20px;
    margin-bottom: 0px;
  }

  @media (--viewportLarge) {
    font-size: 22px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 20px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 25px;      
  }
}

.defaultButton {
  @apply --marketplaceButtonStyles;
  align-self: flex-end;
  min-height: auto;
  height: 40px;
  width: 248px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 5px;
  padding: 10px;

  @media (--viewportMedium) {
    display: block;
    font-size: 22px;
    padding: 8px 20px;
    height: auto;
    width: 200px;
  }

  @media (--viewportLarge) {
    width: 200px;
  }

  @media screen and (min-width: 1200px) {
    width: auto;
    font-size: 20px;
    padding: 5px 20px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 22px;    
    padding: 8px 20px;
  }
}

.closeButton {
  color: var(--matterColorAnti);
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 22px;
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-bottom: 20px;

  @media (--viewportMedium) {
    padding: 0; 
  }
}
