@import '../../marketplace.css';

.root {
  position: relative;
}

.input {
  border-bottom-color: var(--inputBorderBottomColor);
}

.inputSuccess {
  border-bottom-color: var(--inputBorderBottomColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}
