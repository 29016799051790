@import '../../marketplace.css';

.root :global(.fontsLoaded button) {
  font-family: 'Inter', sans-serif !important;
}

.root :global(.intercom-lightweight-app-launcher) {
  background: #f8ce47 !important;
}

.root {}

.newTheme *,
.newTheme p,
.newTheme body {
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

.sectionWithBackground {
  background: #f4f6fa;
}

.listYourCarSection {
  padding: 60px 0;
}

.alignItemsCenter {
  display: flex;
  align-items: center;
}

.justifyContentCenter {
  display: flex;
  justify-content: center;
}

.btn {
  font-size: 16px;
  line-height: normal;
  font-weight: 500;
  padding: 12px 15px;
  text-transform: initial;
  border-radius: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 188px;
  height: 48px;
  min-height: 48px;
  width: auto;
  max-width: 100%;
  transition: 0.3s all cubic-bezier(0.18, 0.52, 0.49, 0.85);
  color: #026786;
  cursor: pointer;
}

.btnPrimary {
  background: #f8ce47;
  border: 1px solid #ffcd2d;
}

.btnPrimary:hover,
.btnPrimary:focus {
  background: #FFC60F;
}

.flexTwoBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flexTwoBox .flexCard {
  width: 48%;
  border-radius: 16px;
  overflow: hidden;
}

.backgroundWithImage {
  background-size: 100% 100%;
  background-position: center;
}

.actionBtn {
  width: 100%;
  margin: 0 auto;
}

.showButton {
  min-height: 48px;
  border-radius: 24px;
  text-transform: initial;
  font-size: 16px;
  padding: 12px 32px;
}

.findCarWrapper .heading {
  font-size: 24px;
  color: #222222;
  margin: 0 0 24px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
    line-height: normal;
    font-weight: 700;
    margin: 0 0 40px;
  }
}

.whiteHeading,
.whitePara {
  color: #fff;
}

.gridContainer {
  padding-top: 30px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.gridContainer .grid {
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 10px 30px;
  width: 100%;

  @media only screen and (min-width: 576px) {
    width: 46%;
  }

  @media only screen and (min-width: 992px) {
    width: 23%;
  }
}

/* Common css ends */

.bannerContainer {
  @media only screen and (min-width: 992px) {
    margin: 12px 36px 0;
  }
}

.hostBannerSection {
  @media (min-width: 768px) and (max-width: 1199px) {
    padding: 56px 0 20px;
  }
}

.bannerContent {
  @media (min-width: 768px) and (max-width: 1199px) {
    padding: 0 0 20px;
  }

  @media only screen and (max-width: 767px) {
    padding-top: 56px;
  }
}

.bannerContent h1 {
  color: #222222;
  font-size: 52px;
  line-height: 1.19;
  font-weight: 700;
  margin: 0 0 16px;

  @media (min-width: 768px) and (max-width: 1199px) {
    text-align: center;
  }

  @media only screen and (max-width: 767px) {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    margin: 0 0 12px;
    text-align: center;
  }
}

.bannerContent h1 span {
  color: #1aa2ac;
}

.bannerContent p {
  font-size: 18px;
  line-height: 1.44;
  color: #222222;
  max-width: 464px;
  margin-bottom: 32px;

  @media (min-width: 768px) and (max-width: 1199px) {
    text-align: center;
    max-width: 100%;
  }

  @media only screen and (max-width: 767px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 20px;
    text-align: center;
  }
}

.bannerContent .field {
  height: 68px;
  border-radius: 68px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid rgba(204, 204, 204, 0.6);
  position: relative;
  overflow: hidden;
  max-width: 516px;

  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 0 auto;
  }

  @media only screen and (max-width: 767px) {
    height: auto;
    border: 0;
    overflow: visible;
  }
}

.bannerContent .field>div {
  height: 100%;
}

.bannerContent .inputWrapper {
  height: 100%;
  width: 100%;
  min-height: 100%;
  border: 0;
  left: 0;
  top: 0;
}

.bannerContent .inputWrapper label {
  display: none;
}

.bannerContent .field input {
  height: 100%;
  width: 100%;
  min-height: 100%;
  border: 0;
  left: 0;
  top: 0;
  padding: 15px 162px 15px 32px;

  @media only screen and (max-width: 767px) {
    height: 48px;
    background: #f4f6fa;
    border: 1px solid rgba(204, 204, 204, 0.6);
    padding: 12px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border-radius: 68px;
  }
}

.bannerContent .field button {
  position: absolute;
  right: 10px;
  top: 0;
  height: 48px;
  min-height: 48px;
  width: 141px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 48px;
  top: 50%;
  transform: translateY(-50%);
  text-transform: capitalize;

  @media only screen and (max-width: 767px) {
    position: static;
    transform: none;
    width: 100%;
    margin-top: 12px;
  }
}

.bannerContent .field button:hover,
.bannerContent .field button:focus {
  background: #FFC60F;
}

.bannerIllustration {
  @media only screen and (max-width: 767px) {
    padding-top: 30px;
  }
}

.bannerIllustration img {
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}

/* Channel Listing Section */

.channelsListingWrapper {
  flex-direction: column;
  padding: 40px 0 22px;

  @media only screen and (min-width: 768px) {
    padding: 75px 0 50px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    padding: 40px 0;
  }
}

.channelsListingOuter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.channelsListingWrapper .channelsListing,
.channelsListingWrapper .channelsListing .channelOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0%;
  @media only screen and (max-width: 767px) {
    align-items: center;
    justify-content: center;
    flex: none;
  }
}
.channelsListingWrapper .channelsListing:first-child .channelOuter img {
  @media only screen and (max-width: 767px) {
    max-width: 186px;
  }
}
.channelsListingWrapper .channelsListing.trustBox {
  justify-content: center;
  display: flex;
}
.channelsListingWrapper .channelsListing.trustBox .channelOuter{
  flex: none;
}
.channelsListingWrapper .channelsListing .channelOuter a{
  padding: 0 5px;
  display: inline-block;
}

.channelsListingWrapper .channelsListing p {
  font-size: 14px;
  color: #222222;
  margin: 0;
  margin-right: 5px;
  @media only screen and (max-width: 768px) {
    opacity: 0.6;
    min-width: 96px;
  }
}

.channelsListingWrapper .channelsListing {
  margin-bottom: 28px;
  flex: 0 0 100%;

  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
    flex: 1;
  }
}

.communitySection {
  background: #1aa2ac;
  padding: 48px 0;

  @media only screen and (max-width: 767px) {
    padding: 40px 0 16px;
  }
}

.communitySection .sectionTitle h2 {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  margin: 0 0 24px;

  @media only screen and (max-width: 767px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}

.communitySection .sectionTitle h2 img {
  display: inline-block;
  margin-left: 10px;
}

.communitySection .statsWrapper {
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 27px 20px;
  border-radius: 16px;
  text-align: center;
  color: #ffffff;

  @media (min-width: 768px) and (max-width: 1199px) {
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 767px) {
    padding: 16px 20px;
    margin-bottom: 24px;
  }
}

.communitySection .statsWrapper strong,
.communitySection .statsWrapper strong span {
  font-size: 40px;
  line-height: 1.2;
  display: block;
  margin: 0 0 4px;

  @media only screen and (max-width: 767px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
}

.communitySection .statsWrapper strong span {
  display: inline;
}

.communitySection .statsWrapper span {
  font-size: 16px;
  line-height: 1.2;
  display: block;

  @media only screen and (max-width: 767px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
}

.benefitSectionWrapper {
  padding: 100px 0 60px;

  @media only screen and (max-width: 767px) {
    padding: 0;
    background: #f4f6fa;
  }
}

.benefitSectionWrapper .sectionTitle {
  margin-bottom: 32px;
}

.benefitSection {
  background: #f4f6fa;
  border-radius: 16px;
  padding: 60px;

  @media only screen and (max-width: 767px) {
    padding: 40px 16px;
    border-radius: 0;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.benefitSection .sectionTitle h2 {
  font-size: 36px;
  line-height: 46px;
  color: #222222;
  margin: -8px 0 12px;

  @media only screen and (max-width: 767px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 8px;
  }
}

.benefitSection .sectionTitle h2 > span {
  font-size: 18px;
  line-height: 1.4;
  color: #222222;
  /* text-align: center; */
  font-weight: 500;
  display: block;
  margin: 20px 0 20px;
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    line-height: normal;
    margin: 10px 0 0;
  }
}

.benefitSection .sectionTitle p {
  font-size: 18px;
  line-height: 28px;
  color: #222222;
  margin: 0 0 32px;

  @media only screen and (max-width: 767px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 24px;
  }
}

.benefitSection .sectionTitle .btnPrimary {
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.benefitGrids {
  padding-top: 60px;

  @media only screen and (max-width: 1024px) {
    padding-top: 0;
  }
}

.benefitGrids .imageThumb {
  margin-bottom: 20px;

  @media only screen and (max-width: 1024px) {
    margin-bottom: 8px;
  }
}

.benefitGrids .imageWrapper {
  width: 60px;
  height: 60px;
  background: rgba(26, 162, 172, 0.06);
  border: 2px solid #1aa2ac;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 767px) {
    width: 48px;
    height: 48px;
  }
}

.benefitGrids .content {
  @media only screen and (max-width: 1024px) {
    margin-bottom: 24px;
  }
}

.benefitGrids h3 {
  font-size: 20px;
  line-height: 1.2;
  color: #222222;
  margin: 0 0 2px;

  @media only screen and (max-width: 767px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}

.benefitGrids p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #222222;
  opacity: 0.6;
  margin: 0;

  @media only screen and (max-width: 767px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

.howSharingWorksWrapper {
  padding: 60px 0 100px;

  @media only screen and (max-width: 767px) {
    padding: 20px 0 0;
  }
}

.howSharingWorksWrapper .sectionTitle h3 {
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: #222222;

  @media only screen and (max-width: 767px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
}

.howSharingWorksWrapper .sectionTitle h3 > span {
  font-size: 18px;
  line-height: 1.4;
  color: #222222;
  text-align: center;
  font-weight: 500;
  display: block;
  margin: 20px 0 0;
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    line-height: normal;
    margin: 10px 0 0;
  }
}

.howSharingWorksWrapper .btnWrapper {
  padding-top: 30px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
}

.howSharingWorksWrapper .btnWrapper button {
  @media only screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
}

.howSharingWorksWrapper .sharingImage {
  max-width: 625px;
  margin-left: auto;
  width: 100%;
}

.gotYourBackWrapper {
  background: #f4f6fa;
  padding: 100px 0;

  @media only screen and (max-width: 767px) {
    padding: 40px 0;
  }
}

.gotYourBackWrapper .sectionTitle {
  margin-bottom: 30px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 24px;
  }
}

.gotYourBackWrapper .sectionTitle h4 {
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: #222222;

  @media only screen and (max-width: 767px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }
}

.gotYourBackWrapper .sectionTitle h4 > span {
  font-size: 18px;
  line-height: 1.4;
  color: #222222;
  text-align: center;
  font-weight: 500;
  display: block;
  margin: 20px 0 0;
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    line-height: normal;
    margin: 10px 0 0;
  }
}

.gotYourBackWrapper .btnWrapper {
  padding-top: 40px;

  @media only screen and (max-width: 767px) {
    padding-top: 4px;
  }
}

.gotYourBackWrapper .btnWrapper button {
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    width: 100%;
  }
}

.gotYourBackWrapper .smallContainer {
  max-width: 844px;
}

.gotYourBackWrapper .contentBox {
  position: relative;
  padding-left: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.6);
  margin-bottom: 60px;

  @media only screen and (max-width: 767px) {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.gotYourBackWrapper .contentBox::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(https://dv0eqz2t0y9gj.cloudfront.net/drivelah/host-themed-landing-page/check-circle.svg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  left: 0;
  top: 4px;
}

.gotYourBackWrapper .contentBox h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin: 0 0 4px;

  @media only screen and (max-width: 767px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}

.gotYourBackWrapper .contentBox p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 4px;
  min-height: 75px;

  @media only screen and (max-width: 767px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    min-height: 10px;
  }
}

.gotYourBackWrapper .btnWrapper {
  text-align: center;
}

.calcWrapper {
  background: #1aa2ac;
  padding: 100px 0;
  text-align: center;

  @media only screen and (max-width: 767px) {
    padding: 40px 0;
    text-align: left;
  }
}

.calcWrapper .calcContainer {
  max-width: 1062px;
}

.calcWrapper h2 {
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
  margin: 0 0 40px;

  @media only screen and (max-width: 767px) {
    text-align: left;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    margin: 0 0 24px;
  }
}

.calcWrapper h2 strong {
  color: #F8CE47;
  position: relative;
  padding-right: 20px;
  display: inline-block;
  background-image: url(https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingPageNew/arrow-down.svg);
  background-position: center right;
  background-size: 10px;
}

.calcWrapper h2 strong::after {
  color: #F8CE47;
  position: relative;
}

.calcWrapper .inlineBox {
  display: inline-block;
  max-width: 100%;
  width: auto;
  min-width: 10px;
  color: inherit;
  position: relative;
}

.calcWrapper .inlineBox.selected:after {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(34, 34, 34, 0.72);
  z-index: 111;
}

.calcWrapper .inlineBox>div {
  position: relative;
}

.calcWrapper .inlineBox.selected>div {
  position: relative;
  z-index: 112;
}

.calcWrapper h2 select,
.calcWrapper h2 input {
  color: #F8CE47;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  font-weight: inherit;
}

.breakDown {
  display: inline-block;
  color: #F8CE47;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  font-weight: 600;
}

.calcWrapper .showOnHover {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #ffffff;

  @media only screen and (max-width: 767px) {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
}

.faqWrapper {
  background: #f4f6fa;
  padding: 100px 0;

  @media only screen and (max-width: 767px) {
    padding: 40px 0;
  }
}

.faqWrapper .sectionTitle {
  margin-bottom: 40px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 24px;
  }
}

.faqWrapper .sectionTitle h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: #222222;

  @media only screen and (max-width: 767px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }
}

.faqWrapper .faqContainer {
  max-width: 684px;
}

.faqWrapper .helpCenterText {
  padding-top: 24px;
}

.faqWrapper .helpCenterText p {
  text-align: center;
  font-size: 18px;
  line-height: normal;
  margin: 0;
  font-weight: 400;
}

.faqWrapper .helpCenterText p a {
  color: #1AA2AC;
  text-decoration: underline;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 1.5;
  }
}

.faqWrapper .helpCenterText p span {
  @media only screen and (max-width: 767px) {
    display: block;
  }
}

.startEarningWrapper {
  background: #1aa2ac;
  padding: 100px 0 20px;
  text-align: center;
  position: relative;

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.startEarningWrapper::after {
  content: "";
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  height: 1px;
  background: rgba(255, 255, 255, 0.48);
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
}

.startEarningWrapper .sectionTitle {
  margin-bottom: 40px;
}

.startEarningWrapper .sectionTitle h6 {
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: #ffffff;
  text-transform: none;
  letter-spacing: normal;
}

.startEarningWrapper .sectionTitle h6 > span {
  font-size: 18px;
  line-height: 1.4;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
  display: block;
  margin: 10px 0 20px;
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    line-height: normal;
    margin: 10px 0 20px;
  }
}

.startEarningWrapper .sectionTitle p {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  max-width: 467px;
  margin: 0 auto 32px;
}

.startEarningWrapper .faqContainer {
  max-width: 684px;
}

/* Love Drivelah section */

.loveDrivelahSection {
  padding: 40px 0;

  @media only screen and (min-width: 768px) {
    padding: 100px 0;
  }
}

.loveDrivelahSection .sectionTitle {
  margin: 0 0 40px;

  @media only screen and (min-width: 768px) {
    margin: 0 0 80px;
  }
}

.loveDrivelahSection .heading {
  font-size: 24px;
  color: #222222;
  margin: 0 0 24px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
    line-height: normal;
    font-weight: 700;
    margin: 0 0 12px;
  }
}

.loveDrivelahSection .desc {
  font-size: 14px;
  text-align: center;
  margin: 0;

  @media only screen and (min-width: 768px) {
    font-size: 18px;
    line-height: normal;
    margin: 0 0 15px;
  }
}

.loveDrivelahSection .drivelahCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 24px;

  @media only screen and (min-width: 768px) {
    margin-top: 80px;
  }
}

.loveDrivelahSection .card {
  background: #1aa2ac;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;

  @media only screen and (max-width: 767px) {
    margin-bottom: 16px;
  }
}

.loveDriveLahCard:nth-child(even) {
  margin-top: 0;

  @media only screen and (min-width: 992px) {
    margin-top: 70px;
  }
}

.loveDrivelahSection .card .cardImg {
  width: 100%;
}

.loveDrivelahSection .cardContent {
  padding: 24px 14px;
  text-align: center;
  background: #1aa2ac;
}

.loveDrivelahSection .cardContent p {
  font-size: 16px;
  font-weight: 400;
  line-height: inherit;
  margin: 0 0 8px;
  color: #ffffff;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
    font-weight: 400;
  }
}

.loveDrivelahSection .cardContent p strong {
  font-size: 14px;
  font-weight: bold;
  margin: 8px 0;
}

.loveDrivelahSection .cardContent .starReview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loveDrivelahSection .cardContent .starReview span {
  margin: 0 2px;
}

.mobileButton {
  display: none;

  @media only screen and (max-width: 767px) {
    display: block;
  }
}

.mobileButton button {
  width: 100%;
}


.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 40px 0 0 0;

  @media (--viewportMedium) {
    padding: 0px;
    flex-basis: 576px;
    min-height: 0;
    min-width: 80vw;
    height: 48vw;
    margin-top: calc(50vh - 30vw);
    border-bottom: 0px solid transparent;
  }

  @media (--viewportLarge) {
    margin-top: calc(50vh - 25vw);
    min-width: 80vw;
    height: 48vw;
  }

  @media (--viewportMLarge) {
    margin-top: calc(50vh - 20vw);
    min-width: 66vw;
    height: 40vw;
  }
}

.modalCloseButton {
  top: 0px;
  right: 0px;
  padding: 10px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    color: var(--matterColorLight);
    top: -40px;
  }

  &:hover {
    color: var(--matterColorAnti) !important;
  }
}


.modalContainer {
  min-height: 100vh;
  height: 100%;
  border-radius: 16px;

  @media (--viewportMedium) {
    flex-basis: 516px;
    max-width: 516px;
    min-height: auto;
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    min-width: 10px;
  }

  @media (--viewportLarge) {
    flex-basis: 525px;
    min-height: auto;
    height: auto;
    padding: 0 32px;
  }

  @media (--viewportMLarge) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  @media only screen and (max-width: 767px) {
    min-height: 80vh;
    height: 80vh;
    border-radius: 16px;
    max-height: 80vh;
    overflow: auto;
    position: relative;
    padding: 0 16px;
  }
}

.modalContainer>button {
  @media only screen and (max-width: 767px) {
    position: absolute;
  }
}

.modalTitle {
  font-size: 28px;
  line-height: 1.35;
  padding: 28px 0;
  text-align: center;
}

.modalTitle h2 {
  margin: 0;
  color: #222222;
}

.modalTitle h2 span {
  margin: 0;
  color: #1AA2AC;
}

.modalFooter {
  border-top: 1px solid rgba(204, 204, 204, 0.6);
  padding: 16px;
  margin: 0;
  text-align: center;
}

.modalFooter p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(34, 34, 34, 0.52);
  margin: 0;
}

.modalFooter p button {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #296683;
  margin: 0;
  border: 0;
  margin: 0;
  outline: none;
  padding: 0;
  display: inline;
}

.closeButtonText {
  display: none;
}

.closeButtonIcon {
  width: 10px;
  height: 10px;
  transform: scale(2.5);
}

.scrollLayerClassName {
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-end;
    background: rgba(0, 0, 0, 0.5) !important;
  }
}

.calcWrapper .input {
  border: 0;
  color: #F8CE47;
}


.explaination {
  position: relative;
  display: inline-block;
  /* width: 15px;
  height: 15px;
  margin-left: 5px; */
  font-size: 12px;
  color: var(--matterColorAnti);
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #ffffff;

  @media only screen and (max-width: 767px) {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
}

.explaination:hover .explainationText {
  display: block;
}

.explainationText {
  display: none;
  position: absolute;
  color: var(--matterColorAnti);
  border: 1px solid var(--matterColorNegative);
  border-radius: 5px;
  background-color: var(--matterColorLight);
  padding: 15px 25px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 10px;
  margin-bottom: 25px;
  font-weight: normal;
  width: 250px;
  /* box-shadow: 3px 3px 10px var(--matterColorNegative); */
  font-size: 14px;
  line-height: 24px;

  @media only screen and (max-width: 767px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  &::after {
    content: " ";
    position: absolute;
    top: 100%;
    /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: var(--matterColorLight) transparent transparent transparent;
  }
}

.errorMessage {
  @media only screen and (min-width: 768px) {
    position: absolute;
    bottom: 0;
    margin: auto;
    padding: 0 0 5px 32px;
  }
}

.GlobalError {
  color: #ff0000;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.2;
}

.timedError {
  display: none;
}
