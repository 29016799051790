@import '../../marketplace.css';

.root {
  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    min-height: 600px;

    & > div {
      flex-basis: calc(50%);
      max-width: calc(50%);
    }

    & > div:last-child {
      flex-basis: calc(50% + 20px);
      max-width: calc(50% + 20px);
      margin-left: -20px;
    }
  }

  @media (--viewportXLarge) {
    min-height: 862px;
  }
}

.content {
  background-image: linear-gradient(to right, var(--marketplaceColorDark), var(--marketplaceColor));
  padding: 40px 20px;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px;
    z-index: 2;
    justify-content: center;
    align-items: center;
  }
}

.becomeFlexHost {
  background-image: linear-gradient(#00a3ad66, #00a3ad66),
    url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/longTermLandingPage/becomeFlexHost.jpg');
  background-size: cover;
  background-position: center;
  height: 442px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .learnMore {
    max-width: 240px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  font-size: 40px;
  line-height: 48px;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightBold);
  letter-spacing: 0;
  max-width: 230px;
  text-align: center;

  @media (--viewportLarge) {
    max-width: unset;
    text-align: left;
  }
}

.titleDesk {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.flexLogo {
  height: 48px;
  width: auto;
  margin-bottom: 4px;
}

.learnMore {
  @apply --marketplaceButtonStylesSecondary;
  min-height: 48px;
  margin-top: 20px;
  border-radius: 5px;
  text-transform: none;
  border-color: var(--marketplaceSecondaryColor);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: base-line;

  @media (--viewportLarge) {
    min-height: 60px;
    max-width: 335px;
    margin-top: 60px;
  }
}

.deskImage {
  display: none;

  @media (--viewportLarge) {
    width: 50%;
    height: auto;
  }
}

.features {
  display: flex;
  flex-direction: column;
}

.feature {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;

  & > svg {
    margin-right: 10px;
  }

  @media (--viewportLarge) {
    margin-top: 20px;
    & > svg {
      height: 82px;
      width: auto;
      margin-right: 18px;
    }
  }

  @media (--viewportXLarge) {
    margin-top: 30;
  }
}

.featureTitle {
  font-size: 20px;
  line-height: 32px;
  color: var(--matterColorLight);
  letter-spacing: 0;
  font-weight: var(--fontWeightBold);

  @media (--viewportLarge) {
    font-size: 24px;
  }
}

.featureDesc {
  font-size: 18px;
  line-height: 24px;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);

  @media (--viewportLarge) {
    font-size: 20px;
  }
}

.deskImage {
  display: none;

  @media (--viewportLarge) {
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
