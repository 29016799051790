@import '../../marketplace.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

.nameLogo {
  position: absolute;
  right: 25px;
  bottom: -27px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-image: linear-gradient(var(--marketplaceColor),var(--marketplaceColorDark));
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: var(--matterColorLight);
  overflow: hidden;
}

.nameLogoImg {
  width: 100%;
  border-radius: 50%;
}

.ownerUsername {
  position: absolute;
  bottom: -55px;
  right: 25px;
  color: var(--marketplaceColorDark);
  font-size: 12px;
  min-width: 60px;
  text-align: center;
  max-width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-height: 40px;        /* fallback */
  max-height: 40px;       /* fallback */
}

.ratingBox {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.star {
  width: 15px;
  height: 15px;
  /* fill: var(--marketplaceSecondaryColor); */
}

.numberRatings {
  font-size: 15px;
  line-height: 15px;
  color: var(--matterColorAnti);
  margin-left: 5px;
  margin-top: 2px;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.linkText {
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColorDark);
  font-weight: normal;
  margin-top: 15px;
  margin-bottom: 0;
  max-width: 67%;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.extraInfo {
  font-size: 18px;
  line-height: 32px;
  color: var(--matterColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.instantBooking {
  height: 26px;
  width: 79px;
  background-color: var(--instantBookingLabelColor);
  color: var(--matterColorLight);
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.superHost {
  height: 26px;
  width: 94px;
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.doneTrips {
  @apply --marketplaceH4FontStyles;
  color: var(--marketplaceColorDark);
  margin: 0;
}

.drivelahGoLogo {
  margin-right: 5px;
}

.superHostLogo {
  margin-right: 5px;
}

.instantBooking {
  padding: 0 8px;
  background-color: var(--instantBookingLabelColor);
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
}

.superHost {
  padding: 0 52px;
  margin-right: 5px;
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
}

.hintText {
  font-size: 12px;
  display: none;
  position: absolute;
  color: var(--matterColorAnti);
  border: 1px solid var(--matterColorNegative);
  border-radius: 5px;
  background-color: var(--matterColorLight);
  font-weight: normal;
  line-height: 16px;
  padding: 15px 25px;
  left: 0px;
  bottom: 0px;
  margin-bottom: 25px;
  width: 275px;
  box-shadow: 3px 3px 10px var(--matterColorNegative);

  &::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 10px;
    margin-left: 10px;
    border-width: 8px;
    border-style: solid;
    border-color: var(--matterColorLight) transparent transparent transparent;
  }
}

.logo {
  position: relative;
  display: inline-block;
}

.logo:hover > .hintText {
  display: block;
}
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.logoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.newLabel {
  position: relative;
  background-color: var(--marketplaceSecondaryColor);
  color: var(--marketplaceColorDark);
  top: 15px;
  left: 15px;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 5px;
  font-weight: 800;
  font-size: 16px;
}

.wrapperLabelDisinfected {
  position: relative;
  display: inline-block;
  top: 15px;
  left: 15px;
  font-size: 20px;

  & .numberDays {
    background-color: var(--marketplaceSecondaryColor);
    color: var(--marketplaceColorDark);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    z-index: 3;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -3px;

    & .number {
      font-weight: 900;
      line-height: 18px;
    }
    & .day {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 12px;
      line-height: 12px;
    }
  }
  & .textLabel {
    color: var(--marketplaceSecondaryColor);
    display: inline-block;
    font-weight: 900;
    text-transform: uppercase;
    background-color: #00a3ad;
    border-radius: 5px;
    padding: 8px 17px;
    z-index: 2;
    padding-left: 30px;
    margin-left: 15px;
  }
}
