@import '../../marketplace.css';

.root {
  position: relative;
}

.input {
  border-bottom-color: var(--inputBorderBottomColor);
}

.inputSuccess {
  border-bottom-color: var(--inputBorderBottomColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.suggestField {
  position: absolute;
  z-index: 60;
  top: 100%;
  left: 0;
  right: 0;
  line-height: 1.5em;
  color: var(--marketplaceColorDark);
  border: 1px solid var(--matterColorAnti);
  border-radius: 5px;
  background-color: var(--matterColorLight);
  max-height: 200px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.optionField {
  cursor: pointer;
  padding: 5px 20px;
  transition: 0.3s;

  &:hover, &:focus {
    background-color: var(--matterColorNegative);
  }
}