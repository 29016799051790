@import '../../marketplace.css';
:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
  --EditListingPhotosForm_imageWidthDesktop: calc(50% - 12px);
}

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.thumbnail {
  margin: 0;
  width: 100%;
  height: 100%;
  /* @media (--viewportLarge) {
    width: var(--EditListingPhotosForm_imageWidthDesktop);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  } */
}

.addImageWrapper {
  float: none;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */

.aspectRatioWrapper {
  /* padding-bottom: calc(100% * (2 / 3)); */
  width: 100%;
  height: 100%;
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  background-color: transparent;
  
  /* Colors */
  /* 

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  } */
  /* Behaviour */
  /* cursor: pointer; */
  transition: var(--transitionStyleButton);
  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.addMoreImage{
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.4;
}

.enableAddMoreImage{
  pointer-events: all;
  cursor: pointer;
  opacity: 1;
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(255,255,255,0.7);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  text-decoration: none;
  color: #FFFFFF;
  cursor: pointer;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  text-decoration: underline;
  color: var(--marketplaceColorDark);
  margin-top: 0;
  margin-bottom: 0;
  text-decoration: none;
  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;
  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 24px;
  background-color: #00a3ad;
  color: #ffffff;
  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.column {
  padding-left: 20px;
  padding-right: 20px;
}

.column12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.column4 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.column3 {
  flex: 0 0 25%;
  max-width: 25%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.displayInlineContainer {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  flex-wrap: wrap;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.displayInlineContainer .flexContent {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  flex: 0 0 100%;
}

.displayInlineContainer .flexBtns {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.displayInlineContainer .flexBtns div:first-child {
  @media (--viewportMobile) {
    margin-bottom: 15px;
  }
}

.newInput {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0 0 20px;
}

.newInput input, .newInput select {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  width: 100%;
  margin: 0;
  &:hover, &:focus {
    border: 1px solid #e6e6e6;
  }
}

.newInput select {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 7px 45px 7px 15px;
}

.newInput textarea {
  height: 120px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 15px 15px;
  font-size: 20px;
  margin: 0;
  resize: none;
  box-sizing: border-box;
  &:hover, &:focus {
    border: 1px solid #e6e6e6;
  }
}

.newInput label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
  @media (max-width: 767px) {
    margin-bottom: 5px;
  }
}

.stickyButtons {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  z-index: 10;
  @media (max-width: 767px) {
    height: auto;
    box-shadow: none;
  }
}

.stickyButtons .stickButtonsContainer {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.stickyButtons button {
  height: 60px;
  border-radius: 4px;
  max-width: 301px;
  margin-left: auto;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    height: 52px;
  }
}

.stickButtonsDescription {
  flex: 0 0 70%;
  max-width: 70%;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 15px 20px;
    background: #eff7f8;
  }
}

.stickButtonsDescription .descIcon {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
}

.stickButtonsDescription .descIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.stickButtonsDescription p {
  color: #4a4a4a;
  max-width: 600px;
  line-height: normal;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }
}

.stickButton {
  text-align: right;
  margin-left: auto;
  flex: 0 0 30%;
  max-width: 30%;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 6px 20px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  }
}

.imagePlaceHolder {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.imageThumb {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.imageThumb img {
  width: 100%;
  display: block;
}

.customInputLabel {
  font-weight: 300;
  color: #4a4a4a;
  padding-top: 0;
  padding-bottom: 5px;
  font-size: 16px;
  line-height: 24px;
}

.viewUploadPhotos {
  height: 44px;
  min-height: 44px;
  width: 100%;
  display: flex;
  border: 1px dashed var(--marketplaceColor);
  color: var(--marketplaceColor);
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: normal;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
}

.stickyButtons .linkedButton {
  color: var(--marketplaceColor);
  font-size: 20px;
  line-height: normal;
  font-weight: 700;
  border: 0;
  outline: none;
  display: inline-flex;
  height: auto;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-decoration: underline;
  flex: 0 0 100%;
  text-align: center;
}

/* Sample Photo Modal */

.containerContent {
  @apply --marketplaceModalBaseStyles;
  padding: 60px;
  flex-basis: 600px;
  border-bottom: 0;
  height: 500px;
  overflow-y: scroll;
  @media (--viewportMobile) {
    padding: 30px 20px;
  }
}

.modalTitle {
  margin-bottom: 30px;
  font-size: 28px;
}

.sampleLink {
  width: 100%;
  margin: 0 auto 20px;
  position: relative;
}

.sampleLink .sampleImage {
  width: 100%;
}

.sampleLink a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #026786;
  font-size: 16px;
}

.sampleLink a:hover {
  text-decoration: none;
}

.sampleLink a .redirectArrow {
  height: 12px;
  margin-left: 5px;
}

.sampleLink:last-child {
  margin-bottom: 0;
}

.linkTitle {
  font-size: 16px;
  margin-bottom: 5px;
}

.addMoreButton{
  position: relative;
}

.viewUploadPhotos .thumbnail{
  display: none;
}