@import '../../marketplace.css';
.listingNewHostSlider {
  background: #026786;
  margin: 0 -40px 30px;
  padding: 30px 40px;
  flex: 0 0 calc(100% + 90px);
  max-width: calc(100% + 90px);
  @media only screen and (max-width: 767px) {
    max-width: calc(100% + 30px);
    flex: 0 0 calc(100% + 30px);
    margin: 0 -20px 30px;
    padding: 20px 10px 30px;
  }
}
.listingNewHostSlider h4 {
  font-size: 26px;
  color: #ffffff;
  font-weight: 900;
  margin: 0 0 20px;
  @media only screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 1.25;
    padding-left: 20px;
  }
}
.listingNewHostSlider :global(.slick-slider) {
  padding: 0 40px;
  @media only screen and (max-width: 767px) {
    padding: 0 0 0 35px;
    margin: 0 -15px;
  }
  /* @media only (min-width: 767px) and (max-width: 1920px) {
    padding: 0 0 0 15px;
    margin: 0 -20px;
  } */
}
.listingNewHostSlider.removeSpacing :global(.slick-slider) {
  @media only screen and (min-width: 767px) {
    padding: 0;
  }
}
.listingNewHostSlider :global(.slick-list) {
  overflow: hidden;
}
.listingNewHostSlider :global(.slick-track) {
  margin: 0;
}
.listingNewHostSlider :global(.slick-arrow) {
  position: absolute;
  left: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  background: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/arrow-down-white.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50px;
  border-radius: 4px;
  z-index: 3;
  font-size: 0;
  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}
.listingNewHostSlider :global(.slick-arrow::before) {
  display: none;
}

.listingNewHostSlider :global(.slick-next) {
  left: auto;
  right: -8px;
  transform: translateY(-50%) rotate(-90deg);
}
.listingNewHostSlider :global(.slick-prev) {
  left: 0;
  right: auto;
  transform: translateY(-50%) rotate(90deg);
}
