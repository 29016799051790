@import '../../marketplace.css';

.root :global(.fontsLoaded button) {
  font-family: 'Inter', sans-serif !important;
}

.root :global(.intercom-lightweight-app-launcher) {
  background: #f8ce47 !important;
}

.root {}

.newTheme *,
.newTheme p,
.newTheme body {
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

.sectionWithBackground {
  background: #f4f6fa;
}

.sectionWithBackground .flexContent div:nth-child(3) img {
  @media only screen and (max-width: 767px) {
    max-width: 96px;
    margin: 0 auto;
  }
}

.listYourCarSection {
  padding: 60px 0;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;

  @media only screen and (min-width: 576px) {
    max-width: 540px;
  }

  @media only screen and (min-width: 768px) {
    max-width: 720px;
  }

  @media only screen and (min-width: 992px) {
    max-width: 960px;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 1140px;
  }

  @media only screen and (min-width: 1400px) {
    max-width: 1368px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.column {
  padding-left: 15px;
  padding-right: 15px;
}

.column12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.column6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.column4 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
}

.column3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.column8 {
  flex: 0 0 66.666%;
  max-width: 66.666%;
}

.column10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.alignItemsCenter {
  display: flex;
  align-items: center;
}

.justifyContentCenter {
  display: flex;
  justify-content: center;
}

.btn {
  font-size: 16px;
  line-height: normal;
  font-weight: 500;
  padding: 12px 15px;
  text-transform: initial;
  border-radius: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 188px;
  height: 48px;
  min-height: 48px;
  width: auto;
  max-width: 100%;
  font-family: 'Inter', sans-serif !important;
  transition: 0.3s all cubic-bezier(0.18, 0.52, 0.49, 0.85);
  color: #026786;
  cursor: pointer;
}

.btnPrimary {
  background: #F8CE47;
  border: 1px solid #ffcd2d;
}

.btnPrimary:hover,
.btnPrimary:focus {
  background: #FFC60F;
}

.flexTwoBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flexTwoBox .flexCard {
  width: 48%;
  border-radius: 16px;
  overflow: hidden;
}

.backgroundWithImage {
  background-size: 100% 100%;
  background-position: center;
}

.actionBtn {
  width: 100%;
  margin: 0 auto;
}

.showButton {
  min-height: 48px;
  border-radius: 24px;
  text-transform: initial;
  font-size: 16px;
  padding: 12px 32px;
}

.findCarWrapper .heading {
  font-size: 24px;
  line-height: 32px;
  color: #222222;
  margin: 0 0 24px;
  text-align: center;
  font-weight: 700;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
    line-height: normal;
    margin: 0 0 40px;
  }
}

.findCarWrapper .heading > span {
  font-size: 18px;
  line-height: 1.4;
  color: #222222;
  text-align: center;
  font-weight: 500;
  display: block;
  margin: 20px 0 0;
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    line-height: normal;
    margin: 10px 0 0;
  }
}

.whiteHeading,
.whitePara {
  color: #fff;
}

.gridContainer {
  padding-top: 30px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.gridContainer .grid {
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 10px 30px;
  width: 100%;

  @media only screen and (min-width: 576px) {
    width: 46%;
  }

  @media only screen and (min-width: 992px) {
    width: 23%;
  }
}

/* Common css ends */

.bannerContainer {
  @media only screen and (min-width: 992px) {
    margin: 12px 36px 0;
  }
}

.bannerSection {
  background-size: 100% 100%;
  background-position: center;
  position: relative;
  padding: 152px 0 0;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingPageNew/banner-mobile.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (min-width: 576px) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingPageNew/banner-bg.jpg');
    padding: 60px 0;
  }
}

.bannerHead {
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -2px;
  color: #222222;
  margin: 0 0 12px;
  font-weight: 700;

  @media only screen and (min-width: 768px) {
    font-size: 58px;
    line-height: 68px;
    font-weight: 600;
  }
}

.bannerDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: #222222;

  @media only screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 28px;
  }
}

.signupForm {
  padding-top: 20px;

  @media only screen and (min-width: 768px) {
    padding-top: 32px;
  }
}

.signupForm .field {
  margin-bottom: 16px;
}

.signupForm input {
  border: 0;
  height: 48px;
  border-radius: 48px;
  background: #f4f6fa;
  border: 1px solid rgba(204, 204, 204, 0.6);
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  padding: 10px 16px;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
    line-height: normal;
    border-radius: 6px;
    height: 58px;
  }
}

.signupForm button {
  border: 1px solid #ffcd2d;
  border-radius: 48px;
  height: 48px;
  padding: 15px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #296683;
  padding: 15px 20px;
  text-transform: none;
  min-height: 48px;

  @media only screen and (min-width: 768px) {
    border-radius: 6px;
    height: 58px;
    font-size: 18px;
    line-height: 24px;
    min-height: 48px;
  }
}

/* .searchBoxContent {
  border-radius: 16px;
  width: 100%;
  display: block;
  position: absolute;
  top: auto;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  bottom: -80px;
  @media only screen and (min-width: 768px) {
    max-width: 800px;
    bottom: -60px;
    box-shadow: 0px 24px 24px rgb(0 0 0 / 6%);
  }
} */

.searchBoxContent {
  background: #FFFFFF;
  border-radius: 16px;
  padding: 16px 16px 32px;
  position: relative;
  box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.14);
  max-width: 100%;
  margin-bottom: -60px;

  @media only screen and (min-width: 1024px) {
    max-width: 516px;
    padding: 32px 32px 32px;
    margin: 0;
  }
}

.searchBoxContent .location {
  background: #f4f6fa;
  border-radius: 16px 16px 0 0;
  padding: 12px;
  margin: 0 15px;

  @media only screen and (min-width: 768px) {
    padding: 30px;
  }
}

.searchBoxContent .info {
  background: rgba(26, 162, 172, 0.14);
  color: #fff;
  font-size: 14px;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 5px 30px;
  border-radius: 0 0 16px 16px;
  margin: 0 15px;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  height: 50px;
  box-sizing: border-box;
  border: 1.2px dashed rgba(26, 162, 172, 0.4);
  border-radius: 4px;

  @media only screen and (min-width: 1024px) {
    font-size: 14px;
  }
}

.searchBoxContent .info .startPercentage {
  margin-right: 10px;
}

.searchBoxContent .info img {
  margin-right: 10px;
}

.searchBoxContent .info span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #1AA2AC;
}

.searchBoxContent .buttonContainer {
  padding-top: 32px;
}

.searchBoxContent .info .link {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

.fieldLocationBLanding {
  position: relative;
  height: 68px;
  border-radius: 34px;
  width: 100%;
  border: 1px solid #cccccc99;
  max-width: 100%;
}

.fieldLocationBLanding input {
  height: 68px;
  border: 0;
  width: 100%;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: none !important;
  padding: 15px 150px 15px 20px;

  &:hover,
  &:focus {
    border-bottom-color: transparent !important;
  }

  @media only screen and (min-width: 768px) {
    padding: 15px 280px 15px 20px;
  }
}

.fieldLocationBLanding .hideSearchIconBLanding {
  display: none !important;
}

.locationIconWrapper {
  width: auto;
  height: 48px;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 12px 20px;

  @media only screen and (min-width: 768px) {
    right: 135px;
  }
}

.locationIconWrapper span {
  font-size: 16px;
  color: #296683;
  margin-left: 5px;
}

.searchIconWrapper {
  display: none;

  @media only screen and (min-width: 768px) {
    background-color: #ffcd05;
    width: auto;
    height: 48px;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    padding: 12px 20px;
  }
}

.searchIconWrapper span {
  font-size: 16px;
  color: #296683;
  margin-left: 12px;
}

.iconInMobile {
  display: none;

  @media only screen and (max-width: 767px) {
    height: 48px;
    width: 100%;
    background-color: #ffcd05;
    margin: 12px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
  }
}

.iconInMobile span {
  font-size: 14px;
  color: #296683;
  margin-left: 12px;
}

/* Channel Listing Section */
/* Channel Listing Section */

.channelsListingWrapper {
  flex-direction: column;
  padding: 110px 0 22px;

  @media only screen and (min-width: 768px) {
    padding: 30px 0;
  }
}

.channelsListingOuter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 938px;
  margin: 0 auto;
}

.channelsListingWrapper .channelsListing,
.channelsListingWrapper .channelsListing .channelOuter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0%;

  @media only screen and (max-width: 767px) {
    align-items: center;
    justify-content: center;
    flex: none;
  }
}

.channelsListingWrapper .channelsListing:first-child .channelOuter img {
  @media only screen and (max-width: 767px) {
    max-width: 186px;
  }
}

.channelsListingWrapper .channelsListing.trustBox .channelOuter {
  flex: none;
}

.channelsListingWrapper .channelsListing .channelOuter a {
  display: inline-block;
  margin: 0 10px;
}

.channelsListingWrapper .channelsListing:first-child .channelOuter a img {
  @media only screen and (max-width: 767px) {
    width: 166px;
  }
}

.channelsListingWrapper .channelsListing.trustBox {
  justify-content: center;
  display: flex;
}

.channelsListingWrapper .channelsListing p {
  font-size: 14px;
  color: #222222;
  margin: 0;
  margin-right: 5px;

  @media only screen and (max-width: 768px) {
    opacity: 0.6;
    min-width: 96px;
  }
}

.channelsListingWrapper .channelsListing {
  margin-bottom: 28px;
  flex: 0 0 100%;

  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
    flex: 1;
  }
}

/* Rent Car Section */

.rentCarWrapper {
  padding: 40px 0;

  @media only screen and (min-width: 768px) {
    padding: 100px;
  }
}

.rentCarWrapper {
  background: #f4f6fa;
  padding: 100px 0;

  @media only screen and (max-width: 767px) {
    padding: 40px 0;
  }
}

.rentCarWrapper .sectionTitle {
  margin-bottom: 40px;

  @media only screen and (max-width: 767px) {
    max-width: 267px;
    margin: 0 auto 24px;
  }
}

.rentCarWrapper .sectionTitle h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: #222222;
  margin: 0;

  @media only screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
  }
}

.rentCarWrapper .sectionTitle h2>span {
  font-size: 18px;
  line-height: 1.4;
  color: #222222;
  text-align: center;
  font-weight: 500;
  display: block;
  margin: 20px 0 0;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
    line-height: normal;
    margin: 10px 0 0;
  }
}

.rentCarWrapper .faqContainer {
  max-width: 684px;
}

.rentCarWrapper .cardContent {
  background: #ffffff;
}

.rentCarWrapper .cardContent h3 {
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}

.rentCarWrapper .btnWrapper {
  text-align: center;
}

.rentCarWrapper .btnWrapper button {
  text-align: center;

  @media only screen and (max-width: 767px) {
    max-width: 100%;
    width: 100%;
  }
}

.tabContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 700px;
  margin: 0 auto 28px;

  @media only screen and (max-width: 767px) {
    overflow: scroll;
    padding: 0 15px;
    margin: 0 auto 10px;
  }
}

.tabContainer .tab {
  font-size: 18px;
  color: #222222;
  padding-bottom: 10px;
  border-bottom: 3px solid transparent;
  white-space: nowrap;
  opacity: 0.52;
  font-weight: 400;

  &:hover {
    cursor: pointer;
    color: #1aa2ac;
    border-bottom: 3px solid #1aa2ac;
    opacity: 1;
  }

  @media only screen and (max-width: 767px) {
    margin-right: 25px;
    font-size: 14px;
  }
}

.rentCarWrapper .actionBtn {
  width: 100%;
  max-width: 185px;
  margin: 0 auto;
}

.showDriveButton {
  font-size: 16px;
  padding: 12px 32px;
  min-height: 48px;
  border-radius: 24px;
  text-transform: initial;
}

.tabContentContainer {
  padding-top: 30px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.listingCardWrapper {
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.listingCardWrapper:hover {
  text-decoration: none;
  outline: none;
}

.imgWrapper {
  position: relative;
  width: 100%;
  display: block;
  padding-bottom: 83.5%;
  height: 0;

  @media only screen and (max-width: 767px) {
    padding-bottom: 70%;
  }
}

.noImageDisplayContainer {
  background: #e7e7e7;
}

.imgContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.favoriteButton {
  width: 50px;
  height: 50px;
  min-height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none !important;
  box-shadow: none !important;
  z-index: 2;
}

.favoriteButton img {
  width: 20px;
  height: auto;
}

.imgContainer :global(.slick-dots) {
  bottom: 10px;
}

.imgContainer :global(.slick-dots li) {
  width: 6px;
  height: 6px;
  margin: 0 3px;
}

.imgContainer :global(.slick-dots li button) {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  padding: 0;
  box-shadow: 0 0 2px rgb(0, 0, 0, 0.5);
}

.imgContainer :global(.slick-dots li.slick-active button) {
  background: #FFFFFF;
}


.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #e7e7e7;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noImageWrapper img {
  width: auto !important;
}

.noImageText {
  @apply --marketplaceH6FontStyles;
  color: var(--matterColor);
  padding-bottom: 24px;

  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.listingCardWrapper {
  margin-bottom: 30px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 16px;
  }
}

.cardContent {
  background: #fff;
  padding: 12px;

  @media only screen and (max-width: 767px) {
    padding: 12px 12px 9px 12px;
  }
}

.cardContent .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardContent .heading {
  font-size: 16px;
  margin: 0 0 10px;
}

.cardContent .heading h3 {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-weight: 600;
}

.cardContent .rating {
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cardContent .rating img {
  display: block;
}

.cardContent .rating span {
  margin-left: 5px;
}

.cardContent .features {
  font-size: 14px;
  margin-bottom: 12px;
  text-transform: capitalize;
}

.tabContentContainer .listingCardWrapper .cardContent .price {
  font-size: 18px;
}

.rentCarWrapper :global(.slick-slider) {
  height: 100%;
}

.rentCarWrapper :global(.slick-list) {
  width: 100%;
  height: 100%;
}

.rentCarWrapper :global(.slick-track) {
  height: 100%;
}

.rentCarWrapper :global(.slick-slide) {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.rentCarWrapper :global(.slick-slide > div) {
  height: 100%;

}

.rentCarWrapper :global(.slick-slide > div img) {
  transition: 0.3s all cubic-bezier(0.18, 0.52, 0.49, 0.85);
}

.rentCarWrapper .listingCardWrapper:hover img {
  transform: scale(1.2);
}

.rentCarWrapper .cardContent .heading {
  font-size: 16px;
  margin: 0;
}

.rentCarWrapper .cardContent .heading h3 {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-weight: 600;
}

.rentCarWrapper .cardContent .features {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 12px;
}

.rentCarWrapper .cardContent .price {
  display: flex;
  align-items: center;
}

.rentCarWrapper .cardContent .price span {
  display: inline-flex;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #222222;
  margin-right: 7px;
}

.rentCarWrapper .cardContent .price span strong {
  font-weight: 500;
}

.rentCarWrapper .cardContent .price .hourlyPrice {
  color: #222222;
  opacity: 0.6;
}

.rentCarWrapper .cardContent .price .timeSpan {
  font-size: 14px;
  line-height: 26px;
}

/* Rental Counter Section*/

.rentalCounterWrapper {
  background-position: center right;
  background-color: #1AA2AC;
  position: relative;
  background-size: contain;
  background-position: 115% 100%;

  @media only screen and (min-width: 992px) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingPageNew/rental-counter.png');
  }
}

.rentalCounterWrapper .rentalCounterContainer {
  padding: 40px 15px;

  @media only screen and (min-width: 768px) {
    padding: 100px 0;
  }

}

.rentalCounterWrapper .rentalCounterContainer .heading {
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 25px;
  text-align: left;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }

  @media only screen and (max-width: 767px) {
    margin: 0 0 14px;
  }
}

.rentalCounterWrapper .rentalCounterContainer .points {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
}

.rentalCounterWrapper .rentalCounterContainer .points .actionBtn {
  margin-top: 20px;

  @media only screen and (max-width: 767px) {
    margin-top: 11px;
  }
}

.rentalCounterWrapper .rentalCounterContainer .points .actionBtn .showSignupButton {
  max-width: 158px;

  @media only screen and (max-width: 767px) {
    margin-top: 11px;
  }
}

.rentalCounterWrapper .rentalCounterContainer .btn {
  @media only screen and (max-width: 767px) {
    height: 40px;
    min-height: 40px;
    font-size: 14px;
    max-width: 152px;
    min-width: 152px;
  }
}

.rentalCounterWrapper .rentalCounterContainer .points .point {
  display: flex;
  align-items: center;
  flex: 100%;
  margin-bottom: 15px;

  @media only screen and (min-width: 768px) {
    flex: 50%;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 11px;
  }
}

.rentalCounterWrapper .rentalCounterContainer .points .point span {
  font-size: 14px;
  margin-left: 12px;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }
}

.rentalCounterWrapper .playVideo {
  display: none;

  @media only screen and (min-width: 992px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 200px;
    transform: translateY(-50%);
  }
}

.rentalCounterWrapper .playVideo span {
  color: #ffcd2d;
  font-size: 16px;
  margin-top: 7px;
}

.bgImage {
  display: block;
  margin-left: -15px;
  margin-right: -15px;

  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.mobilePlay {
  @media only screen and (max-width: 767px) {
    position: relative;
  }
}

.mobilePlay .playVideo {
  @media only screen and (max-width: 767px) {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin: 0 auto;
    max-width: 160px;
    text-align: center;
  }
}

.mobilePlay .playVideo img {
  @media only screen and (max-width: 767px) {
    max-width: 60px;
    margin: 0 auto 10px;
  }
}



.bgImage img {
  width: 100%;
}

/* Video Modal */

.contentModal {
  height: 100%;
}

.videoContentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    display: block;
  }
}

.video {
  width: 280px;
  background-color: var(--matterColorAnti);
  cursor: pointer;

  @media (--viewportMedium) {
    width: 500px;
    /* height: 280px; */
  }

  @media (--viewportLarge) {
    width: 313px;
    /* height: 150px; */
  }
}

.videoLogo {
  width: 100%;
}

.iframeVideo {
  width: 100%;
  height: 40%;

  @media (--viewportMedium) {
    height: 100%;
  }
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 40px 0 0 0;

  @media (--viewportMedium) {
    padding: 0px;
    flex-basis: 576px;
    min-height: 0;
    min-width: 80vw;
    height: 48vw;
    margin-top: calc(50vh - 30vw);
    border-bottom: 0px solid transparent;
  }

  @media (--viewportLarge) {
    margin-top: calc(50vh - 25vw);
    min-width: 80vw;
    height: 48vw;
  }

  @media (--viewportMLarge) {
    margin-top: calc(50vh - 20vw);
    min-width: 66vw;
    height: 40vw;
  }
}

.modalCloseButton {
  top: 0px;
  right: 0px;
  padding: 10px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    color: var(--matterColorLight);
    top: -40px;
  }

  &:hover {
    color: var(--matterColorAnti) !important;
  }
}

/* Find Car */
.findCarWrapper {
  padding: 40px 0;

  @media only screen and (min-width: 768px) {
    padding: 100px 0;
  }
}

/* 
.carContainer {
  overflow: scroll;
  width: 100%;
  @media only screen and (min-width: 768px) {
    margin: 0 auto;
    max-width: 1140px;
    overflow: hidden;
  }
} */

.carGrid {
  display: flex;
  justify-content: space-between;

  @media only screen and (min-width: 768px) {
    width: auto;
  }
}

.card {
  border-radius: 16px;
  overflow: hidden;
  color: inherit;
  text-decoration: none;
  display: block;

  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.cardThumb {
  overflow: hidden;
  position: relative;
  padding-bottom: 82.7%;

  @media only screen and (max-width: 768px) {
    padding-bottom: 100%;
  }
}

.cardThumb img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.cardContent {
  background: #f4f6fa;
  padding: 12px;

  @media only screen and (max-width: 768px) {
    padding: 10px 12px;
  }
}

.cardContent h3 {
  font-size: 18px;
  margin: 0 0 2px;
  font-weight: 600;
}

.cardContent span {
  font-size: 16px;
  display: block;
}

.findCarWrapper .cardContent {
  text-align: center;
}

.findCarWrapper .buttonWrapper {
  padding-top: 40px;
  text-align: center;

  @media only screen and (max-width: 767px) {
    padding-top: 4px;
  }
}

.findCarWrapper .buttonWrapper button {
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    min-height: 40px;
    height: 40px;
    font-size: 14px;
  }
}

.discoverBest {
  background: #f4f6fa;
  padding: 40px 0;

  @media only screen and (min-width: 768px) {
    padding: 100px 0;
  }
}

.discoverBest .heading {
  font-size: 24px;
  color: #222222;
  margin: 0 0 24px;
  text-align: center;
  font-weight: 700;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
    line-height: normal;
    margin: 0 0 40px;
  }
}

.discoverBest .heading > span {
  font-size: 18px;
  line-height: 1.4;
  color: #222222;
  text-align: center;
  font-weight: 500;
  display: block;
  margin: 20px 0 0;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
    line-height: normal;
    margin: 10px 0 0;
  }
}

.discoverBest .cardContent {
  text-align: center;
  background: #ffffff;
}

.discoverBest .buttonWrapper {
  padding-top: 40px;
  text-align: center;

  @media only screen and (max-width: 767px) {
    padding-top: 4px;
  }
}

.discoverBest .buttonWrapper button {
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    min-height: 40px;
    height: 40px;
    font-size: 14px;
  }
}

/* Love Drivelah section */

.loveDrivelahSection {
  padding: 47px 0 40px;

  @media only screen and (min-width: 768px) {
    padding: 100px 0;
  }

}

.loveDrivelahSection .sectionTitle {
  margin: 0 0 19px;

  @media only screen and (min-width: 768px) {
    margin: 0 0 80px;
  }
}

.loveDrivelahSection .heading {
  font-size: 24px;
  color: #222222;
  margin: 0 0 14px;
  text-align: center;
  font-weight: 700;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
    line-height: normal;

    margin: 0 0 12px;
  }
}

.loveDrivelahSection .desc {
  font-size: 14px;
  text-align: center;
  margin: 5px 0 0;

  @media only screen and (min-width: 768px) {
    font-size: 18px;
    line-height: normal;
    margin: 10px 0 15px;
  }
}

.loveDrivelahSection .drivelahCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 24px;

  @media only screen and (min-width: 768px) {
    margin-top: 80px;
  }
}

.loveDrivelahSection .cardThumb {
  @media only screen and (max-width: 767px) {
    padding-bottom: 59.5%;
  }
}

.loveDrivelahSection .cardContent {
  @media only screen and (max-width: 767px) {
    padding: 15px 10px;
  }
}

.loveDrivelahSection .card {
  background: #1aa2ac;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;

  @media only screen and (max-width: 767px) {
    margin-bottom: 19px;
  }
}

.loveDriveLahCard:nth-child(even) {
  margin-top: 0;

  @media only screen and (min-width: 992px) {
    margin-top: 70px;
  }
}

.loveDrivelahSection .card .cardImg {
  width: 100%;
}

.loveDrivelahSection .cardContent {
  padding: 24px 14px;
  text-align: center;
  background: #1AA2AC;

  @media only screen and (max-width: 767px) {
    padding: 17px 14px 19px;
  }
}

.loveDrivelahSection .cardContent p {
  font-size: 16px;
  font-weight: 400;
  line-height: inherit;
  margin: 0 0 8px;
  color: #ffffff;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 0 0 6px;
  }
}

.loveDrivelahSection .cardContent p strong {
  font-size: 14px;
  font-weight: bold;
  margin: 8px 0;
}

.loveDrivelahSection .cardContent .starReview {
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 767px) {
    padding-top: 6px;
  }
}

.loveDrivelahSection .cardContent .starReview span {
  margin: 0 2px;
}

/* About Drivelah section */
.aboutDrivelahSection {
  padding: 40px 0;

  @media only screen and (min-width: 768px) {
    padding: 100px 0;
  }
}

.aboutDrivelahSection .heading {
  font-size: 24px;
  color: #222222;
  margin: 0 0 24px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    font-size: 36px;
    line-height: normal;
    font-weight: 700;
    margin: 0 0 16px;
  }
}

.aboutDrivelahSection p {
  font-size: 18px;
  line-height: 1.555;
  text-align: center;
}

.aboutDrivelahSection p {
  font-size: 14px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    font-size: 18px;
  }
}

/* Featured on section */
.featuredOnSection {
  padding: 30px 50px;
  border-radius: 16px;
  margin-top: 60px;
  max-width: 1110px;
  margin: 60px auto 0;

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    padding: 20px 17px;
  }
}

.featuredOnSection .heading {
  font-size: 18px;
  margin: 0 0 20px;
  line-height: initial;
  text-align: center;
}

.featuredOnSection .flexContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.featuredOnSection .flexContent>div {
  padding: 0 30px;
  flex: 0 0 25%;
  max-width: 25%;

  @media only screen and (max-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 12px;
    margin-bottom: 15px;
  }
}

/* List Your Car section */

.listYourCarSection .rentCar {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingPageNew/rent-a-car.jpg');
  background-position: center center;
  background-size: 100% 100%;
  min-height: 320px;
  transition: 0.3s all cubic-bezier(0.18, 0.52, 0.49, 0.85);

  @media only screen and (max-width: 767px) {
    min-height: 260px;
    background-size: cover;
  }
}

.listYourCarSection .listCar {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingPageNew/list-your-car.jpg');
  background-position: center center;
  background-size: 100% 100%;
  min-height: 320px;
  transition: 0.3s all cubic-bezier(0.18, 0.52, 0.49, 0.85);

  @media only screen and (max-width: 767px) {
    min-height: 260px;
    background-size: cover;
  }
}

.listYourCarSection .contentBox {
  border-radius: 16px;
  overflow: hidden;
  padding: 40px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 16px;
    padding: 12px;
  }
}

.listYourCarSection .contentBox:hover {
  background-size: 120% 120%;
}

.listYourCarSection .whiteHeading {
  font-size: 28px;
  margin: 0 0 8px;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    line-height: normal;
  }
}

.listYourCarSection p {
  font-size: 16px;
  margin: 0 0 30px;
  line-height: 1.5;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
}

/* Get App Section */
.getAppSection {
  background: #1AA2AC;
  border-radius: 20px;
  padding: 60px 60px 0;

  @media only screen and (max-width: 767px) {
    padding: 40px 16px 0;
    border-radius: 0;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.getAppSection .sectionTitle h5 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin: 0 0 8px;

  @media only screen and (max-width: 767px) {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }
}

.getAppSection .sectionTitle p {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  margin: 0 0 48px;
  font-weight: 400;

  @media only screen and (max-width: 767px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}

.getAppSection .field {
  height: 48px;
  border-radius: 48px;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid rgba(204, 204, 204, 0.6);
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  max-width: 456px;
}

.getAppSection .inputWrapper {
  height: 100%;
  width: 100%;
  min-height: 100%;
  border: 0;
  left: 0;
  top: 0;
}

.getAppSection .inputWrapper label {
  display: none;
}

.getAppSection .field input {
  height: 100%;
  width: 100%;
  min-height: 100%;
  border: 0;
  left: 0;
  top: 0;
  padding: 5px 162px 5px 20px;
  font-size: 16px;

  @media only screen and (max-width: 767px) {
    font-size: 13px;
  }
}

.getAppSection .field button {
  position: absolute;
  right: 5px;
  top: 0;
  height: 40px;
  min-height: 40px;
  width: 141px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 48px;
  top: 50%;
  transform: translateY(-50%);
  text-transform: capitalize;

  @media only screen and (max-width: 767px) {
    font-size: 13px;
  }
}

.infoCard {
  background: rgba(41, 102, 131, 0.3);
  border: 2px dashed rgba(41, 102, 131, 0.8);
  border-radius: 6px;
  padding: 8px 24px;
  display: flex;
  align-items: flex-start;
  max-width: 360px;
  margin-bottom: 48px;

  @media only screen and (max-width: 767px) {
    padding: 8px 14px;
  }
}

.infoCard span {
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  color: #ffffff;
  padding-left: 13px;

  @media only screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 20px;
  }
}

.moreWays p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #ffffff;
  margin: 0 0 12px;
}

.moreWays .badgeListing {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;

  @media only screen and (max-width: 767px) {
    margin-left: -5px;
    margin-right: -5px;
  }
}

.moreWays .badgeListing li {
  margin: 0 13px 0 0;

  @media only screen and (max-width: 767px) {
    padding: 0 5px;
    flex: 0 0 50%;
    max-width: 50%;
    margin: 0;
  }
}

.rightImage img {
  margin-left: auto;
}

.showDesktop {
  @media only screen and (max-width: 768px) {
    display: none !important;
  }
}

.showMobile {
  display: none !important;

  @media only screen and (max-width: 768px) {
    display: block !important;
  }
}


.modalContainer {
  min-height: 100vh;
  height: 100%;
  border-radius: 16px;

  @media (--viewportMedium) {
    flex-basis: 516px;
    max-width: 516px;
    min-height: auto;
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    min-width: 10px;
  }

  @media (--viewportLarge) {
    flex-basis: 525px;
    min-height: auto;
    height: auto;
    padding: 0 32px;
  }

  @media (--viewportMLarge) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  @media only screen and (max-width: 767px) {
    min-height: 80vh;
    height: 80vh;
    border-radius: 16px;
    max-height: 80vh;
    overflow: auto;
    position: relative;
    padding: 0 16px;
  }
}

.modalContainer>button {
  @media only screen and (max-width: 767px) {
    position: absolute;
  }
}

.modalTitle {
  font-size: 28px;
  line-height: 1.35;
  padding: 28px 0;
  text-align: center;
}

.modalTitle h2 {
  margin: 0;
  color: #222222;
}

.modalTitle h2 span {
  margin: 0;
  color: #1AA2AC;
}

.modalFooter {
  border-top: 1px solid rgba(204, 204, 204, 0.6);
  padding: 16px 32px;
  margin: 0 -32px;
  text-align: center;
}

.modalFooter p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(34, 34, 34, 0.52);
  margin: 0;
}

.modalFooter p button {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #296683;
  margin: 0;
  border: 0;
  margin: 0;
  outline: none;
  padding: 0;
  display: inline;
  cursor: pointer;
}

.closeButtonText {
  display: none;
}

.closeButtonIcon {
  width: 10px;
  height: 10px;
  transform: scale(2.5);
}

.scrollLayerClassName {
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-end;
    background: transparent !important;
  }
}


.carListingTabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

/* 
.carListingTabsOuter {
  width: 180px;
  margin: 0 auto;
  position: relative;
  border: 1px solid var(--matterColorAnti);
  border-radius: 4px;
  display: flex;
  background: #FFFFFF;

  @media (--viewportMobile) {
    margin: 0;
  }
} */

.activeSwitch {
  height: 100%;
  background: #026786;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  transition: 0.3s all ease;
  border-radius: 4px;
}

.tabButtons {
  color: var(--matterColorAnti);
  font-size: 18px;
  line-height: normal;
  text-align: center;
  font-weight: 400;
  padding: 0 18px;
  cursor: pointer;
  width: auto;
  position: relative;
  padding: 11px 0;
  margin: 0 18px;
  display: inline-block;

  &:first-child {
    margin-right: -4px;
  }
}

.active {
  color: #1AA2AC;
  z-index: 1;
  font-weight: 600;
  border-bottom: 3px solid #1AA2AC;
}

.GlobalError {
  color: #ff0000;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.2;
}

.timedError {
  display: none;
}