@import '../../marketplace.css';

.root {
  margin-top: 10px;
  
  @media (--viewportLarge){
    margin-top: 20px;
  }
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.item {
  margin-top: 10px;
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);

  @media (--viewportLarge){
    font-size: 20px;
    line-height: 24px;
  }
}

.item:first-child {
  margin-top: 0;
}