@import '../../marketplace.css';

.root {
  
  @media (--viewportLarge) {
    max-width: 650px;
  }
}

.inputField {
  margin-bottom: 30px;
}

.description {
  font-size: 16px;
  line-height: 20px;
  color: var(--marketplaceColor);
}

.previewFile {
  margin-left: 0px !important;
  margin-right: 20px;
}