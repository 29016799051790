@import '../../marketplace.css';

.pageTitle {
  text-align: center;
}

.staticPageWrapper {
  /* width: calc(100% - 48px); */
  width: 100%;
  /* max-width: 1056px; */
  /* margin: 24px auto; */

  @media (--viewportMedium) {
    /* width: calc(100% - 72px); */
    /* margin: 72px auto; */
  }
}

.coverImage {
  width: 100%;
  height: calc(100vw - var(--topbarHeight));
  max-height: 305px;
  /* border-radius: 4px; */
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/heroImage.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* margin: 32px 0 40px; */

  @media (--viewportMedium) {
    height: 60vh;
    max-height: unset;
  }

  @media (--viewportMLarge) {
    height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.bottomCoverImage {
  width: 100%;
  height: 200px;
  /* border-radius: 4px; */
  object-fit: cover;
  /* margin: 32px 0 40px; */

  @media (--viewportMedium) {
    height: 300px;
  }

  @media (--viewportLarge) {
    height: calc(100vw / 4.1);
  }
}


.infinityImage {
  width: 100%;
  /* border-radius: 4px; */
  object-fit: cover;
  display: none;

  @media (--viewportMedium){
    display: block;
  }
  /* margin: 32px 0 40px; */
}

.infinityImageMobile {
  width: 280px;
  margin-left: 9px;
  margin-top: 45px;
  /* border-radius: 4px; */
  object-fit: cover;
  display: block;
  @media (--viewportMedium){
    display: none;
  }
  /* margin: 32px 0 40px; */
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-self: center;
  margin: 0 auto;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    width: calc(100% - 80px);
    margin: 72px 40px;
  }

  @media (--viewportLarge) {
    max-width: 920px;
  }

  @media (--viewportMLarge) {
    max-width: 1056px;
  }
}

.bottomWrapper {
  max-width: 100%;
  position: relative;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/background1440-min.webp');
  background-position: center;
  background-size: cover;
}

.contentContainer {
  margin: 40px 0px 0px;
  max-width: 100%;

  @media (--viewportMedium) {
    width: calc(100% - 80px);
    margin: 40px 40px 80px;
  }

  @media (--viewportLarge) {
    margin: 40px auto 100px;
    max-width: 920px;
  }

  @media (--viewportMLarge) {
    max-width: 1056px;
  }
}

.sloganWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-self: center;
  margin: 30px 24px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    width: calc(100% - 80px);
    margin: 50px 40px;
  }

  @media (--viewportLarge) {
    margin: 72px auto;
    max-width: 920px;
  }

  @media (--viewportMLarge) {
    max-width: 1056px;
  }
}


.contentSide {
  /* font-style: italic; */
  text-align: center;
  display: none;

  @media (--viewportMedium) {
    display: block;
    width: 222px;
    margin-right: 50px;
    margin-top: 8px;
    text-align: start;
  }

  @media (--viewportMLarge) {
    margin-right: 103px;
    margin-top: 8px;
    text-align: start;
  }
}

.contentSideMobile {
  display: none;
  margin: 20px 24px;
  text-align: center;

  @media (--viewportMedium) {
    display: none;
  }
}

.contentMain {
  max-width: 100%;
  margin: 0 24px;

  @media (--viewportMedium) {
    text-align: center;
    margin: unset;
    max-width: 650px;
    text-align: start;
  }
}

.sloganMain {
  color: var(--matterColorLight);
  width: 100%;
  text-align: center;
  @media (--viewportMedium) {
    text-align: start;
  }
}

.sloganMain h1 {
  font-size: 30px;
  font-weight: 900;
  margin-top: 0px;
  text-align: left;
  letter-spacing: -1px;

  @media (--viewportMedium) {
    font-size: 48px;
    text-align: center;
  }
}

.sloganMain p {
  text-align: left;
  @media (--viewportMedium) {
    font-size: 26px;
    margin-bottom: 26px;
    text-align: center;
  }
}

.subtitle {
  color:var(--marketplaceColor);
  font-size: 30px;
    font-weight: 900;
    margin-top: 75px;
    line-height: 34px;
    margin-bottom: 0px;
}

.getInTouch {
  margin-top: 45px;
}
.logo {
  width: 100px;
  margin: auto;
  display: block;
  margin-bottom: 30px;
  @media (--viewportMedium) {
    display: inline-block;
    margin-bottom: 20px;
    width: 120px;
    height: 100px;
  }
}

.slogan {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);
  font-weight: 900;
  max-width: 100%;
  margin-left: 24px;
  margin-right: 24px;

  @media (--viewportMedium) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.buttons {
  display: flex;
  margin-top: 170px;
  flex-direction: column;

  @media (--viewportMedium) {
    margin-top: 0px;
    flex-direction: row;
  }
}


.defaultButton {
  @apply --marketplaceButtonStylesPrimary;
  border-radius: 5px;
  font-weight: 700;
  color: var(--matterColorLight);
  box-sizing: border-box;
  min-height: 50px;
  height: 50px;
  padding-top: 15px;
  border-radius: 0px;

  @media (--viewportMedium) {
    border-radius: 5px;
    padding-top: 20px;
    font-size: 30px;
    width: 235px;
    height: 65px;
  }

  @media (--viewportMLarge) {
    font-size: 30px;
    width: 235px;
    height: 65px;
  }
}

.secondaryButton {
  @apply --marketplaceButtonStylesSecondary;
  color: var(--marketplaceColorDark);
  border: 0px solid transparent;
  min-height: 50px;
  height: 50px;
  border-radius: 0px;

  @media (--viewportMedium) {
    border-radius: 5px;
    font-size: 30px;
    width: 235px;
    height: 65px;
    margin-right: 24px;
  }

  @media (--viewportMLarge) {
    font-size: 30px;
    width: 235px;
    height: 65px;
    margin-right: 24px;
  }
}

.joinButton {
  width: 100%;

  @media (--viewportMedium) {
    width: 130px;
  }
}

.description {
  color: var(--marketplaceColor);
  line-height: 1.8em;

  @media (--viewportMedium) {
    line-height: normal;
  }
}

.aboutUs {
  color: var(--marketplaceColor);
  font-weight: 900;
  margin-top: 40px;
  margin-bottom: 0;
}

.aboutUsText {
  color: #4c6878;
}

.defaultTextColor {
  color: var(--marketplaceColorDark);
  font-size: 20px;
  border: 0px solid transparent;
  margin-top: 18px;
  margin-bottom: 0;
  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.contactText {
  color: var(--marketplaceColorDark);
  font-size: 20px;
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 21px;
  }
}

.background {
  background-color: var(--marketplaceColor);
  margin-top: 50px;
}

.driveMateImage{
  max-width: 250px;
}
.driveMateImage img{
  max-width: 100%;
  width: 100%;
}
