@import '../../marketplace.css';

.root {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 0;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  font-size: 22px;
  color: #026786;
  margin-top: 0;
  margin-bottom: 13px;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.rules {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.rule {
  color: var(--marketplaceColorDark);
  font-weight: normal;
}

.index {
  display: inline-block;
  width: 25px;
}

.subTitle {
  font-size: 14px;
  font-weight: 500;
  color: var(--marketplaceColor);
  margin-top: 0px;
  margin-bottom: 24px;
  line-height: 18px;
}

.noRules {
  font-size: 16px;
  color: var(--matterColorAnti);
}

.itemWrapper{
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  width: 100%;
  font-size: 20px;
  color: #4A4A4A;
  display: flex;
  align-items: center;
}
.itemWrapper strong{
  color: #B2B2B2;
  display: block;
  font-weight: 500;
  font-size: 10px;
  line-height: normal;
  mask-border: 5px;
  letter-spacing: normal;
}
.itemWrapper .icon{
  width: 41px;
  min-width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemWrapper .icon img {
  max-width: 22px;
}
.itemWrapper .labelWrapper{
  padding: 5px 8px 5px 0;
}
.item {
  display: inline-flex;
  align-items: center;
  color: var(--marketplaceColorDark);
  flex: 1;
  max-width: 100%;
  margin-bottom: 5px;
  margin-right: 10px;
  padding: 0 2px;
  @media (--viewportMedium) {
    padding: 4px 0;
  }
}