@import '../../marketplace.css';

.root {
  padding: 40px 20px;

  @media (--viewportXLarge) {
    padding: 80px 0;
  }
}

.title {
  font-size: 32px;
  line-height: 40px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColorDark);

  @media (--viewportLarge) {
    text-align: center;
    font-size: 48px;
    line-height: 48px;
  }
}

.sliderWrapper {
  margin-top: 20px;
  @media (--viewportLarge) {
    width: 100%;
    padding: 0 50px;
    display: none;
  }
}

.slider {
  height: 100%;
  display: block;
  margin-top: 20px;

  /* @media (--viewportLarge) {
    max-width: calc(calc(270px * 3) + calc(30px * 3));
    margin: 0 auto;
  }

  @media (--viewportMLarge) {
    max-width: calc(calc(270px * 4) + calc(30px * 4));
  }

   @media (--viewportXLarge) {
    max-width: calc(calc(388px * 4) + calc(36px * 4));
  } */
}

.root :global(.slick-list) {
  padding: 0 30px 0 0;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.root :global(.slick-slide) {
  /* margin-left: 30px;

  @media (--viewportLarge) {
    &:first-child {
      margin-left: 40px;
    }
  }

  @media (--viewportXLarge) {
    margin-left: 36px;
  } */
}

.root :global(.slick-disabled) {
  opacity: 0.5;
}

.root :global(.slick-prev) {
  left: -25px;
  height: 50px;
  width: 30px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/prev.png');
  &::before {
    content: '';
  }
}

.root :global(.slick-next) {
  height: 50px;
  width: 30px;
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/next.png');
  &::before {
    content: '';
  }
}

.root :global(.slick-dots) {
  & > li {
    width: 8px;
    height: 8px;
    opacity: 0.4;
    border-radius: 50%;
  }

  & > li > button {
    width: 8px;
    height: 8px;
    background-color: var(--marketplaceColor);
    border-radius: 50%;
  }
}

.root :global(.slick-active) {
  opacity: 1 !important;
}

.listingCardWrapper {
  padding: 0 30px;

  &:first-child {
    padding-left: 0;
  }

  @media (--viewportLarge) {
    &:first-child {
      padding: 0 30px;
    }
  }
}

.viewAllCar {
  @apply --marketplaceButtonStylesSecondary;
  margin-top: 50px;
  min-height: 40px;
  border-radius: 6px;
  padding: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  text-transform: none;

  @media (--viewportLarge) {
    max-width: 335px;
    min-height: 60px;
    margin: 50px auto 0;
  }
}

.listings {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1600px;
    margin: 30px auto 0;

    & .listing {
      flex-basis: calc((100% - 70px) / 3);
      margin-right: 35px;
      margin-bottom: 20px;
    }

    & .listing:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (--viewportXLarge) {
    margin-top: 60px;
  }
}
