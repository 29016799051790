@import '../../marketplace.css';

.root {
  max-width: 519px;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
}
.sliderWrapper .infoItem {
  font-size: 16px;
  line-height: 1.375;
  color: #4a4a4a;
  max-width: 860px;
  padding: 0 30px;
  margin: 0 auto;
  display: block !important;
  @media (--viewportMobile) {
    font-size: 12px;
    padding: 0;
  }
}
.sliderWrapper {
  margin-top: 0;
  padding: 14px 20px;
  box-shadow: 0px 0px 6px #0000000f;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  left: 0;
  bottom: 75px;
  background: #eef7f7;
  width: 100%;
  z-index: 98;
  @media (--viewportMobile) {
    padding: 18px 27px;
    bottom: 58px;
  }
}
.sliderLeft .infoIcon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00a3ad;
  font-size: 16px;
  line-height: normal;
  color: #ffffff;
  max-width: 24px;
}
.sliderLeft {
  max-width: 24px;
  flex: 1;
}
.sliderRight {
  flex: 1;
  padding-left: 0;
  width: 100%;
  position: relative;
}

.customSlideNumbers {
  padding-top: 10px;
  text-align: center;
  font-size: 10px;
  color: #b2b2b2;
  line-height: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.sliderNext,
.sliderPrev {
  position: absolute;
  width: 16px;
  height: 16px;
  left: auto;
  bottom: 0;
  right: auto;
  margin: 0 auto;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}
.sliderNext svg,
.sliderPrev svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  display: block;
}
.sliderNext {
  right: 0;
  @media (--viewportMobile) {
    right: -20px;
  }
}
.sliderNext svg {
  transform: rotate(-90deg);
}
.sliderPrev {
  left: 0;
  @media (--viewportMobile) {
    left: -20px;
  }
}
.sliderPrev svg {
  transform: rotate(90deg);
}
