@import '../../marketplace.css';

.pageTitle {
  text-align: center;
}

.staticPageWrapper {
  /* width: calc(100% - 48px); */
  width: 100%;
  background-color: var(--matterColorLight);
  /* max-width: 1056px; */
  /* margin: 24px auto; */

  @media (--viewportMedium) {
    /* width: calc(100% - 72px); */
    /* margin: 72px auto; */
  }
}

.title {
  font-size: 36px;
  line-height: 1.03;
  text-align: center;
  color: var(--matterColorRPTitle);
  font-weight: 900;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    font-size: 48px;
    line-height: 1.08;
    text-align: left;
    white-space: nowrap;
  }
}

.coverImage {
  width: 100%;
  /* height: calc(100vw - var(--topbarHeight)); */
  /* border-radius: 4px; */
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/refer-a-host-page-mobile.png');
  height: 280px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* margin: 32px 0 40px; */

  @media (--viewportSmall) {
    background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/refer-a-host-page-desktop.png');
    max-height: 470px;
    height: 29vw;
    background-size: cover;
  }

  @media (--viewportLarge) {
    height: 400px;
  }
}

.contentWrapper, .shareYourLinkContentWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-self: center;
  margin: 0 auto;
  justify-content: center;;
  flex-direction: column;
  height: 100%;

  @media (--viewportSmall) {
    margin-top: 10px;
  }

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    width: calc(100% - 80px);
    justify-content: left;
    flex-direction: row;
    margin-top: 24.5px;
  }

  @media (--viewportLarge) {
    max-width: 920px;
  }

  @media (--viewportMLarge) {
    max-width: 1056px;
  }
}

.contentMain, .shareYourLinkContentMain {
  width: 100%;
  align-self: start;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;

  min-width: 0;

  @media (--viewportMedium) {
    text-align: center;
    max-width: 650px;
    width: 506px;
    text-align: start;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0px;
    margin-bottom: 60px;
  }
}

.content {
  font-size: 16px;
  text-align: center;
  font-weight: normal;
  line-height: 1.19;
  color: var(--matterColorRPContent);

  @media (--viewportMedium) {
    width: 100%;
    line-height: 1.2;
    text-align: left;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    padding: 0;
  }
}

.content .mainInfo {
  min-height: 100px;

  @media (--viewportMedium) {
    height: auto;
  }
}

.termsAndConditions {
  display: block;
  height: 49.8px;

  @media (--viewportMedium) {
    height: auto;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    height: 60.2px;
    color: var(--matterColorRPTitle);
  }
}

/* Getting */
.creditsContentSide, .shareYourLinkContentSide {
  /* font-style: italic; */
  text-align: center;
  padding: 0 20px;
  margin-left: 0;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    margin-left: 64.5px;
    display: block;
    width: 410px;
    height: 220px;
    margin-top: 8px;
    max-width: 381px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0;
  }

  @media (--viewportMLarge) {
    margin-top: 8px;
  }
}

.innerContentSide {
  text-align: center;
  @media (--viewportMedium) {
    display: block;
    text-align: left;
    padding: 30px 0 0 50px;
  }
}

.grey {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    display: block;
    background-color: var(--matterColorRPGrey);
  }
}

.drivelahCreditsIcon {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/drivelah-credits-icon.png');
  width: 88px;
  height: 70px;
  text-align: start;
  margin-bottom: 9.5px;

  @media (--viewportMedium) {
    margin-bottom: 6.5px;
  }
  @media (--viewportMobile) {
    margin: 0 auto 20px;
  }
}

.creditsTitle, .suggestionTitle {
  font-size: 30px;
  font-weight: 900;
  color: var(--matterColorRPTitle);
  line-height: 1.13;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.creditsContent, .suggestionContent {
  text-align: center;;
  color: #066887;
  font-size: 20px;
  line-height: 1.2;
  font-size: 16px;

  @media (--viewportMedium) {
    text-align: left;
    font-size: 20px;
  }
}

/* Share your link input */


.shareYourLink {
  /* margin-top: 80px;
  height: 270px; */
  background-color: var(--matterColorRPGrey);
  align-items: center;
  /* padding-top: 34.5px; */
  display: flex;
  width: 100%;
  align-self: center;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 34.5px;
  padding-bottom: 44.2px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    height: 270px;
    margin-top: 26px 0 0;
    padding-top: 54.5px;
    padding-bottom: 60px;
    flex-direction: row;
    justify-content: center;
  }

}

.shareYourLinkContentWrapper {
  margin-top: 0;
  /* height: 100%; */
  justify-content: space-evenly;
  height: auto;
  align-self: baseline;

  @media (--viewportMedium) {
    justify-content: left;
    margin-top: 0px;
  }
}

.suggestionTitle {
  text-align: left;
}

.shareYourLinkTitle {
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: var(--matterColorRPTitle);
  margin-bottom: 15px;
}

/* Copy Referral Code Section */

.copyReferralCodeSection {
  display: flex;
}

.copyReferralCodeSection {
  border-bottom: 3px solid var(--matterColorRPTitle);
}

.copyReferralCodeSection .copyReferralCode {
  border-bottom: 0px;
  color: var(--matterColorRPCopyReferralCode);
  white-space: nowrap;
  padding-top: 5px;
  padding-bottom: 8px;
  overflow: auto;
  margin-right: 5px;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }

  &:disabled {
    color: var(--matterColorRPCopyReferralCode);
  }

  @media (--viewportMedium) {
    padding-top: 4px;
    padding-bottom: 10px;
  }
}

.copyReferralCodeSection .buttonWrapper {
  width: 80px;
}

.copyReferralCodeSection .copyButton {
  min-height: 30px;
  min-width: 80px;
  border-radius: 6px;
  color: var(--matterColorDark);
  background-color: var(--matterColorRPButton);
  font-size: 19px;

  &::first-letter {
    text-transform: uppercase;
  }

  &:visited, &:focus {
    background-color: var(--marketplaceButtonColor);
  }
}

.copyError {
  color: var(--failColor);
}

/* Suggestions section */

.suggestionContent {
  text-align: left;
  font-size: 20px;
}


.shareYourLinkContentMain {
  @media (--viewportMedium) {
    margin-bottom: 0px;
  }
}

.shareYourLinkContentSide {
  margin-top: 0;
  text-align: left;
  height: auto;
  margin-bottom: 0;
}

.shareYourLinkContentSide > .content {
  height: 134px;

  @media (--viewportMedium) {
    height: inherit;
    margin-bottom: 10px;
  }
}

.suggestionsIcon { 
  display: inline-block;
  align-self: start;
  width: 105.4px;
  height: 30px;
  border-radius: 6px;
  background-color: var(--matterColorLight);
  text-align: center;
}

.gmail {
  font-size: 16px;
  font-weight: bold;;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #555555; 
}

/* How to get Drivelah credits */
.howToSection {
  padding-top: 31.7px;
  background-color: var(--matterColorRPTitle);
  @media (--viewportMedium) {
    padding-top: 49.7px;
    height: 400px;
  }
}

.howToTitle {
  font-size: 40px;
  font-style: normal;
  line-height: 1.05;
  font-weight: 900;
  text-align: center;
  color: var(--matterColorLight);  
}

.howToWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding-bottom: 50px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 25px;
    flex-direction: row;
  }
}

.step {
  margin-top: 30px;
  width: 320.3px;

  @media (--viewportMedium) {
    margin-top: 0;
    text-align: center;
  }
}

.stepLogo {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 21.4px;
  height: 88px;

  @media (--viewportMedium) {
    margin-bottom: 19.4px;
  }
}

.stepTitle {
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: var(--matterColorLight);
}

.stepContent {
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  color: var(--matterColorLight);
}

.logo {
  display: inline-block;
}

.logo > img {
  width: 40px; 
  margin-right: 10px;

  @media (--viewportMedium) {
    width: 30px;
  }
}

.shareIcons {
  margin-top: 15px;
}

/* Google Contacts */

.googleContactsClassName {
  height: 80%;
  overflow: auto;
}

.emailsList {
  max-height: 65vh;  
  max-width: 90vw;
  padding: 0 10px;
  overflow: auto;
  word-break: break-word;

  @media (--viewportMedium) {
    max-height: 60vh;  
  }
}

.googleContactsItem {
  margin-bottom: 10px;
}

.staticPageWrapper .googleContactsContainer {
  padding-bottom: 20px;
}

.googleContactsTitle {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 36px;
  text-align: center;
  font-weight: bold;
  color: var(--marketplaceColor);
}

.googleContactsSubTitle {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--marketplaceColor);
}

.googleContactsButtonWrapper {
  position: absolute;
  bottom: 0;
  padding-bottom: 15vh;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  background-color: var(--matterColorLight);
  padding-top: 20px;

  @media (--viewportMedium) {
    bottom: 0px;
    padding-bottom: 10px;
  }
}

.googleContactsBottomSection {
  position: relative;
}

.googleContactsSendInvite {
  width: 40%;
  &:first-letter {
    text-transform: uppercase;
  }
}

.googleContactsSelectAll {
  font-size: 20px;
}

.signUpButton {
  min-width: 200px;
  max-width: 200px;
  border-radius: 5px;
  text-transform: capitalize;
  font-size: 20px;
  min-height: 50px;
}