@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.moreDesc {
  font-size: 20px;
  font-style: italic;
  color: var(--matterColorAnti);
  margin-top: 35px;
  margin-bottom: 15px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  background-color: #00a3ad;
  color: #ffffff;
  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
.customToggleInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 0;
  @media (max-width: 767px) {
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    margin-top: -1px;
    margin: 15px 0;
  }
}
.customToggleInput label {
  font-size: 20px;
  line-height: 22px;
  font-weight: 300;
  padding: 0;
  @media (max-width: 767px) {
    flex: 1;
  }
}

.toggleLabels {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex: 1;
    max-width: 80%;
  }
}
.toggleInput {
  @media (max-width: 767px) {
    flex: 1;
    max-width: 20%;
    display: flex;
    justify-content: flex-end;
  }
}
.customClass {
  min-width: 16px;
  max-width: 16px;
}

.column {
  padding-left: 20px;
  padding-right: 20px;
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column4 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column3 {
  flex: 0 0 25%;
  max-width: 25%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.displayInlineContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  flex-wrap: wrap;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.newInput {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0 0 20px;
}
.newInput input,
.newInput select {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  width: 100%;
  margin: 0;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}

.newInput select {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 7px 45px 7px 15px;
}

.newInput textarea {
  height: 120px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 15px 15px;
  font-size: 20px;
  margin: 0;
  resize: none;
  box-sizing: border-box;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}
.newInput label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
  @media (max-width: 767px) {
    margin-bottom: 5px;
  }
}

.customInputLabel {
  font-weight: 300;
  color: #4a4a4a;
  padding-top: 6px;
  padding-bottom: 2px;
  font-size: 16px;
  line-height: 24px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  background-color: #00a3ad;
  color: #ffffff;
  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.stickyButtons {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  @media (max-width: 767px) {
    height: auto;
    box-shadow: none;
  }
}
.stickyButtons .stickButtonsContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  @media (--viewportMedium) {
    flex-direction: row;
  }
  @media (max-width: 767px) {
    padding: 6px 20px;
    box-shadow: 0px -3px 6px rgb(0 0 0 / 14%);
  }
}
.stickyButtons button {
  height: 60px;
  border-radius: 4px;
  max-width: 301px;
  margin-left: auto;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    height: 52px;
  }
}
.stickButtonsDescription {
  flex: 0 0 70%;
  max-width: 70%;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: left;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 15px 20px;
    background: #eff7f8;
    display: none;
  }
}

.stickButtonsDescription .descIcon {
  margin-right: 10px;
  width: 80px;
  height: auto;
  min-width: 80px;
  position: relative;
  @media (--viewportMobile){
    width: 42px;
    min-width: 42px;
  }
}

.stickButtonsDescription .descIcon .needle {
  width: 40px;
  position: absolute;
  bottom: -20px;
  left: 0;
  transform-origin: 100%;
  @media (--viewportMobile){
    width: 20px;
    bottom: -12px;
  }
}

.stickButtonsDescription .descIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.stickButtonsDescription p {
  color: #4a4a4a;
  max-width: 600px;
  line-height: normal;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }
}
.stickButton {
  text-align: right;
  flex: 0 0 30%;
  max-width: 30%;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 6px 20px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  }
}

.infoText {
  padding: 8px 0 5px;
  line-height: normal;
  display: flex;
  align-items: flex-start;
  flex: 0 0 100%;
  max-width: 100%;
}
.infoText .infoIcon {
  display: block;
  margin-right: 5px;
  margin-top: 0;
  max-width: 14px;
  flex: 1;
}
.infoText img {
  display: block;
}
.infoText p {
  flex: 1;
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  color: #4a4a4a;
  margin: 0;
}

.inputWithPostFix {
  display: flex;
  align-items: center;
}
.inputWithPostFix .inputPostFix {
  font-size: 20px;
  line-height: 1.2;
  margin-right: 0;
  margin-left: 10px;
  align-self: center;
  @media (max-width: 767px) {
    flex: 1;
    max-width: 10px;
  }
}
.inputWithPostFix .newInput {
  margin: 0;
  @media (max-width: 767px) {
    flex: 1;
    max-width: 100%;
  }
}
.inputWrapper {
  margin-bottom: 20px;
}

.inputClass{
  max-width: 100px;
}

.errorClassName{
  position: relative !important;
  margin-bottom: 5px !important;
}