@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  margin-bottom: 24px;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.city,
.country {
  flex-shrink: 0;
  margin-bottom: 24px;
  margin-top: 10px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
    margin-top: 0px;
  }
}

.building {
  flex-basis: 60%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 6;
  margin-top: 10px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
    margin-top: 0px;
  }
}

.floor {
  flex-basis: 40%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 1;
  margin-top: 10px;
  @media (--viewportMedium) {
    margin-left: 4%;
    margin-bottom: 32px;
    margin-top: 0px;
  }
}

.streetName {
  position: relative;
  flex-basis: 60%;
  flex-shrink: 1;
  margin-top: 10px;

  /* margin-bottom: 24px; */
  flex-grow: 30;
  @media (--viewportMedium) {
    /* margin-bottom: 32px; */
    margin-top: 0px;
    flex-basis: 70%;
  }
  max-width: 100% !important;
}
.newInput {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0 0 20px;
}
.newInput input {
  height: 54px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 20px;
  margin: 0;
  font-weight: 500;
  &:hover,
  &:focus {
    border: 1px solid #e6e6e6;
  }
}
.newInput label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: #4a4a4a;
  @media (max-width: 767px) {
    margin-bottom: 5px;
  }
}

.blockNo {
  flex-basis: 40%;
  flex-shrink: 1;
  margin-bottom: 24px;
  flex-grow: 1;
  margin-top: 10px;
  @media (--viewportMedium) {
    margin-right: 4%;
    flex-basis: 20%;
    margin-bottom: 32px;
    margin-top: 0px;
  }
  @media (--viewportLarge) {
  }
}
.column {
  padding-left: 20px;
  padding-right: 20px;
}
.column12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.column6 {
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column4 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column3 {
  flex: 0 0 25%;
  max-width: 25%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.displayInlineContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  flex-wrap: wrap;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  background-color: #00a3ad;
  color: #ffffff;
  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.stickyButtons {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  @media (max-width: 767px) {
    height: auto;
    box-shadow: none;
  }
}
.stickyButtons .stickButtonsContainer {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}
.stickyButtons button {
  height: 60px;
  border-radius: 4px;
  max-width: 301px;
  margin-left: auto;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    height: 52px;
  }
}

.stickButtonsDescription p {
  color: #4a4a4a;
  max-width: 600px;
  line-height: normal;
}
.stickButton {
  text-align: right;
  flex: 0 0 30%;
  max-width: 30%;
  @media (max-width: 767px) {
    box-shadow: none;
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 6px 20px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.14);
  }
}

.validationClassName{
  position: relative !important;
}