@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;
}

.root {
  padding: 0;
}

/* Dummy Topbar */

.topbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  align-items: center;
  justify-content: center;

  /* fill */
  background-color: var(--marketplaceColor);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    height: var(--topbarHeightDesktop);
    justify-content: center;
  }
}

.home {
  display: flex;
  padding: calc((var(--topbarHeight) - var(--CheckoutPage_logoHeight)) / 2) 24px;

  @media (--viewportLarge) {
    padding: calc((var(--topbarHeightDesktop) - var(--CheckoutPage_logoHeightDesktop)) / 2) 36px;
  }
}

.logoMobile {
  display: block;

  & path {
    fill: var(--marketplaceColor);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.logoDesktop {
  display: none;
  width: auto;
  height: 27px;

  @media (--viewportLarge) {
    display: block;
  }
}

/* /Dummy Topbar */

/**
 * Content: mobile flow vs desktop 2 columns
 * desktop has reduced version of bookListingContainer (incl. title and Stripe paymentForm).
 * priceBreakdown is inside separate column (detailsContainerDesktop)
 */
.contentContainer {
  max-width: 1230px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 150px;
  @media (--viewportMobile) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.contentRow {
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  @media (--viewportMobile) {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.addonsPageTitles {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 33px 15px 0;
  margin-bottom: 30px;
  @media (--viewportMobile) {
    padding: 30px 20px 0;
    margin-bottom: 20px;
  }
}
.addonsPageTitles .addonsBreadcrumbs {
  color: #4a4a4a;
  text-transform: uppercase;
  font-size: 14px;
  line-height: normal;
  font-weight: 900;
  letter-spacing: 2.8px;
  @media (--viewportMobile) {
    font-size: 12px;
    margin-bottom: 10px;
  }
}
.addonsPageTitles .stepTitle {
  color: #026786;
  font-size: 36px;
  line-height: 1.5555;
  font-weight: 900;
  @media (--viewportMobile) {
    font-size: 28px;
    line-height: normal;
    margin-bottom: 10px;
  }
}
.addonsPageTitles p {
  color: #b2b2b2;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 300;
  max-width: 750px;
  margin: 0;
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 1.25;
  }
}

.addonsSectionLeft {
  flex: 0 0 60%;
  max-width: 60%;
  padding-left: 15px;
  padding-right: 15px;
  @media (--viewportMobile) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
.addonsSectionRight {
  flex: 0 0 40%;
  max-width: 40%;
  padding-left: 15px;
  padding-right: 15px;
  @media (--viewportMobile) {
    flex: 0 0 100%;
    max-width: 100%;
    background: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    margin-top: -30px;
  }
}

.bookingBreakdown {
  @media (--viewportLarge) {
    margin: 0 48px;
  }
}

.detailsContainerMain,
.detailsContainerMainFirst {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    border: 1px solid var(--borderColorGray);
    border-radius: 4px;
    margin: 30px 0 0px;
  }
}
.detailsContainerMainFirst {
  margin: 0;
}
.detailsContainerMain .bookingBreakdown,
.detailsContainerMainFirst .bookingBreakdown {
  margin: 0;
}
.containerTitle {
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.14px;
  margin: 0;
  color: #026786;
  font-weight: 700;
  margin-bottom: 20px;

  @media (--viewportMobile) {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.bookListingContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-right: 0;
  }
}

.loading {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bookListingContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-right: 0;
  }
}

.paymentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    /* margin-top: 2px; */
    margin-top: 0;
  }

  @media (--viewportLarge) {
    margin-top: 0;
    padding: 0;
  }

  @media (--viewportMobile) {
    padding: 0;
  }
}

.orderError,
.notFoundError {
  margin: 11px 0 12px 0;

  @media (--viewportMedium) {
    margin: 10px 0 12px 0;
  }
}
.orderError {
  color: var(--failColor);
}
.notFoundError {
  color: var(--attentionColor);
}

.paymentForm {
  flex-grow: 1;
}

.linkContainer {
  border-top: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
  border-left: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
  padding: 20px;

  @media (--viewportMedium) {
    border-top: none;
  }
}

.linkText {
  text-decoration: underline;
  font-size: 18px;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 24px;
  }
}

.textContainer {
  border-bottom: 1px solid #E6E6E6;
  border-left: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
  padding: 20px;
  margin: 0 0 30px;
}

.text {
  font-size: 16px;
  line-height: 20px;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 24px;
  }
}

.arrowBack {
  color: #4a4a4a;
  text-transform: uppercase;
  font-size: 14px;
  line-height: normal;
  font-weight: 900;
  letter-spacing: 2.8px;
  display: flex;
  align-items: center;
}

.arrowBackText {
  display: block;
  margin-right: 5px;
  margin-top: -3px;
}
