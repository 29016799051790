@import '../../marketplace.css';

.popupSize {
  padding: 0 10px 7px 10px;
}

.times {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: column;
    width: 260px;
    margin: 0 auto;
  }
}

.timeSelect {
  width: 100%;
  max-width: calc(50% - 10px);

  @media (--viewportMedium) {
    max-width: 100%;
    margin-bottom: 12px;
  }
}
