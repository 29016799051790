@import '../../marketplace.css';

:root {
  
}

.root {

}

.goLogo {
  display: block;
  height: 54px;
  width: 125px;

  @media (--viewportLarge) {
    height: 125px;
    width: 280px;
  }
}

.sectionBenefitsHosting {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingPageGo/shutterstock_2@2x.jpg');
  background-size: 100% auto;
  background-position: top;
  margin-top: 17px;

  @media (--viewportLarge) {
    background-size: 100% auto;
    margin-top: 50px;

  }
}

.sectionBenefitsHostingContentWrapper {
  margin-top: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;

  @media (--viewportMedium) {
    margin-top: 400px;
  }

  @media (--viewportLarge) {
    max-width: 1056px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 714px;
  }

  @media (--viewportXLarge) {
    margin-top: 945px;
  }

  @media (--viewportXXLarge){
    margin-top: 1260px;
  }
}

.title {
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightBold);
}

.description {
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);
}

.sectionBenefitsHostingContentWrapper > .title {
  font-size: 30px;
  line-height: 36px;
  margin-top: 11px;

  @media (--viewportLarge){
    font-size: 40px;
    line-height: 48px;
  }
}

.benefites {
  padding: 0 30px 0;

  @media (--viewportLarge){
    padding: 0;
    display: flex;
    flex-direction: row;
  }
}

.benefite {
  margin-top: 20px;

  @media (--viewportLarge){
    margin-top: 72px;
    max-width: 316px;
    margin-right: 52px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.benefite > .title {
  font-size: 20;
  line-height: 24px;
  text-align: center;

  @media (--viewportLarge){
    font-size: 26px;
    line-height: 31px;
  }
}

.benefite > .description {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-top: 5px;

  @media (--viewportLarge){
    font-size: 22px;
    line-height: 29px;
    margin-top: 23px;
  }
}

.button {
  @apply --marketplaceButtonStylesPrimary;
  background-color: var(--marketplaceColorDark);
  padding: 13px 38px;
  min-height: 50px;
  max-width: 315px;
  border-radius: 6px;
  text-transform: unset !important;
  margin-top: 30px;

  @media (--viewportMedium) {
    max-width: 418px;

    margin-right: auto;
    margin-left: auto;
    margin-top: 41px;
  }
}

.sectionHowDrivelahGoWork {

}

.sectionHowDrivelahGoWorkContentWrapper {
  padding: 0 30px 36px;

  @media (--viewportLarge){
    max-width: 1056px;
    padding: 100px 0 0;
    margin-right: auto;
    margin-left: auto;
  }
}

.sectionHowDrivelahGoWorkContentWrapper > .title {
  font-size: 24px;
  line-height: 29px;

  @media (--viewportLarge){
    font-size: 40px;
    line-height: 48px;
  }
}

.sectionHowDrivelahGoWorkContentWrapper > .description {
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;

  @media (--viewportLarge){
    font-size: 22px;
    line-height: 27px;
  }
}

.steps {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;

  @media (--viewportLarge){
    margin-top: 60px;
    justify-content: unset;
  }
}

.step {
  flex-basis: calc(50% - 27.5px);
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  

  @media (--viewportLarge) {
    flex-basis: calc(25% - 52.5px);
    margin-right: 70px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.step > .description {
  margin-top: 15px;
  text-align: center;
  font-weight: var(--fontWeightSemiBold);
}

.step:nth-child(n+3) {
  margin-top: 60px;

  @media (--viewportLarge){
    margin-top: 0;
  }
}

.sectionInstallation {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingPageGo/shutterstock@2x.jpg');
  background-size: 100% auto;
  background-position: top;

}

.sectionInstallationContentWrapper {
  margin-top: 312px;
  padding: 0 30px 47px;

  @media (--viewportMedium) {
    margin-top: 535px;
  }

  @media (--viewportLarge) {
    margin-top: 1116px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1056px;
    padding-bottom: 85px;
  }

  @media (--viewportXLarge) {
    margin-top: 1365px;
  }

  @media (--viewportXXLarge) {
    margin-top: 1815px;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportLarge){
    justify-content: center;
  }
}

.titleWrapper > .title {
  font-size: 24px;
  line-height: 29px;
  font-weight: var(--fontWeightBold);
  margin-left: 12px;

  @media (--viewportLarge) {
    font-size: 40px;
    line-height: 48px;
    margin-left: 30px;
  }
}

.sectionInstallationContentWrapper > .description {
  margin-top: 20px;
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightMedium);

  @media (--viewportLarge){
    font-size: 22px;
    line-height: 27px;
  }
}

.items {
  margin-top: 20px;

  @media (--viewportLarge){
    display: flex;
    flex-direction: row;
    margin-top: 40px;
  } 
}

.item {
  display: flex;
  flex-direction: row;

  @media (--viewportLarge){
    flex-direction: column;
    align-items: center;

    flex-basis: calc(25% - 15px);
  }
}

.item > .index {
  font-size: 16px;
  line-height: 19px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightMedium);

  @media (--viewportLarge){
    font-size: 50px;
    line-height: 60px;
    font-weight: var(--fontWeightBold);
  }
}

.item > .description {
  margin-left: 16px;
  font-size: 16px;
  line-height: 19px;

  @media (--viewportLarge){
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 15px;
  }
}

.sectionPricingPlans {
  background-color: var(--marketplaceColorDark);
}

.sectionPricingPlansContentWrapper {
  padding: 30px 30px 55px;

  @media (--viewportLarge){
    max-width: 1056px;
    margin-right: auto;
    margin-left: auto;
    padding: 90px 0 106px;
  }
}

.sectionPricingPlansContentWrapper > .title {
  font-size: 24px;
  line-height: 29px;
  font-weight: var(--fontWeightBold);
  color: var(--matterColorLight);

  @media (--viewportLarge){
    font-size: 40px;
    line-height: 48px;
  }
}

.sectionPricingPlansContentWrapper > .description {
  font-size: 16px;
  line-height: 19px;
  font-size: var(--fontWeightMedium);
  margin-top: 20px;
  color: var(--matterColorLight);

  @media (--viewportLarge){
    font-size: 22px;
    line-height: 27px;
    margin-top: 27px;
  }
}

.highlight {
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);
  margin-top: 20px;

  @media (--viewportLarge){
    font-size: 24px;
    line-height: 29px;
    margin-top: 55px;
  }
}

.outlineButton {
  @apply --marketplaceButtonStylesPrimary;
  padding: 13px 38px;
  min-height: 50px;
  max-width: 315px;
  border-radius: 6px;
  margin-top: 20px;
  text-transform: unset !important;
  background-color: var(--marketplaceColorDark);
  border: solid 1px var(--matterColorLight);
  color: var(--matterColorLight);

  @media (--viewportLarge){
    margin-top: 81px;
  }
}

.sectionImpact {

}

.sectionImpactContentWrapper {
  padding: 30px 30px 40px;

  @media (--viewportLarge) {
    padding: 90px 0 115px;
    max-width: 1056px;
    margin-left: auto;
    margin-right: auto;
  }
}

.sectionImpactContentWrapper > .title {
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 29px;
  color: var(--marketplaceColorDark);

  @media (--viewportLarge){
    font-size: 40px;
    line-height: 48px;
  }
}

.answer {
  font-size: 24px;
  line-height: 29px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);
  margin-top: 5px;

  @media (--viewportLarge){
    font-size: 30px;
    line-height: 36px;

  }
}

.sectionImpactContentWrapper > .description {
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
  margin-top: 15px;

  @media (--viewportLarge){
    font-size: 22px;
    line-height: 27px;
    margin-top: 30px;
  }
}