@import '../../marketplace.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  background-color: var(--matterColorLight);
  color: var(--marketplaceColorDark);
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  text-transform: none;

  &:hover, &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);
  text-transform: none;

  &:hover {
    background-color: var(--marketplaceColorDark);
    color: var(--matterColorLight);
    border: 1px solid var(--marketplaceColorDark);
  }

  &:focus {
    background-color: var(--matterColorLight);
    color: var(--marketplaceColorDark);
    border: 1px solid var(--marketplaceColorDark);
  }
}

.twoColumns {
  @media screen and (min-width: 375px) {
    columns: 2;
  }
}

.threeColumns {
  @media screen and (min-width: 375px) {
    columns: 2;
  }

  @media (--viewportMedium) {
    columns: 3;
  }
}
.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 20px;
  padding-bottom: 30px;
  border: none;
}

.list {
  margin: 0;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.hintText {
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: var(--matterColorNear);
}
