@import '../../marketplace.css';

.root {
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  min-width: 100%;
  padding-bottom: 20px;

  @media (--viewportMedium) {
    max-width: calc(100vw - 48px);
    padding-left: 0;
    padding-right: 0;
  }

  @media (--viewportLarge) {
    width: calc(100vw - 72px);
    padding-left: 0;
    padding-right: 0;
  }
}

.root :global(.slick-next), 
.root :global(.slick-prev) {
  background-color: var(--matterColorAnti);
  opacity: 0.2;
  width: 25px;
  height: 30px;
  width: 30px;
  top: calc(50% - 15px);
  border-radius: 50%;
}

.root :global(.slick-prev) {
  left: -30px;
  &:hover {
    opacity: 1;
  }
}

.root :global(.slick-next) {
  right: -30px;
  &:hover {
    opacity: 1;
  }
}

.root :global(.slick-next::before), 
.root :global(.slick-prev::before) {
  display: none;
}


.title {
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.root .listingCard {
  margin-left: 5px;
  margin-right: 5px;
}

.root .nameLogo {
  height: 45px;
  width: 45px;
  bottom: -22px;
  right: 10px;
}

.root .ownerUsername {
  right: 3px;
}


