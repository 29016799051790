@import '../../marketplace.css';
.root {
  @apply --marketplaceModalFormRootStyles;
  justify-content: flex-start;
  height: auto;
  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperText;
}

/* redesign styles */

.formInput {
  margin-top: 20px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  @media (--viewportMobile){
    margin-top:15px;
  }
}

.formInput label {
  display: none;
}

.formInput input {
  border-bottom: none;
  padding: 12px 20px;
  font-size: 18px;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  @media (--viewportMobile) {
    font-size: 16px;
  }
}

.inputContainer {
  position: relative;
}

.eyeIcon {
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.bottomWrapper {
  margin: 20px 0 0;
  @media (--viewportMobile) {
    margin: 15px 0 0;
  }
}

.bottomWrapper .loginButton {
  background: #FECD2A !important;
  color: #0D6786 !important;
  font-size: 18px;
  line-height: 48px;
  min-height: auto;
  border-radius: 5px;
  text-transform: none;
}

.bottomWrapper .bottomWrapperText {
  margin-top: 10px;
  margin-bottom: 0;
}

.bottomWrapper .bottomWrapperText span a {
  font-size: 18px;
  color: #00A3AD;
}

.loginWith {
  text-align: center;
}

.loginWith .text {
  color: #B2B2B2;
  font-size: 18px;
  display: block;
}

.loginWith .text h3 {
  font-size: 18px;
  text-align: center;
  border-bottom: 1px solid #E6E6E6;
  position: relative;
  margin-top: 0;
  font-weight: normal;
  margin-bottom: 30px;
  @media (--viewportMobile) {
    margin-bottom: 30px;
  }

}

.loginWith .text h3 span {
  background-color: white;
  position: relative;
  top: 10px;
  padding: 0 10px;
}

.loginWith .loginUsing {
  padding: 12px 20px;
  margin-bottom: 15px;
  border-radius: 8px;
}

.loginUsing:last-child {
  background: #E9413505;
}

.loginWith .loginUsing a {
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: #0D6786;
  text-decoration: none;
}

.loginWith .loginUsing .btnText {
  width: calc(100% - 24px)
}

.facebook {
  background: #1877F2 0 0 no-repeat padding-box;
  color: #FFFFFF;
}

.google {
  background: #FFFFFF 0 0 no-repeat padding-box;
  color: #000000
}

.googleBorder {
  border: 1px solid #E6E6E6;
}

.apple {
  background: #000000 0 0 no-repeat padding-box;
  color: #FFFFFF;
}

.signupButton {
  background: #0267860A !important;
  padding: 13px 20px;
  font-size: 18px !important;
  color: #4A4A4A !important;
  text-transform: initial !important;
  line-height: 22px !important;
  min-height: auto !important;
  font-weight: normal !important;
  border-radius: 5px;
}

.signupButton a {
  text-decoration: none !important;
}
