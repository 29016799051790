@import '../../marketplace.css';

.root {
}

.sectionHero {
  background-image: url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingpage/sectionHero@2x.jpg');
  background-image: image-set(
    url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingpage/sectionHero.jpg') 1x,
    url('https://dv0eqz2t0y9gj.cloudfront.net/drivelah/landingpage/sectionHero@2x.jpg') 2x
  );
  background-size: 100% 235px;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: scroll;
  min-height: 235px;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    background-position: center;
    height: 410px;
    background-size: 100% 410px;
  }

  @media (--viewportLarge) {
    background-attachment: fixed;
    background-size: cover;

    height: 680px;
  }
}

.heroTitle {
  font-size: 40px;
  line-height: 45px;
  color: var(--matterColorLight);
  text-shadow: 0px 2px 5px #0000004d;
  margin: 0;
  align-self: flex-start;
  margin-left: 30px;
  margin-bottom: 16px;
  margin-top: 110px;

  @media (--viewportLarge) {
    display: none;
  }
}

.heroTitleDesk {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 70px;
    line-height: 78px;
    margin-bottom: 20px;
    font-weight: var(--fontWeightBold);
  }
}
.heroPara{
  color: #FFFFFF;
}

.heroContent {
  background-color: #0aa3ad;
}

.heroContentMobile {
  display: flex;
  flex-direction: column;
  padding: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media (--viewportLarge) {
    display: none;
  }
}

.heroContentDesk {
  display: none;

  @media (--viewportLarge) {
    display: block;
    background-color: unset;
  }
}

.heroContentText {
  font-size: 20px;
  line-height: 22px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);
  margin-bottom: 22px;
}

.formWrapper {
  display: flex;
  align-items: center;
  padding: 35px 30px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)),
    linear-gradient(var(--marketplaceColor), var(--marketplaceColor));

  @media (--viewportLarge) {
    background-color: #0aa3ad;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1050px;
    border-radius: 16px;

    margin: 16px 16px 0;

    & > div {
      flex-basis: 40%;
    }

    & > div:first-child {
      flex-basis: 60%;
    }
  }

  @media (--viewportXLarge) {
    margin: 65px 0 0 125px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.row svg {
  margin-right: 10px;
  margin-top: 5px;
}

.sectionProvider {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 28px;

  & > div {
    flex-basis: calc(50% - 18px);
    max-width: calc(50% - 18px);
  }

  @media (--viewportLarge) {
    max-width: 430px;
    min-height: 100px;
    margin-top: -100px;
    background-color: var(--matterColorLight);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 16px 28px 0;
    margin-left: 16px;
  }

  @media (--viewportXLarge) {
    margin-left: 125px;
  }
}
.providerTitle {
  font-size: 12px;
  line-height: 14px;
  color: var(--marketplaceColorDark);
  margin-bottom: 10px;
}
.insuranceImage {
  height: auto;
  width: 100%;
}
.landTransport {
  height: auto;
  width: 100%;
}

.sectionStories {
  margin-top: 33px;

  @media (--viewportXLarge) {
    max-width: 1600px;
    margin: 0 auto;
  }
}

.formWrapperDesktop {
  width: 100%;
  height: 100%;
  @media (--viewportLarge) {
    background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)),
      linear-gradient(var(--marketplaceColor), var(--marketplaceColor));
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
  }
}

.mobileChannels {
  background-color: var(--marketplaceColorDark);

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopChannels {
  display: none;

  @media (--viewportLarge) {
    background-color: var(--marketplaceColorDark);
    display: block;
  }
}

.sectionCars {
  @media (--viewportXLarge) {
    max-width: 1600px;
    margin: 0 auto;
  }
}

.signUpTitle {
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  font-weight: var(--fontWeightBold);
  margin-bottom: 30px;

  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 36px;
  }
}
.signUpTitle span{
  color: #ffcd05;
  cursor: pointer;
}

.loginDesc {
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);
  margin: 0;

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 24px;
  }
}

.loadingWrapper {
  flex: 1;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loadingSpinner {
  stroke: var(--matterColorLight);
}
.remarks{
  display: block;
  margin-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #FFFFFF;
  
}
.paragraphMobile{
  display: block;
  @media (--viewportLarge) {
    display: none;
  }
}
.paragraphDesktop{
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}